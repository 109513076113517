import React from 'react';

import { useTranslation } from 'react-i18next';

export default function AdditionalInfoResumeSection(props) {
  const { t } = useTranslation();
  // Define the financial information
  const netWorth = props.addInfo.netWorth; //  
  const provincialNomination = props.addInfo.provincialNomination; //   (Yes/No)

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>{t("forms.title.addinfo")}</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputNetWorth" className="col-sm-3 col-form-label">
          {t("forms.formsquestion.personalInfo.netWorth")}
          </label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputNetWorth" value={netWorth} placeholder="N.A"/>
          </div>
          <label htmlFor="earningsHistory" className="col-sm-3 col-form-label">
          {t("forms.formsquestion.personalInfo.earningsHistory")}
          </label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="earningsHistory" value={props.addInfo.earningsHistory} placeholder="N.A"/>
          </div>
        </div>
      </div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="totalCurrentAssets" className="col-sm-3 col-form-label">
          {t("forms.formsquestion.personalInfo.totalCurrentAssets")}
          </label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="totalCurrentAssets" value={props.addInfo.totalCurrentAssets} placeholder="N.A"/>
          </div>
          <label htmlFor="inputNomination" className="col-sm-3 col-form-label">
          {t("forms.formsquestion.personalInfo.provincialNomination")} {t('countries.canada')} ?
          </label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputNomination" value={provincialNomination === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
          </div>
        </div>
      </div>
    </div>
  );
}
