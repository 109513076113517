import React, { useState, useEffect } from 'react';
import api from '../../../../../services/api';
import CountryShow from '../../../../general/countryShow';

import { useTranslation } from 'react-i18next';
import { canadaStatesConverter, countryName, educationLevel, generalField, languageConverter, quebecEducationLevel, translateYearOfStudy } from '../../../../../services/utils';
import CountryInput from '../../../../general/CountryInput';
import { getCanadaInstitutionName } from '../../../../../services/agents/RequestServices';
import InstitutionShow from '../../../../general/institutionShow';

export default function EducationInCountryInfoResumeSection(props) {

  const [provinces, setProvinces] = useState([]);

  const [visibleDetails, setVisibleDetails] = useState({});
  const { t } = useTranslation();

  const lang = localStorage.getItem('flouslang') || 'en';
      
  const toggleDetailVisibility = (index) => {
    setVisibleDetails(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  useEffect(() => {
      const fetchProvinces = async() => {
          const response = await api.get('/canada-provinces/');
          const provinceOptions = Object.entries(response.data).map(([key, val]) => ({
            value: key,
            label: val,
          }));
          setProvinces(provinceOptions);
      };
  
      fetchProvinces();
  }, []);
  
  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>{t("forms.title.educationin")} {t('countries.canada')} </div>
      <div className="form-group row">
        <label htmlFor="currentlyStudy" className="col-sm-3 col-form-label">{t("forms.formsquestion.educationInCountry.currentlyStudy")} {t('countries.canada')}</label>
        <div className="col-sm-3">
          <input type="text" className="form-control" id="currentlyStudy" value={props.currentlyStudy === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
        </div>
        <label htmlFor="educationInCountry" className="col-sm-3 col-form-label">{t("forms.formsquestion.educationInCountry.educationInCountry")} {t('countries.canada')}</label>
        <div className="col-sm-3">
          <input type="text" className="form-control" id="educationInCountry" value={props.educationInCountry === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
        </div>
      </div>

      {(props.educationInCountry === 'Yes' || props.currentlyStudy === 'Yes') && (
      <div className='mx-3'>
        <div className="form-group">
        <div>
          <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
            <div className='row'>
              <div className='col-sm col-6 mt-2 text-center'>{t("general.table.level")}</div>
              <div className='col-sm col-6 mt-2 text-center'>{t("general.table.name")}</div>
              <div className='col-sm col-6 mt-2 text-center'>{t("general.table.fulltime")}</div>
              <div className='col-sm col-6 mt-2 text-center'>{t("general.table.country")}</div>
              <div className='col-sm col-6 mt-2 text-center'>{t("general.table.province")}</div>
              <div className='col-sm col-6 mt-2 text-center'>{t("general.table.enddate")}</div>
              {props.show !==true && <div className="col-sm col-6 mt-2 text-center">{t("general.table.actions")}</div>}
            </div>
          </div>

          {props.allEducationInCountry.map((educationIn, index) => (
            <div key={index} className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px' }}>
              <div className='row'>
                <div className='col-sm col-6 mt-2 text-center'>{educationLevel(educationIn.degreeDiploma.degreeDiploma, lang)}</div>
                <div className='col-sm col-6 mt-2 text-center'><InstitutionShow value={educationIn.institution.institutionName} /></div>
                <div className='col-sm col-6 mt-2 text-center'>{educationIn.institution.fullTimeStudent === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`}</div>
                <div className='col-sm col-6 mt-2 text-center'><CountryShow value={educationIn.institution.country}/></div>
                {educationIn.institution.country === 'CAN' ? (
                  <div className='col-sm col-6 mt-2 text-center'>{provinces.find(province => province.value === educationIn.institution.provinceState)?.label || ''}</div>
                  ): (<div className='col-sm col-6 mt-2 text-center'><div className='col-sm col-6 mt-2 text-center'>{educationIn.institution.provinceState}</div></div>)}
                <div className='col-sm col-6 mt-2 text-center'>{educationIn.degreeDiploma.endDate}</div>
                {props.show !==true && <div className='col-sm col-6 mt-2 text-center'>
                  <button onClick={() => toggleDetailVisibility(index)} className="btn flous-bg-primary">
                    <i className="nav-icon fas fa-eye"></i>
                  </button>
                </div>}
              </div>
              {(visibleDetails[index] || props.show ===true) && (
              <div className='m-3'>
                <div className="form-group row">
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationInCountry.institutionName")}</div>
                  <div className="col-sm-3">
                    <p className="form-control" id="" ><InstitutionShow value={educationIn.institution.institutionName} /> </p>
                  </div>
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationInCountry.languageOfInstruction")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={languageConverter(educationIn.institution.languageOfInstruction)} placeholder="N.A"/>
                  </div>
                </div>
                <div className="form-group row">
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationInCountry.fullTimeStudent")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={educationIn.institution.fullTimeStudent === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
                  </div>
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationInCountry.generalFieldOfStudy")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={generalField(educationIn.institution.generalFieldOfStudy, lang)} placeholder="N.A"/>
                  </div>
                </div>
                <div className="form-group row">
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationInCountry.fieldOfStudy")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={educationIn.institution.fieldOfStudy} placeholder="N.A"/>
                  </div>
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationInCountry.studyDuration")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={translateYearOfStudy(educationIn.institution.studyDuration, lang)} placeholder="N.A"/>
                  </div>
                </div>
                <div className="form-group row">
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationInCountry.address")}</div>
                  <div className="col-sm-3">
                    <CountryInput countryCode={educationIn.institution.country} />
                  </div>
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationInCountry.provinceState")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={canadaStatesConverter(educationIn.institution.provinceState) || ''} placeholder="N.A"/>
                  </div>
                </div>
                <div className="form-group row">
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationInCountry.city")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={educationIn.institution.city} placeholder="N.A"/>
                  </div>
                </div>

                <div className="form-group row">
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationInCountry.degreeDiploma")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={educationLevel(educationIn.degreeDiploma.degreeDiploma, lang)} placeholder="N.A"/>
                  </div>
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationInCountry.prgDegreeDiploma")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={educationIn.degreeDiploma.prgDegreeDiploma? quebecEducationLevel(educationIn.degreeDiploma.prgDegreeDiploma): 'N.A'} placeholder="N.A"/>
                  </div>
                </div>

                <div className="form-group row">
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationInCountry.pgwpEligibility")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={educationIn.degreeDiploma.pgwpEligibility === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
                  </div>
                </div>

                <div className="form-group row">
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationInCountry.startDate")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={educationIn.degreeDiploma.startDate} placeholder="N.A"/>
                  </div>
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationInCountry.endDate")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={educationIn.degreeDiploma.endDate} placeholder="N.A"/>
                  </div>
                </div>

                <div className="form-group row">
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationInCountry.equivalentProcessCompleted")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={educationIn.diplomaRecognition.equivalentProcessCompleted === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
                  </div>
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationInCountry.provinceProcessed")}</div>
                  <div className="col-sm-3">
                     {educationIn.provinceProcessed.length === 0? (
                        <>
                          <input type="text" className="form-control" id="" placeholder="N.A"/>
                        </>
                     ):(
                      <>
                      {educationIn.provinceProcessed.map((province, i) => (
                        <input type="text" className="form-control mb-1" id="" value={canadaStatesConverter(province.provinceState)} placeholder="N.A"/>
                      ))}
                      </>
                     )}
                  
                  </div>
                </div>
              </div>
              )}
            </div>
          ))}
        </div>
        </div>
      </div>
      )}
    </div>
  );
}
