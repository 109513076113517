import React, { useState } from 'react';
import FlagComponent from '../../general/FlagComponent';
import DeletionModal from '../../general/DeletionModal';
import { useTranslation } from 'react-i18next';

const UserResultItem = ({ item, deleteEvaluation }) => {

    const pageType = "result"
    const { t } = useTranslation();
    const [deletionModalOpen, setDeletionModalOpen] = useState(false);

    const handleContinue = () => {
        deleteEvaluation(item.code);
    }
  
    const handleCloseDeletionModal = () =>{
      setDeletionModalOpen(false);
    }

    return (
        <div className='card p-3 mb-3 mx-1' style={{ borderRadius: '15px' }}>
            <div className='row'>
                <div className='col-sm col-6 mt-2 text-center'>
                    <FlagComponent cca3={item.country_cca3} />
                </div>
                <div className='col-sm-3 col-6 mt-2 text-center'>{item.creation_date}</div>
                <div className='col-sm-3 col-12 mt-2 text-center'>{item.title}</div>
                <div className='col-sm-3 col-12 mt-2 text-center'>
                    <a href={`/user/detail/${pageType}/${item.code}`} className="btn flous-bg-menu mr-2"><i className="nav-icon fas fa-eye"></i></a>
                    <button type="button" className="btn btn-secondary mr-2" onClick={()=>setDeletionModalOpen(true)}>
                        <i className="nav-icon fas fa-trash"></i>
                    </button>
                </div>
            </div>
            <DeletionModal
                isOpen={deletionModalOpen}
                bodyText={t("modal.deletionModal.results")}
                onContinue={handleContinue}
                onClose={handleCloseDeletionModal}
            />
        </div>
        );
};

export default UserResultItem;
