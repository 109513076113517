import React, { useState, useEffect} from 'react';
import PersonalInformation from './sections/section_1_PersonalInformation';
import Residences from './sections/section_3_Residences';
import LanguageProficiency from './sections/section_4_LanguageProficiency';
import EducationInCountry from './sections/section_5_EducationInCountry';
import EducationOutsideCountry from './sections/section_6_EducationOutsideCountry';
import ProfessionalExperienceSection from './sections/section_7_ProfessionalExperience';
import ProfessionalExpertiseSection from './sections/section_8_ProfessionalExpertise';
import JobOffer from './sections/section_9_JobOffer';
// import EntrepreunarialExperience from './sections/section_10_EntrepreneurialExperience';
import EntrepreunarialExperience from './sections/section_10_New_EntrepreneurialExperience';
import SummaryPage from './sections/summary_page';
import { usePrincipalFormStates } from './data/principalStates';
import { usePartnerFormStates } from './data/partnerStates';
import { fetchLanguageProficiency } from '../../../services/levelconvertertoscore';
import { createNewForm, getExistingFormData, UpdateForm } from '../../../services/users/FormsServices';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import GoToBackButton from '../../general/GoBackLink';
import FamilyMembersInfoSection from './sections/section_2_FamilyMembers';
import CompletionFormModal from '../Modals/completionFormModal';
import LoadingOverlay from '../../general/LoadingOverlay';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import { allowedMaritalStatuses } from '../../../services/utils';

export default function CreateEditFormPage () {

  const {newform, rawSurveyCode} = useParams();
  const [formCode, setFormCode] = useState(rawSurveyCode)
  
  const { user } = useOutletContext();
  const navigate = useNavigate();

  const [formCompletionModal, setFormCompeltionModal] = useState(false)

  const [activeBtn, setActiveBtn] = useState('Personal Information'); 
  const [actor, setActor] = useState('Principal');
  const [evaluationCountry, setevaluationCountry] = useState("");
  const [formTitle, setFormTitle] = useState("");
  const [isSameResidence, setIsSameResidence] = useState({
    isSamecurrentResidence: '',
    isSamepreviousAddress: ''
  });
  const [isSameEExperience, setIsSameEExperience] = useState('');
  const [isCoApplication, setIsCoApplication] = useState();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [updateForm, setUpdateForm] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [needPartnerInformation, setNeedPartnerInformation] = useState(false);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const lang = localStorage.getItem('flouslang') || 'en';

  const { t } = useTranslation();

  const principalFormStates = usePrincipalFormStates();
  const partnerFormStates = usePartnerFormStates();

  
  const formStates = actor === 'Principal' ? principalFormStates : partnerFormStates;
  
  const {
    personalInformation, setPersonalInformation,
    children, setChildren,
    familyInfo, setFamilyInfo,
    familyMembers, setFamilyMembers,
    residences, setResidences,
    languageProficiency, setLanguageProficiency,
    educationInCountry, setEducationInCountry,
    specialProgram, setSpecialProgram,
    allEducationInCountry, setAllEducationInCountry,
    educationOutsideCountry, setEducationOutsideCountry,
    allEducationOutsideCountry, setAllEducationOutsideCountry,
    professionalExperience, setProfessionalExperience,
    allProfessionalExperience, setAllProfessionalExperience,
    professionalExpertise, setProfessionalExpertise,
    jobOffer, setJobOffer,
    entrepreunarialExperience, setEntrepreunarialExperience,
    allEntrepreneurialExperience, setAllEntrepreneurialExperience,
    summary, setSummary,
    
  } = formStates;


  useEffect(() =>{
    let isMounted = true;
    setLoading(true);

    const openForm = async () => {
      try {
        if (isMounted) {
          if (newform === 'new'){
            const response = await createNewForm('Canada');
            setFormCode(response.data['raw_survey_code']);
  
            if (response && [200, 201].includes(response.status)) {
              try{
                await setFormData(response);
              } catch (error) {
                setLoading(false);
                isMounted = false;
                if (error.response && [400, 500].includes(error.response.status)){
                  toast.error(error.response.data.error);
                }
              }
            } else { return;}
          
          }else {
            const response = await getExistingFormData(rawSurveyCode);
  
            if (response && [200, 201].includes(response.status)) {
              try{
                await setFormData(response);
                isMounted = false;
              } catch (error) {
                setLoading(false);
                isMounted = false;
                if (error.response && [400, 500].includes(error.response.status)){
                  toast.error(error.response.data.error);
                }
              }
            } else { return;}
  
          }
        
          setLoading(false);
  
        }
      } catch (error) {
        if (isMounted){
          setLoading(false);
          if (error.response && [400, 500].includes(error.response.status)){
            toast.error(error.response.data.error);
          }
        }
      }
      
    }

    if (window.history.length <= 1) {
      navigate(-1);
    } else {
      openForm();
    }

  }, []);

  useEffect(() => {
    // Sauvegarde des données selon l'acteur actuel (Principal ou Partner)
    if (actor === 'Principal') {
      principalFormStates.setPersonalInformation(personalInformation);
      principalFormStates.setFamilyMembers(familyMembers);
      principalFormStates.setChildren(children);
      principalFormStates.setFamilyInfo(familyInfo);
      principalFormStates.setResidences(residences);
      principalFormStates.setLanguageProficiency(languageProficiency);
      principalFormStates.setEducationInCountry(educationInCountry);
      principalFormStates.setSpecialProgram(specialProgram);
      principalFormStates.setAllEducationInCountry(allEducationInCountry);
      principalFormStates.setEducationOutsideCountry(educationOutsideCountry);
      principalFormStates.setAllEducationOutsideCountry(allEducationOutsideCountry);
      principalFormStates.setProfessionalExperience(professionalExperience);
      principalFormStates.setAllProfessionalExperience(allProfessionalExperience);
      principalFormStates.setProfessionalExpertise(professionalExpertise);
      principalFormStates.setJobOffer(jobOffer);
      principalFormStates.setEntrepreunarialExperience(entrepreunarialExperience);
      principalFormStates.setAllEntrepreneurialExperience(allEntrepreneurialExperience);
      principalFormStates.setSummary(summary);

      setIsCoApplication(principalFormStates.personalInformation.personalDetail.personIncluded === 'Yes' ? true : false );
      setNeedPartnerInformation(allowedMaritalStatuses.includes(principalFormStates.personalInformation.personalDetail.maritalStatus));
    } 
    if (actor === 'Partner') {
      // alert("Here to update partnerData")
      partnerFormStates.setPersonalInformation(personalInformation);
      partnerFormStates.setFamilyMembers(familyMembers);
      partnerFormStates.setChildren(children);
      partnerFormStates.setFamilyInfo(familyInfo);
      partnerFormStates.setResidences(residences);
      partnerFormStates.setLanguageProficiency(languageProficiency);
      partnerFormStates.setEducationInCountry(educationInCountry);
      partnerFormStates.setSpecialProgram(specialProgram);
      partnerFormStates.setAllEducationInCountry(allEducationInCountry);
      partnerFormStates.setEducationOutsideCountry(educationOutsideCountry);
      partnerFormStates.setAllEducationOutsideCountry(allEducationOutsideCountry);
      partnerFormStates.setProfessionalExperience(professionalExperience);
      partnerFormStates.setAllProfessionalExperience(allProfessionalExperience);
      partnerFormStates.setProfessionalExpertise(professionalExpertise);
      partnerFormStates.setJobOffer(jobOffer);
      partnerFormStates.setEntrepreunarialExperience(entrepreunarialExperience);
      partnerFormStates.setAllEntrepreneurialExperience(allEntrepreneurialExperience);
      partnerFormStates.setSummary(summary);

    }

  }, [needPartnerInformation, actor, isCoApplication, formTitle, evaluationCountry, personalInformation, residences, languageProficiency, 
    educationInCountry, specialProgram, allEducationInCountry, educationOutsideCountry, allEducationOutsideCountry, 
    professionalExperience, professionalExpertise, jobOffer, entrepreunarialExperience, allEntrepreneurialExperience, 
    allProfessionalExperience, partnerFormStates, principalFormStates, familyMembers, children, familyInfo, summary]);

  useEffect(() => {
    if (isDataLoaded) {
      checkSectionsValidity();
    }

    if (isDataLoaded && updateForm) {
      checkAllSectionValidatedAndSave();
    }
}, [isDataLoaded, updateForm, principalFormStates.summary, partnerFormStates.summary]);

  // Get the user back when he write the URL manually
  /*
  
  useEffect(()=>{
    if ((isCoApplication && (allSummaryTrue(principalFormStates.summary) && allSummaryTrue(partnerFormStates.summary))) ||
        (!isCoApplication && allSummaryTrue(principalFormStates.summary))) {

        if (user.role === 'Customer'){
          if (user.self_analyst){
            navigate(`/user/forms`, {replace: true});
          } else {
            navigate(`/client/forms`, {replace: true});
          }
          window.location.reload();
        } else {
          const userType = user.role === 'AssociateAdmin' ?(user.type_package === 'Professional'? 'professional':'enterprise'):'agent'
          navigate(`/${userType}/customers`, {replace: true});
        }
    }
  }, [partnerFormStates, principalFormStates, isCoApplication, navigate, user]);
  */

  const extractPrincipalData = () => {
    return {
      personalInformation: principalFormStates.personalInformation,
      familyMembers: principalFormStates.familyMembers,
      children: principalFormStates.children,
      familyInfo: principalFormStates.familyInfo,
      residences: principalFormStates.residences,
      languageProficiency: principalFormStates.languageProficiency,
      educationInCountry: principalFormStates.educationInCountry,
      specialProgram: principalFormStates.specialProgram,
      allEducationInCountry: principalFormStates.allEducationInCountry,
      educationOutsideCountry: principalFormStates.educationOutsideCountry,
      allEducationOutsideCountry: principalFormStates.allEducationOutsideCountry,
      professionalExperience: principalFormStates.professionalExperience,
      allProfessionalExperience: principalFormStates.allProfessionalExperience,
      professionalExpertise: principalFormStates.professionalExpertise,
      jobOffer: principalFormStates.jobOffer,
      entrepreunarialExperience: principalFormStates.entrepreunarialExperience,
      allEntrepreneurialExperience: principalFormStates.allEntrepreneurialExperience,
      summary: principalFormStates.summary,
    };
  };

  const extractPartnerData = () => {
    return {
      personalInformation: partnerFormStates.personalInformation,
      familyMembers: partnerFormStates.familyMembers,
      children: partnerFormStates.children,
      familyInfo: partnerFormStates.familyInfo,
      residences: partnerFormStates.residences,
      languageProficiency: partnerFormStates.languageProficiency,
      educationInCountry: partnerFormStates.educationInCountry,
      specialProgram: partnerFormStates.specialProgram,
      allEducationInCountry: partnerFormStates.allEducationInCountry,
      educationOutsideCountry: partnerFormStates.educationOutsideCountry,
      allEducationOutsideCountry: partnerFormStates.allEducationOutsideCountry,
      professionalExperience: partnerFormStates.professionalExperience,
      allProfessionalExperience: partnerFormStates.allProfessionalExperience,
      professionalExpertise: partnerFormStates.professionalExpertise,
      jobOffer: partnerFormStates.jobOffer,
      entrepreunarialExperience: partnerFormStates.entrepreunarialExperience,
      allEntrepreneurialExperience: partnerFormStates.allEntrepreneurialExperience,
      summary: partnerFormStates.summary,
    };
  };


  const setFormData = async (response) =>{
    // console.log(response.data.data)
    setevaluationCountry(response.data.data.evaluationCountry);
    setFormTitle(response.data.data.formTitle);
    setIsCoApplication(response.data.data.isCoApplication);
    setIsSameEExperience(response.data.data.isSameEExperience);
    setIsSameResidence(response.data.data.isSameResidence);

    principalFormStates.setPersonalInformation(response.data.data.principalApplicantData.personalInformation);
    principalFormStates.setResidences(response.data.data.principalApplicantData.residences);
    principalFormStates.setLanguageProficiency(response.data.data.principalApplicantData.languageProficiency);
    principalFormStates.setEducationInCountry(response.data.data.principalApplicantData.educationInCountry);
    principalFormStates.setAllEducationInCountry(response.data.data.principalApplicantData.allEducationInCountry);
    principalFormStates.setEducationOutsideCountry(response.data.data.principalApplicantData.educationOutsideCountry);
    principalFormStates.setAllEducationOutsideCountry(response.data.data.principalApplicantData.allEducationOutsideCountry);
    principalFormStates.setProfessionalExperience(response.data.data.principalApplicantData.professionalExperience);
    principalFormStates.setAllProfessionalExperience(response.data.data.principalApplicantData.allProfessionalExperience);
    principalFormStates.setProfessionalExpertise(response.data.data.principalApplicantData.professionalExpertise);
    principalFormStates.setJobOffer(response.data.data.principalApplicantData.jobOffer);
    principalFormStates.setEntrepreunarialExperience(response.data.data.principalApplicantData.entrepreunarialExperience);
    principalFormStates.setAllEntrepreneurialExperience(response.data.data.principalApplicantData.allEntrepreneurialExperience);
    principalFormStates.setSummary(response.data.data.principalApplicantData.summary);
    principalFormStates.setSpecialProgram(response.data.data.principalApplicantData.specialProgram);
    principalFormStates.setFamilyMembers(response.data.data.principalApplicantData.familyMembers);
    principalFormStates.setChildren(response.data.data.principalApplicantData.children);
    principalFormStates.setFamilyInfo(response.data.data.principalApplicantData.familyInfo);

    partnerFormStates.setPersonalInformation(response.data.data.principalApplicantPartnerData.personalInformation);
    partnerFormStates.setResidences(response.data.data.principalApplicantPartnerData.residences);
    partnerFormStates.setLanguageProficiency(response.data.data.principalApplicantPartnerData.languageProficiency);
    partnerFormStates.setEducationInCountry(response.data.data.principalApplicantPartnerData.educationInCountry);
    partnerFormStates.setAllEducationInCountry(response.data.data.principalApplicantPartnerData.allEducationInCountry);
    partnerFormStates.setEducationOutsideCountry(response.data.data.principalApplicantPartnerData.educationOutsideCountry);
    partnerFormStates.setAllEducationOutsideCountry(response.data.data.principalApplicantPartnerData.allEducationOutsideCountry);
    partnerFormStates.setProfessionalExperience(response.data.data.principalApplicantPartnerData.professionalExperience);
    partnerFormStates.setAllProfessionalExperience(response.data.data.principalApplicantPartnerData.allProfessionalExperience);
    partnerFormStates.setProfessionalExpertise(response.data.data.principalApplicantPartnerData.professionalExpertise);
    partnerFormStates.setJobOffer(response.data.data.principalApplicantPartnerData.jobOffer);
    partnerFormStates.setEntrepreunarialExperience(response.data.data.principalApplicantPartnerData.entrepreunarialExperience);
    partnerFormStates.setAllEntrepreneurialExperience(response.data.data.principalApplicantPartnerData.allEntrepreneurialExperience);
    partnerFormStates.setSummary(response.data.data.principalApplicantPartnerData.summary);
    partnerFormStates.setSpecialProgram(response.data.data.principalApplicantPartnerData.specialProgram);
    partnerFormStates.setFamilyMembers(response.data.data.principalApplicantPartnerData.familyMembers);
    partnerFormStates.setChildren(response.data.data.principalApplicantPartnerData.children);
    partnerFormStates.setFamilyInfo(response.data.data.principalApplicantPartnerData.familyInfo);

    setIsDataLoaded(true);
    setNeedPartnerInformation(allowedMaritalStatuses.includes(response.data.data.principalApplicantData.personalInformation.personalDetail.maritalStatus));
  }

  const allSummaryTrue = (summary) => {
    return Object.entries(summary).every(([key, value]) => {
      return key === "personIncluded" || value === true;
    });
  };

  const checkSectionsValidity = () => {
  
    if (needPartnerInformation) {
      if (allSummaryTrue(principalFormStates.summary) && allSummaryTrue(partnerFormStates.summary)) {
        setIsCompleted(true);
      } else {
        setIsCompleted(false);
      }
    } else {
      if (allSummaryTrue(principalFormStates.summary)) {
        setIsCompleted(true);
      } else {
        setIsCompleted(false);
      }
    }
  }

  const checkAllSectionValidatedAndSave = () =>{
    
    if (needPartnerInformation) {
      if (allSummaryTrue(principalFormStates.summary) && allSummaryTrue(partnerFormStates.summary)) {
        setFormCompeltionModal(true);
      } else {
        setFormCompeltionModal(false);
        handleSavedData();
      }
    } else {
      if (allSummaryTrue(principalFormStates.summary)) {
        setFormCompeltionModal(true);
      } else {
        setFormCompeltionModal(false);
        handleSavedData();
      }
    }
  }

  const handleCloseAndContinueCompletionFormModal = async () => {
    setFormCompeltionModal(false);
    await handleSavedData();
    
    if (user.role === 'Customer'){
      if (user.self_analyst){
        navigate(`/user/forms`, {replace: true});
      } else {
        navigate(`/client/forms`, {replace: true});
      }
    } else {
      sessionStorage.setItem('reloadAfterNavigate', 'true');
      navigate(-1);
    }
  }

  const handleCancel = () =>{
    setFormCompeltionModal(false);
  }

  const handleSavedData = async () =>{
    // setLoading(true);
    try {

      setIsCoApplication(principalFormStates.personalInformation.personalDetail.personIncluded === 'Yes' ? true : false );
      
      principalFormStates.setLanguageProficiency(
        await fetchLanguageProficiency(principalFormStates.languageProficiency)
      );
      partnerFormStates.setLanguageProficiency(
        await fetchLanguageProficiency(partnerFormStates.languageProficiency)
      );
      
      const principalData = extractPrincipalData();
      const partnerData = extractPartnerData();
      
      const data = {
        formTitle:formTitle,
        evaluationCountry: evaluationCountry,
        isSameResidence: isSameResidence,
        isSameEExperience: isSameEExperience,
        isCoApplication: isCoApplication,
        needPartnerInformation: needPartnerInformation,
        principalApplicantData: principalData,
        principalApplicantPartnerData: partnerData,
      }

      const response = await UpdateForm({ 
        code: formCode,
        data: data,
      });

      if (response && [200, 201].includes(response.status)){
        //setLoading(false);
        // toast.success(t('forms.formsquestion.toast.saveForm'));
        // window.location.reload();
      }
      // setMessage(response.data.message);

    } catch (error) {
      // setLoading(false);
      if (error.response && [400, 500].includes(error.response.status)){
        // toast.error(error.response.data.error);
      }
    }

  }

  const handleValidationChange = (key, isValid, sectionActor, update) =>{
    
    if (sectionActor === 'Principal') {
      principalFormStates.setSummary(prevStatus => ({
        ...prevStatus,
        [key]: isValid
      }));
    }
    else {
      partnerFormStates.setSummary(prevStatus => ({
        ...prevStatus,
        [key]: isValid
      }));
    }
    setUpdateForm(update);
  }

  const handleBtnClick = (section, sectionActor) => {
    
    setActor(sectionActor);
    setActiveBtn(section);

    if (evaluationCountry || section === 'Personal Information' || section === 'Summary') {
      // toast.sucess('Error');
    } else {
      // toast.error('Please select the evaluation country !!');
    }
  };

  const renderButton = (label, btnName, isDisabled, summaryKey, sectionActor) => (
    <button
      className={`text-left mb-2 step-btn btn btn-light ${activeBtn === btnName ? 'flous-bg-secondary text-bold text-black' : ''} btn-block`}
      onClick={() => {
        handleBtnClick(btnName, sectionActor)
      }}
      disabled= {isDisabled}
    >
      <div className='row'>
        <div className='col-10'>{label}</div>
        <div className='col-2'>
          {sectionActor === 'Principal'?
            <>
            {principalFormStates.summary[summaryKey] ? (
              <img src={`${process.env.PUBLIC_URL}/flous/svg/check_on.svg`} alt="" />
            ) : (
              <>
              {/*<img src={`${process.env.PUBLIC_URL}/flous/svg/check_off.svg`} alt="" />*/}
              </>
            )}
            </>
            :
            <>
            {partnerFormStates.summary[summaryKey] ? (
              <img src={`${process.env.PUBLIC_URL}/flous/svg/check_on.svg`} alt="" />
            ) : (
              <>
              {/*<img src={`${process.env.PUBLIC_URL}/flous/svg/check_off.svg`} alt="" />*/}
              </>
            )}
            </>
          }
          
        </div>
      </div>
    </button>
  );


  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }
  
  // Fonction pour rendre le contenu en fonction de activeSection
  const renderContent = () => {
    switch (activeBtn) {
      case 'Personal Information':
        return <PersonalInformation actor='Principal'
          sendNext={handleBtnClick}
          goBack={handleBtnClick}
          sendevaluationCountry={setevaluationCountry}
          evaluationCountry={evaluationCountry}
          sendFormTitle={setFormTitle}
          formTitle={formTitle}
          formCode={formCode}
          sendPersonalInformation={setPersonalInformation}
          personalInformation={principalFormStates.personalInformation}
          onValidate={handleValidationChange}
        />;
      case 'Family Members':
        return <FamilyMembersInfoSection actor='Principal'
          sendNext={handleBtnClick}
          goBack={handleBtnClick}
          evaluationCountry={evaluationCountry}

          sendChildren={setChildren}
          children={principalFormStates.children}

          sendFamilyInfo={setFamilyInfo}
          familyInfo={principalFormStates.familyInfo}
          
          familyMembers={familyMembers}
          sendFamilyMembers={setFamilyMembers}
          onValidate={handleValidationChange}
          />;
      case 'Residences':
        return <Residences actor='Principal'
          sendIsSameResidence={setIsSameResidence} isSameResidence={isSameResidence}
          sendNext={handleBtnClick}
          goBack={handleBtnClick}
          evaluationCountry={evaluationCountry}
          sendResidences={setResidences}
          residences={principalFormStates.residences}
          onValidate={handleValidationChange}

          principalCurrentResidence={principalFormStates.residences.currentResidence}
        />;
      case 'Language Proficiency':
        return <LanguageProficiency actor='Principal'
          sendNext={handleBtnClick}
          goBack={handleBtnClick}
          sendLanguageProficiency={setLanguageProficiency}
          languageProficiency={principalFormStates.languageProficiency}
          onValidate={handleValidationChange}
        />;
      case 'Education in country':
        return <EducationInCountry actor='Principal'
          sendNext={handleBtnClick}
          goBack={handleBtnClick}
          evaluationCountry={evaluationCountry}
          sendEducationInCountry={setEducationInCountry}
          educationInCountry={principalFormStates.educationInCountry}
          sendSpecialProgram={setSpecialProgram}
          specialProgram={principalFormStates.specialProgram}
          onValidate={handleValidationChange}
          //
          sendAllEducationInCountry={setAllEducationInCountry}
          allEducationInCountry={principalFormStates.allEducationInCountry}
        />;
      case 'Education outside country':
        return <EducationOutsideCountry actor='Principal'
          sendNext={handleBtnClick}
          goBack={handleBtnClick}
          evaluationCountry={evaluationCountry}
          sendEducationOutsideCountry={setEducationOutsideCountry}
          educationOutsideCountry={principalFormStates.educationOutsideCountry}
          onValidate={handleValidationChange}
          //
          sendAllEducationOutsideCountry={setAllEducationOutsideCountry}
          allEducationOutsideCountry={principalFormStates.allEducationOutsideCountry}
        />;
      case 'Professional Experience':
        return <ProfessionalExperienceSection actor='Principal'
          sendNext={handleBtnClick}
          goBack={handleBtnClick}
          evaluationCountry={evaluationCountry}
          sendProfessionalExperience={setProfessionalExperience}
          professionalExperience={principalFormStates.professionalExperience}
          onValidate={handleValidationChange}
          sendAllProfessionalExperience={setAllProfessionalExperience}
          allProfessionalExperience={principalFormStates.allProfessionalExperience}
        />;
      case 'Professional Expertise':
        return <ProfessionalExpertiseSection actor='Principal'
          sendNext={handleBtnClick}
          goBack={handleBtnClick}
          sendProfessionalExpertise={setProfessionalExpertise}
          professionalExpertise={principalFormStates.professionalExpertise}
          onValidate={handleValidationChange}
        />;
      case 'Job offer':
        return <JobOffer actor='Principal'
          sendNext={handleBtnClick}
          goBack={handleBtnClick}
          evaluationCountry={evaluationCountry}
          sendJobOffer={setJobOffer}
          jobOffer={principalFormStates.jobOffer}
          onValidate={handleValidationChange}
        />;
      case 'Entrepreneurial Experience':
        return <EntrepreunarialExperience actor='Principal' 
          sendNext={handleBtnClick}
          goBack={handleBtnClick}
          sendIsSameEExperience={setIsSameEExperience} isSameEExperience={isSameEExperience}
          evaluationCountry={evaluationCountry}
          sendEntrepreunarialExperience={setEntrepreunarialExperience}
          entrepreunarialExperience={principalFormStates.entrepreunarialExperience}
          onValidate={handleValidationChange}
          
          sendAllEntrepreneurialExperience={setAllEntrepreneurialExperience}
          allEntrepreneurialExperience={principalFormStates.allEntrepreneurialExperience}

          principalEE={principalFormStates.entrepreunarialExperience}
        />;
      case 'Summary':
        return <SummaryPage actor='Principal'
          sendNext={handleBtnClick}
          goBack={handleBtnClick}
          summary={principalFormStates.summary}
          isCompleted={isCompleted}
          onSave={checkAllSectionValidatedAndSave}
          isCoApplication={isCoApplication}
          needPartnerInformation={needPartnerInformation}
        />;

      // Partner Start Sections
      case 'Personal Information Partner':
        return <PersonalInformation actor='Partner'
          sendNext={handleBtnClick}
          goBack={handleBtnClick}
          sendevaluationCountry={setevaluationCountry}
          evaluationCountry={evaluationCountry}
          sendFormTitle={setFormTitle}
          formTitle={formTitle}
          formCode={formCode}
          sendPersonalInformation={setPersonalInformation}
          personalInformation={partnerFormStates.personalInformation}
          onValidate={handleValidationChange}
        />;
      case 'Family Members Partner':
        return <FamilyMembersInfoSection actor='Partner'
          sendNext={handleBtnClick}
          goBack={handleBtnClick}
          evaluationCountry={evaluationCountry}

          sendChildren={setChildren}
          children={partnerFormStates.children}

          sendFamilyInfo={setFamilyInfo}
          familyInfo={partnerFormStates.familyInfo}
          
          familyMembers={partnerFormStates.familyMembers}
          sendFamilyMembers={setFamilyMembers}
          onValidate={handleValidationChange}
          />;
      case 'Residences Partner':
        return <Residences actor='Partner'
          sendIsSameResidence={setIsSameResidence} isSameResidence={isSameResidence}
          sendNext={handleBtnClick}
          goBack={handleBtnClick}
          evaluationCountry={evaluationCountry}
          sendResidences={setResidences}
          residences={partnerFormStates.residences}
          onValidate={handleValidationChange}

          principalCurrentResidence={principalFormStates.residences.currentResidence}
        />;
      case 'Language Proficiency Partner':
        return <LanguageProficiency actor='Partner'
          sendNext={handleBtnClick}
          goBack={handleBtnClick}
          sendLanguageProficiency={setLanguageProficiency}
          languageProficiency={partnerFormStates.languageProficiency}
          onValidate={handleValidationChange}
        />;
      case 'Education in country Partner':
        return <EducationInCountry actor='Partner'
          sendNext={handleBtnClick}
          goBack={handleBtnClick}
          evaluationCountry={evaluationCountry}
          sendEducationInCountry={setEducationInCountry}
          educationInCountry={partnerFormStates.educationInCountry}
          sendSpecialProgram={setSpecialProgram}
          specialProgram={partnerFormStates.specialProgram}
          onValidate={handleValidationChange}
          //
          sendAllEducationInCountry={setAllEducationInCountry}
          allEducationInCountry={partnerFormStates.allEducationInCountry}
        />;
      case 'Education outside country Partner':
        return <EducationOutsideCountry actor='Partner'
          sendNext={handleBtnClick}
          goBack={handleBtnClick}
          evaluationCountry={evaluationCountry}
          sendEducationOutsideCountry={setEducationOutsideCountry}
          educationOutsideCountry={partnerFormStates.educationOutsideCountry}
          onValidate={handleValidationChange}
          //
          sendAllEducationOutsideCountry={setAllEducationOutsideCountry}
          allEducationOutsideCountry={allEducationOutsideCountry}
        />;
      case 'Professional Experience Partner':
        return <ProfessionalExperienceSection actor='Partner'
          sendNext={handleBtnClick}
          goBack={handleBtnClick}
          evaluationCountry={evaluationCountry}
          sendProfessionalExperience={setProfessionalExperience}
          professionalExperience={partnerFormStates.professionalExperience}
          onValidate={handleValidationChange}
          sendAllProfessionalExperience={setAllProfessionalExperience}
          allProfessionalExperience={partnerFormStates.allProfessionalExperience}
        />;
      case 'Professional Expertise Partner':
        return <ProfessionalExpertiseSection actor='Partner'
          sendNext={handleBtnClick}
          goBack={handleBtnClick}
          sendProfessionalExpertise={setProfessionalExpertise}
          professionalExpertise={partnerFormStates.professionalExpertise}
          onValidate={handleValidationChange}
        />;
      case 'Job offer Partner':
        return <JobOffer actor='Partner'
          sendNext={handleBtnClick}
          goBack={handleBtnClick}
          evaluationCountry={evaluationCountry}
          sendJobOffer={setJobOffer}
          jobOffer={partnerFormStates.jobOffer}
          onValidate={handleValidationChange}
        />;
      case 'Entrepreneurial Experience Partner':
        return <EntrepreunarialExperience actor='Partner' 
          sendNext={handleBtnClick}
          goBack={handleBtnClick}
          sendIsSameEExperience={setIsSameEExperience} isSameEExperience={isSameEExperience}
          evaluationCountry={evaluationCountry}
          sendEntrepreunarialExperience={setEntrepreunarialExperience}
          entrepreunarialExperience={partnerFormStates.entrepreunarialExperience}
          onValidate={handleValidationChange}
          
          sendAllEntrepreneurialExperience={setAllEntrepreneurialExperience}
          allEntrepreneurialExperience={partnerFormStates.allEntrepreneurialExperience}

          principalEE={principalFormStates.entrepreunarialExperience}
        />;
      case 'Summary Partner':
        return <SummaryPage actor='Partner'
          sendNext={handleBtnClick}
          goBack={handleBtnClick}
          summary={partnerFormStates.summary}
          isCompleted={isCompleted}
          onSave={checkAllSectionValidatedAndSave}
          isCoApplication={isCoApplication}
          needPartnerInformation={needPartnerInformation}
        />;
      case 'Partner':
        return <div>You must complete the form with your partner's data.</div>;
      default:
        return null;
    }
  };

  return (
    <>
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="mb-2">
            <GoToBackButton />
            <div className="col-sm-6">
              <h2 className="flous-police-one flous-input-color m-3" >{t("client.forms.edit.title")}</h2>
            </div>
          </div>
        </div>
      </div>
      {/* /.content-header */}

      <div className="content">
        <div className="container-fluid">
          {/* Small boxes (Stat box) */}
          <div className="row">
            <div className="col-md-4 order-2 order-md-1"> {/* d-none d-md-block : If we want to hide the section on mobile*/}
              <div className='card p-4 elevation-2 mx-2' style={{ borderRadius: '15px' }}>
                <h4 className='mb-4 flous-police-one'>{t("client.forms.edit.sections")} {t("forms.sections.principalTitle")} </h4>
                {renderButton(`${t("client.forms.edit.sectionslist.personalInformation")}`, 'Personal Information', activeBtn !== 'Personal Information', 'personalInformation', 'Principal')}
                {renderButton(`${t("client.forms.edit.sectionslist.familyMembers")}`, 'Family Members', activeBtn !== 'Family Members', 'familyMembers', 'Principal')}
                {renderButton(`${t("client.forms.edit.sectionslist.residences")}`, 'Residences', activeBtn !== 'Residences', 'residences', 'Principal')} {/* evaluationCountry === '' Instead of true or false */}
                {renderButton(`${t("client.forms.edit.sectionslist.languageProficiency")}`, 'Language Proficiency', activeBtn !== 'Language Proficiency', 'languageProficiency', 'Principal')}
                {renderButton(`${t("client.forms.edit.sectionslist.educationInCountry")} ${evaluationCountry === '' ? 'country' : t('countries.canada')}`, 'Education in country', activeBtn !== 'Education in country', 'educationInCountry', 'Principal')}
                {renderButton(`${t("client.forms.edit.sectionslist.educationOutsideCountry")} ${evaluationCountry === '' ? 'country' : t('countries.canada')}`, 'Education outside country', activeBtn !== 'Education outside country', 'educationOutsideCountry', 'Principal')}
                {renderButton(`${t("client.forms.edit.sectionslist.professionalExperience")}`, 'Professional Experience', activeBtn !== 'Professional Experience', 'professionalExperience', 'Principal')}
                {renderButton(`${t("client.forms.edit.sectionslist.professionalExpertise")}`, 'Professional Expertise', activeBtn !== 'Professional Expertise', 'professionalExpertise', 'Principal')}
                {renderButton(`${t("client.forms.edit.sectionslist.jobOffer")}`, 'Job offer', activeBtn !== 'Job offer', 'jobOffer', 'Principal')}
                {renderButton(`${t("client.forms.edit.sectionslist.entrepreneurialExperience")}`, 'Entrepreneurial Experience', activeBtn !== 'Entrepreneurial Experience', 'entrepreunarialExperience', 'Principal')}
                <button className={`text-left mb-2 step-btn btn btn-light ${activeBtn === 'Summary' ? 'flous-bg-secondary text-bold' : ''} btn-block`} onClick={() => handleBtnClick('Summary', 'Principal')}>
                {t("client.forms.edit.sectionslist.summary")}
                </button>
                
                {/*
                {principalFormStates.personalInformation.personalDetail.personIncluded === 'Yes' && (
                  <button
                    className={`text-left mb-2 step-btn btn btn-light ${actor === 'Partner' ? 'flous-bg-primary text-bold' : 'flous-bg-menu text-bold'} btn-block`}
                    onClick={handleActor}
                  >
                    <div className='row'>
                      <div className='col-10'>{actor === 'Partner' ? `${t("forms.sections.principal")}` : `${t("forms.sections.partner")}`}</div>
                      <div className='col-2'>
                        <i className="fas fa-arrow-circle-right"></i>
                      </div>
                    </div>
                  </button>
                )} */}
                
                {needPartnerInformation && (
                  <>
                    <h4 className='mt-4 mb-4 flous-police-one'>{t("client.forms.edit.sections")} {t("forms.sections.partnerTitle")} </h4>
                    {renderButton(`${t("client.forms.edit.sectionslist.personalInformation")}`, 'Personal Information Partner', activeBtn !== 'Personal Information Partner', 'personalInformation', 'Partner')}
                    {renderButton(`${t("client.forms.edit.sectionslist.familyMembers")}`, 'Family Members Partner', activeBtn !== 'Family Members Partner', 'familyMembers', 'Partner')}
                    {renderButton(`${t("client.forms.edit.sectionslist.residences")}`, 'Residences Partner', activeBtn !== 'Residences Partner', 'residences', 'Partner')}
                    {renderButton(`${t("client.forms.edit.sectionslist.languageProficiency")}`, 'Language Proficiency Partner', activeBtn !== 'Language Proficiency Partner', 'languageProficiency', 'Partner')}
                    {renderButton(`${t("client.forms.edit.sectionslist.educationInCountry")} ${evaluationCountry === '' ? 'country' : t('countries.canada')}`, 'Education in country Partner', activeBtn !== 'Education in country Partner', 'educationInCountry', 'Partner')}
                    {renderButton(`${t("client.forms.edit.sectionslist.educationOutsideCountry")} ${evaluationCountry === '' ? 'country' : t('countries.canada')}`, 'Education outside country Partner', activeBtn !== 'Education outside country Partner', 'educationOutsideCountry', 'Partner')}
                    {renderButton(`${t("client.forms.edit.sectionslist.professionalExperience")}`, 'Professional Experience Partner', activeBtn !== 'Professional Experience Partner', 'professionalExperience', 'Partner')}
                    {renderButton(`${t("client.forms.edit.sectionslist.professionalExpertise")}`, 'Professional Expertise Partner', activeBtn !== 'Professional Expertise Partner', 'professionalExpertise', 'Partner')}
                    {renderButton(`${t("client.forms.edit.sectionslist.jobOffer")}`, 'Job offer Partner', activeBtn !== 'Job offer Partner', 'jobOffer', 'Partner')}
                    {renderButton(`${t("client.forms.edit.sectionslist.entrepreneurialExperience")}`, 'Entrepreneurial Experience Partner', activeBtn !== 'Entrepreneurial Experience Partner', 'entrepreunarialExperience', 'Partner')}
                    <button className={`text-left mb-2 step-btn btn btn-light ${activeBtn === 'Summary Partner' ? 'flous-bg-secondary text-bold' : ''} btn-block`} onClick={() => handleBtnClick('Summary Partner', 'Partner')}>
                    {t("client.forms.edit.sectionslist.summary")}
                </button>
                  </>
                )}
              </div>
            </div>
            <div className='col-md-8 order-1 order-md-2'>
              {/*{message && <div className="text-center mb-2 mt-2" style={{ color: 'green' }}>{message}</div>}*/}
              <div className='card p-3 elevation-2 mx-2' style={{ borderRadius: '15px' }}>
                  <h2 className="text-center flous-input-color flous-police-one">{t("client.forms.edit.subtitle")}</h2>
                  <p className="text-center">{t("client.forms.edit.message")}</p>
                  {/*<p className="text-center"><span className='flous-input-color flous-police-one'>{t("client.forms.edit.text.one")}</span> : {t("client.forms.edit.text.onetext")}.</p> */}
                  <p className="text-center"><span className='flous-input-color flous-police-one'>{t("forms.click.pressNext")}</span> : {t("forms.click.pressNextText")}.</p>
                  <p className="text-center"><span className='flous-input-color flous-police-one'>{t("forms.click.goBack")}</span> : {t("forms.click.goBackText")}.</p>
                  <p className="text-center">(<span style={{ color: 'red' }}>*</span>) : {t("client.forms.edit.isrequired")}</p>
                  <form className='m-3'>
                    {renderContent()}
                  </form>
              </div>
            </div>
          </div>
          <CompletionFormModal 
            isOpen={formCompletionModal} 
            onContinue={handleCloseAndContinueCompletionFormModal} 
            onCancel={handleCancel} />
        </div>
      </div>
    </>
  );
};
