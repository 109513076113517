import React from 'react';
import CountryShow from '../../../../general/countryShow';

import { useTranslation } from 'react-i18next';

export default function IdentificationResumeSection(props) {
  const { t } = useTranslation();
  // Define the personal information
  const firstName = props.identification.firstname;
  const lastName = props.identification.lastname;
  const dateOfBirth = props.identification.dateofbirth; // You can replace this with the actual date if available
  const countryOfBirth = props.identification.countryofbirth; // Add the country of birth if available
  const cityOfBirth = props.identification.cityofbirth; // Add the country of birth if available

  const countryName = CountryShow({ value: countryOfBirth });

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>{t("forms.title.identification")}</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputFirstName" className="col-sm-3 col-form-label">{t("forms.formsquestion.personalInfo.firstname")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputFirstName" value={firstName} placeholder="N.A"/>
          </div>
          <label htmlFor="inputLastName" className="col-sm-3 col-form-label">{t("forms.formsquestion.personalInfo.lastname")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputLastName" value={lastName} placeholder="N.A"/>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputDateOfBirth" className="col-sm-3 col-form-label">{t("forms.formsquestion.personalInfo.dateofbirth")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputDateOfBirth" value={dateOfBirth} placeholder="N.A"/>
          </div>
          <label htmlFor="inputCountryOfBirth" className="col-sm-3 col-form-label">{t("forms.formsquestion.personalInfo.countryofbirth")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputCountryOfBirth" value={countryName} placeholder="N.A"/>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputCityOfBirth" className="col-sm-3 col-form-label">{t("forms.formsquestion.personalInfo.cityofbirth")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputCityOfBirth" value={cityOfBirth} placeholder="N.A"/>
          </div>
        </div>
      </div>
    </div>
  );
};