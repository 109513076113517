import React, { useState } from 'react';
import CountrySelect from '../../../../general/CountrySelect';
import ProvinceSelect from '../../../../general/ProvinceSelect';
import CitySelect from '../../../../general/CitySelect';
import { countriesWithProvinces, dateFieldNames, maxDate, provincesWithCities } from '../../../../../services/utils';
import RequiredIndicator from '../../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";

const CurrentResidenceSection = (props) => {

  const [formData, setFormData] = useState(props.currentResidence);

  const { t } = useTranslation();

  const handleChange = (event) => {
    const { name, value } = event.target;
    
    if (dateFieldNames.includes(name) && value > maxDate) return ;

    const updatedFormData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedFormData);
    props.sendCurrentResidence(updatedFormData);
  };

  const handleCountryChange = (e) =>{
    const value = e.target.value;
    const updatedFormData = {
      ...formData,
      country: value,
      haveStatus: ''
    };
    setFormData(updatedFormData);
    props.sendCurrentResidence(updatedFormData);
  };

  const handleProvinceStateChange = (e) =>{
    const value = e.target.value;
    const updatedFormData = {
      ...formData,
      provinceState: value,
    };
    setFormData(updatedFormData);
    props.sendCurrentResidence(updatedFormData);
  };

  const handleCityChange = (e) =>{
    const value = e.target.value;
    const updatedFormData = {
      ...formData,
      city: value,
    };
    setFormData(updatedFormData);
    props.sendCurrentResidence(updatedFormData);
  }


  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      {props.error && (
          <>
              <p style={{ marginTop: '20px', padding: '10px', color: 'red'}}>
                  {props.error}
              </p>
          </>
      )}
      <p className='text-bold text-center'>{t("forms.title.currentaddress")}</p>
      <p className='p-2'>{t("forms.formsquestion.residences.currentResidence")}</p>
        <div className='row p-2'>
          <p htmlFor="country" className="col-sm-4 col-form-label">{t("forms.formsquestion.residences.country")}<RequiredIndicator /></p>
          <div className="col-sm-8">
            <CountrySelect value={formData.country} onChange={handleCountryChange} required />  
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="provinceState" className="col-sm-4 col-form-label">{t("forms.formsquestion.residences.provinceState")}</p>
          <div className="col-sm-8">
            {countriesWithProvinces.includes(formData.country) ? 
            (<ProvinceSelect 
              value={formData.provinceState} 
              onChange={handleProvinceStateChange} 
              country={formData.country} required
              />
            ):
            (<input 
              type="text" 
              className="form-control rounded-pill flous-input-color" 
              id="provinceState" 
              name="provinceState" 
              value={formData.provinceState} 
              onChange={handleChange} 
              placeholder={`${t('forms.formsquestion.placeholder.province')}`}
            />
            )}
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="city" className="col-sm-4 col-form-label">{t("forms.formsquestion.residences.city")}<RequiredIndicator /></p>
          <div className="col-sm-8">
            {countriesWithProvinces.includes(formData.country) ? 
            (<CitySelect 
              value={formData.city} 
              onChange={handleCityChange} 
              province={formData.provinceState} 
              required
              />
            ):
            (
              <input 
              type="text" 
              className="form-control rounded-pill flous-input-color" 
              id="city" 
              name="city" 
              value={formData.city} 
              onChange={handleChange} 
              placeholder={`${t('forms.formsquestion.placeholder.city')}`}
            />
            )}
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="streetNumber" className="col-sm-4 col-form-label">{t("forms.formsquestion.residences.streetNumber")}<RequiredIndicator /></p>
          <div className="col-sm-8">
            <input 
              type="text" 
              className="form-control rounded-pill flous-input-color" 
              id="streetNumber" 
              name="streetNumber" 
              value={formData.streetNumber} 
              onChange={handleChange} 
              placeholder={`${t('forms.formsquestion.placeholder.streetNumber')}`}
            />
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="postalCode" className="col-sm-4 col-form-label">{t("forms.formsquestion.residences.postalCode")}</p>
          <div className="col-sm-8">
            <input 
              type="text" 
              className="form-control rounded-pill flous-input-color" 
              id="postalCode" 
              name="postalCode" 
              value={formData.postalCode} 
              onChange={handleChange} 
              placeholder={`${t('forms.formsquestion.placeholder.codePostal')}`}
            />
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="startDate" className="col-sm-4 col-form-label">{t("forms.formsquestion.residences.startDate")}<RequiredIndicator /></p>
          <div className="col-sm-8">
            <input 
              type="date"
              id="startDate"
              name="startDate"
              className="form-control rounded-pill flous-input-color"
              onChange={handleChange}
              value={formData.startDate} max={new Date().toISOString().split("T")[0]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentResidenceSection;