import React from 'react';
import { useTranslation } from 'react-i18next';

const UpdateCreditModal = ({ isOpen, onClose, onConfirm }) => {

    const { t } = useTranslation();
    if (!isOpen) {
        return null;
    }

    return (
        <>
        {isOpen && (
          <div className="modal-backdrop fade show"></div>
        )}
        <div className="modal fade" id="updateModal" tabindex="-1" aria-labelledby="updateModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content p-5" style={{ borderRadius: '15px' }}>
                    <div className="modal-header d-flex justify-content-center flous-input-color">
                        <h3 className='flous-police-one text-center'>{t("modal.updatecredit.title")}<br/>{t("modal.updatecredit.title2")}<br/>{t("modal.updatecredit.title3")}</h3>
                    </div>
                    <div className="modal-body text-center">
                        <h5 className="modal-title" id="updateModalLabel">{t("modal.updatecredit.title4")}</h5>
                        <hr/>
                        <div type="button" className="btn btn-block text-bold btn-lg btn-light elevation-1" data-dismiss="modal">{t("modal.updatecredit.message")}</div>
                        <div type="button" className="btn btn-block text-bold btn-lg btn-light elevation-1" data-dismiss="modal">{t("modal.updatecredit.message2")}</div>
                        <div className='flous-input-color m-3'>{t("modal.updatecredit.message3")}</div>
                        <button type="button" className="btn btn-lg text-bold flous-bg-menu rounded-pill px-5" data-dismiss="modal" onClick={onConfirm}>{t("modal.updatecredit.submit")}</button>
                    </div>
                    <div className="modal-footer d-flex justify-content-center">
                        <button type="button" className="btn btn-lg flous-input-color flous-bg-thirty rounded-pill px-5" data-dismiss="modal" onClick={onClose}>{t("modal.cancel")}</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default UpdateCreditModal;
