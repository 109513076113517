import React, { useEffect, useState } from 'react';
import { Page, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import CountryShow from '../../../general/countryShow';
import api from '../../../../services/api';
import { sortDictionaryByValues } from '../../../../services/utils';
import styles from '../styles/pdfstyles';

export default function ProfessionalExperienceResumeSectionPDF({ professionalExperience, allProfessionalExperience, show }) {
  const { t } = useTranslation();
  const [provinces, setProvinces] = useState([]);
  const [jobs, setJobs] = useState([]);
  const getOptions = (data) => {
    return Object.entries(data).map(([key, val]) => ({
      value: key,
      label: val,
    }));
  };

  useEffect(() => {
      const fetchProvinces = async() => {
        const response = await api.get('/canada-provinces/');
          const provinceOptions = Object.entries(response.data).map(([key, val]) => ({
            value: key,
            label: val,
          }));
          setProvinces(provinceOptions);
      };
      const fetchJob = async () =>{
        const response = await api.get('/professions/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setJobs(options);
      };
      fetchJob();
  
      fetchProvinces();
  }, []);

  const renderExperienceDetails = (profession) => (
    <>

      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.selfEmployed")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{profession.jobDetail.selfEmployed === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.currentJob")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{profession.jobDetail.currentJob === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
        </View>
      </View>
      
      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.startDate")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{profession.jobDetail.startDate}</Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.endDate")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{profession.jobDetail.endDate || 'N/A'}</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.column70}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.authorization")} {t('countries.canada')}?</Text>
        </View>
        <View style={styles.column30}>
          <Text style={styles.value}>{profession.jobDetail.authorization === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.column70}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.obtainingAuthorization")}</Text>
        </View>
        <View style={styles.column30}>
          <Text style={styles.value}>{profession.jobDetail.obtainingAuthorization === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.column70}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.jobInAIOrIT")}</Text>
        </View>
        <View style={styles.column30}>
          <Text style={styles.value}>{profession.jobDetail.jobInAIOrIT === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.column70}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.hoursPerWeek")}</Text>
        </View>
        <View style={styles.column30}>
          <Text style={styles.value}>{profession.companyInfo.hoursPerWeek}</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.column70}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.hourlyRate")}</Text>
        </View>
        <View style={styles.column30}>
          <Text style={styles.value}>{profession.companyInfo.hourlyRate}</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.column70}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.paydayLoans")}</Text>
        </View>
        <View style={styles.column30}>
          <Text style={styles.value}>{profession.jobSpecifics.paydayLoans === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.column70}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.explicitContent")}</Text>
        </View>
        <View style={styles.column30}>
          <Text style={styles.value}>{profession.jobSpecifics.explicitContent === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.column70}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.realEstate")}</Text>
        </View>
        <View style={styles.column30}>
          <Text style={styles.value}>{profession.jobSpecifics.realEstate === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.city")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{profession.jobAddress.city}</Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.streetNumber")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{profession.jobAddress.streetNumber}</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.column30}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.postalCode")}</Text>
        </View>
        <View style={styles.column70}>
          <Text style={styles.value}>{profession.jobAddress.postalCode}</Text>
        </View>
      </View>
    </>
  );

  return (
    <Page style={styles.page}>
      <Text style={styles.mainTitle}>{t("client.results.blockTitle.bloc6")}</Text>
      
      <View style={styles.card}>
        <View style={styles.row}>
          <View style={styles.column70}>
            <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.professionalExperience")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>
              {professionalExperience.professionalExperience === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
            </Text>
          </View>
        </View>
      </View>

      {professionalExperience.professionalExperience === 'Yes' ? (
        <View style={styles.card}>
          {allProfessionalExperience.map((profession, index) => (
            <View key={index} style={styles.experienceCard}>
              <View style={styles.row}>
                <View style={styles.column30}>
                  <Text style={styles.label}>{t("general.table.title")}</Text>
                </View>
                <View style={styles.column70}>
                  <Text style={styles.value}>
                    {profession.jobDetail.jobTitle} - {jobs.find(option => option.value === profession.jobDetail.jobTitle)?.label}
                  </Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.column30}>
                  <Text style={styles.label}>{t("general.table.company")}</Text>
                </View>
                <View style={styles.column70}>
                  <Text style={styles.value}>{profession.companyInfo.companyName}</Text>
                </View>
              </View>
              
              <View style={styles.row}>
                <View style={styles.labelColumn}>
                  <Text style={styles.label}>{t("general.table.startdate")}</Text>
                </View>
                <View style={styles.valueColumn}>
                  <Text style={styles.value}>{profession.jobDetail.startDate}</Text>
                </View>
                <View style={styles.labelColumn}>
                  <Text style={styles.label}>{t("general.table.iscurrentjob")}</Text>
                </View>
                <View style={styles.valueColumn}>
                  <Text style={styles.value}>{profession.jobDetail.currentJob === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
                </View>
              </View>
              
              <View style={styles.row}>
                <View style={styles.labelColumn}>
                  <Text style={styles.label}>{t("general.table.country")}</Text>
                </View>
                <View style={styles.valueColumn}>
                  <Text style={styles.value}><CountryShow value={profession.jobAddress.country}/></Text>
                </View>
                <View style={styles.labelColumn}>
                  <Text style={styles.label}>{t("general.table.province")}</Text>
                </View>
                <View style={styles.valueColumn}>
                  <Text style={styles.value}>
                    {profession.jobAddress.country === 'CAN'
                      ? provinces.find(province => province.value === profession.jobAddress.provinceState)?.label || ''
                      : profession.jobAddress.provinceState}
                  </Text>
                </View>
              </View>

              {show && renderExperienceDetails(profession)}
            </View>
          ))}
        </View>
      ) : (
        <View style={styles.card}>
          <Text style={styles.cardTitle}>{t("general.table.applicantEmpty.noElement")}</Text>
        </View>
      )}
    </Page>
  );
}

