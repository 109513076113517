import axios from 'axios';

const API_KEY = process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATION_KEY;
const isDevMode = process.env.NODE_ENV !== 'production'

const ENDPOINT = `https://translation.googleapis.com/language/translate/v2`;

const decodeHtml = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.documentElement.textContent || doc.body.textContent;
};

const capitalizeFirstLetter = (text) => {
  if (text.length === 0) return text;
  return text.charAt(0).toUpperCase() + text.slice(1);
};

const translateText = async (text, targetLang = 'fr') => {

  if (isDevMode) return text;

  if (targetLang === 'en') return text;

  try {
    const response = await axios.post(ENDPOINT, null, {
      params: {
        q: text,
        target: targetLang,
        key: API_KEY,
      },
    });

    const decodedText =  decodeHtml(response.data.data.translations[0].translatedText);

    return capitalizeFirstLetter(decodedText);

  } catch (error) {
    // console.error('Error when translating:', error);
    return '';
  }
};

export  { translateText } ;

const translateNotifications = async (notifications, targetLang = 'fr') => {
    const translatedNotifications = await Promise.all(
      notifications.dates.map(async (dateObj) => {
        const translatedNotificationsForDate = await Promise.all(
          dateObj.notifications.map(async (notification) => {
            const translatedContent = await translateText(notification.content, targetLang);
            return { ...notification, content: translatedContent };
          })
        );
        return { ...dateObj, notifications: translatedNotificationsForDate };
      })
    );
  
    return { ...notifications, dates: translatedNotifications };
  };
  
  export { translateNotifications };

  const translateResults = async (dictionary, targetLang = 'fr') => {
    const translatedDictionary = {};
    
    try {
      for (const key in dictionary) {
        if (dictionary.hasOwnProperty(key)) {
          // Translate each element of the table associated with this key
          const translatedItems = await Promise.all(
            dictionary[key].map(async (item) => {
              const translatedName = await translateText(item.name, targetLang);
              return { ...item, name: translatedName };  // Keep the rest of the object intact
            })
          );
    
          // Add the key with its translated elements to the new dictionary
          translatedDictionary[key] = translatedItems;
        }
      }
    
      return translatedDictionary;
  
    } catch (error) {
      return dictionary;  // Return original object in case of error
    }
  };
  
  export { translateResults };


  const translateComments = async (comments, targetLang = 'fr') => {
    const translatedComments = {};
  
    for (const key in comments) {
      if (comments.hasOwnProperty(key)) {

        const translatedKey = await translateText(key, targetLang);

        // Translate each comment (which is a list)
        const translatedList = await Promise.all(
          comments[key].map(async (comment) => {
            const translatedComment = await translateText(comment, targetLang);
            return translatedComment;
          })
        );

        translatedComments[translatedKey] = translatedList;  // Update key with translated list
      }
    }
  
    return translatedComments;
  };

  const translateScoringAndConditionsKeys = async (data, targetLang = 'fr') => {
    try {
      const translatedData = {};
  
      for (const [category, items] of Object.entries(data)) {
        const translatedItems = {};
  
        await Promise.all(
          Object.entries(items).map(async ([key, value]) => {
            const translatedKey = await translateText(key, targetLang);
            translatedItems[translatedKey] = value; // Conserver les valeurs
          })
        );
  
        translatedData[category] = translatedItems;
      }
  
      return translatedData;
  
    } catch (error) {
      return data;
    }
  };
  
  // Main function for translating all data
  const translateProgramDetails = async (response, targetLang = 'fr') => {
    const translatedResponse = { ...response };
  
    try {
      // translatedResponse.data.name = await translateText(response.data.name, targetLang);
      translatedResponse.data.legislation = await translateText(response.data.legislation, targetLang);
  
      // Translation of comments (list dictionary)
      translatedResponse.data.comments.comments = await translateComments(response.data.comments.comments, targetLang);
      translatedResponse.data.program_comments = await translateComments(response.data.program_comments, targetLang);

      // Translation of Scoring and Conditions Data Keys
      translatedResponse.data.checking_data = await translateScoringAndConditionsKeys(response.data.checking_data, targetLang);
  
      return translatedResponse;
  
    } catch (error) {
      // console.error('Error when translating evaluation details:', error);
      return response;  // Return original object in case of error
    }
  };
  
  export { translateProgramDetails };

  const translateDictionaryList = async (data, targetLang = 'fr') => {

    try {
          // Translate each element of the table associated with this key
        const translatedItems = await Promise.all(
          data.map(async (item) => {
            const translatedName = await translateText(item.label, targetLang);
            return { ...item, label: translatedName };  // Keep the rest of the object intact
          })
        );
    
      return translatedItems;
  
    } catch (error) {
      return data;  // Return original object in case of error
    }
  };
  
  export { translateDictionaryList };
