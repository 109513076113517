import React, { useState } from 'react';
import api from "../../services/api";
import { toast, ToastContainer } from 'react-toastify';
import { useTranslation } from "react-i18next";

export default function PaymentSection() {
  const previousUrl = window.location.href;

  const { t } = useTranslation();

  const handleRedirect = async () => {
    try {
      const response = await api.post('/stripe/customer-portal/', {"previousURL": previousUrl});
      if (response.data.url) {
        window.open(response.data.url, '_blank');
      }
    } catch (error) {
      if (error.response && [400, 500].includes(error.response.status)){
        toast.error("Network error, please try again later.");
      }else {
        toast.error("Network error");
      }
    }
  };

  return (
    <div id='Payment'>
      <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
        <div className='text-center text-bold'>{t("general.profile.sections.payment.subtitle")}</div>
        <hr />
        <div className='mx-3'>
          <div className="form-group row">
            <label htmlFor="inputCard" className="col-sm-4 col-form-label">
            {t("general.profile.sections.payment.content.currentplan")}
            </label>
            <div className="col-sm-8">
              <p className="text-muted">
              {t("general.profile.sections.payment.content.planmessage")}
              </p>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputExpiryDate" className="col-sm-4 col-form-label">
            {t("general.profile.sections.payment.content.methods")}
            </label>
            <div className="col-sm-8">
              <p className="text-muted">
              {t("general.profile.sections.payment.content.methodmessage")}
              </p>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputCode" className="col-sm-4 col-form-label">
            {t("general.profile.sections.payment.content.billing")}
            </label>
            <div className="col-sm-8">
              <p className="text-muted">
              {t("general.profile.sections.payment.content.billingmessage")}
              </p>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputCode" className="col-sm-4 col-form-label">
            {t("general.profile.sections.payment.content.history")}
            </label>
            <div className="col-sm-8">
              <p className="text-muted">
              {t("general.profile.sections.payment.content.historymessage")}
              </p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <button type="button" 
              className="btn flous-btn-gradient btn-block rounded-pill" 
              onClick={handleRedirect}
            >
              {t("general.profile.sections.payment.content.submit")}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}