import React, { useState, useEffect } from 'react';
import axios from 'axios';
import api from '../../services/api';

const InstitutionShow = ({ value }) => {
  const [institutionName, setInstitutionName] = useState(value);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInstitution = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await api.get(`/canada/institutions/${value}`);
        console.log(response)
        setInstitutionName(response.data.institution_name);
      } catch (err) {
        // setError("Une erreur est survenue lors du chargement.");
      } finally {
        setLoading(false);
      }
    };

    fetchInstitution();
  }, [value]);

  if (loading) return <p>Chargement...</p>;
  if (error) return <p>{error}</p>;

  return institutionName;
};

export default InstitutionShow;
