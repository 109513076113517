import React from 'react';

import { useTranslation } from 'react-i18next';

export default function CompanyDetailsResumeSection(props) {
  const { t } = useTranslation();
  // Define the company details information
  const companyDetails = {
    ownerOrPartner: props.ownershipDetail.ownershipType, //  
    companyName: props.ownershipDetail.companyName, //  
    ownershipPercentage: props.ownershipDetail.ownershipPercentage, //  
    managementExperienceMonths: props.ownershipDetail.managementExperience, //  
    personalEquityInvestment: props.ownershipDetail.personalEquityInvestment,
  };

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>{t("forms.title.companydetail")}</div>
      <div className='mx-3'>
        { /*<div className="form-group row">
          <label htmlFor="inputOwnerOrPartner" className="col-sm-6 col-form-label">
            Are you the owner or a business partner?
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputOwnerOrPartner" value={companyDetails.ownerOrPartner} />
          </div>
        </div>*/ }
        <div className="form-group row">
          <label htmlFor="inputCompanyName" className="col-sm-6 col-form-label">
          {t("forms.formsquestion.entrepreunarialExperience.companyName")}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputCompanyName" value={companyDetails.companyName} placeholder="N.A"/>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputOwnershipPercentage" className="col-sm-6 col-form-label">
          {t("forms.formsquestion.entrepreunarialExperience.ownershipPercentage")}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputOwnershipPercentage" value={companyDetails.ownershipPercentage} placeholder="N.A"/>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="personalEquityInvestment" className="col-sm-6 col-form-label">
          {t("forms.formsquestion.entrepreunarialExperience.personalEquityInvestment")}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="personalEquityInvestment" value={companyDetails.personalEquityInvestment} placeholder="N.A"/>
          </div>
        </div>
        {/*
        <div className="form-group row">
          <label htmlFor="inputManagementExperienceMonths" className="col-sm-6 col-form-label">
          {t("forms.formsquestion.entrepreunarialExperience.managementExperience")}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputManagementExperienceMonths" value={companyDetails.managementExperienceMonths} placeholder="N.A"/>
          </div>
        </div>
        */}
      </div>
    </div>
  );
}
