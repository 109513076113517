import React, { useState, useEffect } from 'react';
import { dateFieldNames, maxDate, NumberInputControl, sortDictionaryByValues, translateRelationship } from '../../../../services/utils';
import api from '../../../../services/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import { useTranslation } from "react-i18next";
import RequiredIndicator from '../../../general/requiredIndicator';
import { translateDictionaryList, translateText } from '../../../../services/general/TranslationServices';
import FamilyMemberForm from './section_family_members/FamilyMemberForm';
import CountryShow from '../../../general/countryShow';

const FamilyMembersInfoSection = (props) => {
    const [relationships, setRelationships] = useState([]);

    const [childrenData, setChildrenData] = useState(props.children);
    const [familyInfo, setFamilyInfo] = useState(props.familyInfo);
    const { t } = useTranslation();

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        nationality: '',
        permanent: '',
        birthday: '',
        familyRelationship: '',
        liveWith: '',
        country: '',
        provinceState: '',
        city: '',
        streetNumber: '',
        postalCode: '',
        deathday: '',
        startDate: '',
    });
    const [members, setMembers] = useState(props.familyMembers);
    const [errors, setErrors] = useState({});

    const [addMemberClicked, setAddMemberClicked] = useState(false);
    const [addChildrenClicked, setAddChildrenClicked] = useState(false);
    const [addSiblingClicked, setAddSiblingClicked] = useState(false);
    const [addRelativesClicked, setAddRelativesClicked] = useState(false);
    
    const [siblingRequirementsMet, setSiblingRequirementsMet] = useState(false);
    const [childrenRequirementsMet, setChildrenRequirementsMet] = useState(false);
    const [parentsRequirementsMet, setParentsRequirementsMet] = useState(false);
    const [canOrRepRequirementsMet, setCanOrRepRequirementsMet] = useState(false);

    const [currentMember, setCurrentMember] = useState(null);
    
    const [relationshipsOriginal, setRelationshipsOriginal] = useState([]);
  
    const lang = localStorage.getItem('flouslang') || 'en';

    const [validationMessage, setValidationMessage] = useState('');

    const parentRelationships = ["Father", "Stepfather", "Mother", "Stepmother"];
    const otherRelativesRelationships = ["Grandfather", "Grandmother", "Nephew", "Niece"];
    const siblingRelationships = ["Sister", "Stepsister", "Brother", "Stepbrother"];
    const childrenRelationships = ["Stepson", "Stepdaughter", "Son", "Daughter"];

    const canadianOrResidentPermanent = ["Yes"];
  
    useEffect(()=>{
      handleTranslate();
    }, [lang, relationshipsOriginal]);

    useEffect(() => {
        const fetchRelationship = async () => {
            const response = await api.get('/familyrelationship/');
            const sorted = sortDictionaryByValues(response.data);
            const options = getOptions(sorted);
            setRelationships(options);
            setRelationshipsOriginal(options);
        };
        fetchRelationship();
    }, []);

    useEffect(()=>{
        if (childrenData.totalDependantChildren < childrenData.childrenUnder22) {
            const updatedFormData = {
                ...childrenData,
                childrenUnder22: childrenData.totalDependantChildren,
            };
            setChildrenData(updatedFormData);
        }
        props.sendChildren(childrenData);
        props.sendFamilyInfo(familyInfo);
        props.onValidate('familyMembers', false, props.actor, false);
      }, [childrenData, familyInfo, props]);

    useEffect(() => {
        validateFamilyList();
        setValidationMessage(t('forms.formsquestion.toast.familyMembers.criteriaMeet'));
    }, [members, familyInfo, childrenData, lang]);

    useEffect(()=>{
        props.sendFamilyMembers(members);
        props.onValidate('familyMembers', false, props.actor, false);
    }, [members, props]);

    const handleTranslate = async (data)=>{
        if (lang !== 'en') {
            const relationshipTranslated = await translateDictionaryList(relationshipsOriginal, lang);
            setRelationships(relationshipTranslated);
        } else {
            setRelationships(relationshipsOriginal);
        }
    };

    const getOptions = (data) => {
        return Object.entries(data).map(([key, val]) => ({
            value: key,
            label: val,
        }));
    };

    const handleChildrenDataChange = (event) => {
        handleSetStates();
        const { name } = event.target;
        let value = event.target.value;

        if (name==='totalDependantChildren' && value < 1 ){
            value = 1;
        }

        else if (name==='childrenUnder22' && value > childrenData.totalDependantChildren){
            value = childrenData.totalDependantChildren;
        }

        else if (name==='childrenUnder22' && value < 0 ){
            value = 0;
        }

        const updatedFormData = {
          ...childrenData,
          [name]: value,
        };
        setChildrenData(updatedFormData);
        delete errors.childrenData;
    };

    const handleNumberChange = (event) =>{
        handleSetStates();
        const { name } = event.target;
        let value = event.target.value;

        if (value < 0){
            value = 0;
        }
        const updatedFormData = {
          ...familyInfo,
          [name]: value,
        };
        setFamilyInfo(updatedFormData);
    }
    
    const handleChange = (event) => {
        const { name, value } = event.target;
        
        if (dateFieldNames.includes(name) && value > maxDate) return ;

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        if (name === "permanent" && value === "Yes"){
            setFormData((prevFormData) => ({
                ...prevFormData,
                nationality: "CAN",
            }));
        }
    };

    const handleCountryChange = (e) =>{
        const value = e.target.value;
        const updatedFormData = {
          ...formData,
          country: value,
        };
        setFormData(updatedFormData);
    };

    const handleNationalityChange = (e) =>{
        const { value } = e.target;
        const updatedFormData = {
          ...formData,
          nationality: value,
        };
        setFormData(updatedFormData);
    };

    const handleProvinceStateChange = (e) => {
        const value = e.target.value
        setFormData((prevFormData) => ({
            ...prevFormData,
            provinceState: value,
        }));
    };

    const handleCityChange = (e) => {
        const value = e.target.value
        setFormData((prevFormData) => ({
            ...prevFormData,
            city: value,
        }));
        //props.sendFamilyMembers(formData);
    };

    const handleAddMembers = () => {
        if(checkFormData()) {
            if (currentMember === null) {
                setMembers((prevMembers) => [...prevMembers, formData]);
            }else {
                const updateData = [...members];
                updateData[currentMember] = formData;
                setMembers(updateData);
            }
            setFormData({
                firstname: '',
                lastname: '',
                nationality: formData.nationality,
                permanent: '',
                birthday: '',
                familyRelationship: formData.familyRelationship,
                liveWith: '',
                country: formData.country,
                provinceState: formData.provinceState,
                city: '',
                streetNumber: '',
                postalCode: '',
                deathday: '',
                startDate: '',
            });
            setAddRelativesClicked(false);
            setAddMemberClicked(false);
            setAddChildrenClicked(false);
            setAddSiblingClicked(false);
            setCurrentMember(null);
            delete errors.formData;
        }else{
            toast.error(`${t('forms.formsquestion.toast.familyMembers.allRequiredFields')}`);
        }
    };

    const handleSetStates = () => {
        setAddRelativesClicked(false);
        setAddMemberClicked(false);
        setAddChildrenClicked(false);
        setAddSiblingClicked(false);
    }

    const handleCancelMember = () => {
        handleSetStates();
        setCurrentMember(null);
        setFormData({
            firstname: '',
            lastname: '',
            nationality: formData.nationality,
            permanent: '',
            birthday: '',
            familyRelationship: formData.familyRelationship,
            liveWith: '',
            country: formData.country,
            provinceState: formData.provinceState,
            city: '',
            streetNumber: '',
            postalCode: '',
            deathday: '',
            startDate: '',
        });
        delete errors.formData;
    }

    const handleEditMember = (index) => {
        const updatedFormData = [...members];
        const member = updatedFormData[index];
        setFormData({
            firstname: member.firstname,
            lastname: member.lastname,
            nationality: member.nationality,
            permanent: member.permanent,
            birthday: member.birthday,
            familyRelationship: member.familyRelationship,
            liveWith: member.liveWith,
            country: member.country,
            provinceState: member.provinceState,
            city: member.city,
            streetNumber: member.streetNumber,
            postalCode: member.postalCode,
            deathday: member.deathday,
            startDate: member.startDate,
        });
        // setAddMemberClicked(true);
        setCurrentMember(index);
        delete errors.formData;
    }

    const validateFamilyList = () => {
        const parentCount = members.filter(member => parentRelationships.includes(member.familyRelationship)).length;
        const siblingCount = members.filter(member => siblingRelationships.includes(member.familyRelationship)).length;
        const canOrRpCount = members.filter(member => canadianOrResidentPermanent.includes(member.permanent)).length;
        const childrenCount = members.filter(member => childrenRelationships.includes(member.familyRelationship)).length;

        if (parentCount < 2) {
            setErrors(prevErrors => ({
                ...prevErrors,
                parentsRequirements: `${t('forms.formsquestion.toast.familyMembers.parentsRequirements')}`
            }));
            setParentsRequirementsMet(false);
        } else {
            setParentsRequirementsMet(true);
            delete errors.parentsRequirements;
        }

        if ((familyInfo.totalSibling - siblingCount) > 0) {
            setErrors(prevErrors => ({
                ...prevErrors,
                siblingRequirements: `${t('forms.formsquestion.toast.familyMembers.siblingRequirements')}`
            }));
            setSiblingRequirementsMet(false);
        } else {
            setSiblingRequirementsMet(true);
            delete errors.siblingRequirements;
        }

        if ( (familyInfo.totalCanadianOrPResident - canOrRpCount) > 0) {
            setErrors(prevErrors => ({
                ...prevErrors,
                canOrRpRequirements: `${t('forms.formsquestion.toast.familyMembers.canOrRpRequirements')}`
            }));
            setCanOrRepRequirementsMet(false);
        } else {
            setCanOrRepRequirementsMet(true);
            delete errors.canOrRpRequirements;
        }

        if (childrenData.dependantChildren === 'Yes' && (childrenData.totalDependantChildren - childrenCount) > 0) {
            setErrors(prevErrors => ({
                ...prevErrors,
                childrenRequirements: `${t('forms.formsquestion.toast.familyMembers.childrenRequirements')}`
            }));
            setChildrenRequirementsMet(false);
        } else {
            setChildrenRequirementsMet(true);
            delete errors.childrenRequirements;
        }

        if (parentCount >= 2 
            && ((familyInfo.totalSibling - siblingCount) <= 0 
            && (familyInfo.totalCanadianOrPResident - canOrRpCount) <= 0
            && (
                (childrenData.dependantChildren === 'Yes' && (childrenData.totalDependantChildren - childrenCount) <= 0) 
                || childrenData.dependantChildren === 'No'
            )
        )) {
            return true;
        } else {
            return false;
        }
    };

    const removeMember = (index) => {
        const updatedFamilyList = members.filter((_, i) => i !== index);
        setMembers(updatedFamilyList);
    };

    const checkFormData = () => {
        if (
            formData.firstname === '' ||
            formData.lastname === '' ||
            formData.nationality === '' ||
            formData.permanent === '' ||
            formData.birthday === '' ||
            formData.familyRelationship === '' ||
            formData.liveWith === '' ||
            (formData.liveWith === 'No' && (
                (formData.country === '' || (formData.country === 'CAN' && formData.startDate === '')) ||
                formData.provinceState === '' ||
                formData.city === '' ||
                formData.streetNumber === '' // ||
                //formData.postalCode === '' ||
                //formData.deathday === ''
            ))
        ) {
            setErrors(prevErrors => ({
                ...prevErrors,
                formData: `${t('forms.formsquestion.toast.familyMembers.allRequiredFields')}`
            }));
            return false;
        } else {
            delete errors.formData;
            return true;
        }
    };

    const checkDependantChildren = () =>{
        if (
            childrenData.dependantChildren === '' ||
            ( childrenData.dependantChildren === 'Yes' && 
                (childrenData.totalDependantChildren === '' || childrenData.childrenUnder22 === ''))
        ){
            setErrors(prevErrors => ({
                ...prevErrors,
                childrenData: `${t('forms.formsquestion.toast.familyMembers.allRequiredFields')}`
            }));
            return false;

        } else {
            delete errors.childrenData;
            return true;
        }
    }

    const checkFamilyInfo = () =>{
        if (familyInfo.totalSibling === '' ||
            familyInfo.totalCanadianOrPResident === ''){
            setErrors(prevErrors => ({
                ...prevErrors,
                familyInfo: `${t('forms.formsquestion.toast.familyMembers.moreInfo')}`
            }));
            return false;

        } else {
            delete errors.familyInfo;
            return true;
        }
    }

    const validateSection = () => {
        props.onValidate('familyMembers', false, props.actor, false);

        if(checkFamilyInfo() && (validateFamilyList() && checkDependantChildren())) {
            toast.success(`${t('forms.formsquestion.toast.sectionValidate')}`);
            setErrors({});
            return true;
        } else {
            toast.error(`${t('forms.formsquestion.toast.familyMembers.membersRequirements')}`);
            return false;
        }
    };

    const pressNext = async ()=> {
    
        const validation = validateSection();
    
        await props.onValidate('familyMembers', validation, props.actor, true);
    
        if (validation) {
          // toast.success(`${t('forms.formsquestion.toast.sectionValidate')}`);
          props.sendNext(props.actor === 'Principal'? "Residences":"Residences Partner", props.actor);
        }
    };

    const goBack = ()=> {
      if (props.actor === 'Principal') {
        props.goBack("Personal Information", "Principal");
      } else {
        props.goBack("Personal Information Partner", "Partner");
      } 
    };

    return (
        <div className="form-card">

            <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
                <p className="text-bold text-center">{t("forms.title.familyChildren")}</p>

                {errors.childrenData && (
                    <>
                        <p style={{ marginTop: '20px', padding: '10px', color: 'red'}}>
                            {errors.childrenData}
                        </p>
                    </>
                )}
                <div className='row p-2'>
                    <p className='col-sm-8 col-form-label'>{t("forms.formsquestion.familyMembers.dependantChildren")} <RequiredIndicator /></p>
                    <div className='col-sm-4'>
                    <div className='row'>
                        <div className="col form-check">
                        <input 
                            type="radio"
                            className="form-check-input rounded-pill"
                            name="dependantChildren"
                            value="Yes"
                            onChange={handleChildrenDataChange}
                            checked={childrenData.dependantChildren === "Yes"}
                        />
                        <label className="form-check-label">{t("forms.click.yes")}</label>
                        </div>
                        <div className="col form-check">
                        <input 
                            type="radio"
                            className="form-check-input rounded-pill"
                            name="dependantChildren"
                            value="No"
                            onChange={handleChildrenDataChange}
                            checked={childrenData.dependantChildren === "No"}
                        />
                        <label className="form-check-label">{t("forms.click.no")}</label>
                        </div>
                    </div>
                    </div>
                </div>

                {childrenData.dependantChildren === 'Yes' && (
                    <>

                    <div>
                        <div className='row p-2'>
                        <p htmlFor="totalDependantChildren" className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.totalDependantChildren")} <RequiredIndicator /></p>
                        <div className="col-sm-6">
                            <input 
                            type="number" 
                            className="form-control rounded-pill flous-input-color" 
                            id="totalDependantChildren" 
                            name="totalDependantChildren"
                            placeholder={`${t('forms.formsquestion.placeholder.dependantChildren')}`}
                            value={childrenData.totalDependantChildren}
                            onChange={handleChildrenDataChange} min={1}
                            onKeyDown={(e) => NumberInputControl(e)}
                            />
                        </div>
                        </div>
                        <div className='row p-2'>
                        <p htmlFor="childrenUnder22" className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.childrenUnder22")} <RequiredIndicator /></p>
                        <div className="col-sm-6">
                            <input 
                            type="number" 
                            className="form-control rounded-pill flous-input-color" 
                            id="childrenUnder22" 
                            name="childrenUnder22"
                            placeholder={`${t('forms.formsquestion.placeholder.dependantChildrenUnder22')}`}
                            value={childrenData.childrenUnder22}
                            onChange={handleChildrenDataChange} min={0}
                            onKeyDown={(e) => NumberInputControl(e)}
                            />
                        </div>
                        </div>
                    </div>
                    
                    {childrenData.totalDependantChildren > 0 && (
                        <>
                        {errors.childrenRequirements && (
                            <>
                                <p style={{ marginTop: '20px', padding: '10px', color: 'red'}}>
                                    {errors.childrenRequirements}
                                </p>
                            </>
                        )}
                        
                        {childrenRequirementsMet && (
                            <p style={{ marginTop: '20px', padding: '10px', color: 'green'}}>
                                {validationMessage}
                            </p>
                        )}
                        
                        <p className="text-bold mt-3 p-1">{t("forms.formsquestion.familyMembers.childrenTitle")} : {childrenData.totalDependantChildren}</p>
                        <p className="pr-1 italic">{t("forms.formsquestion.familyMembers.childrenDisclaimer")}</p>

                        <div className="card-header p-1 mb-1 shadow bg-white" style={{ borderRadius: '15px 15px 0px 0px' }}>
                            <div className="row text-bold">
                                <div className="col-sm col-6 mt-2 text-center">{t("general.table.relation")}</div>
                                <div className="col-sm col-6 mt-2 text-center">{t("general.table.firstname")}</div>
                                <div className="col-sm col-6 mt-2 text-center">{t("general.table.lastname")}</div>
                                <div className="col-sm col-6 mt-2 text-center">{t("general.table.nationality")}</div>
                                <div className="col-sm col-6 mt-2 text-center">{t("general.table.birthday")}</div>
                                <div className="col-sm col-6 mt-2 text-center">{t("general.table.iscanadian")}</div>
                                <div className="col-sm col-6 mt-2 text-center">{t("general.table.actions")}</div>
                            </div>
                        </div>

                        {!members || members.length === 0 ? (
                            <>
                            <p className="text-center">{t("general.table.empty.familytwo")}</p>
                            <p className="text-center">{t("general.table.empty.familythree")}</p>
                            </>
                        ) : (
                        members
                            .filter(member =>  childrenRelationships.includes(member.familyRelationship))
                            .map((member, index) => {

                                const originalIndex = members.findIndex(m => m === member);

                                return (
                                    <div className="card-header p-1 mb-1 shadow bg-white" style={{ borderRadius: '15px' }}>
                                        <div className="row text-center" key={index}>
                                            <div className="col-sm col-6 mt-2">{translateRelationship(member.familyRelationship, lang)}</div>
                                            <div className="col-sm col-6 mt-2">{member.firstname}</div>
                                            <div className="col-sm col-6 mt-2">{member.lastname}</div>
                                            <div className="col-sm col-6 mt-2"><CountryShow value={member.nationality} /></div>
                                            <div className="col-sm col-6 mt-2">{member.birthday}</div>
                                            <div className="col-sm col-6 mt-2">{member.permanent === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`}</div>
                                            <div className="col-sm col-6 mt-2">
                                                <button type='button'
                                                    className="btn flous-bg-primary mr-1 mt-1" 
                                                    onClick={() => {
                                                        setAddChildrenClicked(true);
                                                        handleEditMember(originalIndex);
                                                    }}
                                                >
                                                    <i className="nav-icon fas fa-edit"></i>
                                                </button>
                                                <button type='button' className="btn btn-secondary btn-sm mt-1"
                                                    onClick={() => removeMember(originalIndex)}><i className="nav-icon fas fa-trash"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                )
                        }))}
                        {(!addChildrenClicked && !childrenRequirementsMet) &&
                            <div className='mt-3'>
                                <button type='button' className='btn btn-block btn-secondary rounded-pill' onClick={()=>{
                                    setAddChildrenClicked(true);
                                    setAddSiblingClicked(false);
                                    setAddMemberClicked(false);
                                    setAddRelativesClicked(false);
                                    }}
                                >{t("forms.addMoreButton.addChildren")}</button>
                            </div>
                        }

                        {addChildrenClicked &&
                            <FamilyMemberForm 
                                formData={formData}
                                errors={errors}
                                relationships={relationships}
                                allowedRelationships={childrenRelationships}
                                handleChange={handleChange}
                                handleCityChange={handleCityChange}
                                handleCountryChange={handleCountryChange}
                                handleNationalityChange={handleNationalityChange}
                                handleProvinceStateChange={handleProvinceStateChange}
                                handleCancelMember={handleCancelMember} 
                                handleAddMember={handleAddMembers}          
                            />
                        }

                        </>
                        
                    )}
                    </>

                )}

            </div>

            <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
                <p className="text-bold text-center">{t("forms.title.familySibling")}</p>

                {errors.familyInfo && (
                    <>
                        <p style={{ marginTop: '20px', padding: '10px', color: 'red'}}>
                            {errors.familyInfo}
                        </p>
                    </>
                )}

                <div className='row p-2'>
                    <p htmlFor="childrenUnder22" className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.siblingRelation")}<RequiredIndicator />
                        <i className="nav-icon fas fa-info-circle"  data-tooltip-id="tooltip1" 
                        data-tooltip-content={t("forms.formsquestion.tooltip.familyMembers.siblingsList")}>
                        </i>
                    </p>
                    <div className="col-sm-6">
                        <input 
                        type="number" 
                        className="form-control rounded-pill flous-input-color" 
                        id="totalSibling" 
                        name="totalSibling"
                        placeholder={`${t('forms.formsquestion.placeholder.numberSibling')}`}
                        value={familyInfo.totalSibling}
                        onChange={handleNumberChange} min={0}
                        onKeyDown={(e) => NumberInputControl(e)}
                        />
                    </div>
                    <Tooltip id='tooltip1' />
                </div>

                {familyInfo.totalSibling > 0 && (
                    <>
                        {errors.siblingRequirements && (
                            <>
                                <p style={{ marginTop: '20px', padding: '10px', color: 'red'}}>
                                    {errors.siblingRequirements}
                                </p>
                            </>
                        )}
                        
                        {siblingRequirementsMet && (
                            <p style={{ marginTop: '20px', padding: '10px', color: 'green'}}>
                                {validationMessage}
                            </p>
                        )}

                        <p className="text-bold mt-3 p-1">{t("forms.formsquestion.familyMembers.siblingTitle")} : {familyInfo.totalSibling}</p>
                        <p className="pr-1 italic">{t("forms.formsquestion.familyMembers.siblingDisclaimer")}</p>

                        <div className="card-header p-1 mb-1 shadow bg-white" style={{ borderRadius: '15px 15px 0px 0px' }}>
                            <div className="row text-bold">
                                <div className="col-sm col-6 mt-2 text-center">{t("general.table.relation")}</div>
                                <div className="col-sm col-6 mt-2 text-center">{t("general.table.firstname")}</div>
                                <div className="col-sm col-6 mt-2 text-center">{t("general.table.lastname")}</div>
                                <div className="col-sm col-6 mt-2 text-center">{t("general.table.nationality")}</div>
                                <div className="col-sm col-6 mt-2 text-center">{t("general.table.birthday")}</div>
                                <div className="col-sm col-6 mt-2 text-center">{t("general.table.iscanadian")}</div>
                                <div className="col-sm col-6 mt-2 text-center">{t("general.table.actions")}</div>
                            </div>
                        </div>

                        {!members || members.length === 0 ? (
                            <>
                            <p className="text-center">{t("general.table.empty.familytwo")}</p>
                            <p className="text-center">{t("general.table.empty.familythree")}</p>
                            </>
                        ) : (
                        members
                            .filter(member =>  siblingRelationships.includes(member.familyRelationship))
                            .map((member, index) => {

                                const originalIndex = members.findIndex(m => m === member);

                                return (
                                    <div className="card-header p-1 mb-1 shadow bg-white" style={{ borderRadius: '15px' }}>
                                        <div className="row text-center" key={index}>
                                            <div className="col-sm col-6 mt-2">{translateRelationship(member.familyRelationship, lang)}</div>
                                            <div className="col-sm col-6 mt-2">{member.firstname}</div>
                                            <div className="col-sm col-6 mt-2">{member.lastname}</div>
                                            <div className="col-sm col-6 mt-2"><CountryShow value={member.nationality} /></div>
                                            <div className="col-sm col-6 mt-2">{member.birthday}</div>
                                            <div className="col-sm col-6 mt-2">{member.permanent === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`}</div>
                                            <div className="col-sm col-6 mt-2">
                                                <button type='button'
                                                    className="btn flous-bg-primary mr-1 mt-1" 
                                                    onClick={() => {
                                                        setAddSiblingClicked(true);
                                                        handleEditMember(originalIndex);
                                                    }}
                                                >
                                                    <i className="nav-icon fas fa-edit"></i>
                                                </button>
                                                <button type='button' className="btn btn-secondary btn-sm mt-1"
                                                    onClick={() => removeMember(originalIndex)}><i className="nav-icon fas fa-trash"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                )
                        }))}
                        {(!addSiblingClicked && !siblingRequirementsMet) &&
                            <div className='mt-3'>
                                <button type='button' className='btn btn-block btn-secondary rounded-pill' onClick={()=>{
                                    setAddSiblingClicked(true);
                                    setAddChildrenClicked(false);
                                    setAddMemberClicked(false);
                                    setAddRelativesClicked(false);
                                }}
                                >{t("forms.addMoreButton.addSibling")}</button>
                            </div>
                        }

                        {addSiblingClicked &&
                            <FamilyMemberForm 
                                formData={formData}
                                errors={errors}
                                relationships={relationships}
                                allowedRelationships={siblingRelationships}
                                handleChange={handleChange}
                                handleCityChange={handleCityChange}
                                handleCountryChange={handleCountryChange}
                                handleNationalityChange={handleNationalityChange}
                                handleProvinceStateChange={handleProvinceStateChange}
                                handleCancelMember={handleCancelMember} 
                                handleAddMember={handleAddMembers}          
                            />
                        }

                    </>
                )}

            </div>

            <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
                <p className="text-bold text-center">{t("forms.title.familyParents")}</p>

                {errors.parentsRequirements && (
                    <>
                        <p style={{ marginTop: '20px', padding: '10px', color: 'red'}}>
                            {errors.parentsRequirements}
                        </p>
                    </>
                )}

                {(parentsRequirementsMet && canOrRepRequirementsMet) && (
                    <p style={{ marginTop: '20px', padding: '10px', color: 'green'}}>
                        {validationMessage}
                    </p>
                )}
                
                <p className="text-bold mt-3 p-1">{t("forms.formsquestion.familyMembers.parentsTitle")} : 2</p>
                <p className="pr-1 italic">{t("forms.formsquestion.familyMembers.parentsDisclaimer")}</p>

                <div>
                    <div className="card-header p-1 mb-1 shadow bg-white" style={{ borderRadius: '15px 15px 0px 0px' }}>
                        <div className="row text-bold">
                            <div className="col-sm col-6 mt-2 text-center">{t("general.table.relation")}</div>
                            <div className="col-sm col-6 mt-2 text-center">{t("general.table.firstname")}</div>
                            <div className="col-sm col-6 mt-2 text-center">{t("general.table.lastname")}</div>
                            <div className="col-sm col-6 mt-2 text-center">{t("general.table.nationality")}</div>
                            <div className="col-sm col-6 mt-2 text-center">{t("general.table.birthday")}</div>
                            <div className="col-sm col-6 mt-2 text-center">{t("general.table.iscanadian")}</div>
                            <div className="col-sm col-6 mt-2 text-center">{t("general.table.actions")}</div>
                        </div>
                    </div>
                    
                    
                    {!members || members.length === 0 ? (
                            <>
                            <p className="text-center">{t("general.table.empty.familytwo")}</p>
                            <p className="text-center">{t("general.table.empty.familythree")}</p>
                            </>
                        ) : (

                        members
                            .filter(member =>  parentRelationships.includes(member.familyRelationship))
                            .map((member, index) => {

                                const originalIndex = members.findIndex(m => m === member);

                                return (
                                    <div className="card-header p-1 mb-1 shadow bg-white" style={{ borderRadius: '15px' }}>
                                        <div className="row text-center" key={index}>
                                            <div className="col-sm col-6 mt-2">{translateRelationship(member.familyRelationship, lang)}</div>
                                            <div className="col-sm col-6 mt-2">{member.firstname}</div>
                                            <div className="col-sm col-6 mt-2">{member.lastname}</div>
                                            <div className="col-sm col-6 mt-2"><CountryShow value={member.nationality} /></div>
                                            <div className="col-sm col-6 mt-2">{member.birthday}</div>
                                            <div className="col-sm col-6 mt-2">{member.permanent === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`}</div>
                                            <div className="col-sm col-6 mt-2">
                                                <button type='button'
                                                    className="btn flous-bg-primary mr-1 mt-1" 
                                                    onClick={() => {
                                                        setAddMemberClicked(true);
                                                        handleEditMember(originalIndex);
                                                    }}
                                                >
                                                    <i className="nav-icon fas fa-edit"></i>
                                                </button>
                                                <button type='button' className="btn btn-secondary btn-sm mt-1"
                                                    onClick={() => removeMember(originalIndex)}><i className="nav-icon fas fa-trash"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                )
                        }))}
                        {(!addMemberClicked && !parentsRequirementsMet) &&
                            <div className='mt-3'>
                                <button type='button' className='btn btn-block btn-secondary rounded-pill' onClick={()=>{
                                    setAddMemberClicked(true);
                                    setAddRelativesClicked(false);
                                    setAddChildrenClicked(false);
                                    setAddSiblingClicked(false);
                                    }}
                                >{t("forms.addMoreButton.addMember")}</button>
                            </div>
                        }

                        {addMemberClicked &&
                            <FamilyMemberForm 
                                formData={formData}
                                errors={errors}
                                relationships={relationships}
                                allowedRelationships={parentRelationships}
                                handleChange={handleChange}
                                handleCityChange={handleCityChange}
                                handleCountryChange={handleCountryChange}
                                handleNationalityChange={handleNationalityChange}
                                handleProvinceStateChange={handleProvinceStateChange}
                                handleCancelMember={handleCancelMember} 
                                handleAddMember={handleAddMembers}          
                            />
                        }
                </div>
            </div>

            <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
                <p className="text-bold text-center">{t("forms.title.otherRelatives")}</p>
                
                <p className="pr-1 italic">
                    {t("forms.formsquestion.familyMembers.otherRelativesDisclaimer")}
                    <i className="nav-icon fas fa-info-circle"  data-tooltip-id="tooltipOtherRelatives" 
                        data-tooltip-content={t('forms.formsquestion.tooltip.familyMembers.otherRelativesList')}>
                    </i>
                </p>
                
                <Tooltip id='tooltipOtherRelatives' />

                <div>
                    <div className="card-header p-1 mb-1 shadow bg-white" style={{ borderRadius: '15px 15px 0px 0px' }}>
                        <div className="row text-bold">
                            <div className="col-sm col-6 mt-2 text-center">{t("general.table.relation")}</div>
                            <div className="col-sm col-6 mt-2 text-center">{t("general.table.firstname")}</div>
                            <div className="col-sm col-6 mt-2 text-center">{t("general.table.lastname")}</div>
                            <div className="col-sm col-6 mt-2 text-center">{t("general.table.nationality")}</div>
                            <div className="col-sm col-6 mt-2 text-center">{t("general.table.birthday")}</div>
                            <div className="col-sm col-6 mt-2 text-center">{t("general.table.iscanadian")}</div>
                            <div className="col-sm col-6 mt-2 text-center">{t("general.table.actions")}</div>
                        </div>
                    </div>
                    
                    
                    {!members || members.length === 0 ? (
                            <>
                            <p className="text-center">{t("general.table.empty.familytwo")}</p>
                            <p className="text-center">{t("general.table.empty.familythree")}</p>
                            </>
                        ) : (

                        members
                            .filter(member =>  otherRelativesRelationships.includes(member.familyRelationship))
                            .map((member, index) => {

                                const originalIndex = members.findIndex(m => m === member);

                                return (
                                    <div className="card-header p-1 mb-1 shadow bg-white" style={{ borderRadius: '15px' }}>
                                        <div className="row text-center" key={index}>
                                            <div className="col-sm col-6 mt-2">{translateRelationship(member.familyRelationship, lang)}</div>
                                            <div className="col-sm col-6 mt-2">{member.firstname}</div>
                                            <div className="col-sm col-6 mt-2">{member.lastname}</div>
                                            <div className="col-sm col-6 mt-2"><CountryShow value={member.nationality} /></div>
                                            <div className="col-sm col-6 mt-2">{member.birthday}</div>
                                            <div className="col-sm col-6 mt-2">{member.permanent === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`}</div>
                                            <div className="col-sm col-6 mt-2">
                                                <button type='button'
                                                    className="btn flous-bg-primary mr-1 mt-1" 
                                                    onClick={() => {
                                                        setAddRelativesClicked(true);
                                                        handleEditMember(originalIndex);
                                                    }}
                                                >
                                                    <i className="nav-icon fas fa-edit"></i>
                                                </button>
                                                <button type='button' className="btn btn-secondary btn-sm mt-1"
                                                    onClick={() => removeMember(originalIndex)}><i className="nav-icon fas fa-trash"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                )
                        }))}
                        {(!addRelativesClicked) &&
                            <div className='mt-3'>
                                <button type='button' className='btn btn-block btn-secondary rounded-pill' onClick={()=>{
                                    setAddRelativesClicked(true);
                                    setAddMemberClicked(false);
                                    setAddChildrenClicked(false);
                                    setAddSiblingClicked(false);
                                    }}
                                >{t("forms.addMoreButton.addRelatives")}</button>
                            </div>
                        }

                        {addRelativesClicked &&
                            <FamilyMemberForm 
                                formData={formData}
                                errors={errors}
                                relationships={relationships}
                                allowedRelationships={otherRelativesRelationships}
                                handleChange={handleChange}
                                handleCityChange={handleCityChange}
                                handleCountryChange={handleCountryChange}
                                handleNationalityChange={handleNationalityChange}
                                handleProvinceStateChange={handleProvinceStateChange}
                                handleCancelMember={handleCancelMember} 
                                handleAddMember={handleAddMembers}          
                            />
                        }
                </div>
            </div>

            <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
                <p className="text-bold text-center">{t("forms.title.family")}</p>
                
                {errors.canOrRpRequirements && (
                    <>
                        <p style={{ marginTop: '20px', padding: '10px', color: 'red'}}>
                            {errors.canOrRpRequirements}
                        </p>
                    </>
                )}

                <div className='row p-2'>
                    <p htmlFor="totalCanadianOrPResident" className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.permanentOrCitizen")}<RequiredIndicator />
                        <i className="nav-icon fas fa-info-circle"  data-tooltip-id="tooltip2" 
                        data-tooltip-content={t("forms.formsquestion.tooltip.familyMembers.allList")}>
                        </i>
                    </p>
                    <div className="col-sm-6">
                        <input 
                            type="number" 
                            className="form-control rounded-pill flous-input-color" 
                            id="totalCanadianOrPResident" 
                            name="totalCanadianOrPResident"
                            placeholder={`${t('forms.formsquestion.placeholder.numberCanPR')}`}
                            value={familyInfo.totalCanadianOrPResident}
                            onChange={handleNumberChange} min={0}
                            onKeyDown={(e) => NumberInputControl(e)}
                        />
                    </div>
                    <Tooltip id='tooltip2' />
                </div>
            </div>
            
            <div className='d-flex justify-content-center'>
            <div className='col-sm-10'>
                <div className='d-flex flex-column flex-md-row justify-content-between'>
                    <input type="button" name="save" className="col-12 col-md-6 mb-2 mb-md-0 btn flous-btn-thirty rounded-pill mx-1" value={t("forms.click.goBack")} onClick={goBack}/>
                    <input type="button" name="next" className="col-12 col-md-6 btn flous-btn-primary rounded-pill mx-1" value={t("forms.click.pressNext")} onClick={pressNext}/>
                </div>
            </div>
            </div>
        <ToastContainer />
        </div>
    );
};

export default FamilyMembersInfoSection;
