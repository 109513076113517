import React from 'react';
import EducationInCountryInfoResumeSection from './section_education/educationInCountrySection';
import EducationOutsideCountryInfoResumeSection from './section_education/educationOutsideCountrySection';
import SpecialProgramResumeSection from './section_education/specialProgramSection';
import { useTranslation } from 'react-i18next';

export default function EducationResumeSection(props) {
  
  const { t } = useTranslation();

  return (
    <div id='language'>
      <h3 className='flous-police-one flous-input-color text-center'>{t("client.results.blockTitle.bloc5")}</h3>
      <EducationInCountryInfoResumeSection 
        allEducationInCountry={props.allEducationInCountry} 
        show={props.show} 
        educationInCountry={props.educationInCountry.educationInCountry}
        currentlyStudy={props.educationInCountry.currentlyStudy}
      />
      <SpecialProgramResumeSection specialProgram={props.specialProgram} />
      
      <EducationOutsideCountryInfoResumeSection 
      educationOutsideCountry={props.educationOutsideCountry.educationOutsideCountry}
      currentlyStudy={props.educationOutsideCountry.currentlyStudy}
      allEducationOutsideCountry={props.allEducationOutsideCountry} 
      show={props.show} 
      />
      
    </div>
  );
}
