import React, { useEffect, useState } from "react";
import { countryName } from "../../services/utils";
import axios from "axios";

const CountryInput = ({ countryCode }) => {
  const [countryName, setCountryName] = useState(""); // État pour stocker le nom du pays

  // Fonction pour récupérer le nom du pays
  useEffect(() => {
    axios.get(`https://restcountries.com/v3.1/alpha/${countryCode}`)
      .then(response => {
        const country = response.data[0];
        const name = country.name.common;
        setCountryName(name);
      })
      .catch(error => {
        console.error('Error fetching the flag data:', error);
      });

  }, [countryCode]);

  return (
    <input
      type="text"
      id=""
      className="form-control"
      value={countryName}
      placeholder="N.A"
    />
  );
}

export default CountryInput
