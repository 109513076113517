import React, { useEffect, useState } from 'react';
import RequiredIndicator from '../../../../general/requiredIndicator';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import { useTranslation } from "react-i18next";
import { translateText } from '../../../../../services/general/TranslationServices';
import { NumberInputControl } from '../../../../../services/utils';

const AddInfoSection = ( props) => {
  const [addInfo, setAddInfo] = useState(props.addInfo);
  const { t } = useTranslation();
  
  const lang = localStorage.getItem('flouslang') || 'en';

  /*
  const [tooltip1, setTooltip1] = useState();
  const [tooltip2, setTooltip2] = useState();
  const [tooltip3, setTooltip3] = useState();
  const [tooltip4, setTooltip4] = useState();

  const handleTranslateTooltip = async ()=>{
    if (lang !== 'en') {
      const textTranslated1 = await translateText(tooltip1, lang);
      const textTranslated2 = await translateText(tooltip2, lang);
      const textTranslated3 = await translateText(tooltip3, lang);
      const textTranslated4 = await translateText(tooltip4, lang);
      setTooltip1(textTranslated1);
      setTooltip2(textTranslated2);
      setTooltip3(textTranslated3);
      setTooltip4(textTranslated4);
    } else {
      setTooltip1("The total value of all your and your partner’s assets, including property, investments, and valuables, minus any debts or liabilities.");
      setTooltip2("Total income earned in a year, including wages, salaries, and other earnings.");
      setTooltip3("The combined amount of cash and easily accessible funds, such as bank account balances or liquid investments, for you and your partner.");
      setTooltip4("A provincial nomination is a province's approval for your permanent residency application in Canada.");
    }
  };

  useEffect(()=>{
    handleTranslateTooltip();
  }, [lang]);
  */

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedFormData = {
      ...addInfo,
      [name]: value,
    }
    setAddInfo(updatedFormData);
    props.sendAddInfo(updatedFormData);
  };

  return (
      <div className="form-card">
        <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
        <div style={{ color: 'red' }}>{props.error !== '' ? `${t('forms.formsquestion.toast.completeSection')}` : ''}</div>
        <p className='text-bold text-center'>{t("forms.title.addinfo")}</p>
          <div className=''>
            <p htmlFor="netWorth" className=''>{t("forms.formsquestion.personalInfo.netWorth")}<RequiredIndicator />
              <i className="nav-icon fas fa-info-circle"  
                data-tooltip-id="tooltip1" 
                data-tooltip-content={t("forms.formsquestion.tooltip.personalInfomation.addInfo.tooltip1")}
              >
              </i>
            </p>
            
            <div className="mb-2">
              <input
                type="number"
                className="form-control rounded-pill flous-input-color"
                id="netWorth"
                name="netWorth"
                placeholder="$ (CAD)"
                value={addInfo.netWorth}
                onChange={handleChange} min={0}
                onKeyDown={(e) => NumberInputControl(e)}
              />
            </div>
            <Tooltip id='tooltip1' />
            <p htmlFor="earningsHistory" className=''>
              {t("forms.formsquestion.personalInfo.earningsHistory")}<RequiredIndicator />
              <i className="nav-icon fas fa-info-circle"  
                data-tooltip-id="tooltip2" 
                data-tooltip-content={t("forms.formsquestion.tooltip.personalInfomation.addInfo.tooltip2")}
              >

              </i>
            </p>
            <div className="mb-2">
              <input
                type="number"
                className="form-control rounded-pill flous-input-color"
                id="earningsHistory"
                name="earningsHistory"
                placeholder="$ (CAD)"
                value={addInfo.earningsHistory}
                onChange={handleChange} min={0}
                onKeyDown={(e) => NumberInputControl(e)}
              />
            </div>
            <Tooltip id='tooltip2' />
            <p htmlFor="totalCurrentAssets" className=''>
            {t("forms.formsquestion.personalInfo.totalCurrentAssets")}<RequiredIndicator />
            <i className="nav-icon fas fa-info-circle"  
              data-tooltip-id="tooltip3" 
              data-tooltip-content={t("forms.formsquestion.tooltip.personalInfomation.addInfo.tooltip3")}
            ></i>
            </p>
            <div className="mb-2">
              <input
                type="number"
                className="form-control rounded-pill flous-input-color"
                id="totalCurrentAssets"
                name="totalCurrentAssets"
                placeholder="$ (CAD)"
                value={addInfo.totalCurrentAssets}
                onChange={handleChange} min={0}
                onKeyDown={(e) => NumberInputControl(e)}
              />
            </div>
            <Tooltip id='tooltip3' />
          </div>
          <div className='row p-2'>
            <p className='col-sm-10 col-form-label'>{t("forms.formsquestion.personalInfo.provincialNomination")} {props.evaluationCountry}?<RequiredIndicator />
              <i className="nav-icon fas fa-info-circle"  
              data-tooltip-id="tooltip4" 
              data-tooltip-content={t("forms.formsquestion.tooltip.personalInfomation.addInfo.tooltip4")}
              ></i>
            </p>
            
            <div className='col-sm-2'>
              <div className='row'>
                <div className="col form-check">
                  <input 
                    type="radio"
                    className="form-check-input"
                    id="provincialNominationYes"
                    name="provincialNomination"
                    value="Yes"
                    onChange={handleChange}
                    checked={addInfo.provincialNomination === "Yes"}
                  />
                  <label className="form-check-label" htmlFor="provincialNominationYes">{t("forms.click.yes")}</label>
                </div>
                <div className="col form-check">
                  <input 
                    type="radio"
                    className="form-check-input"
                    id="provincialNominationNo"
                    name="provincialNomination"
                    value="No"
                    onChange={handleChange}
                    checked={addInfo.provincialNomination === "No"}
                  />
                  <label className="form-check-label" htmlFor="provincialNominationNo">{t("forms.click.no")}</label>
                </div>
              </div>
            </div>
            <Tooltip id='tooltip4' />
          </div>
        </div>
      </div>
  );
};

export default AddInfoSection;