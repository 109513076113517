import React, { useEffect, useState } from 'react';
import RequiredIndicator from '../../../../../general/requiredIndicator';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import { useTranslation } from 'react-i18next';
import { translateText } from '../../../../../../services/general/TranslationServices';

const OwnerShipDetailSection = (props) => {
  const [formData, setFormData] = useState(props.ownershipDetail);
  const { t } = useTranslation();
  
  const lang = localStorage.getItem('flouslang') || 'en';

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedData);
    props.sendOwnershipDetail(updatedData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? `${t('forms.formsquestion.toast.completeSection')}` : ''}</div>
        <p className='text-bold text-center'>{props.titre}</p>
        <div className='row p-2'>
          <p htmlFor="companyName" className="col-sm-6 col-form-label">{t("forms.formsquestion.entrepreunarialExperience.companyName")}<RequiredIndicator /></p>
          <div className="col-sm-6">
            <input 
              type="text" 
              className="form-control rounded-pill flous-input-color"
              id="companyName"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              placeholder={`${t('forms.formsquestion.placeholder.companyName')}`}
            />
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="ownershipPercentage" className="col-sm-6 col-form-label">{t("forms.formsquestion.entrepreunarialExperience.ownershipPercentage")}<RequiredIndicator />
            <i className="nav-icon fas fa-info-circle"  
            data-tooltip-id="tooltip1" data-tooltip-content={t("forms.formsquestion.tooltip.enterpreneurial.tooltip2")}
            ></i>
          </p>
          <div className="col-sm-6">
            <input 
              type="number" 
              className="form-control rounded-pill flous-input-color"
              id="ownershipPercentage"
              name="ownershipPercentage"
              value={formData.ownershipPercentage}
              onChange={handleChange} min={0}
              placeholder={`${t('forms.formsquestion.placeholder.ownership')}`}
            />
          </div>
          <Tooltip id='tooltip1' />
        </div>
        <div className='row p-2'>
          <p htmlFor="personalEquityInvestment" className="col-sm-6 col-form-label">{t("forms.formsquestion.entrepreunarialExperience.personalEquityInvestment")}<RequiredIndicator /></p>
          <div className="col-sm-6">
            <input 
              type="number" 
              className="form-control rounded-pill flous-input-color"
              id="personalEquityInvestment"
              name="personalEquityInvestment"
              value={formData.personalEquityInvestment}
              onChange={handleChange} min={0}
              placeholder='$ (CAD)'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OwnerShipDetailSection;