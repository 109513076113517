import React, { useEffect, useState } from 'react';
import CountrySelect from '../../../../general/CountrySelect'
import LoadingOverlay from '../../../../general/LoadingOverlay';
import { getFormOwner } from '../../../../../services/users/FormsServices';
import { toast, ToastContainer } from 'react-toastify';
import RequiredIndicator from '../../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";
import { dateFieldNames, maxDate } from '../../../../../services/utils';

const IdentificationSection = (props) => {
  const [formData, setFormData] = useState(props.identification);

  const [owner, setOwner] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    dateOfBirth: ''
  })

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {

    const handleOwner = async(code) => {
      // setLoading(true);
      try{
        const response = await getFormOwner(code);
  
        if (response.status === 200){
          setOwner(response.data);
          const updatedFormData = {
            ...formData,
            firstname: response.data.firstName,
            lastname: response.data.lastName,
          };
          setFormData(updatedFormData);
          props.sendIdentification(updatedFormData);
        }
  
        // await new Promise((resolve) => setTimeout(resolve, 500));
        // setLoading(false);
  
      }  catch (error){
        // setLoading(false);
        if (error.response && [400, 500].includes(error.response.status)){
          // toast.error(error.response.data.error);
        }
      }
    };

    handleOwner(props.formCode);
    
  }, []);

  //
  const handleChange = (event) => {
    const { name, value } = event.target;

    if (dateFieldNames.includes(name) && value > maxDate) return ;

    const updatedFormData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedFormData);
    props.sendIdentification(updatedFormData);
  };

  const handleCountryChange = (e) =>{
    const value = e.target.value;
    const updatedFormData = {
      ...formData,
      countryofbirth: value,
    };
    setFormData(updatedFormData);
    props.sendIdentification(updatedFormData);
  }

  if (loading) {
      return <LoadingOverlay />;
  }

  return (
      <div className="form-card">
        <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
        <div style={{ color: 'red' }}>{props.error !== '' ? `${t('forms.formsquestion.toast.completeSection')}` : ''}</div>
          <p className='text-bold text-center'>{t("forms.title.identification")}</p>
          <div className='row p-2'>
            <p htmlFor="firstname" className="col-sm-4 col-form-label">{t("forms.formsquestion.personalInfo.firstname")}<RequiredIndicator /></p>
            <div className="col-sm-8">
              <input 
                type="text" 
                className="form-control rounded-pill flous-input-color" 
                id="firstname" 
                name="firstname" 
                placeholder={`${t('forms.formsquestion.placeholder.firstname')}`}
                value={formData.firstname}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='row p-2'>
            <p htmlFor="lastname" className="col-sm-4 col-form-label">{t("forms.formsquestion.personalInfo.lastname")} <RequiredIndicator /></p>
            <div className="col-sm-8">
              <input 
                type="text" 
                className="form-control rounded-pill flous-input-color" 
                id="lastname" 
                name="lastname" 
                placeholder={`${t('forms.formsquestion.placeholder.lastname')}`}
                value={formData.lastname}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='row p-2'>
            <p htmlFor="dateofbirth" className="col-sm-4 col-form-label">{t("forms.formsquestion.personalInfo.dateofbirth")}<RequiredIndicator /></p>
            <div className="col-sm-8">
              <input 
                type="date" 
                className="form-control rounded-pill flous-input-color" 
                id="dateofbirth" 
                name="dateofbirth" 
                placeholder="DD/MM/YYYY"
                value={formData.dateofbirth}
                onChange={handleChange} max={new Date().toISOString().split("T")[0]}
              />
            </div>
          </div>
          <div className='row p-2'>
            <p htmlFor="countryofbirth" className="col-sm-4 col-form-label">{t("forms.formsquestion.personalInfo.countryofbirth")}<RequiredIndicator /></p>
            <div className="col-sm-8">
              <CountrySelect value={formData.countryofbirth} onChange={handleCountryChange} required />              
            </div>
          </div>
          <div className='row p-2'>
            <p htmlFor="countryofbirth" className="col-sm-4 col-form-label">{t("forms.formsquestion.personalInfo.cityofbirth")}<RequiredIndicator /></p>
            <div className="col-sm-8">
            <input 
              type="text" 
              className="form-control rounded-pill flous-input-color" 
              id="city" 
              name="cityofbirth" 
              value={formData.cityofbirth} 
              onChange={handleChange}
              placeholder={`${t('forms.formsquestion.placeholder.city')}`}
            />
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
  );
};

export default IdentificationSection;
