import React, { useState, useEffect } from 'react';
import { countriesWithProvinces, provincesWithCities, sortDictionaryByValues } from '../../../../../services/utils';
import api from '../../../../../services/api';
import CitySelect from '../../../../general/CitySelect';
import ProvinceSelect from '../../../../general/ProvinceSelect';
import CountrySelect from '../../../../general/CountrySelect';
import Select from 'react-select';
import RequiredIndicator from '../../../../general/requiredIndicator';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import { useTranslation } from "react-i18next";
import { translateDictionaryList, translateText } from '../../../../../services/general/TranslationServices';

const InstitutionSection = (props) => {
  const [formData, setFormData] = useState(props.institution);

  const [provinceInstitution, setProvincesInstitution] = useState([]);
  const [generalStudyFields, setGeneralStudyFields] = useState([]);
  const [studyFields, setStudyFields] = useState([]);
  const [studyLanguages, setStudyLanguages] = useState([
    {value: 'FR', label: 'Français'}, 
    {value: 'EN', label: 'English'}, 
  ]);
  const [studyDurations, setStudyDurations] = useState([]);
  const { t } = useTranslation();
  
  const lang = localStorage.getItem('flouslang') || 'en';

  const [studyDurationsOriginal, setStudyDurationsOriginal] = useState([]);
  const [studyFieldsOriginal, setStudyFieldsOriginal] = useState([]);
  const [generalStudyFieldsOriginal, setGeneralStudyFieldsOriginal] = useState([]);

  const handleTranslate = async (data)=>{
    if (lang !== 'en') {
      const studyDurationsTranslated = await translateDictionaryList(studyDurationsOriginal, lang);
      setStudyDurations(studyDurationsTranslated);
      const studyFieldsTranslated = await translateDictionaryList(studyFieldsOriginal, lang);
      setStudyFields(studyFieldsTranslated);
      const generalStudyFieldsTranslated = await translateDictionaryList(generalStudyFieldsOriginal, lang);
      setGeneralStudyFields(generalStudyFieldsTranslated);
    } else {
      setStudyFields(studyFieldsOriginal);
      setGeneralStudyFields(generalStudyFieldsOriginal);
      setStudyDurations(studyDurationsOriginal);
    }
  };

  useEffect(()=>{
    handleTranslate();
  }, [lang, studyFieldsOriginal, generalStudyFieldsOriginal, studyDurationsOriginal]);

  useEffect(() => {
    const fetchGeneralStudyField = async () =>{
      const response = await api.get('/generaltrainingfield/');
      const sorted = sortDictionaryByValues(response.data);
      const options = getOptions(sorted);
      setGeneralStudyFields(options);
      setGeneralStudyFieldsOriginal(options);
    };  
    const fetchStudyField = async () =>{
        const response = await api.get('/trainingfieldinside/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setStudyFields(options);
        setStudyFieldsOriginal(options);
    };
    const fetchStudyDuration = async () =>{
        const response = await api.get('/yearsofstudy/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setStudyDurations(options);
        setStudyDurationsOriginal(options);
    };

    fetchGeneralStudyField();
    fetchStudyField();
    // fetchStudyLanguage();
    fetchStudyDuration();

  }, []);

  useEffect(() => {
    const fetchCanadaInstitution = async () =>{
      const response = await api.post('/canada/institutions/', {
        'province': formData.provinceState
      });
      const sorted = sortDictionaryByValues(response.data);
      const options = getOptions(sorted);
      setProvincesInstitution(options);
    }; 
    fetchCanadaInstitution();
  }, [formData.provinceState])

  const getOptions = (data) => {
    return Object.entries(data).map(([key, val]) => ({
      value: key,
      label: val,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updateData = {
      ...formData,
      [name]: value,
    }
    setFormData(updateData);
    props.sendInstitution(updateData);
  };

  const handleProvinceStateChange = (e) =>{
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      provinceState: value,
    };
    setFormData(updatedFormData);
    props.sendInstitution(updatedFormData);
  };

  const handleCityChange = (e) =>{
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      city: value,
    };
    setFormData(updatedFormData);
    props.sendInstitution(updatedFormData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      {props.error && (
        <>
            <p style={{ marginTop: '20px', padding: '10px', color: 'red'}}>
                {props.error}
            </p>
        </>
      )}
      <p className='text-bold text-center'>{t("forms.title.institution")}</p>
        <div className='row p-2'>
          <p htmlFor="address" className="col-form-label">{t("forms.formsquestion.educationInCountry.address")}<RequiredIndicator /></p>
        </div>
        
        <div className='row p-2'>
          <p htmlFor="provinceState" className="col-sm-6 col-form-label">{t("forms.formsquestion.educationInCountry.provinceState")}<RequiredIndicator /></p>
          <div className="col-sm-6">
            {countriesWithProvinces.includes(formData.country) ? 
              (<ProvinceSelect 
                value={formData.provinceState} 
                onChange={handleProvinceStateChange} 
                country={formData.country} required
                />
              ):
              (<input 
                type="text" 
                className="form-control rounded-pill flous-input-color" 
                id="provinceState" 
                name="provinceState" 
                value={formData.provinceState} 
                onChange={handleChange} 
                placeholder={`${t('forms.formsquestion.placeholder.province')}`}
              />
              )}
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="city" className="col-sm-6 col-form-label">{t("forms.formsquestion.educationInCountry.city")}<RequiredIndicator /></p>
          <div className="col-sm-6">
            {countriesWithProvinces.includes(formData.country) ? 
              (<CitySelect 
                value={formData.city} 
                onChange={handleCityChange} 
                province={formData.provinceState} 
                required
                />
              ):
              (
                <input 
                type="text" 
                className="form-control rounded-pill flous-input-color" 
                id="city" 
                name="city" 
                value={formData.city} 
                onChange={handleChange} 
                placeholder={`${t('forms.formsquestion.placeholder.city')}`}
              />
              )}
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="institutionName" className="col-sm-6 col-form-label">{t("forms.formsquestion.educationInCountry.institutionName")}<RequiredIndicator /></p>
          <div className="col-sm-6">
            {(formData.provinceState && provinceInstitution.length >0) ? (
              <Select
              name="institutionName"
              className="react-select-container rounded-pill flous-input-color"
              classNamePrefix="react-select"
              value={provinceInstitution.find(option => option.value === formData.institutionName)}
              onChange={(selectedOption) => handleChange({ target: { name: 'institutionName', value: selectedOption ? selectedOption.value : '' } })}
              options={provinceInstitution}
              placeholder={`${t('forms.formsquestion.placeholder.option')}`}
              isSearchable
              isClearable
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                  borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                  borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                }),
              }}
              required
            />
            ) : (
              <input 
                type="text" 
                name="institutionName"
                className="form-control rounded-pill flous-input-color"
                onChange={handleChange}
                value={formData.institutionName}
                placeholder={`${t('forms.formsquestion.placeholder.institutionName')}`}
              />
            )}
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="languageOfInstruction" className="col-sm-6 col-form-label">{t("forms.formsquestion.educationInCountry.languageOfInstruction")}<RequiredIndicator /></p>
          <div className="col-sm-6">
          <Select
              name="languageOfInstruction"
              className="react-select-container rounded-pill flous-input-color"
              classNamePrefix="react-select"
              value={studyLanguages.find(option => option.value === formData.languageOfInstruction)}
              onChange={(selectedOption) => handleChange({ target: { name: 'languageOfInstruction', value: selectedOption ? selectedOption.value : '' } })}
              options={studyLanguages}
              placeholder={`${t('forms.formsquestion.placeholder.option')}`}
              isSearchable
              isClearable
              required
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                  borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                  borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                }),
              }}
            />
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="fullTimeStudent" className="col-sm-10 col-form-label">{t("forms.formsquestion.educationInCountry.fullTimeStudent")}<RequiredIndicator /></p>
          <div className='col-sm-2'>
            <div className='row'>
              <div className="col form-check">
                <input 
                  type="radio"
                  id="fullTimeStudentYes"
                  name="fullTimeStudent"
                  className="form-check-input" 
                  value="Yes"
                  onChange={handleChange}
                  checked={formData.fullTimeStudent === "Yes"}
                />
                <p htmlFor="fullTimeStudentYes" className="form-check-p">{t("forms.click.yes")}</p>
              </div>
              <div className="col form-check">
                <input 
                  type="radio"
                  id="fullTimeStudentNo"
                  name="fullTimeStudent"
                  className="form-check-input" 
                  value="No"
                  onChange={handleChange}
                  checked={formData.fullTimeStudent === "No"}
                />
                <p htmlFor="fullTimeStudentNo" className="form-check-p">{t("forms.click.no")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="generalFieldOfStudy" className="col-sm-6 col-form-label">{t("forms.formsquestion.educationInCountry.generalFieldOfStudy")}<RequiredIndicator /></p>
          <div className="col-sm-6">
            <Select
              name="generalFieldOfStudy"
              className="react-select-container rounded-pill flous-input-color"
              classNamePrefix="react-select"
              value={generalStudyFields.find(option => option.value === formData.generalFieldOfStudy)}
              onChange={(selectedOption) => handleChange({ target: { name: 'generalFieldOfStudy', value: selectedOption ? selectedOption.value : '' } })}
              options={generalStudyFields}
              placeholder={`${t('forms.formsquestion.placeholder.option')}`}
              isSearchable
              isClearable
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                  borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                  borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                }),
              }}
              required
            />
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="fieldOfStudy" className="col-sm-6 col-form-label">{t("forms.formsquestion.educationInCountry.fieldOfStudy")}<RequiredIndicator />
            <i className="nav-icon fas fa-info-circle"  
              data-tooltip-id="tooltipSpecFieldStudy" 
              data-tooltip-content={t("forms.formsquestion.tooltip.education.tooltip1")}
            ></i>
          </p>
          
          <div className="col-sm-6">
            <Select
              name="fieldOfStudy"
              className="react-select-container rounded-pill flous-input-color"
              classNamePrefix="react-select"
              value={studyFields.find(option => option.value === formData.fieldOfStudy)}
              onChange={(selectedOption) => handleChange({ target: { name: 'fieldOfStudy', value: selectedOption ? selectedOption.value : '' } })}
              options={studyFields}
              placeholder={`${t('forms.formsquestion.placeholder.option')}`}
              isSearchable
              isClearable
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                  borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                  borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                }),
              }}
              required
            />
          </div>
          <Tooltip id='tooltipSpecFieldStudy' />
        </div>
        <div className='row p-2'>
          <p htmlFor="studyDuration" className="col-sm-6 col-form-label">{t("forms.formsquestion.educationInCountry.studyDuration")}<RequiredIndicator />
            <i className="nav-icon fas fa-info-circle"  
              data-tooltip-id="tooltip2" 
              data-tooltip-content={t("forms.formsquestion.tooltip.education.tooltip2")}>
            </i>
          </p>
          
          <div className="col-sm-6">
          <Select
              name="studyDuration"
              className="react-select-container rounded-pill flous-input-color"
              classNamePrefix="react-select"
              value={studyDurations.find(option => option.value === formData.studyDuration)}
              onChange={(selectedOption) => handleChange({ target: { name: 'studyDuration', value: selectedOption ? selectedOption.value : '' } })}
              options={studyDurations}
              placeholder={`${t('forms.formsquestion.placeholder.option')}`}
              isSearchable
              isClearable
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                  borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                  borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                }),
              }}
              required
            />
          </div>
          <Tooltip id='tooltip2' />
        </div>
      </div>
    </div>
  );
};

export default InstitutionSection;
