import React, { useEffect, useState } from 'react';
import { sortDictionaryByValues } from '../../../../../services/utils';
import api from '../../../../../services/api';
import Select from 'react-select';
import RequiredIndicator from '../../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";
import { translateDictionaryList } from '../../../../../services/general/TranslationServices';

const StatusInCountrySection = (props) => {
  const [formData, setFormData] = useState(props.statusInCountry);
  const [legalStatus, setLegalStatus] = useState([]);
  
  const lang = localStorage.getItem('flouslang') || 'en';
  const [legalStatusOriginal, setLegalStatusOriginal] = useState([]);
  const { t } = useTranslation();

  const handleTranslate = async (data)=>{
    if (lang !== 'en') {
      const legalStatusTranslated = await translateDictionaryList(legalStatusOriginal, lang);
      setLegalStatus(legalStatusTranslated);
    } else {
      setLegalStatus(legalStatusOriginal);
    }
  };

  useEffect(()=>{
    handleTranslate();
  }, [lang, legalStatusOriginal]);

  useEffect(() => {
    const fetchLegalStatus = async () =>{
        const response = await api.get('/legalstatus/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setLegalStatus(options);
        setLegalStatusOriginal(options);
    };
    fetchLegalStatus();

  }, []);

  const getOptions = (data) => {
    return Object.entries(data).map(([key, val]) => ({
      value: key,
      label: val,
    }));
  };

  const handleChange = (event) =>{
    const { name, value } = event.target;
    const updatedFormData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedFormData);
    props.sendStatusInCountry(updatedFormData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      {props.error && (
          <>
              <p style={{ marginTop: '20px', padding: '10px', color: 'red'}}>
                  {props.error}
              </p>
          </>
      )}
      <p className='text-bold text-center'>{t("forms.title.statusin")} {t('countries.canada')} </p>
        <div className='row p-2'>
          <p htmlFor="currentStatus" className="col-sm-6 col-form-label">{t("forms.formsquestion.residences.currentStatus")} {t('countries.canada')} ?<RequiredIndicator /></p>
          <div className="col-sm-6">
            <Select
              id="currentStatus"
              name="currentStatus"
              className="react-select-container rounded-pill flous-input-color"
              classNamePrefix="react-select"
              value={legalStatus.find(option => option.value === formData.currentStatus)}
              onChange={(selectedOption) => handleChange({ target: { name: 'currentStatus', value: selectedOption ? selectedOption.value : '' } })}
              options={legalStatus}
              placeholder={`${t('forms.formsquestion.placeholder.option')}`}
              isSearchable
              isClearable
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                  borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                  borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                }),
              }}
              required
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusInCountrySection;