import React, { useEffect, useState } from 'react';
import { Page, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import CountryShow from '../../../general/countryShow';
import api from '../../../../services/api';
import styles from '../styles/pdfstyles';
import { canadaStatesConverter, educationLevel, generalField, languageConverter, quebecEducationLevel, recognitionOrganization, translateYearOfStudy } from '../../../../services/utils';
import InstitutionShow from '../../../general/institutionShow';

export default function EducationResumeSectionPDF(props) {
  const { t } = useTranslation();

  const lang = localStorage.getItem('flouslang') || 'en';

  const [provinces, setProvinces] = useState([]);

  useEffect(() => {
      const fetchProvinces = async() => {
        const response = await api.get('/canada-provinces/');
          const provinceOptions = Object.entries(response.data).map(([key, val]) => ({
            value: key,
            label: val,
          }));
          setProvinces(provinceOptions);
      };
  
      fetchProvinces();
  }, []);

  const renderEducationInCanada = () => (
    <View style={styles.card}>

      <Text style={styles.cardTitle}>{t("forms.title.educationin")} {t('countries.canada')}</Text>
      <View style={styles.row}>
        <View style={styles.column70}>
          <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.currentlyStudy")} {t('countries.canada')} ?</Text>
        </View>
        <View style={styles.column30}>
          <Text style={styles.value}>{props.educationInCountry.currentlyStudy === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.column70}>
          <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.educationInCountry")} {t('countries.canada')} ?</Text>
        </View>
        <View style={styles.column30}>
          <Text style={styles.value}>{props.educationInCountry.educationInCountry === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
        </View>
      </View>

      {props.allEducationInCountry.map((educationIn, index) => (
        <View key={index} style={styles.educationCard}>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.provinceState")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>
                {provinces.find(province => province.value === educationIn.institution.provinceState)?.label || ''}
              </Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.city")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>
                {educationIn.institution.city}
              </Text>
            </View>
          </View>
          
          {/* Additional details */}
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.languageOfInstruction")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{languageConverter(educationIn.institution.languageOfInstruction)}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.generalFieldOfStudy")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{generalField(educationIn.institution.generalFieldOfStudy)}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{width: '50%'}}>
              <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.fieldOfStudy")}</Text>
              </View>
            <View style={{width: '50%'}}>
              <Text style={styles.value}>{educationIn.institution.fieldOfStudy}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.column70}>
              <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.studyDuration")}</Text>
              </View>
            <View style={styles.column30}>
              <Text style={styles.value}>{translateYearOfStudy(educationIn.institution.studyDuration, lang)}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.institutionName")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}><InstitutionShow value={educationIn.institution.institutionName} /></Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.fullTimeStudent")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationIn.institution.fullTimeStudent === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.column50}>
              <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.degreeDiploma")}</Text>
            </View>
            <View style={styles.column50}>
              <Text style={styles.value}>{educationLevel(educationIn.degreeDiploma.degreeDiploma, lang)}</Text>
            </View>
          </View>
          {educationIn.degreeDiploma.degreeDiploma === "PRG" && (
            <View style={styles.row}>
            <View style={styles.column50}>
              <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.prgDegreeDiploma")}</Text>
            </View>
            <View style={styles.column50}>
              <Text style={styles.value}>{quebecEducationLevel(educationIn.degreeDiploma.prgDegreeDiploma, lang)}</Text>
            </View>
            </View>
          )}

          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.startDate")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationIn.degreeDiploma.startDate}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.endDate")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationIn.degreeDiploma.endDate}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.column70}>
              <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.pgwpEligibility")}</Text>
              </View>
            <View style={styles.column30}>
              <Text style={styles.value}>{educationIn.degreeDiploma.pgwpEligibility === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.column70}>
              <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.equivalentProcessCompleted")}</Text>
              </View>
            <View style={styles.column30}>
              <Text style={styles.value}>{educationIn.diplomaRecognition.equivalentProcessCompleted === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
            </View>
          </View>
          { (educationIn.provinceProcessed && educationIn.provinceProcessed.length > 0) && (
            <View style={styles.row}>
            <View style={{width: '50%'}}>
              <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.provinceProcessed")}</Text>
            </View>
          {educationIn.provinceProcessed && educationIn.provinceProcessed.length > 0 ? (
                educationIn.provinceProcessed.map((credential, index) => (
              <View style={{width: '50%'}}>
                <Text style={styles.value}>{canadaStatesConverter(credential.provinceState)}</Text>
              </View>
            ))
            ) : (
              <Text style={styles.cardTitle}>{t("general.table.applicantEmpty.noElement")}</Text>
          )}
          </View>
          )}
        </View>
      ))}
    </View>
  );

  const renderSpecialProgram = () => (
    <View style={styles.card}>
      <Text style={styles.cardTitle}>{t("forms.title.specialprogram")}</Text>
      <View style={styles.row}>
        <View style={styles.column70}>
          <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.homeCareProgram")}</Text>
        </View>
        <View style={styles.column30}>
          <Text style={styles.value}>{props.specialProgram.homeCareProgram === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
        </View>
      </View>
      {props.specialProgram.homeCareProgram === 'No' && (
        <View style={styles.row}>
        <View style={styles.column70}>
          <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.proposeProgram")}</Text>
        </View>
        <View style={styles.column30}>
          <Text style={styles.value}>{props.specialProgram.proposeProgram === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
        </View>
      </View>
      )}
    </View>
  );

  const renderEducationOutsideCanada = () => (
    <View style={styles.card}>
      <Text style={styles.cardTitle}>{t("forms.title.educationoutside")} {t('countries.canada')} </Text>
      <View style={styles.row}>
        <View style={styles.column70}>
          <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.currentlyStudy")} {t('countries.canada')} ?</Text>
        </View>
        <View style={styles.column30}>
          <Text style={styles.value}>{props.educationOutsideCountry.currentlyStudy === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.column70}>
          <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.educationOutsideCountry")} {t('countries.canada')} ?</Text>
        </View>
        <View style={styles.column30}>
          <Text style={styles.value}>{props.educationOutsideCountry.educationOutsideCountry === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
        </View>
      </View>
      {props.allEducationOutsideCountry.map((educationOut, index) => (
        <View key={index} style={styles.educationCard}>
          <View style={styles.row}>
            <View style={styles.column50}>
              <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.degreelevel")}</Text>
            </View>
            <View style={styles.column50}>
              <Text style={styles.value}>{educationLevel(educationOut.educationDetail.degreelevel, lang)}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.column50}>
              <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.generalFieldOfStudy")}</Text>
            </View>
            <View style={styles.column50}>
              <Text style={styles.value}>{generalField(educationOut.educationDetail.generalFieldOfStudy, lang)}</Text>
            </View>
          </View>

          <View style={styles.row}>
          {educationOut.educationDetail.degreelevel === "PRG" && (
              <>
              <View style={styles.column50}>
                <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.prgDegreeDiploma")}</Text>
              </View>
              <View style={styles.column50}>
                <Text style={styles.value}>{quebecEducationLevel(educationOut.educationDetail.prgDegreeDiploma)}</Text>
              </View>
              </>
            )}
          </View>
          <View style={styles.row}>
            <View style={{width: '50%'}}>
              <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.fieldOfStudy")}</Text>
            </View>
            <View style={{width: '50%'}}>
              <Text style={styles.value}>{educationOut.educationDetail.fieldOfStudy}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{width: '80%'}}>
              <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.programDuration")}</Text>
            </View>
            <View style={{width: '20%'}}>
              <Text style={styles.value}>{translateYearOfStudy(educationOut.educationDetail.programDuration, lang)}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{width: '10%'}}>
              <Text style={styles.label}>{t("general.table.name")}</Text>
            </View>
            <View style={{width: '90%'}}>
              <Text style={styles.value}>{educationOut.educationInstitution.institutionName}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.address")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}><CountryShow value={educationOut.educationInstitution.country}/></Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.provinceState")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>
                {educationOut.educationInstitution.provinceState}
              </Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.city")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationOut.educationInstitution.city}</Text>
            </View>
          </View>
          
          {/* Additional details */}
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("general.table.fulltime")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationOut.educationInstitution.fullTimeStudent === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationInCountry.languageOfInstruction")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{languageConverter(educationOut.educationInstitution.languageOfInstruction)}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.startDate")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationOut.dateofStudy.startDate}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.endDate")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{educationOut.dateofStudy.endDate}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{width: '80%'}}>
              <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.ecaObtained")}</Text>
            </View>
            <View style={{width: '20%'}}>
              <Text style={styles.value}>{educationOut.educationalCredentialAssessment.ecaObtained === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{width: '50%'}}>
              <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.issuingOrganization")}</Text>
            </View>
            <View style={{width: '50%'}}>
              <Text style={styles.value}>{recognitionOrganization(educationOut.educationalCredentialAssessment.issuingOrganization, lang)}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{width: '50%'}}>
              <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.recognizedCredential")}</Text>
            </View>
            <View style={{width: '50%'}}>
              <Text style={styles.value}>{educationOut.educationalCredentialAssessment.recognizedCredential}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{width: '50%'}}>
              <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.recognizedDiplomaLevel")}</Text>
            </View>
            <View style={{width: '50%'}}>
              <Text style={styles.value}>{educationLevel(educationOut.educationalCredentialAssessment.recognizedDiplomaLevel, lang)}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{width: '80%'}}>
              <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.epqObtained")}</Text>
            </View>
            <View style={{width: '20%'}}>
              <Text style={styles.value}>{educationOut.equivalencyProcessQuebec.epqObtained === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
            </View>
          </View>
          {educationOut.equivalencyProcessQuebec.epqObtained === 'Yes' && (
          <>
            <View style={styles.row}>
            <View style={{width: '50%'}}>
              <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.recognizedCredential")}</Text>
            </View>
            <View style={{width: '50%'}}>
              <Text style={styles.value}>{educationOut.equivalencyProcessQuebec.recognizedCredential}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{width: '50%'}}>
              <Text style={styles.label}>{t("forms.formsquestion.educationOutsideCountry.recognizedDiplomaLevel")}</Text>
            </View>
            <View style={{width: '50%'}}>
              <Text style={styles.value}>{educationLevel(educationOut.equivalencyProcessQuebec.recognizedDiplomaLevel, lang)}</Text>
            </View>
          </View>
          </>
          )}
        </View>
      ))}
    </View>
  );

  return (
    <Page style={styles.page}>
      <Text style={styles.mainTitle}>{t("client.results.blockTitle.bloc5")}</Text>
      {(props.educationInCountry.educationInCountry === 'Yes' || props.educationInCountry.currentlyStudy === 'Yes') && renderEducationInCanada()}
      {(props.educationInCountry.educationInCountry !== 'Yes' && props.educationInCountry.currentlyStudy !== 'Yes') && (
        <View style={styles.card}>
          <Text style={styles.cardTitle}>{t("general.table.applicantEmpty.educationin")}</Text>
        </View>
      )}
      {renderSpecialProgram()}
      {(props.educationOutsideCountry.educationOutsideCountry === 'Yes' || props.educationOutsideCountry.currentlyStudy === 'Yes') && renderEducationOutsideCanada()}
      {(props.educationOutsideCountry.educationOutsideCountry !== 'Yes' && props.educationOutsideCountry.currentlyStudy !== 'Yes') && (
        <View style={styles.card}>
          <Text style={styles.cardTitle}>{t("general.table.applicantEmpty.educationout")}</Text>
        </View>
      )}
    </Page>
  );
}

