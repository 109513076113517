import React, { useState, useEffect } from 'react';
import PaginationSection from '../Layout/PaginationSection';
import NoItemsMessage from '../general/NoItemsToDisplaysItem';
import GoToBackButton from '../general/GoBackLink';
import Select from 'react-select';
import { getCurrentItems } from '../../services/users/FormsServices';
import { truncateMessage } from '../../services/utils';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingOverlay from '../general/LoadingOverlay';
import { fetchBlogData } from '../../services/flous/blogServices';
import api from '../../services/api';
import { useTranslation } from 'react-i18next';
import DOMPurify from "dompurify";

export default function FlousBlogsPage() {

  const [blogs, setBlog] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useOutletContext();
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const pageSize = 5;
  const onPageChange = (page) => {
    setPage(page + 1);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  useEffect( () => {
    let isMounted = true;

    const getSoloDataList = async () => {
      if (user.id) {
        try {
          const response = await fetchBlogData();
          if (isMounted) {
            setBlog(response.data.data);
            setLoading(false);
          }
        } catch (error) {
          // console.error('Error when preparing data:', error);
          if (isMounted){
            setLoading(false);
            if (error.response && [400, 500].includes(error.response.status)) {
              setError(error);
              toast.error(error.response.data.error);
            }
          }
        }
      }
    };

    getSoloDataList();

    return () => {
      isMounted = false;
    };

  }, []);

  const [provinces, setProvinces] = useState([]);

  useEffect(() => {
      const fetchProvinces = async() => {
        
          const response = await api.get('/canada-provinces/');
          const provinceOptions = Object.entries(response.data).map(([key, val]) => ({
            value: key,
            label: val,
          }));
          setProvinces(provinceOptions);
        
      };
  
      fetchProvinces();
  }, []);

  const currentItems = getCurrentItems(blogs, page, pageSize);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }
  
  return (
    <div className="content">
      <div className="container-fluid">
        <div className="content-header">
          <div className="container-fluid">
            <div className="mb-2">
              <GoToBackButton />
              <div className="col-sm-6">
                <h1 className="m-0 flous-police-one flous-input-color">{t("flous.blogs.title")}</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="row">
                <a href='/flous/blogs/create' className="btn btn-lg flous-bg-primary col-sm-6 col-12">{t("flous.blogs.create")}</a>
                </div>
                <br />
                <div className="card elevation-2">
                  <div className="card-header">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-sm col-12">
                        <div className="form-group">
                          <Select
                            name="company"
                            classNamePrefix="react-select"
                            // value={options.companies.find(option => option.value === selectedCompany)}
                            // onChange={(selectedOption) => setSelectedCompany(selectedOption ? selectedOption.value : 'N.A')}
                            // options={options.companies}
                            placeholder="Target"
                            isSearchable={true}
                            isClearable
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                borderRadius: '20px',
                                borderColor: '#ccc',
                              }),
                            }}
                          />
                        </div>
                      </div><div className='col-sm-2 col-6'>
                        <button type="submit" className="btn flous-input-color">
                          <i className="nav-icon fas fa-search"></i> Search
                        </button>
                      </div>
                      <div className='col-sm-1 col-6 btn flous-input-color'>
                        <i className="nav-icon fas fa-sort"></i> Sort
                      </div>
                    </div>
                  </form>
                  </div>
                  <div className="card-body">
                    <div className='flous-bg-thirty' style={{ borderRadius: '15px' }}>
                        <div className='card-header p-2 mb-2 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                            <div className='row text-bold'>
                                <div className='col-sm-1 col-4 m-2'>.</div>
                                <div className='col-sm-3 col m-2'>{t("flous.blogs.idtitle")}</div>
                                <div className='col-sm-2 col-12 m-2'>{t("flous.blogs.province")}</div>
                                <div className='col-sm col-12 mt-2'>{t("flous.blogs.description")}</div>
                                <div className='col-sm-1 col-12 mt-2 text-center'>{t("flous.blogs.actions")}</div>
                            </div>
                        </div>
                        {currentItems.length > 0 ? (
                          currentItems.map((blog) => (
                            <div className='card-header p-2 mb-2 shadow bg-white' style={{ borderRadius: '15px' }}>
                                <div className='row'>
                                    <div className='col-sm-1 col-4 m-2'>
                                    <span className="image">             
                                        <img src={blog.image} className="border rounded-circle" alt="o" width="30" height="30"/> 
                                    </span>
                                    </div>
                                    <div className='col-sm-3 col m-2'>{blog.title.en}</div>
                                    <div className='col-sm-2 col-12 m-2'>{provinces.find(province => province.value === blog.province)?.label || ''}</div>
                                    <div className='col-sm col-12 mt-2'
                                      dangerouslySetInnerHTML={{ __html: truncateMessage(DOMPurify.sanitize(blog.description.en), 100) }}
                                    />
                                    <div className='col-sm-1 col-12 mt-2 text-center'>
                                        <span className="btn btn-secondary mr-2 ml-2" disabled>
                                            <i className="nav-icon fas fa-archive"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                          ))) : (
                            <NoItemsMessage />
                        )}
                    </div>
                    <PaginationSection
                      page={page}
                      pageSize={pageSize}
                      totalItems={blogs.length}
                      onPageChange={onPageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}