import React, { useState, useEffect } from 'react';
import axios from 'axios';
import api from '../../../../../services/api';
import CountryShow from '../../../../general/countryShow';

import { useTranslation } from 'react-i18next';
import { educationLevel, generalField, languageConverter, preferredLang, recognitionOrganization, translateYearOfStudy } from '../../../../../services/utils';
import CountryInput from '../../../../general/CountryInput';

export default function EducationOutsideCountryInfoResumeSection(props) {

  const [provinces, setProvinces] = useState([]);

  const [visibleDetails, setVisibleDetails] = useState({});
  const { t } = useTranslation();

  const lang = localStorage.getItem('flouslang') || 'en';
        
  const toggleDetailVisibility = (index) => {
    setVisibleDetails(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  useEffect(() => {
      const fetchProvinces = async() => {
          const response = await api.get('/canada-provinces/');
          const provinceOptions = Object.entries(response.data).map(([key, val]) => ({
            value: key,
            label: val,
          }));
          setProvinces(provinceOptions);
      };
  
      fetchProvinces();
  }, []);
  
  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>{t("forms.title.educationoutside")} {t('countries.canada')}</div>
      <div className="form-group row">
        <label htmlFor="currentlyStudy" className="col-sm-3 col-form-label">{t("forms.formsquestion.educationOutsideCountry.currentlyStudy")} {t('countries.canada')}</label>
        <div className="col-sm-3">
          <input type="text" className="form-control" id="currentlyStudy" value={props.currentlyStudy === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
        </div>
        <label htmlFor="educationOutsideCountry" className="col-sm-3 col-form-label">{t("forms.formsquestion.educationOutsideCountry.educationOutsideCountry")} {t('countries.canada')}</label>
        <div className="col-sm-3">
          <input type="text" className="form-control" id="educationOutsideCountry" value={props.educationOutsideCountry === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
        </div>
      </div>
      {(props.educationOutsideCountry === 'Yes' || props.currentlyStudy === 'Yes') && (
      <div className='mx-3'>
        <div className="form-group">
        <div>
          <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
            <div className='row'>
              <div className='col-sm col-6 mt-2 text-center'>{t("general.table.level")}</div>
              <div className='col-sm col-6 mt-2 text-center'>{t("general.table.name")}</div>
              <div className='col-sm col-6 mt-2 text-center'>{t("general.table.fulltime")}</div>
              <div className='col-sm col-6 mt-2 text-center'>{t("general.table.country")}</div>
              <div className='col-sm col-6 mt-2 text-center'>{t("general.table.province")}</div>
              <div className='col-sm col-6 mt-2 text-center'>{t("general.table.enddate")}</div>
              {props.show !==true && <div className="col-sm col-6 mt-2 text-center">{t("general.table.actions")}</div>}
            </div>
          </div>

          {props.allEducationOutsideCountry.map((educationOut, index) => (
            <div key={index} className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px' }}>
              <div className='row'>
                <div className='col-sm col-6 mt-2 text-center'>{educationLevel(educationOut.educationDetail.degreelevel, lang)}</div>
                <div className='col-sm col-6 mt-2 text-center'>{educationOut.educationInstitution.institutionName}</div>
                <div className='col-sm col-6 mt-2 text-center'>{educationOut.educationInstitution.fullTimeStudent === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`}</div>
                <div className='col-sm col-6 mt-2 text-center'><CountryShow value={educationOut.educationInstitution.country}/></div>
                {educationOut.educationInstitution.country === 'CAN' ? (
                  <div className='col-sm col-6 mt-2 text-center'>{provinces.find(province => province.value === educationOut.educationInstitution.provinceState)?.label || ''}</div>
                  ): (<div className='col-sm col-6 mt-2 text-center'><div className='col-sm col-6 mt-2 text-center'>{educationOut.educationInstitution.provinceState}</div></div>)}
                <div className='col-sm col-6 mt-2 text-center'>{educationOut.dateofStudy.endDate}</div>
                {props.show !==true && <div className='col-sm col-6 mt-2 text-center'>
                  <button onClick={() => toggleDetailVisibility(index)} className="btn flous-bg-primary">
                    <i className="nav-icon fas fa-eye"></i>
                  </button>
                </div>}
              </div>
              {(visibleDetails[index] || props.show ===true) && (
              <div className='m-3'>
                <div className="form-group row">
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationOutsideCountry.institutionName")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={educationOut.educationInstitution.institutionName} placeholder="N.A"/>
                  </div>
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationOutsideCountry.languageOfInstruction")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={languageConverter(educationOut.educationInstitution.languageOfInstruction)} placeholder="N.A"/>
                  </div>
                </div>
                <div className="form-group row">
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationOutsideCountry.fullTimeStudent")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={educationOut.educationInstitution.fullTimeStudent === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
                  </div>
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationOutsideCountry.country")}</div>
                  <div className="col-sm-3">
                    <CountryInput countryCode={educationOut.educationInstitution.country} />
                  </div>
                </div>
                <div className="form-group row">
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationOutsideCountry.provinceState")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={educationOut.educationInstitution.provinceState} placeholder="N.A"/>
                  </div>
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationOutsideCountry.city")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={educationOut.educationInstitution.city} placeholder="N.A"/>
                  </div>
                </div>
                <div className="form-group row">
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationOutsideCountry.degreelevel")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={educationLevel(educationOut.educationDetail.degreelevel, lang)} placeholder="N.A"/>
                  </div>
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationOutsideCountry.generalFieldOfStudy")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={generalField(educationOut.educationDetail.generalFieldOfStudy, lang)} placeholder="N.A"/>
                  </div>
                </div>
                <div className="form-group row">
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationOutsideCountry.fieldOfStudy")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={educationOut.educationDetail.fieldOfStudy} placeholder="N.A"/>
                  </div>
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationOutsideCountry.programDuration")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={translateYearOfStudy(educationOut.educationDetail.programDuration, lang)} placeholder="N.A"/>
                  </div>
                </div>

                <div className="form-group row">
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationOutsideCountry.startDate")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={educationOut.dateofStudy.startDate} placeholder="N.A"/>
                  </div>
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationOutsideCountry.endDate")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={educationOut.dateofStudy.endDate} placeholder="N.A"/>
                  </div>
                </div>

                <div className="form-group row">
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationOutsideCountry.ecaObtained")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={educationOut.educationalCredentialAssessment.ecaObtained === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
                  </div>
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationOutsideCountry.issuingOrganization")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={recognitionOrganization(educationOut.educationalCredentialAssessment.issuingOrganization, lang)} placeholder="N.A"/>
                  </div>
                </div>
                <div className="form-group row">
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationOutsideCountry.recognizedCredential")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={educationOut.educationalCredentialAssessment.recognizedCredential} placeholder="N.A"/>
                  </div>
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationOutsideCountry.recognizedDiplomaLevel")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={educationLevel(educationOut.educationalCredentialAssessment.recognizedDiplomaLevel, lang)} placeholder="N.A"/>
                  </div>
                </div>

                <div className="form-group row">
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationOutsideCountry.epqObtained")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={educationOut.equivalencyProcessQuebec.epqObtained === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
                  </div>
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationOutsideCountry.recognizedCredential")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={educationOut.equivalencyProcessQuebec.recognizedCredential} placeholder="N.A"/>
                  </div>
                </div>
                <div className="form-group row">
                  <div htmlFor="" className="col-sm-3">{t("forms.formsquestion.educationOutsideCountry.recognizedDiplomaLevel")}</div>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" id="" value={educationLevel(educationOut.equivalencyProcessQuebec.recognizedDiplomaLevel, lang)} placeholder="N.A"/>
                  </div>
                </div>

              </div>
              )}
            </div>
          ))}
        </div>
        </div>
      </div>
      )}
    </div>
  );
}
