import React, { useEffect, useState } from 'react';
import { canadaStatesConverter } from '../../../../services/utils';
import LoadingOverlay from '../../../general/LoadingOverlay';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EvaluatedScenarioItem from '../../../general/item/EvaluatedScenarioItem';

export default function EvaluationPage(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const [statesList, setProvinceList] = useState(props.statesList);
  const [principalResults, setPrincipalResults] = useState(props.principalResults);
  const [partnerResults, setPartnerResults] = useState(props.partnerResults);

  const [activeProvince, setActiveProvince] = useState(statesList[0]);
  const [principalPrograms, setPrincipalPrograms] = useState(principalResults[statesList[0]]);
  const [partnerPrograms, setPartnerPrograms] = useState(props.isCoApplication? partnerResults[statesList[0]]: []);

  const [activeBtn, setActiveBtn] = useState('Principal');

  useEffect(()=>{
    setPrincipalResults(props.principalResults);
    setPartnerResults(props.partnerResults);
    setPrincipalPrograms(principalResults[activeProvince]);
    setPartnerPrograms(props.isCoApplication? partnerResults[activeProvince]: []);
  }, [props, activeProvince, principalResults, partnerResults])

  const handleProvinceClick = (province) => {
    setActiveProvince(province);
    setPrincipalPrograms(principalResults[province]);
    setPartnerPrograms(props.isCoApplication? partnerResults[province]: []);
  };

  const handleBtnClick = (section) => {
    setActiveBtn(section);
  };

  if (loading) {
    return <LoadingOverlay />
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }

  return (
    <div id='evaluation'>
      <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
        <h4 className='text-bold'>{t('client.results.firstTitle')}</h4>
        <hr />
        <div className='mx-3'>
          <div className="form-group row">
            <label htmlFor="inputCountry" className="col-sm-3 col-form-label">{t('client.results.country')}</label>
            <div className="col-sm-3">
              <input type="text" className="form-control" id="inputCountry" value={props.evaluationCountry} />
            </div>
            <label htmlFor="inputEvaluationNumber" className="col-sm-3 col-form-label">{t('client.results.evaluationCode')}</label>
            <div className="col-sm-3">
              <input type="text" className="form-control" id="inputEvaluationNumber" value={props.appCode} />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputDate" className="col-sm-3 col-form-label">{t('client.results.date')}</label>
            <div className="col-sm-3">
              <input type="text" className="form-control" id="inputDate" value={props.appCreationDate} />
            </div>
            <label htmlFor="inputTotalProg" className="col-sm-3 col-form-label">{t('client.results.totalPrograms')}</label>
            <div className="col-sm-3">
              <input type="text" className="form-control" id="inputTotalProg" value={props.totalProgram} />
            </div>
          </div>
        </div>
      </div>
      <div className="elevation-1 p-2 mb-3" style={{ borderRadius: '15px' }}>
        <div className="row">
          {statesList.map((element, index) => (
            <div className="col" key={index}>
              <div
                className={`btn btn-block ${activeProvince === element ? 'flous-bg-thirty text-bold flous-input-color' : ''}`}
                onClick={() => handleProvinceClick(element)}
              >
                {canadaStatesConverter(element)}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
        {props.isCoApplication && 
        (
          <div className='row'>
            <div onClick={() => handleBtnClick("Principal")} className={`card ${activeBtn === "Principal" ? 'flous-bg-thirty text-bold flous-input-color' : ''} text-center p-1 m-1 mb-3 col`} style={{ borderRadius: '5px' }}>
              <h4 className="pl-1 text-bold">{t('client.results.principal')}</h4>
            </div>
            <div onClick={() => handleBtnClick("Partner")} className={`card ${activeBtn === "Partner" ? 'flous-bg-thirty text-bold flous-input-color' : ''} text-center p-1 m-1 mb-3 col`} style={{ borderRadius: '5px' }}>
              <h4 className="pl-1 text-bold">{t('client.results.partner')}</h4>
            </div>
          </div>
        )}
        {activeBtn === "Principal" && (
          <>
          <div className='card-header p-3 mb-3 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
            <div className='row text-center text-bold'>
              <div className='col-sm-4 col-12  text-center text-sm-left'>{t('client.results.program')}</div>
              <div className='col-sm col-6 text-center'>{t('client.results.score')}</div>
              <div className='col-sm col-6 text-center'>{t('client.results.points')}</div>
              <div className='col-sm-2 col-12 text-center'>{t('client.results.actions')}</div>
            </div>
          </div>
          {principalPrograms.map((program) => (            
            <EvaluatedScenarioItem 
              program={program}
              province={activeProvince}
            />
          ))}
          </>
        )}
        {(props.isCoApplication && activeBtn === "Partner") && (
          <>
          <div className='card-header p-3 mb-3 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
            <div className='row text-center text-bold'>
              <div className='col-sm-4 col-12  text-center text-sm-left'>{t('client.results.program')}</div>
              <div className='col'>{t('client.results.score')}</div>
              <div className='col'>{t('client.results.points')}</div>
              <div className='col-sm-2 col-12 text-center'>{t('client.results.actions')}</div>
            </div>
          </div>
          {partnerPrograms.map((program) => (
            <EvaluatedScenarioItem 
              program={program}
              province={activeProvince}
            />
          ))}
          </>
        )}
      </div>
    </div>
  );
}
