import React, { useEffect, useState } from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import CountryShow from '../../../general/countryShow';
import { LegalStatus } from '../../../../services/utils';
import api from '../../../../services/api';
import styles from '../styles/pdfstyles';

export default function ResidenceInfoResumePDF(props) {
  const { t } = useTranslation();
  const lang = 'en'; // Assuming 'en' as default language

  const [provinces, setProvinces] = useState([]);

  useEffect(() => {
      const fetchProvinces = async() => {
        const response = await api.get('/canada-provinces/');
          const provinceOptions = Object.entries(response.data).map(([key, val]) => ({
            value: key,
            label: val,
          }));
          setProvinces(provinceOptions);
      };
  
      fetchProvinces();
  }, []);
  
  const renderCurrentAddress = () => (
    <View style={styles.card}>
      <Text style={styles.cardTitle}>{t("forms.title.currentaddress")}</Text>
      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.residences.country")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}><CountryShow value={props.residences.currentResidence.country} /></Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.residences.provinceState")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>
            {props.residences.currentResidence.country === 'CAN'
              ? provinces.find(province => province.value === props.residences.currentResidence.provinceState)?.label || ''
              : props.residences.currentResidence.provinceState || ''}
          </Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.residences.city")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{props.residences.currentResidence.city}</Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.residences.streetNumber")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{props.residences.currentResidence.streetNumber}</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.residences.postalCode")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{props.residences.currentResidence.postalCode}</Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.residences.startDate")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{props.residences.currentResidence.startDate}</Text>
        </View>
      </View>
    </View>
  );

  const renderCanadaStatus = () => (
    <View style={styles.card}>
      <Text style={styles.cardTitle}>{t("forms.title.statusin")} {t('countries.canada')} </Text>
      <View style={styles.row}>
        <View style={styles.column50}>
          <Text style={styles.label}>{t("forms.formsquestion.residences.haveStatus")} {t('countries.canada')}?</Text>
        </View>
        <View style={styles.column50}>
          <Text style={styles.value}>
            {props.residences.currentResidence.haveStatus === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
          </Text>
        </View>
      </View>
      {props.residences.currentResidence.haveStatus === 'Yes' && (
        <View style={styles.row}>
          <View style={styles.column50}>
            <Text style={styles.label}>{t("forms.formsquestion.residences.currentStatus")} {t('countries.canada')}?</Text>
          </View>
          <View style={styles.column50}>
            <Text style={styles.value}>{LegalStatus(props.residences.statusInCountry.currentStatus, lang)}</Text>
          </View>
        </View>
      )}
    </View>
  );

  const renderImmigrationDocument = () => (
    <View style={styles.card}>
      <Text style={styles.cardTitle}>{t("forms.title.immigrationdoc")}</Text>
      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.residences.dateOfIssue")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{props.residences.immigrationDocument.dateOfIssue}</Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.residences.dateOfExpiration")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{props.residences.immigrationDocument.dateOfExpiration}</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.residences.dateOfEntry")} {t('countries.canada')}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{props.residences.immigrationDocument.dateOfEntry}</Text>
        </View>
      </View>
    </View>
  );

  const renderPreviousAddresses = () => (
    <View style={styles.card}>
      <Text style={styles.cardTitle}>{t("forms.title.previousaddress")}</Text>
      {props.residences.previousAddresses.map((address, index) => (
        <View key={index} style={styles.addressCard}>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("general.table.startdate")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{address.startDate}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("general.table.enddate")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{address.endDate}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("general.table.country")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}><CountryShow value={address.country} /></Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("general.table.province")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>
                {address.country === 'CAN'
                  ? provinces.find(province => province.value === address.provinceState)?.label || ''
                  : address.provinceState}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("general.table.city")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{address.city}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.residences.streetNumber")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{address.streetNumber}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.residences.postalCode")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{address.postalCode}</Text>
            </View>
          </View>
        </View>
      ))}
    </View>
  );

  return (
    <Page style={styles.page}>
      <Text style={styles.mainTitle}>{t("client.results.blockTitle.bloc3")}</Text>
      {renderCurrentAddress()}
      {renderCanadaStatus()}

      {(props.residences.statusInCountry.currentStatus !== '' && 
        props.residences.statusInCountry.currentStatus !== 'NS' && 
        props.residences.statusInCountry.currentStatus !== 'No') && renderImmigrationDocument()
        
      }

      {props.residences.livedInDifferentAddress === 'Yes' ? 
        renderPreviousAddresses() : 
        <View style={styles.card}>
          <Text style={styles.cardTitle}>{t("general.table.applicantEmpty.previousaddress")}</Text>
        </View>
      }
    </Page>
  );
}

