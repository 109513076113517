import axios from "axios";

export const sortDictionaryByValues = (dict) => {
    return Object.fromEntries(
      Object.entries(dict).sort(([, a], [, b]) => a.localeCompare(b))
    );
};

export const countryName = async (value) => {

  await axios.get(`https://restcountries.com/v3.1/alpha/${value}`)
    .then(response => {
      const country = response.data[0];
      const name = country.name.common;
      return name;
    })
    .catch(error => {
      console.error('Error fetching the flag data:', error);
    });
   
};


export const countriesWithProvinces = ['CAN'];
export const provincesWithCities = ['QC'];

export const getLevelChoices = (lang) => {
  
  const levelChoices = {
    en: {
      "A1":"A1 - Beginner",
      "A2":"A2 - Elementary",
      "B1":"B1 - Intermediate",
      "B2":"B2 - Upper-Intermediate",
      "C1":"C1 - Advanced",
      "C2":"C2 - Proficient"
    },
    fr: {
      "A1":"A1 - Débutant",
      "A2":"A2 - Élémentaire",
      "B1":"B1 - Intermédiaire",
      "B2":"B2 - Intermédiaire avancé",
      "C1":"C1 - Avancé",
      "C2":"C2 - Maitrise/Compétence avancée"
    },
    es: {
      "A1":"A1 - Principiante",
      "A2":"A2 - Básico",
      "B1":"B1 - Intermedio",
      "B2":"B2 - Intermedio alto",
      "C1":"C1 - Avanzado",
      "C2":"C2 - Competente/Dominio del idioma"
    }
  };
  
  return levelChoices[lang];
}

export const isOldTest = (testDate) =>{
  
  let testDateCreated = new Date(testDate);
  let limitOldTestDate = new Date('2023-12-10');

  if (testDateCreated <= limitOldTestDate) return true;

  return false;
}

export const scoreChoices = (testType) => {
  const choices = {
    CELPIPG: {
      Reading:{
        10:"10",
        9:"9",
        8:"8",
        7:"7",
        6:"6",
        5:"5",
        4:"4",
      },
      Writing:{
        10:"10",
        9:"9",
        8:"8",
        7:"7",
        6:"6",
        5:"5",
        4:"4",
      },
      Listening:{
        10:"10",
        9:"9",
        8:"8",
        7:"7",
        6:"6",
        5:"5",
        4:"4",
      },
      Speaking:{
        10:"10",
        9:"9",
        8:"8",
        7:"7",
        6:"6",
        5:"5",
        4:"4",
      },
    },
    IELTS: {
      Reading:{
        10:"8",
        9:"7",
        8:"6.5",
        7:"6",
        6:"5",
        5:"4",
        4:"3.5",
      },
      Writing:{
        10:"7.5",
        9:"7",
        8:"6.5",
        7:"6",
        6:"5.5",
        5:"5",
        4:"4",
      },
      Listening:{
        10:"8.5",
        9:"8",
        8:"7.5",
        7:"6",
        6:"5.5",
        5:"5",
        4:"4.5",
      },
      Speaking:{
        10:"7.5",
        9:"7",
        8:"6.5",
        7:"6",
        6:"5.5",
        5:"5",
        4:"4",
      },
    },
    PTE: {
      Reading:{
        10:"88-90",
        9:"78-87",
        8:"69-77",
        7:"60-68",
        6:"51-59",
        5:"42-50",
        4:"33-41",
        3:"24-32",
      },
      Writing:{
        10:"90",
        9:"88-89",
        8:"79-87",
        7:"69-78",
        6:"60-68",
        5:"51-59",
        4:"41-50",
        3:"32-40",
      },
      Listening:{
        10:"89-90",
        9:"82-88",
        8:"71-81",
        7:"60-70",
        6:"50-59",
        5:"39-49",
        4:"28-38",
        3:"18-27",
      },
      Speaking:{
        10:"89-90",
        9:"84-88",
        8:"76-83",
        7:"68-75",
        6:"59-67",
        5:"51-58",
        4:"42-50",
        3:"34-41",
      },
    },
    TCFCANADA: {
      Reading:{
        10:"549-699",
        9:"524-548",
        8:"499-523",
        7:"453-498",
        6:"406-452",
        5:"375-405",
        4:"342-374",
      },
      Writing:{
        10:"16-20",
        9:"14-15",
        8:"12-13",
        7:"10-11",
        6:"7-9",
        5:"6",
        4:"4-5",
      },
      Listening:{
        10:"549-699",
        9:"523-548",
        8:"503-522",
        7:"458-502",
        6:"398-457",
        5:"369-397",
        4:"331-368",
      },
      Speaking:{
        10:"16-20",
        9:"14-15",
        8:"12-13",
        7:"10-11",
        6:"7-9",
        5:"6",
        4:"4-5",
      },
    },
    TEFCANADANEW: {
      Reading:{
        10:"546-699",
        9:"503-545",
        8:"462-502",
        7:"434-461",
        6:"393-433",
        5:"352-392",
        4:"306-351",
      },
      Writing:{
        10:"558-699",
        9:"512-557",
        8:"472-511",
        7:"428-471",
        6:"379-427",
        5:"330-378",
        4:"268-329",
      },
      Listening:{
        10:"546-699",
        9:"503-545",
        8:"462-502",
        7:"434-461",
        6:"393-433",
        5:"352-392",
        4:"306-351",
      },
      Speaking:{
        10:"556-699",
        9:"518-555",
        8:"494-517",
        7:"456-493",
        6:"422-455",
        5:"387-421",
        4:"328-386",
      },
    },
    TEFCANADAOLD: {
      Reading:{
        10:"566-699",
        9:"533-565",
        8:"500-532",
        7:"450-499",
        6:"400-449",
        5:"350-399",
        4:"300-349",
      },
      Writing:{
        10:"566-699",
        9:"533-565",
        8:"500-532",
        7:"450-499",
        6:"400-449",
        5:"350-399",
        4:"300-349",
      },
      Listening:{
        10:"566-699",
        9:"533-565",
        8:"500-532",
        7:"450-499",
        6:"400-449",
        5:"350-399",
        4:"300-349",
      },
      Speaking:{
        10:"566-699",
        9:"533-565",
        8:"500-532",
        7:"450-499",
        6:"400-449",
        5:"350-399",
        4:"300-349",
      },
    },
  };

  return choices[testType]
};

export const evaluationCountryConverter = (evaluationCountry) =>{

  const country_name_to_code = {
    'Canada': 'CAN',
    'New Zealand': 'NZW',
  }
  
  return country_name_to_code[evaluationCountry]
}

export const evaluationCountryConverterCodeToName = (code) =>{

  const country_name_to_code = {
    'CAN': 'Canada',
    'NZL': 'New Zealand',
  }
  
  return country_name_to_code[code]
}

export const canadaStatesConverter = (stateCode) =>{

  const state_code_to_name = {
    'FD': 'Federal',
    'QC': 'Québec',
    'ON': 'Ontario',
    'BC': 'British Columbia',
    'AB': 'Alberta',
    'MA': 'Manitoba',
    'NB': 'New Brunswick',
    'NL': 'Newfoundland and Labrador',
    'NS': 'Nova Scotia',
    'NT': 'NorthWest Territories (Territory)',
    'NU': 'Nanuvut (Territory)',
    'PE': 'Prince Edouard Island',
    'SA': 'Saskatchewan',
    'YU': 'Yukon (Territory)'
  }
  
  return state_code_to_name[stateCode] || stateCode;
}

export const languageConverter = (languageCode) => {
  const language_code_to_name = {
    'FR': 'Français',
    'EN': 'English',
    'ES': 'Español',
    'DE': 'Deutsch',
    'MD': "Mandarin",
    'AB': "Arabic",
    'OR': 'Other',
    // Ajoutez d'autres codes de langue et noms selon vos besoins
  };
  
  return language_code_to_name[languageCode] || languageCode;
};

export const MessageStatesConverter = (state) => {
  const statesConverter = {
    'PD': 'PENDING',
    'DN': 'DONE',
  };
  
  return statesConverter[state];
};

export const MessageTypesConverter = (type) => {
  const typesConverter = {
    'URG': 'URGENT',
    'TEC': 'TECHNICAL',
    'COM': 'COMMENT/SUGGESTION',
  };
  
  return typesConverter[type];
};

export const FirmTypesConverter = (type) => {
  const typesConverter = {
    'LF': 'Law Firm',
    'CF': 'Consultant Firm',
    'LCF': 'Law & Consultant Firm',
  };
  
  return typesConverter[type];
};

export const OccupationConverter = (occupation) => {
  const occupationConverter = {
    'IML': 'Immigration Lawyer',
    'IMC': 'Immigration Consultant',
    'NAT': 'Not A Request',
  };
  
  return occupationConverter[occupation];
};

export const truncateMessage = (str, limit=15) => {
  return str.length > limit ? str.substring(0, limit) + '...' : str;
};

export const createShortName = (firstName, lastName) => {
  // Helper function to get letters from the name or duplicate if not enough
  const getLetters = (name, requiredLength) => {
    let result = name.substring(0, requiredLength);
    while (result.length < requiredLength) {
      result += name.charAt(result.length % name.length);
    }
    return result;
  };

  // Try to get 3 letters from firstName, if not enough, take from lastName
  let shortFirstName = getLetters(firstName, 3);
  if (shortFirstName.length < 3) {
    shortFirstName += getLetters(lastName, 3 - shortFirstName.length);
  }

  // Try to get 2 letters from lastName, if not enough, take from firstName
  let shortLastName = getLetters(lastName, 2);
  if (shortLastName.length < 2) {
    shortLastName += getLetters(firstName, 2 - shortLastName.length);
  }

  return shortFirstName + shortLastName;
}

export const admissibleUsers = ['tatianaoviedot@gmail.com', 'inoussa.legrene.1@ens.etsmtl.ca', 
  'inous.legrene@gmail.com', 'flousdev@gmail.com']


export const sectionList = [
  "Summary",
  "Personal Informations",
  "Family Members",
  "Residences",
  "Languages",
  "Education",
  "Experience",
  "Expertise",
  "Job offer",
  "Entrepreneurial Experience"
];

export const sectionListFR = [
  "Sommaire",
  "Informations Personnelles",
  "Membres de la Famille",
  "Residences",
  "Langues",
  "Éducation",
  "Expérience",
  "Expertise",
  "Offre d'Emploi",
  "Expérience entrepreneuriale"
];

export const sectionListES = [
  "Resumen",
  "Información personal",
  "Miembros de la Familia",
  "Residencias",
  "Idiomas",
  "Educación",
  "Experiencia",
  "Experiencia",
  "Oferta de Empleo",
  "Experiencia Empresarial"
];

export const mapStateToDescription = (v, lang) => {
  const dataEN = {
    CR: "CREATED",
    IP: "IN PROGRESS",
    AB: "ABANDONED",
    CP: "COMPLETED",
    PS: "EVALUATION IN PROGRESS",
    DL: "DELETED",
    EV: "EVALUATED",
  };
  const dataFR = {
    CR: "CRÉÉ",
    IP: "EN COURS",
    AB: "ABANDONNÉ",
    CP: "COMPLETÉ",
    PS: "EVALUATION EN COURS",
    DL: "SUPPRIMÉ",
    EV: "ÉVALUÉ",
  };
  const dataES = {
    CR: "CREADO",
    IP: "EN CURSO",
    AB: "ABANDONED",
    CP: "COMPLETADO",
    PS: "EVALUACIÓN EN CURSO",
    DL: "SUPRIMIDO",
    EV: "EVALUADO",
  };
  return lang === 'en' ? dataEN[v]:(lang === 'fr' ? dataFR[v]:dataES[v])
};

export const gender = (g, lang) =>{

  const genderEN = {
    'Fem': "Female",
    'Mal': "Male",
    'Oth': "Other",
    'Unk': "Prefer not to say"
  }
  const genderFR = {
    'Fem': "Femme",
    'Mal': "Homme",
    'Oth': "Autre",
    'Unk': "Préfère ne pas dire"
  }
  const genderES = {
    'Fem': "Mujer",
    'Mal': "Hombre",
    'Oth': "Otros",
    'Unk': "Prefiero no decirlo"
  }
  
  return lang === 'en' ? genderEN[g]:(lang === 'fr' ? genderFR[g]:genderES[g])
}

export const mStatus = (s, lang) =>{

  const statusEN = {
    "Single":"Single / Never Married",
    "Common_law_partner":"Common-law partner",
    "Married": "Married",
    "Married_Cancelled": "Annulled Marriage",
    "Divorced": "Divorced",
    "Separated": "Legally separated",
    "Widow": "Widowed",
  }
  const statusFR = {
    "Single":"Célibataire / Jamais Marié(e)",
    "Common_law_partner":"Conjoint de fait",
    "Married": "Marié(e)",
    "Married_Cancelled": "Mariage annulé",
    "Divorced": "Divorcé",
    "Separated": "Séparation légale",
    "Widow": "Veuve",
  }
  const statusES = {
    "Single":"Único / Nunca se casó",
    "Common_law_partner":"Pareja de hecho",
    "Married": "Casado",
    "Married_Cancelled": "Matrimonio anulado",
    "Divorced": "Divorciado",
    "Separated": "Legalmente separado",
    "Widow": "Viuda",
  }
  
  return lang === 'en' ? statusEN[s]:(lang === 'fr' ? statusFR[s]:statusES[s])
}

export const LegalStatus = (s, lang) =>{

  const statusEN = {
    "TP_OWP": "Open Work Permit Holder",
    "TP_PGWP": "Post-Graduation Work Permit Holder",
    "TP_CWP": "Closed Work Permit Holder",
    "TP_SP": "Study Permit Holder",
    "VS": "Visitor Visa",
    "RC": "Refugee Claimant",
    "HCA": "Humanitarian and Compassionate Applicant",
    "NS": "No Status",
  }
  const statusFR = {
    "TP_OWP": "Détenteur d'un permis de travail ouvert",
    "TP_PGWP": "Titulaire d'un permis de travail post-diplôme",
    "TP_CWP": "Titulaire d'un permis de travail fermé",
    "TP_SP": "Titulaire du permis d'étude",
    "VS": "Visa de visiteur",
    "RC": "Demandeur d'asile",
    "HCA": "Candidat humanitaire et compatissant",
    "NS": "Pas de statut",
  }
  const statusES = {
    "TP_OWP": "Titular de un permiso de trabajo abierto",
    "TP_PGWP": "Titular de un permiso de trabajo de postgrado",
    "TP_CWP": "Titular de un permiso de trabajo cerrado",
    "TP_SP": "Titular del permiso de estudios",
    "VS": "Visado de visitante",
    "RC": "Solicitante de la condición de refugiado",
    "HCA": "Solicitante humanitario y compasivo",
    "NS": "Sin estado",
  }
  
  return lang === 'en' ? statusEN[s]:(lang === 'fr' ? statusFR[s]:statusES[s])
}

export const familyRelation = (v, lang) =>{

  const dataEN = {
    "Father": "Father",
    "Stepfather": "Stepfather",
    "Mother": "Mother",
    "Stepmother": "Stepmother",
    "Sister": "Sister",
    "Stepsister": "Stepsister",
    "Brother": "Brother",
    "Stepbrother": "Stepbrother",
    "Grandfather": "Grandfather",
    "Grandmother": "Grandmother",
    "Uncle": "Uncle",
    "Aunt": "Aunt",
    "Niece": "Niece",
    "Nephew": "Nephew",
    "Other": "Other",
  }
  const dataFR = {
    "Father": "Père",
    "Stepfather": "Beau-père",
    "Mother": "Mère",
    "Stepmother": "Belle-mère",
    "Sister": "Sœur",
    "Stepsister": "Belle-sœur",
    "Brother": "Frère",
    "Stepbrother": "Demi-frère",
    "Grandfather": "Grand-père",
    "Grandmother": "Grand-mère",
    "Uncle": "Oncle",
    "Aunt": "Tante",
    "Niece": "Nièce",
    "Nephew": "Neveu",
    "Other": "Autre",
  }
  const dataES = {
    "Father": "Padre",
    "Stepfather": "Padrastro",
    "Mother": "Madre",
    "Stepmother": "Madrastra",
    "Sister": "Hermana",
    "Stepsister": "Hermanastra",
    "Brother": "Hermano",
    "Stepbrother": "Hermanastro",
    "Grandfather": "Abuelo",
    "Grandmother": "Abuela",
    "Uncle": "Tío",
    "Aunt": "Tía",
    "Niece": "Sobrina",
    "Nephew": "Sobrino",
    "Other": "Otro",
  }
  
  return lang === 'en' ? dataEN[v]:(lang === 'fr' ? dataFR[v]:dataES[v])
}

export const companyType = (v, lang) =>{

  const dataEN = {
    "Existing": "Existing",
    "Start a new company": "Start a new company",
    "Undertake an existing company": "Undertake an existing company",
  }
  const dataFR = {
    "Existing": "Existante",
    "Start a new company": "Créer une nouvelle entreprise",
    "Undertake an existing company": "Acquérir une entreprise existante",
  }
  const dataES = {
    "Existing": "Existente",
    "Start a new company": "Crear una nueva empresa",
    "Undertake an existing company": "Adquisición de una empresa existente",
  }
  
  return lang === 'en' ? dataEN[v]:(lang === 'fr' ? dataFR[v]:dataES[v])
}

export const preferredLang = (v) =>{

  const languages = {
    "EN":"English",
    "FR":"Français",
    "ES":"Spanish",
  }
  
  return languages[v];
}

export const recognitionOrganization = (v, lang) => {
  const dataEN = {
    LHS: "MIFI - Ministère de l'Immigration, de la Francisation et de l'Intégration",
    WES: "WES - World Education Services (WES)",
    CES: "CES - Comparative Education Service – University of Toronto School of Continuing Studies",
    ICA: "ICA - International Credential Assessment Service of Canada",
    IQAS: "IQAS - International Qualifications Assessment Service",
    ICE: "ICE - International Credential Evaluation Service – British Columbia Institute of Technology",
    OA: "OA - Order of Architects",
    OP: "OP - Order of Pharmacists",
    SFP: "SFP - Specialists and family physicians",
  };
  const dataFR = {
    LHS: "MIFI - Ministère de l'Immigration, de la Francisation et de l'Intégration",
    WES: "WES - Services mondiaux de l'éducation (WES)",
    CES: "CES - Service d'éducation comparée - École d'études permanentes de l'Université de Toronto",
    ICA: "ICA - Service canadien d'évaluation des diplômes internationaux",
    IQAS: "IQAS - cService international d'évaluation des qualifications",
    ICE: "ICE - Service d'évaluation des diplômes internationaux - British Columbia Institute of Technology",
    OA: "OA - Ordre des architectes",
    OP: "OP - Ordre des pharmaciens",
    SFP: "SFP - Spécialistes et médecins de famille",
  };
  const dataES = {
    LHS: "MIFI - Ministerio de Inmigración, Extranjería e Integración",
    WES: "WES - Servicios Mundiales de Educación",
    CES: "CES - Servicio de Educación Comparada - Escuela de Estudios Continuos de la Universidad de Toronto",
    ICA: "ICA - Servicio internacional de evaluación de credenciales de Canadá",
    IQAS: "IQAS - Servicio Internacional de Evaluación de las Cualificaciones",
    ICE: "ICE - Servicio internacional de evaluación de credenciales - British Columbia Institute of Technology",
    OA: "OA - Orden de Arquitectos",
    OP: "OP - Orden de Farmacéuticos",
    SFP: "SFP - Especialistas y médicos de familia",
  };
  return lang === 'en' ? dataEN[v]:(lang === 'fr' ? dataFR[v]:dataES[v])
};

export const educationLevel = (v, lang) => {
  const dataEN = {
    LHS: "LHS - None, or less than secondary (High School)",
    HSG: "HSG - Secondary Diploma (Hish school graduation)",
    PRG: "PRG - Program in a college, trade or trade of technical school, or other institute",
    PDL: "PDL - Professional degree needed to practice in a licensed profession",
    UND: "UND - Undergraduate program at a university",
    BAC: "BAC - Bachelor's degree ( three or more year program at a university, college, trade or trade of technical school, or other institute)",
    GRD: "GRD - Graduate program at a university (Specialized or Master's degree)",
    PGR: "PGR - Postgraduate program at a university (PhD)",
  };
  const dataFR = {
    LHS: "LHS - Aucun, ou moins que le secondaire (High School)",
    HSG: "HSG - Diplôme d'études secondaires (Hish school graduation)",
    PRG: "PRG - Programme dans un collège, une école professionnelle ou technique, ou un autre institut",
    PDL: "PDL - Diplôme professionnel nécessaire pour exercer une profession autorisée",
    UND: "UND - Programme de premier cycle dans une université",
    BAC: "BAC - Licence (programme de trois ans ou plus dans une université, un collège, une école professionnelle ou technique, ou un autre institut)",
    GRD: "GRD - Programme d'études supérieures dans une université (diplôme de spécialisation ou de maîtrise)",
    PGR: "PGR - Programme de troisième cycle dans une université (doctorat)",
  };
  const dataES = {
    LHS: "LHS - Ninguna, o menos de secundaria (Bachillerato)",
    HSG: "HSG - Diploma de Secundaria (graduación escolar en Hish)",
    PRG: "PRG - Programa en una universidad, escuela técnica u otro instituto",
    PDL: "PDL - Título profesional necesario para ejercer una profesión autorizada",
    UND: "UND - Programa de licenciatura en una universidad",
    BAC: "BAC - Licenciatura (programa de tres años o más en una universidad, escuela superior, escuela técnica u otro instituto)",
    GRD: "GRD - Programa de posgrado en una universidad (especialización o máster)",
    PGR: "PGR - Programa de posgrado en una universidad (doctorado)",
  };
  return lang === 'en' ? dataEN[v]:(lang === 'fr' ? dataFR[v]:dataES[v])
};

export const quebecEducationLevel = (v, lang) => {
  const dataEN = {
    AEC: "AEC - Attestation of College Studies",
    ASG: "ASP - Attestation of Vocational Specialization",
    DECG: "DEC G - Diploma of College Studies (General)",
    DECT: "DEC T - Diploma of College Studies (Technical)",
    DEP: "DEP - Diploma of Vocational Studies",
    NAT: "N.A - Not Applicable",
  };
  const dataFR = {
    AEC: "AEC - Attestation d'études collégiales",
    ASG: "ASP - Attestation de spécialisation professionnelle",
    DECG: "DEC G - Diplôme d'études collégiales (général)",
    DECT: "DEC T - Diplôme d'études collégiales (technique)",
    DEP: "DEP - Diplôme d'études professionnelles",
    NAT: "N.A - Sans Objet",
  };
  const dataES = {
    AEC: "AEC - Certificado de estudios universitarios",
    ASG: "ASP - Certificado de especialización profesional",
    DECG: "DEC G - Diploma de estudios universitarios (General)",
    DECT: "DEC T - Diploma de estudios universitarios (Técnico)",
    DEP: "DEP - Diploma de estudios profesionales",
    NAT: "N.A - No Aplicable",
  };
  return lang === 'en' ? dataEN[v]:(lang === 'fr' ? dataFR[v]:dataES[v])
};

export const generalField = (v, lang) => {
  const dataEN = {
    AGR: "AGR - Agriculture, agriculture operations and related sciences",
    BIO: "BIO - Biological and biomedical sciences",
    CIS: "CIS - Computer and information sciences and support services",
    ENG: "ENG - Engineering technology and engineering-related fields",
    HEA: "HEA - Health professions and related programs",
    NAS: "NAS - Natural and applied sciences",
    NRR: "NRR - Natural resources and related",
    PHY: "PHY - Physical sciences",
    ARC: "ARC - Architecture",
    FIN: "FIN - Finances and administration",
    TRA: "TRA - Trades, transport and equipment operators and related",
    LAW: "LAW - Education, law, social, community and government",
    ART: "ART - Art, culture, recreation and sport",
    SAL: "SAL - Sales and services",
    MAN: "MAN - Manufacturing and utilities",
    OTH: "OTH - Others",
  };
  const dataFR = {
    AGR: "AGR - Agriculture, opérations agricoles et sciences connexes",
    BIO: "BIO - Sciences biologiques et biomédicales",
    CIS: "CIS - Sciences informatiques et de l'information et services de soutien",
    ENG: "ENG - Technologie de l'ingénierie et domaines liés à l'ingénierie",
    HEA: "HEA - Professions de la santé et programmes connexes",
    NAS: "NAS - Sciences naturelles et appliquées",
    NRR: "NRR - Ressources naturelles et domaines connexes",
    PHY: "PHY - Sciences physiques",
    ARC: "ARC - Architecture",
    FIN: "FIN - Finances et administration",
    TRA: "TRA - Métiers, transport et opérateurs d'équipement et domaines connexes",
    LAW: "LAW - Éducation, droit, social, communautaire et gouvernement",
    ART: "ART - Art, culture, loisirs et sport",
    SAL: "SAL - Ventes et services",
    MAN: "MAN - Fabrication et services publics",
    OTH: "OTH - Autres"
};
  const dataES = {
    AGR: "AGR - Agricultura, operaciones agrícolas y ciencias relacionadas",
    BIO: "BIO - Ciencias biológicas y biomédicas",
    CIS: "CIS - Ciencias de la computación e información y servicios de apoyo",
    ENG: "ENG - Tecnología de ingeniería y campos relacionados con la ingeniería",
    HEA: "HEA - Profesiones de la salud y programas relacionados",
    NAS: "NAS - Ciencias naturales y aplicadas",
    NRR: "NRR - Recursos naturales y relacionados",
    PHY: "PHY - Ciencias físicas",
    ARC: "ARC - Arquitectura",
    FIN: "FIN - Finanzas y administración",
    TRA: "TRA - Oficios, transporte y operadores de equipos y relacionados",
    LAW: "LAW - Educación, derecho, social, comunitario y gobierno",
    ART: "ART - Arte, cultura, recreación y deporte",
    SAL: "SAL - Ventas y servicios",
    MAN: "MAN - Manufactura y servicios públicos",
    OTH: "OTH - Otros"
  };
  return lang === 'en' ? dataEN[v]:(lang === 'fr' ? dataFR[v]:dataES[v])
};

export const allowedMaritalStatuses = ['Common_law_partner', 'Married'];
export const identity = ['Divorced', 'Widow', 'Separated'];

export const notAdmissibleCharactersInNumbers = ['e', '-', '+', '*', '/', '.', ','];

export const NumberInputControl = (e)=>{
  if (notAdmissibleCharactersInNumbers.includes(e.key)) {
      return e.preventDefault();
  }
}

export const dateFieldNames = [
  'dateofbirth', 'birthday', 'deathday', 'startDate', 'endDate',
  'testDate', 'managementStartDate', 'managementEndDate'
]

export const maxDate = new Date().toISOString().split("T")[0];

export const checkStartDate = (startDate, durationAgo=10) => {

  if (startDate !== '') {
    const currentStartDate = new Date(startDate);

    const yearsAgo = new Date();

    yearsAgo.setFullYear(yearsAgo.getFullYear()-durationAgo);

    return currentStartDate > yearsAgo;
  }
  return false;

}

export const checkFullCoverage = (currentStartDate, previousAddresses, duration=10) => {
  const today = new Date();
  const durationYearsAgo = new Date();
  durationYearsAgo.setFullYear(today.getFullYear() - duration);
  durationYearsAgo.setDate(1);

  // Convert currentStartDate to Date object
  let currentStart = new Date(currentStartDate);
  currentStart.setDate(1);

  // Sort the previousAddresses by startDate
  previousAddresses.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));

  let coverageStart = currentStart; // Starts with currentStartDate

  // Check whether the current period already covers duration years
  if (coverageStart <= durationYearsAgo) return true;

  // Check previousAddresses to fill in the gaps
  // Merge overlapping periods or periods with small gaps
  let mergedPeriods = [];
  for (let address of previousAddresses) {
    let prevStart = new Date(address.startDate);
    let prevEnd = new Date(address.endDate);

    prevStart.setDate(1);
    prevEnd.setDate(1);
    if (mergedPeriods.length === 0) {
      mergedPeriods.push({ startDate: prevStart, endDate: prevEnd });
    } else {
        let lastPeriod = mergedPeriods[mergedPeriods.length - 1];

        // Check whether the gap between two periods is small
        if (prevStart <= lastPeriod.endDate || isOneMonthApart(lastPeriod.endDate, prevStart) || isFewDaysApart(lastPeriod.endDate, prevStart)) {
            lastPeriod.endDate = prevEnd > lastPeriod.endDate ? prevEnd : lastPeriod.endDate;
        } else {
            mergedPeriods.push({ startDate: prevStart, endDate: prevEnd });
        }
    }
  }

  // Check whether the current period already covers the requested duration
  if (coverageStart <= durationYearsAgo) return true;

  // Check merged periods to fill gaps
  for (let period of mergedPeriods.reverse()) {
      if (period.endDate >= coverageStart || isOneMonthApart(period.endDate, coverageStart) || isFewDaysApart(period.endDate, coverageStart)) {
          coverageStart = period.startDate;
      }

      // If you reach or exceed the required duration, you're good to go.
      if (coverageStart <= durationYearsAgo) return true;
  }

  return false;
};

// Function that checks whether two dates are separated by a maximum of one month
const isOneMonthApart = (endDate, nextStartDate) => {
  let endYear = endDate.getFullYear();
  let endMonth = endDate.getMonth();
  let startYear = nextStartDate.getFullYear();
  let startMonth = nextStartDate.getMonth();

  // Checks whether startMonth and endMonth are consecutive
  return (
      (endYear === startYear && startMonth === endMonth + 1) ||  // Same year, consecutive months
      (endYear + 1 === startYear && startMonth === 0 && endMonth === 11) // December → January
  );
};

const isFewDaysApart = (endDate, nextStartDate, maxDays=30) => {
  const differenceInTime = nextStartDate - endDate;
  const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);
  return differenceInDays <= maxDays; // 30-day tolerance
};

const translations = {
  fr: {
    Father: "Père",
    Stepfather: "Beau-père",
    Mother: "Mère",
    Stepmother: "Belle-mère",
    Grandfather: "Grand-père",
    Grandmother: "Grand-mère",
    Nephew: "Neveu",
    Niece: "Nièce",
    Sister: "Sœur",
    Stepsister: "Demi-sœur",
    Brother: "Frère",
    Stepbrother: "Demi-frère",
    Stepson: "Beau-fils",
    Stepdaughter: "Belle-fille",
    Son: "Fils",
    Daughter: "Fille"
  },
  es: {
    Father: "Padre",
    Stepfather: "Padrastro",
    Mother: "Madre",
    Stepmother: "Madrastra",
    Grandfather: "Abuelo",
    Grandmother: "Abuela",
    Nephew: "Sobrino",
    Niece: "Sobrina",
    Sister: "Hermana",
    Stepsister: "Hermanastra",
    Brother: "Hermano",
    Stepbrother: "Hermanastro",
    Stepson: "Hijastro",
    Stepdaughter: "Hijastra",
    Son: "Hijo",
    Daughter: "Hija"
  },
  en: {
    // Identique à la valeur d'origine
    Father: "Father",
    Stepfather: "Stepfather",
    Mother: "Mother",
    Stepmother: "Stepmother",
    Grandfather: "Grandfather",
    Grandmother: "Grandmother",
    Nephew: "Nephew",
    Niece: "Niece",
    Sister: "Sister",
    Stepsister: "Stepsister",
    Brother: "Brother",
    Stepbrother: "Stepbrother",
    Stepson: "Stepson",
    Stepdaughter: "Stepdaughter",
    Son: "Son",
    Daughter: "Daughter"
  }
};

// Fonction de traduction
export const translateRelationship = (word, lang) => {
  if (!translations[lang]) {
    return `Language not supported: ${lang}`;
  }

  const translation = translations[lang][word];
  if (!translation) {
    return `${word}`;
  }

  return translation;
}

// Dictionnaire des traductions pour chaque langue
const yearOfStudyTranslations = {
  en: {
    LY1: "Less than 1 year",
    Y1: "1 Year",
    Y12: "1 to 2 Years",
    Y2: "2 Years",
    Y23: "2 to 3 Years",
    Y3: "3 Years",
    Y35: "3 to 5 Years",
    MY5: "More than 5 Years",
  },
  fr: {
    LY1: "Moins d'un an",
    Y1: "1 an",
    Y12: "1 à 2 ans",
    Y2: "2 ans",
    Y23: "2 à 3 ans",
    Y3: "3 ans",
    Y35: "3 à 5 ans",
    MY5: "Plus de 5 ans",
  },
  es: {
    LY1: "Menos de 1 año",
    Y1: "1 año",
    Y12: "1 a 2 años",
    Y2: "2 años",
    Y23: "2 a 3 años",
    Y3: "3 años",
    Y35: "3 a 5 años",
    MY5: "Más de 5 años",
  }
};

// Fonction de traduction
export const translateYearOfStudy = (code, lang) => {
  // Check if the language is supported
  if (!yearOfStudyTranslations[lang]) {
    return `Language not supported : ${lang}`;
  }

  const translation = yearOfStudyTranslations[lang][code];

  if (!translation) {
    return `Invalid study year code : ${code}`;
  }

  return `${code} - ${translation}`;
}


export const getOptions = (data) => {
  return Object.entries(data).map(([key, val]) => ({
    value: key,
    label: val,
  }));
};

