import React, { useState } from 'react';
import { BASE_URL } from '../../../services/constants';
import FlagComponent from '../../general/FlagComponent';
import LoadingOverlay from '../../general/LoadingOverlay';
import { activateCompany, deactivateCompany, deleteCompany } from '../../../services/flous/ProfesionnalServices';
import DeletionModal from '../../general/DeletionModal';
import { useTranslation } from 'react-i18next';

const FlousCompanyItem = ({ company }) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { t } = useTranslation();
    const [deletionModalOpen, setDeletionModalOpen] = useState(false);

    const handleDeleteCompany = async (code) =>{
        setLoading(true);
        try{
        const response = await deleteCompany(code);

        if (response && response.status === 200) {
            setLoading(false);
            window.location.reload();
        }
        }  catch (error){
            if (error.response && [400, 500].includes(error.response.status)){
                setError(error.response.data.error);
                setLoading(false);
            }
        }
    }

    const handleDeActivateCompany = async (code) =>{
        setLoading(true);
        try{
        const response = await deactivateCompany(code);

        if (response && response.status === 200) {
            setLoading(false);
            window.location.reload();
        }
        }  catch (error){
            if (error.response && [400, 500].includes(error.response.status)){
                setError(error.response.data.error);
                setLoading(false);
            }
        }
    }

    const handleActivateCompany = async (code) =>{
        setLoading(true);
        try{
        const response = await activateCompany(code);

        if (response && response.status === 200) {
            setLoading(false);
            window.location.reload();
        }
        }  catch (error){
            if (error.response && [400, 500].includes(error.response.status)){
                setError(error.response.data.error);
                setLoading(false);
            }
        }
    }

    const handleContinue = async () => {
        await handleDeleteCompany(company.code);
    }
  
    const handleCloseDeletionModal = () =>{
      setDeletionModalOpen(false);
    }

    if (loading) {
      return <LoadingOverlay />;
    }
  
    if (error) {
      return <div>Error : {error}</div>;
    }
    
    return (
        <div className='card-header p-3 mb-2 shadow bg-white' style={{ borderRadius: '15px' }}>
            <div className='row'>
                <div className='col-sm col-12 mt-2 text-center'>
                    <FlagComponent cca3={company.location} />
                </div>
                <div className='col-sm col-6 mt-2'>
                    <div className='flex items-center'>
                        <span className="image">             
                            {company.logoUrl ? (
                                <img src={`${BASE_URL}${company.logoUrl}`} className="border rounded-circle" alt="User Profile" width="30" height="30"/>
                            ):(
                                <img src={`${process.env.PUBLIC_URL}/flous/logos/logo_2.svg`} className="border rounded-circle" alt="User Profile" width="30" height="30"/>
                            )} 
                        </span>
                        <span className='ml-1'>{company.name}</span>
                    </div>
                </div>
                <div className='col-sm col-6 mt-2 text-center'>{company.package}</div>
                <div className='col-sm col-6 mt-2 text-center'>{company.totalSeats}</div>
                <div className='col-sm col-6 mt-2 text-center'>{company.registeredSeats}</div>
                <div className='col-sm col-12 mt-2 text-center'>
                    <div  className={`btn ${company.status === "Active" ? "flous-bg-menu" : "btn-secondary"}`}>{company.status}</div>
                </div>
                <div className='col-sm-4 col-12 mt-2 text-center'>
                    <a href={`/flous/company/detail/${company.code}`} className="btn flous-bg-menu mr-2">
                    <i className="nav-icon fas fa-eye"></i> Detail
                    </a>
                    {company.isActive? (
                        <span onClick={()=>handleDeActivateCompany(company.code)} className="btn flous-bg-thirty">Deactivate</span>
                    ):(
                        <span onClick={()=>handleActivateCompany(company.code)} className="btn flous-bg-secondary">Activate</span>
                    )}
                    <span onClick={()=>setDeletionModalOpen(true)} className="btn btn-secondary mr-2 ml-2">
                        <i className="nav-icon fas fa-trash"></i>
                    </span>
                </div>
            </div>
            <DeletionModal
                isOpen={deletionModalOpen}
                bodyText={t("modal.deletionModal.company")}
                onContinue={handleContinue}
                onClose={handleCloseDeletionModal}
            />
        </div>
    );
}

export default FlousCompanyItem;