import React, {useState} from 'react';
import EntreExpeResumeSection from './section_expentre/entreexp_section';
import TakeoverBidResumeSection from './section_expentre/takeoverbid_section';
import CompanyDetailsResumeSection from './section_expentre/companyDetail_Section';
import CompanyActivitiesResumeSection from './section_expentre/companyActivities_section';
import CompanyFinanceResumeSection from './section_expentre/companyFinance_section';
import RoleAndExperienceResumeSection from './section_expentre/roleAndExperience_section';
import CompanyDetailsTwoResumeSection from './section_expentre/companyDetailTwo_section';
import SupportbySpecializedOResumeSection from './section_expentre/supportbySpecializedO_section';
import InnovativeActivitiesResume from './section_expentre/innovativeActivites_section';
import { useTranslation } from 'react-i18next';
import CountryShow from '../../../general/countryShow';
import { companyType } from '../../../../services/utils';

export default function EntrepreneurialExperienceResumeSection(props) {
    const { t } = useTranslation();

    const lang = localStorage.getItem('flouslang') || 'en';
    const [visibleDetails, setVisibleDetails] = useState({});
            
    const toggleDetailVisibility = (index) => {
    setVisibleDetails(prevState => ({
        ...prevState,
        [index]: !prevState[index]
    }));
    };
  
  return (
    <div id=''>
      <h3 className='flous-police-one flous-input-color text-center'>{t("client.results.blockTitle.bloc9")}</h3>
        <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
            <p className='text-bold text-center'>{t("forms.title.managementexperience")}</p>
            <div className="form-group row mx-3">
                <label htmlFor="managementExperience" className="col-sm-6 col-form-label">{t("forms.formsquestion.professionalExperience.professionalExperience")}</label>
                <div className="col-sm-6">
                    <input type="text" className="form-control" id="managementExperience" value={props.managementExperience.managementExperience === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
                </div>
            </div>
            {props.managementExperience.managementExperience === 'Yes' && (
                <div className="form-group row mx-3">
                    <label htmlFor="managementStartDate" className="col-sm-3 col-form-label">{t("forms.formsquestion.entrepreunarialExperience.managementStartDate")}</label>
                    <div className="col-sm-3">
                        <input type="text" className="form-control" id="managementStartDate" value={props.managementExperience.managementStartDate} placeholder="N.A"/>
                    </div>
                    <label htmlFor="managementEndDate" className="col-sm-3 col-form-label">{t("forms.formsquestion.entrepreunarialExperience.managementEndDate")}</label>
                    <div className="col-sm-3">
                        <input type="text" className="form-control" id="managementEndDate" value={props.managementExperience.managementEndDate} placeholder="N.A"/>
                    </div>
                </div>
            )}
        </div>
        <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
            <p className='text-bold text-center'>{t("forms.title.entrepreneurialexperience")}</p>
            <div className="form-group row mx-3">
                <label htmlFor="haveBusinesses" className="col-sm-6 col-form-label">{t("forms.formsquestion.entrepreunarialExperience.entrepreneurialIntro")}</label>
                <div className="col-sm-6">
                    <input type="text" className="form-control" id="haveBusinesses" value={props.entrepreunarialExperience.haveBusinesses === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
                </div>
            </div>
            <div className='mx-3 mt-4'>
                {(props.allEntrepreneurialExperience && props.allEntrepreneurialExperience.length > 0) && (
                <div>
                    <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                        <div className='row'>
                            <div className='col-sm col-6 mt-2 text-center'>{t("general.table.type")}</div>
                            <div className='col-sm col-6 mt-2 text-center'>{t("general.table.company")}</div>
                            <div className='col-sm col-6 mt-2 text-center'>{t("general.table.ownershippercentage")}</div>
                            <div className='col-sm col-6 mt-2 text-center'>{t("general.table.country")}</div>
                            <div className='col-sm col-6 mt-2 text-center'>{t("general.table.actions")}</div>
                        </div>
                    </div>
                    {props.allEntrepreneurialExperience.map((entreExperience, index) => (
                        <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px' }} key={index}>
                            <div className='row'>
                                <div className='col-sm col-6 mt-2 text-center'>{entreExperience.entrepreunarialExperience.ownership === 'Yes' ? companyType('Existing', lang): companyType(entreExperience.entrepreunarialExperience.newcompany, lang)}</div>
                                <div className='col-sm col-6 mt-2 text-center'>{entreExperience.ownershipDetail.companyName}</div>
                                <div className='col-sm col-6 mt-2 text-center'>{entreExperience.ownershipDetail.ownershipPercentage} %</div>
                                <div className='col-sm col-6 mt-2 text-center'><CountryShow value={entreExperience.companyDetails.country} /></div>
                                <div className='col-sm col-6 mt-2 text-center'>
                                    <button onClick={() => toggleDetailVisibility(index)} className="btn flous-bg-primary">
                                        <i className="nav-icon fas fa-eye"></i>
                                    </button>
                                </div>
                            </div>
                            {(visibleDetails[index] || props.show ===true) && (
                            <div>
                                <EntreExpeResumeSection entrepreunarialExperience={entreExperience.entrepreunarialExperience} />
                                {entreExperience.entrepreunarialExperience.ownership === 'Undertake an existing company' && (
                                    <TakeoverBidResumeSection takeoverBid={entreExperience.takeoverBid} />
                                )}
                                
                                <CompanyDetailsTwoResumeSection companyDetails={entreExperience.companyDetails} />
                                {(entreExperience.entrepreunarialExperience.newcompany === 'No' && entreExperience.entrepreunarialExperience.newcompany !== '') && (
                                    <InnovativeActivitiesResume innovativeActivities={entreExperience.entrepreunarialExperience.innovativeActivities} />
                                )}
                                <CompanyDetailsResumeSection ownershipDetail={entreExperience.ownershipDetail} />
                                <CompanyActivitiesResumeSection companyActivites={entreExperience.companyActivites} />
                                <SupportbySpecializedOResumeSection supportby={entreExperience.supportby} />
                                <CompanyFinanceResumeSection companyFinances={entreExperience.companyFinances} />
                                <RoleAndExperienceResumeSection roleAndExperience={entreExperience.roleAndExperience} />
                                
                            </div>)}
                        </div>
                    ))}
                </div>
                )}
            </div>
        </div>
    </div>
  );
}