import React, { useEffect, useState } from 'react';
import RequiredIndicator from '../../../../../general/requiredIndicator';
import 'react-tooltip/dist/react-tooltip.css';

import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { translateText } from '../../../../../../services/general/TranslationServices';

const ManagementExperienceSection = (props) => {
  const [formData, setFormData] = useState(props.otherManagementExperience);
  const { t } = useTranslation();

  const lang = localStorage.getItem('flouslang') || 'en';

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedData);
    props.sendOtherManagementExperience(updatedData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? `${t('forms.formsquestion.toast.completeSection')}` : ''}</div>
        <p className='text-bold text-center'>{t("forms.title.managementexperience")}</p>
        <div className='row p-2'>
          <p className='col-sm-10'>{t("forms.formsquestion.entrepreunarialExperience.managementExperience")} <RequiredIndicator />
            <i className="nav-icon fas fa-info-circle"  
              data-tooltip-id="tooltip1" data-tooltip-content={t("forms.formsquestion.tooltip.enterpreneurial.tooltip1")}>
              
            </i>
          </p>
          <div className='col-sm-2'>
            <div className='row'>
              <div className="form-check col">
                <input 
                  type="radio"
                  className="form-check-input"
                  name="managementExperience"
                  value="Yes"
                  checked={formData.managementExperience === "Yes"}
                  onChange={handleChange}
                />
                <label className="form-check-label">{t("forms.click.yes")}</label>
              </div>
              <div className="form-check col">
                <input 
                  type="radio"
                  className="form-check-input"
                  name="managementExperience"
                  value="No"
                  checked={formData.managementExperience === "No"}
                  onChange={handleChange}
                />
                <label className="form-check-label">{t("forms.click.no")}</label>
              </div>
            </div>
          </div>
          <Tooltip id='tooltip1' />
        </div>
        {formData.managementExperience === 'Yes' && (
          <>
          <div className='row p-2'>
            <p htmlFor="managementStartDate" className="col-sm-6 col-form-label">{t("forms.formsquestion.entrepreunarialExperience.managementStartDate")} <RequiredIndicator /></p>
            <div className="col-sm-6">
              <input 
                type="date" 
                className="form-control rounded-pill flous-input-color"
                id='managementStartDate'
                name="managementStartDate"
                value={formData.managementStartDate}
                onChange={handleChange} max={new Date().toISOString().split("T")[0]}
              />
            </div>
          </div>
          <div className='row p-2'>
            <p htmlFor="managementEndDate" className="col-sm-6 col-form-label">{t("forms.formsquestion.entrepreunarialExperience.managementEndDate")} <RequiredIndicator /></p>
            <div className="col-sm-6">
              <input 
                type="date" 
                className="form-control rounded-pill flous-input-color"
                id='managementEndDate'
                name="managementEndDate"
                value={formData.managementEndDate}
                onChange={handleChange} max={new Date().toISOString().split("T")[0]}
              />
            </div>
          </div>
        </>
        )}
      </div>
    </div>
  );
};

export default ManagementExperienceSection;