import React from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import styles from '../styles/pdfstyles';

export default function ProfessionalExpertiseResumeSectionPDF(props) {
  const { t } = useTranslation();

  const professionalExpertise = {
    workedInSameProfession: props.professionalExpertise.professionalExpertise.workedSameProfession,
    exceptionalExpertise: props.professionalExpertise.professionalExpertise.exceptionalExpertise,
    notableAchievements: props.professionalExpertise.notableAchievements.recognition,
    impactsOfExpertise: props.professionalExpertise.impactofExpertise.impact
  };

  return (
    <Page style={styles.page}>
      <Text style={styles.mainTitle}>{t("client.results.blockTitle.bloc7")}</Text>
      
      <View style={styles.card}>
        <View style={styles.row}>
          <View style={styles.column70}>
            <Text style={styles.label}>{t("forms.formsquestion.professionalExpertise.workedSameProfession")}</Text>
          </View>
          <View style={styles.cplumn30}>
            <Text style={styles.value}>
              {professionalExpertise.workedInSameProfession === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
            </Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.column70}>
            <Text style={styles.label}>{t("forms.formsquestion.professionalExpertise.exceptionalExpertise")}</Text>
          </View>
          <View style={styles.cplumn30}>
            <Text style={styles.value}>
              {professionalExpertise.exceptionalExpertise === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
            </Text>
          </View>
        </View>

        {props.professionalExpertise.professionalExpertise.exceptionalExpertise === 'Yes' && (
          <>
            <View style={styles.row}>
              <View style={styles.column70}>
                <Text style={styles.label}>{t("forms.formsquestion.professionalExpertise.notableAchievements")}</Text>
              </View>
              <View style={styles.cplumn30}>
                <Text style={styles.value}>
                  {professionalExpertise.notableAchievements === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
                </Text>
              </View>
            </View>

            {props.professionalExpertise.notableAchievements.recognition === 'Yes' && (
              <View style={styles.row}>
                <View style={styles.column70}>
                  <Text style={styles.label}>{t("forms.formsquestion.professionalExpertise.impactofExpertise")}</Text>
                </View>
                <View style={styles.cplumn30}>
                  <Text style={styles.value}>
                    {professionalExpertise.impactsOfExpertise === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
                  </Text>
                </View>
              </View>
            )}
          </>
        )}
      </View>
    </Page>
  );
}

