import React, { useState } from 'react';
import { deleteCustomer, viewMoreCustomer } from '../../../services/enterprise/CustomersServices';
import LoadingOverlay from '../../general/LoadingOverlay';
import FlagComponent from '../../general/FlagComponent';
import { toast, ToastContainer } from 'react-toastify';
import AssignToAdvisorsModal from '../modal/AssignToAdvisorsModal';
import DeletionModal from '../../general/DeletionModal';
import { useTranslation } from 'react-i18next';

const EnterpriseCustomersItem = ({ customer, advisors }) => {
    const [loading, setLoading] = useState(false);
    const [deletionModalOpen, setDeletionModalOpen] = useState(false);
    
    const { t } = useTranslation();

    const handleDeleteCustomer = async (code) =>{
        setLoading(true);
        try{
        const response = await deleteCustomer(code);
        if (response && response.status===200) {
            //await new Promise((resolve) => setTimeout(resolve, 500));
            setLoading(false);
        }

        window.location.reload();
        }  catch (error){
            if (error.response && [400, 500].includes(error.response.status)){
                toast.error(error.response.data.error);
                setLoading(false);
            }
        }
    }

    const handleContinue = async () => {
        await handleDeleteCustomer(customer.code);
    }
  
    const handleCloseDeletionModal = () =>{
      setDeletionModalOpen(false);
    }

    if (loading) {
        return <LoadingOverlay />;
    }
    
    return (
        <div className='card-header p-3 mb-3 shadow bg-white' style={{ borderRadius: '15px' }}>
            <div className='row'>
                <div className='col-sm col-12 mt-2 text-center'>{customer.date}</div>
                <div className='col-sm col-6 mt-2 text-center'>
                    {customer.country_cca3 ? <FlagComponent cca3={customer.country_cca3} /> : 'Undefined'}
                </div>
                <div className='col-sm col-6 mt-2 text-center'>{customer.firstName}</div>
                <div className='col-sm col-6 mt-2 text-center'>{customer.lastName}</div>
                <div className='col-sm col-6 mt-2 text-center'>{customer.advisor}</div>
                <div className='col-sm-4 col-12 mt-2 text-center'>
                    <a href={`/enterprise/customer/${customer.code}`} className="btn flous-bg-primary mr-2"><i className="nav-icon fas fa-eye"></i></a>
                    <button type="button" className="btn flous-bg-secondary mr-2" data-toggle="modal" data-target="#assignToAdvisor">Assign To</button>
                    <span onClick={() => setDeletionModalOpen(true)}>
                        <span className="btn btn-secondary mr-2">
                            <i className="nav-icon fas fa-trash"></i>
                        </span>
                    </span>
                </div>
                <AssignToAdvisorsModal customer={customer} advisors={advisors} />
            </div>
            <DeletionModal 
                isOpen={deletionModalOpen}
                bodyText={t("modal.deletionModal.client")}
                onContinue={handleContinue}
                onClose={handleCloseDeletionModal}
            />
            <ToastContainer />
        </div>
    );
}

export default EnterpriseCustomersItem;