

export function IELTSLevelToScore(level) {
    const conversion = {
      "A1": 2,
      "A2": 4,
      "B1": 6,
      "B2": 8,
      "C1": 10,
      "C2": 12
    };
    return conversion[level] || 4;
}

export function TEFLevelToScore(level) {
    const conversion = {
      "A1": 2,
      "A2": 4,
      "B1": 6,
      "B2": 8,
      "C1": 10,
      "C2": 12
    };
    return conversion[level] || 4;
}

const fetchFrenchTestResults = async (testLanguageProficiency) =>{
    if (testLanguageProficiency.frenchtest === 'No') {
      if (testLanguageProficiency.frenchEducation){
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split('T')[0] // .replace(/-/g, '/');

        testLanguageProficiency.testDate = formattedDate
        testLanguageProficiency.testType = 'TEF'
        testLanguageProficiency.speakingScore = TEFLevelToScore(testLanguageProficiency.speakingLevel);
        testLanguageProficiency.listeningScore = TEFLevelToScore(testLanguageProficiency.listeningLevel);
        testLanguageProficiency.writingScore = TEFLevelToScore(testLanguageProficiency.writingLevel);
        testLanguageProficiency.readingScore = TEFLevelToScore(testLanguageProficiency.readingLevel);
      }
    } else {
      if (testLanguageProficiency.writingScore === '') {
        testLanguageProficiency.writingScore = TEFLevelToScore("A1");
      }
      if (testLanguageProficiency.readingScore === '') {
        testLanguageProficiency.readingScore = TEFLevelToScore("A1");
      }
    }
    return testLanguageProficiency
  }

  const fetchEnglishTestResults = async (testLanguageProficiency) =>{
    if (testLanguageProficiency.englishtest === 'No') {
      if (testLanguageProficiency.englishEducation){
        testLanguageProficiency.testType = 'IELTS';
        testLanguageProficiency.speakingScore = IELTSLevelToScore(testLanguageProficiency.speakingLevel);
        testLanguageProficiency.listeningScore = IELTSLevelToScore(testLanguageProficiency.listeningLevel);
        testLanguageProficiency.writingScore = IELTSLevelToScore(testLanguageProficiency.writingLevel);
        testLanguageProficiency.readingScore = IELTSLevelToScore(testLanguageProficiency.readingLevel);
      }
    }
    return testLanguageProficiency
  }

  export const fetchLanguageProficiency = async (languageProficiency) => {
    languageProficiency.englishLanguageProficiency = await fetchEnglishTestResults(languageProficiency.englishLanguageProficiency);
    languageProficiency.frenchLanguageProficiency = await fetchFrenchTestResults(languageProficiency.frenchLanguageProficiency);
    
    return languageProficiency;
  }
