import React, { useState, useEffect } from 'react';
import { sortDictionaryByValues } from '../../../../../services/utils';
import api from '../../../../../services/api';
import Select from 'react-select';
import RequiredIndicator from '../../../../general/requiredIndicator';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import { useTranslation } from "react-i18next";
import { translateDictionaryList, translateText } from '../../../../../services/general/TranslationServices';

const EducationDetailSection = (props) => {
  const [formData, setFormData] = useState(props.educationDetail);

  const [degreeLevels, setDegreeLevel] = useState([]);
  const [studyDurations, setStudyDurations] = useState([]);
  const [trainingFields, setTrainingFields] = useState([]);
  const [generalStudyFields, setGeneralStudyFields] = useState([]);
  
  
  const [degreeLevelsOriginal, setDegreeLevelOriginal] = useState([]);
  const [studyDurationsOriginal, setStudyDurationsOriginal] = useState([]);
  const [trainingFieldsOriginal, setTrainingFieldsOriginal] = useState([]);
  const [generalStudyFieldsOriginal, setGeneralStudyFieldsOriginal] = useState([]);
  
    const [quebecDegreeDiplomas, setQuebecDegreeDiplomas] = useState([]);
    const [quebecDegreeDiplomasOriginal, setQuebecDegreeDiplomasOriginal] = useState([]);

  const { t } = useTranslation();
  
  const lang = localStorage.getItem('flouslang') || 'en';

  const handleTranslate = async ()=>{
    if (lang !== 'en') {
      const degreeLevelsTranslated = await translateDictionaryList(degreeLevelsOriginal, lang);
      setDegreeLevel(degreeLevelsTranslated);
      const trainingFieldsTranslated = await translateDictionaryList(trainingFieldsOriginal, lang);
      setTrainingFields(trainingFieldsTranslated);
      const generalStudyFieldsTranslated = await translateDictionaryList(generalStudyFieldsOriginal, lang);
      setGeneralStudyFields(generalStudyFieldsTranslated);
      const studyDurationsTranslated = await translateDictionaryList(studyDurationsOriginal, lang);
      setStudyDurations(studyDurationsTranslated);
      const studyQCLevelTranslated = await translateDictionaryList(quebecDegreeDiplomasOriginal, lang);
      setQuebecDegreeDiplomas(studyQCLevelTranslated);
    } else {
      setDegreeLevel(degreeLevelsOriginal);
      setTrainingFields(trainingFieldsOriginal);
      setGeneralStudyFields(generalStudyFieldsOriginal);
      setStudyDurations(studyDurationsOriginal);
      setQuebecDegreeDiplomas(quebecDegreeDiplomasOriginal);
    }
  };

  useEffect(()=>{
    handleTranslate();
  }, [lang, degreeLevelsOriginal, trainingFieldsOriginal, generalStudyFieldsOriginal, 
    studyDurationsOriginal, quebecDegreeDiplomasOriginal]);

  useEffect(() => {
    const fetchLevels = async () =>{
        const response = await api.get('/educationlevel/');
        const sorted = response.data; // sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setDegreeLevel(options);
        setDegreeLevelOriginal(options);
      
        const responseQC = await api.get('/qc-educationlevel/');
        const data = responseQC.data; // sortDictionaryByValues(response.data);
        const qcOptions = Object.entries(data).map(([key, val]) => ({ value: key, label: val }));
        setQuebecDegreeDiplomas(qcOptions);
        setQuebecDegreeDiplomasOriginal(qcOptions);
    };
    const fetchStudyDuration = async () =>{
        const response = await api.get('/yearsofstudy/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setStudyDurations(options);
        setStudyDurationsOriginal(options);
    };
    const fetchGeneralStudyField = async () =>{
      const response = await api.get('/generaltrainingfield/');
      const sorted = sortDictionaryByValues(response.data);
      const options = getOptions(sorted);
      setGeneralStudyFields(options);
      setGeneralStudyFieldsOriginal(options);
    };  
    const fetchtrainingFields = async () =>{
        const response = await api.get('/trainingfieldoutside/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setTrainingFields(options);
        setTrainingFieldsOriginal(options);
    };

    fetchGeneralStudyField();
    fetchtrainingFields();
    fetchLevels();
    fetchStudyDuration();

  }, []);

  const getOptions = (data) => {
    return Object.entries(data).map(([key, val]) => ({
      value: key,
      label: val,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedData);
    props.sendEducationDetail(updatedData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? `${t('forms.formsquestion.toast.completeSection')}` : ''}</div>
        <p className='text-bold text-center'>{t("forms.title.educationdetail")}</p>
        <div className='row p-2'>
          <p htmlFor="degreelevel" className="col-sm-6 col-form-label">{t("forms.formsquestion.educationOutsideCountry.degreelevel")}<RequiredIndicator /></p>
          <Select
            name="degreelevel"
            className="react-select-container rounded-pill flous-input-color col-sm-6"
            classNamePrefix="react-select"
            value={degreeLevels.find(option => option.value === formData.degreelevel)}
            onChange={(selectedOption) => handleChange({ target: { name: 'degreelevel', value: selectedOption ? selectedOption.value : '' } })}
            options={degreeLevels}
            placeholder={`${t('forms.formsquestion.placeholder.option')}`}
            isSearchable
            isClearable
            styles={{
              control: (provided) => ({
                ...provided,
                width: '100%',
                borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
              }),
            }}
            required
          />
        </div>
        
        { formData.degreelevel === 'PRG' && (            
            <div className='row p-2'>
              <p htmlFor="prgDegreeDiploma" className="col-sm-6 col-form-label">{t("forms.formsquestion.educationInCountry.prgDegreeDiploma")}<RequiredIndicator /></p>
              <Select
                  name="prgDegreeDiploma"
                  className="col react-select-container rounded-pill flous-input-color"
                  classNamePrefix="react-select"
                  value={quebecDegreeDiplomas.find(option => option.value === formData.prgDegreeDiploma)}
                  onChange={selectedOption => handleChange({ target: { name: 'prgDegreeDiploma', value: selectedOption ? selectedOption.value : '' } })}
                  options={quebecDegreeDiplomas}
                  placeholder={`${t('forms.formsquestion.placeholder.option')}`}
                  isSearchable
                  isClearable
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '100%',
                      borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                      borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                    }),
                  }}
                  required
                />
            </div>
        )}
        <div className='row p-2'>
          <p htmlFor="generalFieldOfStudy" className="col-sm-6 col-form-label">{t("forms.formsquestion.educationOutsideCountry.generalFieldOfStudy")}<RequiredIndicator /></p>
          <Select
            name="generalFieldOfStudy"
            className="react-select-container rounded-pill flous-input-color col-sm-6"
            classNamePrefix="react-select"
            value={generalStudyFields.find(option => option.value === formData.generalFieldOfStudy)}
            onChange={(selectedOption) => handleChange({ target: { name: 'generalFieldOfStudy', value: selectedOption ? selectedOption.value : '' } })}
            options={generalStudyFields}
            placeholder={`${t('forms.formsquestion.placeholder.option')}`}
            isSearchable
            isClearable
            styles={{
              control: (provided) => ({
                ...provided,
                width: '100%',
                borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
              }),
            }}
            required
          />
        </div>
        <div className='row p-2'>
          <p htmlFor="fieldOfStudy" className="col-sm-6 col-form-label">{t("forms.formsquestion.educationOutsideCountry.fieldOfStudy")}<RequiredIndicator /></p>
          <Select
            name="fieldOfStudy"
            className="react-select-container rounded-pill flous-input-color col-sm-6"
            classNamePrefix="react-select"
            value={trainingFields.find(option => option.value === formData.fieldOfStudy)}
            onChange={(selectedOption) => handleChange({ target: { name: 'fieldOfStudy', value: selectedOption ? selectedOption.value : '' } })}
            options={trainingFields}
            placeholder={`${t('forms.formsquestion.placeholder.option')}`}
            isSearchable
            isClearable
            styles={{
              control: (provided) => ({
                ...provided,
                width: '100%',
                borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
              }),
            }}
            required
          />
        </div>
        <div className='row p-2'>
          <p htmlFor="programDuration" className="col-12 col-form-label">{t("forms.formsquestion.educationOutsideCountry.programDuration")}<RequiredIndicator />
            <i className="nav-icon fas fa-info-circle"  
              data-tooltip-id="tooltip1" data-tooltip-content={t("forms.formsquestion.tooltip.education.tooltip2")}>
            </i>
          </p>
          
          <Select
            name="programDuration"
            className="react-select-container rounded-pill flous-input-color col-sm-12"
            classNamePrefix="react-select"
            value={studyDurations.find(option => option.value === formData.programDuration)}
            onChange={(selectedOption) => handleChange({ target: { name: 'programDuration', value: selectedOption ? selectedOption.value : '' } })}
            options={studyDurations}
            placeholder={`${t('forms.formsquestion.placeholder.option')}`}
            isSearchable
            isClearable
            styles={{
              control: (provided) => ({
                ...provided,
                width: '100%',
                borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
              }),
            }}
            required
          />
          <Tooltip id='tooltip1' />
        </div>
      </div>
    </div>
  );
};

export default EducationDetailSection;
