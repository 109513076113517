import React, {useEffect, useState} from 'react';
import { getCurrentItems } from '../../services/users/FormsServices';
import AgentCustomersFormItem from '../agent/item/AgentCustomersFormItem';
import AgentCustomersResultsItem from '../agent/item/AgentCustomersResultsItem';
import PaginationSection from '../Layout/PaginationSection';
import { useNavigate, useParams, useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingOverlay from '../general/LoadingOverlay';
import { isMyClientCheck, viewMoreCustomer } from '../../services/enterprise/CustomersServices';
import NoItemsMessage from '../general/NoItemsToDisplaysItem';
import { languageConverter, mapStateToDescription } from '../../services/utils';
import GoToBackButton from '../general/GoBackLink';
import CountrySelect from '../general/CountrySelect';
import Select from 'react-select';
import { createClientNewForm } from '../../services/agents/CustomersServices';
import { useTranslation } from 'react-i18next';

export default function EntrepriseCustomerDetail() {

  const lang = localStorage.getItem('flouslang') || 'en';

  const { user } = useOutletContext();
  const [customer, setCustomer] = useState({});
  const [forms, setForms] = useState([]);
  const [evaluations, setEvaluations] = useState([]);

  const [isMyClient, setIsMyClient] = useState(false);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {code} = useParams();

  const [country, setCountry] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(null);
  const { t } = useTranslation();

  const handleCountryChange = (e) =>{
    setCountry(e.target.value);
  }

  const formsStates = [
    {abbr: 'AB', name: 'ABANDONED'},
    {abbr: 'CR', name: 'CREATED'},
    {abbr: 'IP', name: 'IN PROGRESS'},
    {abbr: 'CP', name: 'COMPLETETD'},
    {abbr: 'EV', name: 'EVALUATED'},
  ]

  const handleSubmit = (event) => {
    event.preventDefault();
    const queryParams = new URLSearchParams({
      country: country || '',
      status: selectedStatus || ''
    }).toString();
    filterItems();
    //console.log(queryParams);
  };

  const filterItems = async () => {
    let isMounted = true;
    if (user.id) {
        try {
          const response = await viewMoreCustomer(code);
          if (isMounted) {
            if (!country && !selectedStatus) {
              setForms(response.data.raw_data);
              setEvaluations(response.data.app_data);
              setLoading(false);
              return;
            }
            const filteredItemsForms = response.data.raw_data.filter((item) => {
              const matchesCountry = country ? item.country_cca3 === country : true;
              const matchesStatus = selectedStatus ? item.state === selectedStatus : true;
              return matchesCountry && matchesStatus;
            });
            const filteredItemsEvaluations = response.data.app_data.filter((item) => {
              const matchesCountry = country ? item.country_cca3 === country : true;
              const matchesStatus = selectedStatus ? item.state === selectedStatus : true;
              return matchesCountry && matchesStatus;
            });
            setForms(filteredItemsForms);
            setEvaluations(filteredItemsEvaluations);
            setLoading(false);
          }
        } catch (error) {
          // console.error('Error when preparing data:', error);
          if (isMounted){
            setLoading(false);
            if (error.response && [400, 500].includes(error.response.status)){
              toast.error(error.response.data.error);
            }
          }
        }
      }
  };

  useEffect(()=>{

    const handleViewMore = async(code) => {
      setLoading(true);
      try{
        const response = await viewMoreCustomer(code);
        
        if (response.status === 200){
          setCustomer(response.data.customer_data);
          setForms(response.data.raw_data);
          setEvaluations(response.data.app_data);
        }

        setLoading(false);
  
      }  catch (error){
        setLoading(false);
        if (error.response && [400, 500].includes(error.response.status)){
          navigate(user.type_package === 'Enterprise'? `/enterprise/customers/list` : `/professional/customers` , {replace: true});
          toast.error(error.response.data.error);
        }
      }
    };

    handleViewMore(code);

  }, [code, navigate]);

  useEffect(()=>{

    const handleIsMyClient = async(code) => {
      setLoading(true);
      try{
        const response = await isMyClientCheck(code);
        
        if (response.status === 200){
          setIsMyClient(response.data.is_my_client);
        }
        setLoading(false);
  
      }  catch (error){
        setLoading(false);
        if (error.response && [400, 500].includes(error.response.status)){
          toast.error(error.response.data.error);
        }
      }
    };

    handleIsMyClient(code);

  }, [code]);

  const [page, setPage] = useState(1);
  const pageSize = 5;
  const onPageChange = (page) => {
    setPage(page + 1);
  };
  
  const currentFormsItems = getCurrentItems(forms, page, pageSize);

  const [pageResults, setPageResults] = useState(1);
  const pageSizeResults = 5;
  const onPageChangeResults = (page) => {
    setPageResults(page + 1);
  };
  
  const currentEvaluationsResults = getCurrentItems(evaluations, pageResults, pageSizeResults);

  const handleCreateNewForm = async () =>{
    
    setLoading(true);
    try {

      const response = await createClientNewForm('Canada', code);

      if (response && response.status === 200){
        setLoading(false);
        window.location.reload();
      }
    }catch (error) {
      setLoading(false);
      if (error.response && [400, 500].includes(error.response.status)){
        toast.error(error.response.data.error);
      }
    }

  }

  if (loading) {
      return <LoadingOverlay />;
  }

  return (
    <>
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="mb-2">
            <GoToBackButton />
            <div className="col-sm-6">
              <h1 className="m-0 flous-police-one flous-input-color">{t("enterprise.customers.detail.title")}</h1>
            </div>
          </div>
        </div>
      </div>
      {/* /.content-header */}

      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col">
              <div className="col mb-2">
                <div className="row">
                  {isMyClient &&
                    <>
                    <span onClick={handleCreateNewForm} className="btn btn-lg flous-bg-primary mx-2 col-lg-6 text-bold">
                    {t("enterprise.customers.detail.create")} <i className="nav-icon fas fa-edit"></i>
                    </span>
                    </>
                  }
                </div>
              </div>
              <div className="card elevation-2">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3">
                      {/* Profile Image */}
                      <div className="card card-primary elevation-2" style={{ borderRadius: '15px' }}>
                        <div className="card-body box-profile">
                          <div className="text-center">
                            <div className="flex justify-content-center">
                              {customer.profilePictureUrl ? (
                                <>
                                <img src={customer.profilePictureUrl} className="border rounded-circle" alt="User Profile" style={{ width: '200px', height: '200px', objectFit: 'cover' }}/>
                                </>
                              ):(
                                <>
                                <img src={`${process.env.PUBLIC_URL}/avatar/avatar_2.png`} className="border rounded-circle" alt="User Profile" width="150" height="150"/>
                                </>
                              )}
                            </div>
                          </div>
                          <h3 className="profile-username text-center text-bold">{customer.lastName} {customer.firstName}</h3>
                          <p className="text-center">{customer.role === 'Customer'? 'Client' : (customer.role === 'AssociateAdmin'? 'Administrator' : customer.role)}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col m-3">
                      <h4 className='mx-3 flous-input-color mb-3'>{t("enterprise.customers.detail.contact")}</h4>
                      <div className='col-sm-5 btn card'>
                        <div className='row'>
                          <div className='col-4 text-bold text-left'>{t("enterprise.customers.detail.language")}: </div>
                          <div className='col text-left'>{customer.communicationLanguage !== 'Undefined'? languageConverter(customer.communicationLanguage):customer.communicationLanguage}</div>
                        </div>
                      </div>
                      <div className='col-sm-5 btn card'>
                        <div className='row'>
                          <div className='col-4 text-bold text-left'>{t("enterprise.customers.detail.phone")}: </div>
                          <div className='col text-left'>{customer.phone}</div>
                        </div>
                      </div>
                      <div className='col-sm-5 btn card'>
                        <div className='row'>
                          <div className='col-4 text-bold text-left'>{t("enterprise.customers.detail.email")}: </div>
                          <div className='col text-left'>{customer.email}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card elevation-2"  style={{ borderRadius: '15px' }}>
                <div className="card-header">
                  <h4 className='flous-police-one flous-input-color'>{t("enterprise.customers.detail.form")}</h4>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col">
                        <CountrySelect onChange={handleCountryChange} value={country} required />
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <Select
                            name="status"
                            classNamePrefix="react-select"
                            value={formsStates.find(state => state.value === selectedStatus)}
                            onChange={(selectedOption) =>
                              setSelectedStatus(selectedOption ? selectedOption.value : '')
                            }
                            options={formsStates.map(state => ({
                              value: state.abbr,
                              label: mapStateToDescription(state.abbr, lang)
                            }))}
                            placeholder={`${t('forms.formsquestion.placeholder.option')}`}
                            isSearchable={false}
                            isClearable
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                                borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                              }),
                            }}
                          />
                        </div>
                      </div>
                      <div className='col-sm-2 col-6'>
                        <button type="submit" className="btn flous-input-color">
                        <i className="nav-icon fas fa-search"></i> {t("general.table.search")}
                      </button>
                      </div>
                      <div className='col-sm-1 col-6 btn flous-input-color'><i className="nav-icon fas fa-sort"></i> {t("general.table.sort")}</div>
                    </div>
                    {/* @csrf - React doesn't use CSRF tokens like Laravel, typically handled by session or headers */}
                </form>
                </div>
                <div className="card-body p-2">
                  <div className='flous-bg-thirty m-2' style={{ borderRadius: '15px' }}>
                      <div className='card-header p-3 mb-3 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                          <div className='row text-bold'>
                              <div className='col-sm col-6 mt-2'>{t("general.table.country")}</div>
                              <div className='col-sm col-6 mt-2 text-center'>{t("general.table.date")}</div>
                              <div className='col-sm col-6 mt-2 text-center'>{t("general.table.title")}</div>
                              <div className='col-sm col-6 mt-2 text-center'>{t("general.table.status")}</div>
                              <div className='col-sm-4 col-12 mt-2 text-center'>{t("general.table.actions")}</div>
                          </div>
                      </div>
                      {currentFormsItems.length > 0 ? (
                        currentFormsItems.map((form) => (
                        <AgentCustomersFormItem 
                          key={form.id} item={form}
                          userType={user.type_package === 'Enterprise'? 'enterprise': 'professional'} 
                        />
                        ))
                      ) : (
                        <NoItemsMessage />
                      )}
                  </div>
                </div>
                <PaginationSection
                  page={page}
                  pageSize={pageSize}
                  totalItems={forms.length}
                  onPageChange={onPageChange}
                />
              </div>
              <div className="card elevation-2" style={{ borderRadius: '15px' }}>
                <div className="card-header">
                  <h4 className='flous-police-one flous-input-color mt-2'>{t("enterprise.customers.detail.result")}</h4>
                <form action="" method="get">
                    <div className="row">
                      {/*<div className="col-sm col-12">
                        <CountrySelect required />
                      </div>
                      <div className='col-sm-1 col-6 btn flous-input-color'><i className="nav-icon fas fa-search"></i> Search</div>*/}
                      <div className='col-sm-11 col-12 btn flous-input-color text-right'><i className="nav-icon fas fa-sort"></i> {t("general.table.sort")}</div>
                    </div>
                    {/* @csrf - React doesn't use CSRF tokens like Laravel, typically handled by session or headers */}
                </form>
                </div>
                <div className="card-body p-2">
                  <div className='flous-bg-thirty m-2' style={{ borderRadius: '15px' }}>
                      <div className='card-header p-3 mb-3 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                          <div className='row text-bold'>
                              <div className='col-sm col-6 mt-2'>{t("general.table.country")}</div>
                              <div className='col-sm col-6 mt-2 text-center'>{t("general.table.date")}</div>
                              <div className='col-sm col-6 mt-2 text-center'>{t("general.table.title")}</div>
                              <div className='col-sm col-6 mt-2 text-center'>{t("general.table.actions")}</div>
                          </div>
                      </div>
                      {currentEvaluationsResults.length > 0 ? (
                        currentEvaluationsResults.map((evaluation) => (
                          <AgentCustomersResultsItem key={evaluation.id} item={evaluation} 
                            userType={user.type_package === 'Enterprise'? 'enterprise': 'professional'} 
                          />
                        ))
                      ) : (
                        <NoItemsMessage />
                      )}
                  </div>
                </div>
                <PaginationSection
                  page={pageResults}
                  pageSize={pageSizeResults}
                  totalItems={evaluations.length}
                  onPageChange={onPageChangeResults}
                />
              </div>
            </div>
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
      {/* /.content */}
    </>
  );
};