import api from '../api';

let isLoading = false;

export const fetchCustomersRequestsDataFromAdvisors = async () => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/advisors/get-all-requests`);
        return response;

    } catch (error){
        throw error;
    } finally {
        isLoading = false;
    }  
};

export const getCustomersRequestsDataFromAdvisors = async (request) => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/advisors/get-request/${request}`);
        return response;

    } catch (error){
        throw error;
    } finally {
        isLoading = false;
    }  
};

export const acceptRequestFromAdvisors = async (request) => {

    try{
        const response = await api.get(`/advisors/accept-request/${request}`);
        return response;

    } catch (error){
        throw error;
    }   
};

export const rejectRequestFromAdvisors = async (request) => {

    try{
        const response = await api.get(`/advisors/reject-request/${request}`);
        return response;

    } catch (error){
        throw error;
    }   
};