import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import CountrySelect from '../general/CountrySelect';
import { UpdateCompanyProfile } from '../../services/general/ProfileServices';
import Select from 'react-select';
import { useTranslation } from "react-i18next";

export default function EnterpriseProfileSection() {

  const { t } = useTranslation();
  const [profileInfo, setProfileInfo] = useState({
    language: [],
    linkedin: '',
    website: '',
    location: '',
    services: [],
    aboutus: ''
  });

  const [country, setCountry] = useState('');
  const languageOptions = [
    { value: 'FR', label: 'Français' },
    { value: 'EN', label: 'English' },
    { value: 'ES', label: 'Español' }
  ];

  const serviceOptions = [
    { value: 'General Consultation Services', label: 'General Consultation Services' },
    { value: 'Temporary Residence Applications', label: 'Temporary Residence Applications' },
    { value: 'Permanent Residence Applications', label: 'Permanent Residence Applications' },
    { value: 'Sponsorship Applications', label: 'Sponsorship Applications' },
    { value: 'Verification and Status Services', label: 'Verification and Status Services' },
    { value: 'Citizenship Applications', label: 'Citizenship Applications' },
    { value: 'Asylum and Refugee Services', label: 'Asylum and Refugee Services' },
    { value: 'Judicial and Review Services', label: 'Judicial and Review Services' },
    { value: 'Humanitarian and Compassionate Applications', label: 'Humanitarian and Compassionate Applications' },
    { value: 'Other Specialized Services', label: 'Other Specialized Services' },
  ];

  const handleCountryChange = (e) => {
    const { name, value } = e.target;
    setCountry(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (e.target.multiple) {
      const values = Array.from(e.target.selectedOptions, option => option.value);
      setProfileInfo((prevInfo) => ({
        ...prevInfo,
        [name]: values
      }));
    } else {
      setProfileInfo((prevInfo) => ({
        ...prevInfo,
        [name]: value
      }));
    }
  };

  useEffect(()=>{
    setProfileInfo((prevInfo) => ({
      ...prevInfo,
      location: country
    }));
  }, [country]);

  const handleSaveProfile = async (e) => {
    e.preventDefault();
    if (!profileInfo.language.length === 0 || !profileInfo.services.length === 0 ||
      !profileInfo.location || !profileInfo.aboutus
     ) {
      toast.error("Please complete the required field before update your company informations");
      return;
    }
    
    try {
      const response = await UpdateCompanyProfile(profileInfo);
      if (response && response.status === 200) {
        toast.success("Your personal information has been successfully updated, \nplease log in below.");
      }
    } catch (error) {
      if (error.response && [400, 401, 500].includes(error.response.status)){
        toast.error(error.response.data.error);
      }
    }
  };

  return (
    <div id="Profile">
      <div className="flous-bg-thirty p-3 elevation-2 mb-3" style={{ borderRadius: "15px" }}>
        <div className="text-center text-bold">{t("enterprise.profile.company.subtitle")}</div>
        <hr />
        <div className="mx-3">
          <p>(<span style={{ color: 'red' }}>*</span>) : {t("enterprise.profile.company.isrequired")}</p>
          <div className="form-group row">
            <label htmlFor="inputLanguage" className="col-sm-4 col-form-label"><i class="fas fa-language"></i> {t("enterprise.profile.company.language")}<span style={{ color: 'red' }}> *</span> :</label>
            <div className="col-sm-8">
            <Select
              name="language"
              className="react-select-container"
              classNamePrefix="react-select"
              value={languageOptions.filter(option => profileInfo.language.includes(option.value))}
              onChange={(selectedOptions) =>
                handleChange({
                  target: {
                    name: 'language',
                    value: selectedOptions ? selectedOptions.map(option => option.value) : []
                  }
                })
              }
              options={languageOptions}
              placeholder={t("enterprise.profile.company.language")}
              isMulti
              isSearchable
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                  borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                  borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                }),
              }}
            />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputLinkedIn" className="col-sm-4 col-form-label"><i class="fab fa-linkedin"></i> {t("enterprise.profile.company.linkedin")} :</label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                id="inputLinkedIn"
                placeholder={t("enterprise.profile.company.linkedin")}
                name="linkedin"
                value={profileInfo.linkedin}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputWebsite" className="col-sm-4 col-form-label"><i class="fas fa-globe"></i> {t("enterprise.profile.company.website")} :</label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                id="inputWebsite"
                placeholder={t("enterprise.profile.company.website")}
                name="website"
                value={profileInfo.website}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="country" className="col-sm-4 col-form-label"><i class="fas fa-map-marker-alt"></i> {t("enterprise.profile.company.location")}<span style={{ color: 'red' }}> *</span> :</label>
            <div className="col-sm-8">
              <CountrySelect
                className="flous-input-color"
                value={country} onChange={handleCountryChange}  
                required 
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputServices" className="col-sm-4 col-form-label">{t("enterprise.profile.company.services")}<span style={{ color: 'red' }}> *</span> :</label>
            <div className="col-sm-8">
            <Select
              name="services"
              className="react-select-container"
              classNamePrefix="react-select"
              value={serviceOptions.filter(option => profileInfo.services.includes(option.value))}
              onChange={(selectedOptions) =>
                handleChange({
                  target: {
                    name: 'services',
                    value: selectedOptions ? selectedOptions.map(option => option.value) : []
                  }
                })
              }
              options={serviceOptions}
              placeholder={t("enterprise.profile.company.services")}
              isMulti
              isSearchable
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                  borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                  borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                }),
              }}
            />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputAboutUs" className="col-sm-4 col-form-label">{t("enterprise.profile.company.aboutus")}<span style={{ color: 'red' }}> *</span> :</label>
            <div className="col-sm-8">
              <textarea
                className="form-control  flous-input-color"
                id="inputAboutUs"
                placeholder={t("enterprise.profile.company.aboutusPlaceholder")}
                name="aboutus"
                rows="4"
                value={profileInfo.aboutus}
                onChange={handleChange}
                required
              ></textarea>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <button type="button" className="col-4 btn flous-btn-gradient btn-block rounded-pill" onClick={handleSaveProfile}>
          {t("enterprise.profile.company.submit")}
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}