import React, { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { DeleteForm, DuplicateFormFromCustomer } from '../../../services/users/FormsServices';
import FlagComponent from '../../general/FlagComponent';
import LoadingOverlay from '../../general/LoadingOverlay';
import { toast } from 'react-toastify';
import { mapStateToDescription } from '../../../services/utils';
import { useTranslation } from 'react-i18next';
import DeletionModal from '../../general/DeletionModal';

const UserFormItem = ({ item, editForm, evaluateForm}) => {

    const lang = localStorage.getItem('flouslang') || 'en';
    const { t } = useTranslation();
    const [deletionModalOpen, setDeletionModalOpen] = useState(false);
    
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [newform, setNewForm] = useState('edit');
    const pageType = "form"

    const stateList = ['CP', 'PS']
    const evStateList = ['EV', 'CP', 'PS'];

    const { user } = useOutletContext();
    const navigate = useNavigate();

    const handleDuplicateForm = async (code) =>{
      setLoading(true);
      try{
            const response = await DuplicateFormFromCustomer(code);
  
            if (response && [200, 201].includes(response.status)){
              setLoading(false);
              navigate('/user/results');
              // window.location.reload();
            }
        }  catch (error){
            setLoading(false);
            if (error.response && [400, 500].includes(error.response.status)){
              toast.error(error.response.data.error);
            }
      }
    }


    const handleDeleteForm = async (code) =>{
      setLoading(true);
      try{
            const response = await DeleteForm(code);
            setLoading(false);
            window.location.reload();
        }  catch (error){
            setLoading(false);
            throw error;
      }
    }

    const handleContinue = async () => {
        await handleDeleteForm(item.code);
    }
  
    const handleCloseDeletionModal = () =>{
      setDeletionModalOpen(false);
    }

    if (loading) {
        return <LoadingOverlay />;
    }

    if (error) {
        return <div>Error : {error.message}</div>;
    }

    return (
        <div className='card p-3 mb-3 mx-1' style={{ borderRadius: '15px' }}>
            <div className='row'>
                <div className='col-sm col-6 mt-2 text-center'>
                    <FlagComponent cca3={item.country_cca3} />
                </div>
                <div className='col-sm col-6 mt-2 text-center'>{item.creation_date}</div>
                <div className='col-sm col-12 mt-2 text-center'>{item.title}</div>
                <div className='col-sm col-12 mt-2 text-center'>
                    <div
                        style={{ pointerEvents: 'none' }}
                        className={`btn ${
                            item.state === 'CR'
                                ? 'flous-bg-thirty'
                                : item.state === 'IP'
                                ? 'flous-bg-secondary'
                                : item.state === 'AB'
                                ? 'flous-bg-thirty'
                                : item.state === 'CP'
                                ? 'flous-bg-menu'
                                : 'flous-bg-primary'
                            }`}
                    >
                        {mapStateToDescription(item.state, lang)}
                    </div>
                </div>
                <div className='col-sm-4 col-12 mt-2 text-center'>
                    {(user.role === 'Customer' && !user.self_analyst)? (
                        <>
                            {!evStateList.includes(item.state) ? (
                                <a href={`/client/form/${newform}/${item.code}`} onClick={() => editForm(item.code)}  title="Edit the form"> {/* data-toggle="modal" data-target="#updateModal"*/}
                                    <span className="btn flous-bg-secondary mr-1 mt-1">
                                        <i className="nav-icon fas fa-edit"></i>
                                    </span>
                                </a>
                            ) : (
                                <span className="btn flous-bg-secondary mr-1 mt-1 disabled" aria-disabled="true"><i className="nav-icon fas fa-edit"></i></span>
                            )}
                            <a href={`/client/detail/${pageType}/${item.code}`} className="btn flous-bg-menu mr-2"   title="View the form content">
                                <i className="nav-icon fas fa-eye"></i>
                            </a>
                        </>
                    ):(
                        <>
                            <a href={`/user/detail/${pageType}/${item.code}`} className="btn flous-bg-menu mr-2"   title="View the form content"><i className="nav-icon fas fa-eye"></i></a>
                            {stateList.includes(item.state) ? (
                            <span onClick={() => evaluateForm(item.code)} className="btn flous-bg-primary mr-2" data-toggle="modal" data-target="#evaluateModal">
                                <span >{t("forms.click.evaluate")}</span>
                            </span>
                            ) : (
                                <span className="btn flous-bg-primary mr-2 disabled" aria-disabled="true">{t("forms.click.evaluate")}</span>
                            )}
                            
                            {!evStateList.includes(item.state) ? (
                                <a href={`/user/form/${newform}/${item.code}`} onClick={() => editForm(item.code)}   title="Edit the form"> {/* data-toggle="modal" data-target="#updateModal"*/}
                                    <span className="btn flous-bg-secondary mr-1 mt-1">
                                        <i className="nav-icon fas fa-edit"></i>
                                    </span>
                                </a>
                            ) : (
                                <span className="btn flous-bg-secondary mr-1 mt-1 disabled" aria-disabled="true"><i className="nav-icon fas fa-edit"></i></span>
                            )}
                            <span onClick={() => handleDuplicateForm(item.code)}  title="Duplicate the form">
                                <span className="btn btn-secondary mr-1">
                                    <i className="nav-icon fas fa-copy"></i>
                                </span>
                            </span>
                            <span onClick={() => setDeletionModalOpen(true)}  title="Delete the form">
                                <span className="btn btn-secondary mr-2">
                                    <i className="nav-icon fas fa-trash"></i>
                                </span>
                            </span>
                        </>
                    )} 
                </div>
            </div>
            <DeletionModal
                isOpen={deletionModalOpen}
                bodyText={t("modal.deletionModal.form")}
                onContinue={handleContinue}
                onClose={handleCloseDeletionModal}
            />
        </div>
    );
};

export default UserFormItem;
