import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import LoadingOverlay from '../../general/LoadingOverlay';
import { deleteAdvisor } from '../../../services/enterprise/AdvisorsServices';
import { toast } from 'react-toastify';
import DeletionModal from '../../general/DeletionModal';
import { useTranslation } from 'react-i18next';

const EnterpriseAdvisorsItem = ({ advisor }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { user } = useOutletContext();
    const { t } = useTranslation();
    const [deletionModalOpen, setDeletionModalOpen] = useState(false);

    const handleDeleteAdvisor = async (code) =>{
        setLoading(true);
        try{
        const response = await deleteAdvisor(code);
        setLoading(false);
        window.location.reload();
        }  catch (error){
            setLoading(false);
            if (error.response && [400, 500].includes(error.response.status)){
              setError(error.response.data.error);
              toast.error(error.response.data.error);
            }
        }
    }

    const handleContinue = async () => {
        await handleDeleteAdvisor(advisor.code);
    }
  
    const handleCloseDeletionModal = () =>{
      setDeletionModalOpen(false);
    }

    if (loading) {
        return <LoadingOverlay />;
    }

    if (error) {
        return <div>Error : {error.message}</div>;
    }
    
    return (
        <div className='card-header p-3 mb-3 shadow bg-white' style={{ borderRadius: '15px' }}>
            <div className='row'>
                <div className='col-sm col-6 mt-2 flex justify-content-center'>
                    {advisor.profilePictureUrl ? (
                        <img className="rounded-circle" src={advisor.profilePictureUrl} alt={``} style={{ width: '40px', height: '40px' }} />
                    ):(
                        <img className="rounded-circle" src={`${process.env.PUBLIC_URL}/avatar/avatar.png`} alt={``} style={{ width: '30px', height: '30px' }} />
                    )}
                    
                </div>
                <div className='col-sm col-6 mt-2 text-center'>{advisor.firstName}</div>
                <div className='col-sm col-6 mt-2 text-center'>{advisor.lastName}</div>
                <div className='col-sm col-6 mt-2 text-center'>{advisor.numberOfCustomers}</div>
                <div className='col-sm col-12 mt-2 text-center'><div className={`btn ${advisor.status === "Active" ? "flous-bg-menu" : "btn-secondary"}`}>{advisor.status}</div></div>
                <div className='col-sm-4 col-12 mt-2 text-center'>
                    <a href={`/enterprise/advisor/${advisor.code}`} className="btn flous-bg-primary mr-2"><i className="nav-icon fas fa-eye"></i></a>
                    {user.code === advisor.code ? (
                        <>
                            <span>
                                <span className="btn btn-secondary mr-2 disabled"  aria-disabled="true">
                                    <i className="nav-icon fas fa-trash"></i>
                                </span>
                            </span>
                        </>
                        ):(
                        <>
                            <span onClick={()=>setDeletionModalOpen(true)} > {/** data-toggle="modal" data-target="#deleteModalAction" */}
                                <span className="btn btn-secondary mr-2">
                                    <i className="nav-icon fas fa-trash"></i>
                                </span>
                            </span>
                        </>
                    )}
                </div>
            </div>
            <DeletionModal 
                isOpen={deletionModalOpen}
                bodyText={t("modal.deletionModal.advisor")}
                onContinue={handleContinue}
                onClose={handleCloseDeletionModal}
            />
        </div>
    );
}

export default EnterpriseAdvisorsItem;