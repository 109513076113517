import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast, ToastContainer } from 'react-toastify';

const SummaryPage = (props) => {

  const { t } = useTranslation();

  // const [actor, setActor] = useState(props.actor)

  /*useEffect(()=>{
    setActor(props.actor);
  }, [])*/

  // const allTrue = obj => Object.values(obj).every(value => value === true);
  const [steps, setSteps] = useState([
    { id: "step-td1", label: `${t("client.forms.edit.sectionslist.personalInformation")}`, validated: props.summary.personalInformation },
    { id: "step-td2", label: `${t("client.forms.edit.sectionslist.familyMembers")}`, validated: props.summary.familyMembers },
    { id: "step-td3", label: `${t("client.forms.edit.sectionslist.residences")}`, validated: props.summary.residences },
    { id: "step-td4", label: `${t("client.forms.edit.sectionslist.languageProficiency")}`, validated: props.summary.languageProficiency },
    { id: "step-td5", label: `${t("client.forms.edit.sectionslist.educationInCountry")}`, validated: props.summary.educationInCountry },
    { id: "step-td6", label: `${t("client.forms.edit.sectionslist.educationOutsideCountry")}`, validated: props.summary.educationOutsideCountry },
    { id: "step-td7", label: `${t("client.forms.edit.sectionslist.professionalExperience")}`, validated: props.summary.professionalExperience },
    { id: "step-td8", label: `${t("client.forms.edit.sectionslist.professionalExpertise")}`, validated: props.summary.professionalExpertise },
    { id: "step-td9", label: `${t("client.forms.edit.sectionslist.jobOffer")}`, validated: props.summary.jobOffer },
    { id: "step-td10", label: `${t("client.forms.edit.sectionslist.entrepreneurialExperience")}`, validated: props.summary.entrepreunarialExperience },
  ])

  useEffect(()=> {
    setSteps([
      { id: "step-td1", label: `${t("client.forms.edit.sectionslist.personalInformation")}`, validated: props.summary.personalInformation },
      { id: "step-td2", label: `${t("client.forms.edit.sectionslist.familyMembers")}`, validated: props.summary.familyMembers },
      { id: "step-td3", label: `${t("client.forms.edit.sectionslist.residences")}`, validated: props.summary.residences },
      { id: "step-td4", label: `${t("client.forms.edit.sectionslist.languageProficiency")}`, validated: props.summary.languageProficiency },
      { id: "step-td5", label: `${t("client.forms.edit.sectionslist.educationInCountry")}`, validated: props.summary.educationInCountry },
      { id: "step-td6", label: `${t("client.forms.edit.sectionslist.educationOutsideCountry")}`, validated: props.summary.educationOutsideCountry },
      { id: "step-td7", label: `${t("client.forms.edit.sectionslist.professionalExperience")}`, validated: props.summary.professionalExperience },
      { id: "step-td8", label: `${t("client.forms.edit.sectionslist.professionalExpertise")}`, validated: props.summary.professionalExpertise },
      { id: "step-td9", label: `${t("client.forms.edit.sectionslist.jobOffer")}`, validated: props.summary.jobOffer },
      { id: "step-td10", label: `${t("client.forms.edit.sectionslist.entrepreneurialExperience")}`, validated: props.summary.entrepreunarialExperience },
    ]);
  }, [props]);

  /*useEffect(()=>{
    if (actor === 'Principal'){
      const updatedSteps = [
        ...steps,
        { id: "step-td10", label: "Partner", validated: allTrue(props.summary)}
      ];
      setSteps(updatedSteps);
    }
  }, [actor]); */


  const pressNext = () => {
    if (props.needPartnerInformation){
      props.sendNext('Personal Information Partner', 'Partner')
    } else {
      props.sendNext('Personal Information', props.actor)
    }
    
  };

  const goBack = ()=> {
    if (props.actor === 'Principal') {
      props.goBack("Entrepreneurial Experience", "Principal");
    } else {
      props.goBack("Entrepreneurial Experience Partner", "Partner");
    } 
  };

  const pressSave = () => {
    props.onSave();
  };

  return (
    <fieldset id="step10">
      <legend className='flous-police-one'>{t("client.forms.edit.sectionslist.summary")}</legend>
      <hr></hr>
      <div className="form-card">
        <table className="table table-borderless table-hover">
          <tbody>
            {steps.map((step) => (
              <tr 
                id={step.id} 
                className="step-td" 
                key={step.id} 
                onClick={() => pressNext(step.label)}
              >
                <td>{step.label}</td>
                <td>{step.validated ? `${t("forms.sections.validated")}` : `${t("forms.sections.inProgress")}`}</td>
                <td>
                  {step.validated ? (
                    <img src={`${process.env.PUBLIC_URL}/flous/svg/check_on.svg`} alt=""/>
                  ) : (
                    <img src={`${process.env.PUBLIC_URL}/flous/svg/check_off.svg`} alt=""/>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      <div className='d-flex justify-content-center'>
        <div className='col-sm-10'>
          <div className='d-flex flex-column flex-md-row justify-content-between'>
            <input type="button" name="save" className="col-12 col-md-6 mb-2 mb-md-0 btn flous-btn-thirty rounded-pill mx-1" value={t("forms.click.goBack")} onClick={goBack}/>
            {props.isCompleted ? (
              <input type="button" name="next" className="col-12 col-md-6 btn flous-btn-primary rounded-pill mx-1" value={t("forms.click.pressSave")} onClick={pressSave}/>
            ):(
              <input type="button" name="next" className="col-12 col-md-6 btn flous-btn-primary rounded-pill mx-1" value={t("forms.click.pressNext")} onClick={pressNext}/>
            )}
            
          </div>
        </div>
      </div>
      
      <ToastContainer />
    </fieldset>
  );
};

export default SummaryPage;