
export const programsTranslations = {
    FD: {
        en: { // Mettre une virgule après un programme et continuer avec le suivant en dessous
            "AF-CAD": "Agri-Food Pilot - Residing in Canada",
            "AF-OUT": "Agri-Food Pilot- Outside Canada",
            "FST": "Federal Skilled Trades",
            "HK-B": "Hong Kong residents - Stream B: Canadian work experience",
            "CEC": "Canadian Experience Class",
            "AIP": "Atlantic Immigration Program",
            "S-UP": "Start-up Visa Program",
            "HK-A": "Hong Kong residents - Stream A: In-Canada graduate",
            "FSW": "Federal Skilled Worker",
            "HC": "Home Care Worker - Home Child Care",
            "HS": "Home Care Worker - Home Support",
            "SE": "Self-employed person class"
            
        },

        fr: {
            "AF-CAD": "Programme pilote sur l’agroalimentaire - Résidez au Canada",
            "AF-OUT": "Programme pilote sur l’agroalimentaire - Résidez à l’extérieur au Canada",
            "FST": "Programme des travailleurs de métiers spécialisés",
            "HK-B": "Résidents de Hong Kong - Volet B : Expérience de travail au Canada",
            "CEC": "Catégorie de l’expérience canadienne",
            "AIP": "Programme d’immigration au Canada atlantique",
            "S-UP": "Programme de visa pour démarrage d’entreprise",
            "HK-A":"Résidents de Hong Kong - Volet A : Diplômés au Canada",
            "FSW": "Programme des travailleurs qualifiés",
            "HC": "Aides de soins à domicile - Gardiens d’enfants en milieu familial",
            "HS": "Aides de soins à domicile - Aides familiaux à domicile",
            "SE": "Travailleur Autonome"
        },

        es: {
            "AF-CAD": "Programa Piloto de Agroalimentación - Reside en Canadá",
            "AF-OUT": "Programa Piloto de Agroalimentación - Reside fuera de Canadá",
            "FST": "Trabajadores de Oficios Especializados",
            "HK-B": "Residentes de Hong Kong - Vía B: Experiencia laboral en Canadá",
            "CEC": "Experiencia Canadiense",
            "AIP": "Programa de Inmigración del Atlántico de Canadá",
            "S-UP": "Programa de Visa para Startups",
            "HK-A": "Residentes de Hong Kong - Vía A: Graduados en Canadá",
            "FSW": "Trabajadores Calificados",
            "HC": "Trabajadores de cuidado en el hogar: Cuidadores de niños en el hogar",
            "HS": "Trabajadores de cuidado en el hogar: Apoyo domiciliario",
            "SE": "Trabajador Autónomo"
        }
    },

    QC: {
        en: { // Mettre une virgule après un programme et continuer avec le suivant en dessous
            "PEQ-V2": "Quebec Experience Program (PEQ) - Stream: Temporary Foreign Workers",
            "PTA": "Self-Employed Worker Program",
            "PSTQ-V4": "Skilled Worker Selection Program - Stream: Exceptional Talents",
            "PP-TA": "Food Processing Workers",
            "PE-V2-1": "Entrepreneur Program - Stream: Start-Up Business (New Business)",
            "PI": "Investor Program",
            "PE-V1-1": "Entrepreneur Program - Stream: Innovative Business (Starting an Innovative Business)",
            "PE-V2-2": "Entrepreneur Program - Stream: Start-Up Business (Established Business)",
            "PE-V3-2": "Entrepreneur Program - Stream: Business Succession (Acquired Business)",
            "PP-IA-V1-1": "Workers in AI, IT, and Visual Effects - Stream: Artificial Intelligence - Foreign Worker (Francization)",
            "PP-IA-V2-1": "Workers in AI, IT, and Visual Effects - Stream: Artificial Intelligence - Quebec Diploma (Francization)",
            "PE-V1-2": "Entrepreneur Program - Stream: Innovative Business (Innovative Project Implementation)",
            "PP-PB-V2": "Orderlies Pilot Program - Stream : Studies-Work",
            "PP-TI-V-1": "Workers in AI, IT, and Visual Effects - Stream: Information Technologies and Visual Effects (Francization)",
            "PP-IA-V2-2": "Workers in AI, IT, and Visual Effects - Stream: Artificial Intelligence - Quebec Diploma (Francophone)",
            "PE-V3-1": "Entrepreneur Program - Stream: Business Succession (Business in Acquisition Process)",
            "PP-IA-V1-2": "Workers in AI, IT, and Visual Effects - Stream: Artificial Intelligence - Foreign Worker (Francophone)",
            "PSTQ-V1": "Skilled Worker Selection Program - Stream: Highly Qualified and Specialized Skills",
            "PSTQ-V2": "Skilled Worker Selection Program - Stream: Intermediate and Manual Skills",
            "PP-PB-V1": "Orderlies Pilot Program - Stream: Work",
            "PP-TI-V-2": "Workers in AI, IT, and Visual Effects - Stream: Information Technology and Visual Effects (Francophone)",
            "PEQ-V1": "Quebec Experience Program (PEQ) - Stream: Quebec Graduates",
            "PSTQ-V3": "Skilled Worker Selection Program - Stream:  Regulated Professions"







            
        },

        fr: {
            "PEQ-V2": "Programme de l'expérience québécoise (PEQ) - Volet: Travailleurs étrangers temporaires",
            "PTA": "Programme des travailleurs autonomes",
            "PSTQ-V4": "Programme de sélection des travailleurs qualifiés - Volet: Talents exceptionnels",
            "PP-TA": "Travailleurs de la transformation alimentaire",
            "PE-V2-1": "Programme des entrepreneurs - Volet: Démarrage d'entreprise (Nouvelle entreprise)",
            "PI": "Programme des investisseurs",
            "PE-V1-1": "Programme des entrepreneurs - Volet: Entreprise innovante (Démarrer une entreprise innovante)",
            "PE-V2-2": "Programme des entrepreneurs - Volet: Démarrage d'entreprise (Entreprise démarrée)",
            "PE-V3-2": "Programme des entrepreneurs - Volet: Repreneuriat (Entreprise acquise)",
            "PP-IA-V1-1": "Travailleurs des secteurs de l'IA, TI et des effets visuels - Volet: Intelligence artificielle -Travailleur étranger (Francisation)",
            "PP-IA-V2-1": "Travailleurs des secteurs de l'IA, TI et des effets visuels - Volet: Intelligence artificielle - Diplôme du Québec (Francisation)",
            "PE-V1-2": "Programme des entrepreneurs - Volet: Entreprise innovante (Projet novateur)",
            "PP-PB-V2": "Préposés aux bénéficiaires - Volet: Études-Travail",
            "PP-TI-V-1": "Travailleurs des secteurs de l'IA, TI et des effets visuels - Volet: Intelligence artificielle - Technologies de l'information et effets visuels (Francisation)",
            "PP-IA-V2-2": "Travailleurs des secteurs de l'IA, TI et des effets visuels - Volet: Intelligence artificielle - Diplôme du Québec (Francophone)",
            "PE-V3-1": "Programme des entrepreneurs - Volet: Repreneuriat (Entreprise en voie d'acquisition)",
            "PP-IA-V1-2": "Travailleurs des secteurs de l'IA, TI et des effets visuels - Volet: Intelligence artificielle -Travailleur étranger (Francophone)",
            "PSTQ-V1": "Programme de sélection des travailleurs qualifiés - Volet: Haute qualification et compétences spécialisées",
            "PSTQ-V2": "Programme de sélection des travailleurs qualifiés - Volet: Compétences intermédiaires et manuelles",
            "PP-PB-V1": "Préposés aux bénéficiaires - Volet: Travail",
            "PP-TI-V-2": "Travailleurs des secteurs de l'IA, TI et des effets visuels - Volet: Intelligence artificielle - Technologies de l'information et effets visuels (Francophone)",
            "PEQ-V1": "Programme de l'expérience québécoise (PEQ) - Volet: Diplômés du Québec",
            "PSTQ-V3": "Programme de sélection des travailleurs qualifiés - Volet: Professions réglementées"
        },

        es: {
            "PEQ-V2": "Programa de Experiencia Quebequense (PEQ) - Categoria: Trabajadores Extranjeros Temporales",
            "PTA": "Programa para Trabajadores Autónomos",
            "PSTQ-V4": "Programa de Selección de Trabajadores Calificados - Categoria: Talentos Excepcionales",
            "PP-TA": "Trabajadores de la Industria de Transformación de Alimentos",
            "PE-V2-1": "Programa de Emprendedores - Categoria: Iniciar un Negocio (Nueva Empresa)",
            "PI": "Programa de Inversionistas",
            "PE-V1-1": "Programa de Emprendedores - Categoria: Empresa Innovadora (Iniciar una Empresa Innovadora)",
            "PE-V2-2": "Programa de Emprendedores - Categoria: Iniciar un Negocio (Empresa ya Establecida)",
            "PE-V3-2": "Programa de Emprendedores - Categoria: Sucesión Empresarial (Empresa Adquirida)",
            "PP-IA-V1-1": "Trabajadores en IA, TI y Efectos Visuales - Categoria: Inteligencia Artificial - Trabajador Extranjero (Francización)",
            "PP-IA-V2-1": "Trabajadores en IA, TI y Efectos Visuales - Categoria: Inteligencia Artificial - Diploma de Quebec (Francización)",
            "PE-V1-2": "Programa de Emprendedores - Categoria: Empresa Innovadora (Implementación de Proyecto Innovador)",
            "PP-PB-V2": "Programa Piloto para Cuidadores - Categoria: Estudios-Trabajo",
            "PP-TI-V-1": "Trabajadores en IA, TI y Efectos Visuales - Categoria: Tecnologías de la Información y Efectos Visuales (Francización)",
            "PP-IA-V2-2": "Trabajadores en IA, TI y Efectos Visuales - Categoria: Inteligencia Artificial - Diploma de Quebec (Francófono)",
            "PE-V3-1": "Programa de Emprendedores - Categoria: Sucesión Empresarial (Empresa en Proceso de Adquisición)",
            "PP-IA-V1-2": "Trabajadores en IA, TI y Efectos Visuales - Categoria: Inteligencia Artificial - Trabajador Extranjero (Francófono)",
            "PSTQ-V1": "Programa de Selección de Trabajadores Calificados - Categoria: Alta Cualificación y Habilidades Especializadas",
            "PSTQ-V2": "Programa de Selección de Trabajadores Calificados - Categoria: Habilidades Intermedias y Manuales",
            "PP-PB-V1": "Programa Piloto para Cuidadores - Categoria: Trabajo",
            "PP-TI-V-2": "Trabajadores en IA, TI y Efectos Visuales - Categoria: Tecnologías de la Información y Efectos Visuales (Francófono)",
            "PEQ-V1": "Programa de Experiencia Quebequense (PEQ) - Categoria: Graduados de Quebec",
            "PSTQ-V3": "Programa de Selección de Trabajadores Calificados - Categoria: Profesiones Reguladas"
        }
    },

    ON: {
        en: { // Mettre une virgule après un programme et continuer avec le suivant en dessous
            "HCP": "Human Capital Priorities",
            "FSSW": "French-Speaking Skilled Worker Stream",
            "STS": "Skilled Trades Stream",
            "MG":"The master's Graduate Category",
            "PHD": "The Ph.D. Graduate Category",
            "JO-DS": "Job Offer: In-Demand Skills Stream",
            "JO-FW": "Job Offer: Foreign Worker Stream",
            "JO-IS": "Job Offer: International Student Stream"

        },

        fr: {
            "HCP": "Priorité basée sur le capital humain",
            "FSSW": "Travailleurs qualifiés francophones",
            "STS": "Métiers spécialisés",
            "MG": "Candidats étrangers titulaires d'une maîtrise",
            "PHD": "Candidats étrangers titulaires d'un doctorat",
            "JO-DS": "Offre d'emploi : Compétences recherchées",
            "JO-FW": "Offre d'emploi : Travailleurs étrangers",
            "JO-IS": "Offre d'emploi : Étudiants étrangers"
        },

        es: {
            "HCP": "Prioridades de Capital Humano",
            "FSSW": "Trabajadores Calificados de Habla Francesa",
            "STS": "Oficios Especializados",
            "MG": "Graduados de Maestría",
            "PHD": "Graduados de Doctorado (Ph.D.)",
            "JO-DS": "Oferta de Empleo: Habilidades en Demanda",
            "JO-FW": "Oferta de Empleo: Trabajador Extranjero",
            "JO-IS": "Oferta de Empleo Estudiantes Internacionales"
        }
    },
    
    BC: {
        en: { // Mettre une virgule après un programme et continuer avec le suivant en dessous
            "EIBS": "Entrepreneur Immigration Base Stream",
            "EIRS": "Entrepreneur Immigration Regional Stream",
            "SP": "Strategic Projects",
            "SWS": "Skilled Worker Stream",
            "IPGS": "International Post-Graduate Stream",
            "HAS": "Health Authority Stream",
            "IGS": "International Graduate Stream",
            "ELSS": "Entry Level and Semi-Skilled (ELSS) Stream"

        },

        fr: {
            "EIBS": "Immigration des entrepreneurs - Catégorie de base",
            "EIRS": "Immigration des entrepreneurs - Catégorie régionale",
            "PS": "Projets stratégiques",
            "SWS": "Travailleurs qualifiés",
            "IPGS": "Diplômés internationaux - Études supérieures",
            "HAS": "Autorités sanitaires",
            "IGS": "Diplômés internationaux",
            "ELSS": "Travailleurs de niveau débutant et semi-qualifiés"
        },

        es: {
            "EIBS": "Inmigración para Emprendedores - Categoría Base",
            "EIRS": "Inmigración para Emprendedores - Categoría Regional",
            "PS": "Proyectos Estratégicos",
            "SWS": "Trabajadores Calificados",
            "IPGS": "Posgrado Internacional",
            "HAS": "Autoridades Sanitarias",
            "IGS": "Graduados Internacionales",
            "ELSS": "Trabajadores de Nivel Inicial y Semi-Calificados"
        }
    },
    
    AB: {
        en: { // Mettre une virgule après un programme et continuer avec le suivant en dessous
            "Prog1. Abbr": "Texte de Prog1  en Aglais",
            "Prog2. Abbr": "Texte de Prog2 V1 en Aglais",
            
        },

        fr: {
            "Prog. Abbr": "Texte de PSTQ V1 en Francais",
        },

        es: {
            "Prog. Abbr": "Texte de PSTQ V1 en Espagnol",
        }
    },
    

  };