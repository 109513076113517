import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import CountryShow from '../../../general/countryShow';
import { useTranslation } from 'react-i18next';
import { familyRelation } from '../../../../services/utils';
import styles from '../styles/pdfstyles';
import api from '../../../../services/api';

export default function FamilyMembersResumeSectionPDF(props) {
  const { t } = useTranslation();
  const lang = localStorage.getItem('flouslang') || 'en';

  const {
    children: { dependantChildren, totalDependantChildren, childrenUnder22 },
    familyMembers,
    familyInfo: { totalSibling, totalCanadianOrPResident }, 
    show,
  } = props;

  const [provinces, setProvinces] = useState([]);

  useEffect(() => {
      const fetchProvinces = async() => {
        const response = await api.get('/canada-provinces/');
          const provinceOptions = Object.entries(response.data).map(([key, val]) => ({
            value: key,
            label: val,
          }));
          setProvinces(provinceOptions);
      };
  
      fetchProvinces();
  }, []);

  return (
    <Page style={styles.page}>
      <Text style={styles.mainTitle}>{t("client.results.blockTitle.bloc2")}</Text>

      <View style={styles.card}>
        <View style={styles.row}>
          <View style={styles.column70}>
            <Text style={styles.label}>{t('forms.formsquestion.familyMembers.dependantChildren')}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>
              {dependantChildren === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
            </Text>
          </View>
        </View>

        {dependantChildren === 'Yes' && (
          <>
            <View style={styles.row}>
              <View style={styles.labelColumn}>
                <Text style={styles.label}>{t('forms.formsquestion.familyMembers.totalDependantChildren')}</Text>
              </View>
              <View style={styles.valueColumn}>
                <Text style={styles.value}>{totalDependantChildren}</Text>
              </View>
              <View style={styles.labelColumn}>
                <Text style={styles.label}>{t('forms.formsquestion.familyMembers.childrenUnder22')}</Text>
              </View>
              <View style={styles.valueColumn}>
                <Text style={styles.value}>{childrenUnder22}</Text>
              </View>
            </View>
          </>
        )}
      </View>

      <View style={styles.card}>

        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t('forms.formsquestion.familyMembers.siblingRelation')}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{totalSibling}</Text>
          </View>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t('forms.formsquestion.familyMembers.permanentOrCitizen')}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{totalCanadianOrPResident}</Text>
          </View>
        </View>
      </View>

      <View style={styles.card}>
        {!familyMembers || familyMembers.length === 0 ? (
          <Text style={styles.label}>{t('general.table.applicantEmpty.familyone')}</Text>
        ) : (
          familyMembers.map((member, index) => (
            <View key={index} style={styles.memberCard}>
              <View style={styles.row}>
                <View style={styles.labelColumn}>
                  <Text style={styles.label}>{t('general.table.firstname')}</Text>
                </View>
                <View style={styles.valueColumn}>
                  <Text style={styles.value}>{member.firstname}</Text>
                </View>
                <View style={styles.labelColumn}>
                  <Text style={styles.label}>{t('general.table.lastname')}</Text>
                </View>
                <View style={styles.valueColumn}>
                  <Text style={styles.value}>{member.lastname}</Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.labelColumn}>
                  <Text style={styles.label}>{t('general.table.nationality')}</Text>
                </View>
                <View style={styles.valueColumn}>
                  <Text style={styles.value}><CountryShow value={member.nationality} /></Text>
                </View>
                <View style={styles.labelColumn}>
                  <Text style={styles.label}>{t('forms.formsquestion.familyMembers.familyRelationship')}</Text>
                </View>
                <View style={styles.valueColumn}>
                  <Text style={styles.value}>{familyRelation(member.familyRelationship, lang)}</Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.labelColumn}>
                  <Text style={styles.label}>{t('forms.formsquestion.familyMembers.liveWith')}</Text>
                </View>
                <View style={styles.valueColumn}>
                  <Text style={styles.value}>{member.liveWith}</Text>
                </View>
                <View style={styles.labelColumn}>
                  <Text style={styles.label}>{t('forms.formsquestion.familyMembers.permanentOrCan')}</Text>
                </View>
                <View style={styles.valueColumn}>
                  <Text style={styles.value}>{member.permanent === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`}</Text>
                </View>
              </View>

              {member.liveWith === 'No' && (
                <>
                  <View style={styles.row}>
                    <View style={styles.labelColumn}>
                      <Text style={styles.label}>{t('forms.formsquestion.familyMembers.country')}</Text>
                    </View>
                    <View style={styles.valueColumn}>
                      <Text style={styles.value}><CountryShow value={member.country} /></Text>
                    </View>
                    <View style={styles.labelColumn}>
                      <Text style={styles.label}>{t('forms.formsquestion.familyMembers.provinceState')}</Text>
                    </View>
                    <View style={styles.valueColumn}>
                      <Text style={styles.value}>
                        {member.country === 'CAN'
                          ? provinces.find(province => province.value === member.provinceState)?.label || ''
                          : member.provinceState || ''
                        }
                      </Text>
                    </View>
                  </View>

                  <View style={styles.row}>
                    <View style={styles.labelColumn}>
                      <Text style={styles.label}>{t('forms.formsquestion.familyMembers.city')}</Text>
                    </View>
                    <View style={styles.valueColumn}>
                      <Text style={styles.value}>{member.city}</Text>
                    </View>
                    <View style={styles.labelColumn}>
                      <Text style={styles.label}>{t('forms.formsquestion.familyMembers.streetNumber')}</Text>
                    </View>
                    <View style={styles.valueColumn}>
                      <Text style={styles.value}>{member.streetNumber}</Text>
                    </View>
                  </View>

                  <View style={styles.row}>
                    <View style={styles.labelColumn}>
                      <Text style={styles.label}>{t('forms.formsquestion.familyMembers.postalCode')}</Text>
                    </View>
                    <View style={styles.valueColumn}>
                      <Text style={styles.value}>{member.postalCode}</Text>
                    </View>
                    {member.country === 'CAN' && (
                      <>
                      <View style={styles.labelColumn}>
                        <Text style={styles.label}>{t('forms.formsquestion.familyMembers.startDate')}</Text>
                      </View>
                      <View style={styles.valueColumn}>
                        <Text style={styles.value}>{member.startDate}</Text>
                      </View>
                      </>
                    )}
                  </View>           
                </>
              )}

              <View style={styles.row}>
                <View style={styles.labelColumn}>
                  <Text style={styles.label}>{t('forms.formsquestion.familyMembers.dateofbirth')}</Text>
                </View>
                <View style={styles.valueColumn}>
                  <Text style={styles.value}>{member.birthday}</Text>
                </View>
                <View style={styles.labelColumn}>
                  <Text style={styles.label}>{t('forms.formsquestion.familyMembers.deathday')}</Text>
                </View>
                <View style={styles.valueColumn}>
                  <Text style={styles.value}>{member.deathday || t('forms.click.no')}</Text>
                </View>
              </View>

            </View>
          ))
        )}
      </View>
    </Page>
  );
}

