import React, { useEffect, useState } from 'react';
import { Document, StyleSheet, PDFViewer, PDFDownloadLink  } from '@react-pdf/renderer';
import { usePrincipalFormStates } from '../forms/data/principalStates';
import { usePartnerFormStates } from '../forms/data/partnerStates';
import { getExistingFormData } from '../../../services/users/FormsServices';
import FormDetailIntroductionPDF from './sections/FormDetatilIntroductionPDF';
import PersonalInfoResumePDF from './sections/PersonalInfoResumePDF';
import FamilyMembersResumeSectionPDF from './sections/FamilyMemberResumePDF';
import ProfessionalExperiencePDF from './sections/ProfessionalExperienceResumePDF';
import ProfessionalExpertiseResumeSectionPDF from './sections/ProfessionalExpertiseResumePDF';
import ResidenceInfoResumePDF from './sections/ResidenceInfoResumePDF';
import LangueResumeSectionPDF from './sections/langueResumePDF';
import EducationResumeSectionPDF from './sections/EducationResumePDF';
import JobOfferResumeSectionPDF from './sections/JobOfferResumePDF';
import EntrepreneurialExperienceResumeSectionPDF from './sections/EntrepreneurialExperienceResumePDF';
import FirstPagePDF from './sections/FirstPagePDF';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PartnerFirstPagePDF from './sections/PartnerFirstPage';
import { allowedMaritalStatuses } from '../../../services/utils';

const styles = StyleSheet.create({
  downloadButton: {
    padding: '10px 50px',  /* Réduire la taille du bouton */
    backgroundColor: '#235CBE',
    color: 'white',
    borderRadius: '5px',
    textDecoration: 'none',
    display: 'inline-block',
    marginBottom: '10px',
    marginTop: '5px',
    marginLeft: 'auto',  /* Centrer le bouton horizontalement */
    marginRight: 'auto',  /* Centrer le bouton horizontalement */
    width: 'auto',  /* La largeur s'ajuste en fonction du contenu */
    textAlign: 'center',  /* Assure que le texte est centré dans le bouton */
  },  
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  pdfViewer: {
    width: '100%',
    flex: 1,
  }
});

// Fonction pour formater le nom du fichier
const formatFileName = (personalInfo, formCode) => {
  if (!personalInfo || !personalInfo.lastname || !personalInfo.firstname) {
    return `document_${formCode}.pdf`;
  }

  const lastName = personalInfo.lastname.toUpperCase();
  const firstName = personalInfo.firstname.charAt(0).toUpperCase() + 
                   personalInfo.firstname.slice(1).toLowerCase();
  
  return `${lastName}_${firstName}_Questionnaire.pdf`;
};

// Composant du document PDF
const PDFDocument = ({ code, formStates, partnerFormStates, sampleData, needPartnerInformation }) => (
  <Document>
    <FirstPagePDF data={sampleData} />
    <FormDetailIntroductionPDF data={sampleData} />

    <PersonalInfoResumePDF 
      personalInformation={formStates.personalInformation} 
    />

    <FamilyMembersResumeSectionPDF 
      familyMembers={formStates.familyMembers} 
      children={formStates.children} 
      familyInfo={formStates.familyInfo}
      show={true} 
    />

    <ResidenceInfoResumePDF 
      residences={formStates.residences} show={true} 
    />

    <LangueResumeSectionPDF 
      languageProficiency={formStates.languageProficiency} 
    />

    <EducationResumeSectionPDF 
      educationInCountry={formStates.educationInCountry}
      specialProgram={formStates.specialProgram}
      allEducationInCountry={formStates.allEducationInCountry}
      educationOutsideCountry={formStates.educationOutsideCountry}
      allEducationOutsideCountry={formStates.allEducationOutsideCountry}
      show={true}
    />
    
    <ProfessionalExperiencePDF 
      professionalExperience={formStates.professionalExperience}
      allProfessionalExperience={formStates.allProfessionalExperience}
      show={true}
    />

    <ProfessionalExpertiseResumeSectionPDF 
      professionalExpertise={formStates.professionalExpertise} 
    />

    <JobOfferResumeSectionPDF 
      jobOffer={formStates.jobOffer} 
    />

    <EntrepreneurialExperienceResumeSectionPDF 
      entrepreunarialExperience={formStates.entrepreunarialExperience} 
      allEntrepreneurialExperience={formStates.allEntrepreneurialExperience} 
    />

    {needPartnerInformation && (
      <>
        <PartnerFirstPagePDF data={sampleData} />
        <PersonalInfoResumePDF 
          personalInformation={partnerFormStates.personalInformation} 
        />

        <FamilyMembersResumeSectionPDF 
          familyMembers={partnerFormStates.familyMembers} 
          children={partnerFormStates.children} 
          familyInfo={formStates.familyInfo}
          show={true} 
        />

        <ResidenceInfoResumePDF 
          residences={partnerFormStates.residences} show={true} 
        />
        
        <LangueResumeSectionPDF 
          languageProficiency={partnerFormStates.languageProficiency} 
        />

        <EducationResumeSectionPDF 
          educationInCountry={partnerFormStates.educationInCountry}
          specialProgram={partnerFormStates.specialProgram}
          allEducationInCountry={partnerFormStates.allEducationInCountry}
          educationOutsideCountry={partnerFormStates.educationOutsideCountry}
          allEducationOutsideCountry={partnerFormStates.allEducationOutsideCountry}
          show={true}
        />
        <ProfessionalExperiencePDF 
          professionalExperience={partnerFormStates.professionalExperience}
          allProfessionalExperience={partnerFormStates.allProfessionalExperience}
          show={true}
        />
        <ProfessionalExpertiseResumeSectionPDF professionalExpertise={partnerFormStates.professionalExpertise} />
        <JobOfferResumeSectionPDF jobOffer={partnerFormStates.jobOffer} />
        <EntrepreneurialExperienceResumeSectionPDF entrepreunarialExperience={partnerFormStates.entrepreunarialExperience} allEntrepreneurialExperience={partnerFormStates.allEntrepreneurialExperience} />
      </>
    )}
  </Document>
);

// Composant d'export
const ExportableContent = () => {

  const {code} = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [formStates, setFormStates] = useState(null);
    const [evaluationCountry, setevaluationCountry] = useState("");
    const [formTitle, setFormTitle] = useState("");
    const [isSameResidence, setIsSameResidence] = useState({
      isSamecurrentResidence: '',
      isSamepreviousAddress: ''
    });
    const [isCoApplication, setIsCoApplication] = useState();
    const [needPartnerInformation, setNeedPartnerInformation] = useState(false);
  
    const [formStatus, setFormStatus] = useState('');
    const [formCode, setFormCode] = useState('');
    const [creationDate, setCreationDate] = useState('');
    const [updateCompletedDate, setUpdateCompletedDate] = useState('');
  
    const principalFormStates = usePrincipalFormStates();
    const partnerFormStates = usePartnerFormStates();
  
    const setFormData = async (response) =>{
      setevaluationCountry(response.data.data.evaluationCountry);
      setFormTitle(response.data.data.formTitle);
      setIsCoApplication(response.data.data.isCoApplication);
      //setIsSameEExperience(response.data.data.isSameEExperience);
      setIsSameResidence(response.data.data.isSameResidence);
      setCreationDate(response.data.creation_date);
      setUpdateCompletedDate(response.data.update_completed_date);
      setFormStatus(response.data.form_status);
      setFormCode(response.data.form_code);
  
      principalFormStates.setPersonalInformation(response.data.data.principalApplicantData.personalInformation);
      principalFormStates.setFamilyMembers(response.data.data.principalApplicantData.familyMembers);
      principalFormStates.setFamilyInfo(response.data.data.principalApplicantData.familyInfo);
      principalFormStates.setChildren(response.data.data.principalApplicantData.children);
      principalFormStates.setResidences(response.data.data.principalApplicantData.residences);
      principalFormStates.setLanguageProficiency(response.data.data.principalApplicantData.languageProficiency);
      principalFormStates.setEducationInCountry(response.data.data.principalApplicantData.educationInCountry);
      principalFormStates.setAllEducationInCountry(response.data.data.principalApplicantData.allEducationInCountry);
      principalFormStates.setEducationOutsideCountry(response.data.data.principalApplicantData.educationOutsideCountry);
      principalFormStates.setAllEducationOutsideCountry(response.data.data.principalApplicantData.allEducationOutsideCountry);
      principalFormStates.setProfessionalExperience(response.data.data.principalApplicantData.professionalExperience);
      principalFormStates.setAllProfessionalExperience(response.data.data.principalApplicantData.allProfessionalExperience);
      principalFormStates.setProfessionalExpertise(response.data.data.principalApplicantData.professionalExpertise);
      principalFormStates.setJobOffer(response.data.data.principalApplicantData.jobOffer);
      principalFormStates.setEntrepreunarialExperience(response.data.data.principalApplicantData.entrepreunarialExperience);
      principalFormStates.setAllEntrepreneurialExperience(response.data.data.principalApplicantData.allEntrepreneurialExperience);
      principalFormStates.setSummary(response.data.data.principalApplicantData.summary);
      principalFormStates.setSpecialProgram(response.data.data.principalApplicantData.specialProgram);
      setNeedPartnerInformation(allowedMaritalStatuses.includes(
        response.data.data.principalApplicantData.personalInformation.personalDetail.maritalStatus
      ));
  
      partnerFormStates.setPersonalInformation(response.data.data.principalApplicantPartnerData.personalInformation);
      partnerFormStates.setFamilyMembers(response.data.data.principalApplicantPartnerData.familyMembers);
      partnerFormStates.setFamilyInfo(response.data.data.principalApplicantPartnerData.familyInfo);
      partnerFormStates.setChildren(response.data.data.principalApplicantPartnerData.children);
      partnerFormStates.setResidences(response.data.data.principalApplicantPartnerData.residences);
      partnerFormStates.setLanguageProficiency(response.data.data.principalApplicantPartnerData.languageProficiency);
      partnerFormStates.setEducationInCountry(response.data.data.principalApplicantPartnerData.educationInCountry);
      partnerFormStates.setAllEducationInCountry(response.data.data.principalApplicantPartnerData.allEducationInCountry);
      partnerFormStates.setEducationOutsideCountry(response.data.data.principalApplicantPartnerData.educationOutsideCountry);
      partnerFormStates.setAllEducationOutsideCountry(response.data.data.principalApplicantPartnerData.allEducationOutsideCountry);
      partnerFormStates.setProfessionalExperience(response.data.data.principalApplicantPartnerData.professionalExperience);
      partnerFormStates.setAllProfessionalExperience(response.data.data.principalApplicantPartnerData.allProfessionalExperience);
      partnerFormStates.setProfessionalExpertise(response.data.data.principalApplicantPartnerData.professionalExpertise);
      partnerFormStates.setJobOffer(response.data.data.principalApplicantPartnerData.jobOffer);
      partnerFormStates.setEntrepreunarialExperience(response.data.data.principalApplicantPartnerData.entrepreunarialExperience);
      partnerFormStates.setAllEntrepreneurialExperience(response.data.data.principalApplicantPartnerData.allEntrepreneurialExperience);
      partnerFormStates.setSummary(response.data.data.principalApplicantPartnerData.summary);
      partnerFormStates.setSpecialProgram(response.data.data.principalApplicantPartnerData.specialProgram);
    }
  
    useEffect(() =>{
      let isMounted = true;
  
      const loadForm = async () => {
        try {
          if (isMounted) {
            const response = await getExistingFormData(code);
  
            if (response && response.status === 200){
              await setFormData(response);
              setFormStates(principalFormStates);
              setLoading(false);
            }
    
          }
        } catch (error) {
          if (isMounted){
            setError(error);
            setLoading(false);
          }
        }
        
      }
  
      loadForm();
    }, []);
  
    useEffect(() => {
      setFormStates(principalFormStates);
    }, [loading]);
  
  
    if (loading) {
      return null;
    }
  
    if (error) {
      return <div>Error : {error.message}</div>;
    }
    if (!code) {
      return <div>Error : No form code</div>;
    }

    const sampleData = {
      formTitle: formTitle,
      formCode: formCode,
      evaluationCountry: evaluationCountry,
      creationDate: creationDate,
      updateCompletedDate: updateCompletedDate,
      formStatus: formStatus,
      isCoApplication: isCoApplication
    }

    return (
      <div style={styles.container}>
        <PDFDownloadLink
          document={
            <PDFDocument
              formStates={formStates}
              partnerFormStates={partnerFormStates}
              sampleData={sampleData}
              needPartnerInformation={needPartnerInformation}
            />
          }
          fileName={formatFileName(formStates.personalInformation.identification, formCode)}
          style={styles.downloadButton}
          onClick={() => {
            // Attendre un court instant pour que le téléchargement démarre
            setTimeout(() => {
              window.close(); // Ferme la fenêtre/onglet actuel
            }, 1000);
          }}
        >
          {({ blob, url, loading, error }) =>
            loading ? 'Loading PDF ...' : `${t("client.forms.detail.download")} ${formatFileName(formStates.personalInformation.identification, formCode) || 'document'}`
          }
        </PDFDownloadLink>
        
        <PDFViewer style={styles.pdfViewer}>
          <PDFDocument 
            formStates={formStates}
            partnerFormStates={partnerFormStates}
            sampleData={sampleData}
            needPartnerInformation={needPartnerInformation}
          />
        </PDFViewer>
      </div>
    );
};

export default ExportableContent;