import React, {useState} from 'react';
import api from '../../services/api';
import LanguageSelect from '../general/languageSelect';
import { useTranslation } from 'react-i18next';
import { translateText } from '../../services/general/TranslationServices';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordPage = () => {

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  
  const { t } = useTranslation();
  const lang = localStorage.getItem('flouslang') || 'en';
  
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
      e.preventDefault();
      try {
          const response = await api.post('/auth/reset-password/', { email });
          if (response && [200, 201].includes(response.status)){
            // const textTranslated = await translateText(response.data.message, lang);
            setMessage(t('login.resetInstructionsSent'));
            setEmail('')
          }
      } catch (error) {
        if (error.response && [400, 404].includes(error.response.status)){
          setErrorMessage(t('login.noAccountssociated'));
        } else {
          setErrorMessage(t('login.noAccountssociated'));
        }
      }
  };


  return (
    <div className='relative overflow-hidden bg-[linear-gradient(270deg,#7FA1F8_0%,#FDFEFE_88%)] min-h-screen'>
      <div className="top-[20px]  bottom-0">
        <img src={`${process.env.PUBLIC_URL}/flous/bg/login-bg.png`} alt="" className="w-[900px] ml-[35%] sm:ml-[60%]   object-cover z-0 " />
      </div>
      <div className='absolute top-0 left-0 right-0 bottom-0 p-2'>
        <div className='flex justify-between'>
          <div className="sm:ml-5 mt-3">
            <a href="/">
              <img
                src={`${process.env.PUBLIC_URL}/flous/logos/trans_1.svg`}
                alt="Flous Logo"
                className="brand-image"
                style={{ opacity: 0.8, height: '150px', width: 'auto' }}
              />
            </a>
          </div>
          <div className="sm:ml-5 mt-5 pt-3">
            <div className='right-0'><LanguageSelect /></div>
          </div>
        </div>
        
        <div className="col-lg-12 mx-auto text-center text-bold h5">
          <span> 
            {t("login.alreadyHaveAccount")} 
            <a className="btn mb-2 text-bold" style={{ textDecoration: 'underline', color: 'blue' }} href="/login">{t("login.signIn")}</a>
          </span>
        </div>
        <div className="d-flex justify-center align-items-start min-h-screen pt-10">
          <div className="col-lg-6 mx-auto">
            <div className="card card-outline elevation-2 p-5" style={{ borderRadius: '15px' }}>
              <div className="card-header text-center">
                <h3 className="flous-police-one">{t("forgotPassword.title")}</h3>
              </div>

              {message && <div className="text-center mb-2 mt-2 text-bold" style={{ color: 'green' }}>{message}</div>}
              {errorMessage && <div className="text-center mb-2 mt-2 text-bold" style={{ color: 'red' }}>{errorMessage}</div>}

              <div className="card-body">
                <p className="text-center">{t("forgotPassword.instructions")}</p>
                <form onSubmit={handleSubmit}>
                  <label className="input-group col-lg-8 mx-auto">{t("forgotPassword.email")}</label>
                  <div className="input-group mb-3 col-lg-8 mx-auto">
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-envelope"></span>
                      </div>
                    </div>
                    <input
                      type="email"
                      placeholder={t("forgotPassword.email")}
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                      required
                      autoComplete="email"
                    />
                  </div>

                  <div className="col-lg-8 mx-auto">
                    <button type="submit" className="btn flous-btn-gradient btn-block rounded-pill">{t("forgotPassword.button")}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
