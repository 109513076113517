import React, { useState, useEffect } from 'react';
import CompanyDetailsSection from './section_job_offer/CompanyDetailSection';
import JobDetailJOSection from './section_job_offer/JobDetailJOSection';
import ProfessionalDetailSection from './section_job_offer/ProfessionalDetailSection';
import JobCompensationSection from './section_job_offer/JobCompensationSection';
import CompanyInformationSection from './section_job_offer/CompanyInformationSection';
import { toast, ToastContainer } from 'react-toastify';
import RequiredIndicator from '../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";

const JobOffer = (props) => {
  const [jobOffer, setJobOffer] = useState(props.jobOffer.jobOffer);
  const [companyDetail, setCompanyDetail] = useState(props.jobOffer.companyDetail);
  const [jobDetail, setJobDetail] = useState(props.jobOffer.jobDetail);
  const [professionalDetail, setProfessionalDetail] = useState(props.jobOffer.professionalDetail);
  const [jobCompensation, setJobCompensation] = useState(props.jobOffer.jobCompensation);
  const [companyInfo, setCompanyInfo] = useState(props.jobOffer.companyInfo);
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  const handleChange = (event) => {
    const {value} = event.target;
    setJobOffer(value);
    const JobOfferParent = {
      jobOffer: value,
      companyDetail,
      jobDetail,
      professionalDetail,
      jobCompensation,
      companyInfo
    };
    props.sendJobOffer(JobOfferParent);
    setErrors({});
  };

  useEffect(() => {
    const JobOfferParent = {
        jobOffer,
        companyDetail,
        jobDetail,
        professionalDetail,
        jobCompensation,
        companyInfo
    };
    props.sendJobOffer(JobOfferParent);
    props.onValidate('jobOffer', false, props.actor, false);
  }, [jobOffer, companyDetail, jobDetail, professionalDetail, jobCompensation, companyInfo, props]);

  const checkCompanyAddress = () => {
    if (props.jobOffer.companyDetail.country === '' || 
      props.jobOffer.companyDetail.provinceState === '' || 
      props.jobOffer.companyDetail.city === ''){
      toast.error(`${t('forms.formsquestion.toast.jobOffer.companyAddress')}`);
      setErrors(prevErrors =>({
        ...prevErrors,
        companyDetail: `${t('forms.formsquestion.toast.jobOffer.companyAddress')}`
      }));
      return false;
    } else {
      delete errors.companyDetail;
      return true;
    }
  }

  const checkJobDetail = () => {
    if (props.jobOffer.jobDetail.dateOfIssued === '' || props.jobOffer.jobDetail.currentJob === '' || 
      props.jobOffer.jobDetail.lmiaExempt === '' || props.jobOffer.jobDetail.provinceValidated === ''){
      toast.error(`${t('forms.formsquestion.toast.jobOffer.jobDetail')}`);
      delete errors.companyDetail;
      setErrors(prevErrors =>({
        ...prevErrors,
        jobDetail: `${t('forms.formsquestion.toast.jobOffer.jobDetail')}`
      }));
      return false;
    } else {
      delete errors.jobDetail;
      return true;
    }
  }

  const checkProfessionalDetail = () => {
    if (props.jobOffer.professionalDetail.professionalField === '' || props.jobOffer.professionalDetail.jobInAISector === '' || 
      (props.jobOffer.professionalDetail.authorizationToPractice === 'No' && props.jobOffer.professionalDetail.processOfObtainingAuthorization === '')){
      toast.error(`${t('forms.formsquestion.toast.jobOffer.professionalDetail')}`);
      delete errors.jobDetail;
      setErrors(prevErrors =>({
        ...prevErrors,
        professionalDetail: `${t('forms.formsquestion.toast.jobOffer.professionalDetail')}`
      }));
      return false;
    } else {
      delete errors.professionalDetail;
      return true
    }
  }

  const checkJobCompensation = () => {
    if (props.jobOffer.jobCompensation.hourlyRate === '' && props.jobOffer.jobCompensation.annualSalary === ''){
      toast.error(`${t('forms.formsquestion.toast.jobOffer.jobCompensation')}`);
      delete errors.professionalDetail;
      setErrors(prevErrors =>({
        ...prevErrors,
        jobCompensation: `${t('forms.formsquestion.toast.jobOffer.jobCompensation')}`
      }));
      return false;
    } else {
      delete errors.jobCompensation;
      return true
    }
  }

  const checkCompanyInfo = () => {
    if (!props.jobOffer.companyInfo.companyName || props.jobOffer.companyInfo.numberOfEmployed === '' 
      || props.jobOffer.companyInfo.annualGrossRevenue === '' || props.jobOffer.companyInfo.foundingDate === '' ||
      props.jobOffer.companyInfo.primaryBusinessActivities === '' ){
        toast.error(`${t('forms.formsquestion.toast.jobOffer.companyInfo')}`);
        delete errors.jobCompensation;
        setErrors(prevErrors =>({
          ...prevErrors,
          companyInfo: `${t('forms.formsquestion.toast.jobOffer.companyInfo')}`
        }));
      return false;
    } else {
      delete errors.companyInfo;
      return true
    }
  }

  const validateSection = () => {
    if (!jobOffer){
      toast.error(`${t('forms.formsquestion.toast.jobOffer.option')}`);
      return false;
    }
    else if (jobOffer === 'Yes' && 
      (checkCompanyAddress() && checkJobDetail() && checkProfessionalDetail() && 
      checkJobCompensation() && checkCompanyInfo())){
      toast.success(`${t('forms.formsquestion.toast.sectionValidate')}`);
      setErrors({});
      return true;
    }
    else if (jobOffer === 'No'){
      toast.success(`${t('forms.formsquestion.toast.sectionValidate')}`);
      setErrors({});
      return true;
    }
  };

  const receivedCompanyDetail = (data) => {setCompanyDetail(data);};
  const receivedJobDetail = (data) => {setJobDetail(data);};
  const receivedProfessionalDetail = (data) => {setProfessionalDetail(data);};
  const receivedJobCompensation = (data) => {setJobCompensation(data);};
  const receivedCompanyInfo = (data) => {setCompanyInfo(data);};

  const pressNext = async () => {
    
    const validation = validateSection();

    await props.onValidate('jobOffer', validation, props.actor, true);

    if (validation) {
      props.sendNext(props.actor === 'Principal'? "Entrepreneurial Experience":"Entrepreneurial Experience Partner", props.actor);
    }
  };

  const goBack = ()=> {
    if (props.actor === 'Principal') {
      props.goBack("Professional Expertise", "Principal");
    } else {
      props.goBack("Professional Expertise Partner", "Partner");
    } 
  };

  return (
    <fieldset id="step8">
      <ul style={{ color: 'red' }}>
        {Object.values(errors).map((value, index) => {
            if (value !== '') {
                return <li key={index}>{value}</li>;
            }
            return null;
        })}
      </ul>
      <div className="form-card">
        <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
          <p className='text-bold text-center'>{t("forms.title.joboffer")}</p>
          <div className='row p-2'>
            <p className="col-form-label col-sm-10">{t("forms.formsquestion.jobOffer.jobOffer")} {props.evaluationCountry} ?<RequiredIndicator /></p>
            <div className='col-sm-2'>
              <div className='row'>
                <div className="form-check col">
                  <input 
                    type="radio"
                    className="form-check-input"
                    name="jobOffer"
                    value="Yes"
                    checked={jobOffer === "Yes"}
                    onChange={handleChange}
                  />
                  <label className="form-check-label">{t("forms.click.yes")}</label>
                </div>
                <div className="form-check col">
                  <input 
                    type="radio"
                    className="form-check-input"
                    name="jobOffer"
                    value="No"
                    checked={jobOffer === "No"}
                    onChange={handleChange}
                  />
                  <label className="form-check-label">{t("forms.click.no")}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {jobOffer === 'Yes' && (
            <div>
                <CompanyDetailsSection error={errors.companyDetail ? errors.companyDetail : ''}
                  sendCompanyDetail={receivedCompanyDetail} companyDetail={companyDetail}  />
                <JobDetailJOSection error={errors.jobDetail ? errors.jobDetail : ''}
                   sendJobDetail={receivedJobDetail} jobDetail={jobDetail} />
                <ProfessionalDetailSection error={errors.professionalDetail ? errors.professionalDetail : ''}
                  sendProfessionalDetail={receivedProfessionalDetail} professionalDetail={professionalDetail} />
                <JobCompensationSection error={errors.jobCompensation ? errors.jobCompensation : ''}
                  sendJobCompensation={receivedJobCompensation} jobCompensation={jobCompensation} />
                <CompanyInformationSection error={errors.companyInfo ? errors.companyInfo : ''}
                  sendCompanyInfo={receivedCompanyInfo} companyInfo={companyInfo} province={companyDetail.provinceState}/>
            </div>
        )}
        
        
        <div className='d-flex justify-content-center'>
          <div className='col-sm-10'>
            <div className='d-flex flex-column flex-md-row justify-content-between'>
              <input type="button" name="save" className="col-12 col-md-6 mb-2 mb-md-0 btn flous-btn-thirty rounded-pill mx-1" value={t("forms.click.goBack")} onClick={goBack}/>
              <input type="button" name="next" className="col-12 col-md-6 btn flous-btn-primary rounded-pill mx-1" value={t("forms.click.pressNext")} onClick={pressNext}/>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </fieldset>
  );
};

export default JobOffer;