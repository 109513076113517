import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { validatePassword } from './validation';
import LanguageSelect from '../general/languageSelect';
import { useTranslation } from 'react-i18next';

const ResetPassword = () => {
  const { resetPassword } = useContext(AuthContext);
  const { uidb64, token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  
  const { t } = useTranslation()

  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (newPassword !== confirmPassword) {
      setConfirmPasswordError("The passwords are different!");
    } else {
      setConfirmPasswordError('');
    }
  }, [confirmPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match");
      return;
    } else {
      setPasswordError(validatePassword(newPassword));

      if (validatePassword(newPassword) !== '') {
        return;
      } else {
        try {
          const response = await resetPassword(newPassword, confirmPassword, token, uidb64);
          if (!response) {
            setMessage(t('setPassword.tokenExpired'));
          } else if (response.status === 200) {
            setMessage(response.data.message);
            navigate('/login', { state: { message: t('setPassword.passwordUpdated') } });
          }
        } catch (error) {
          if (error.response && [400, 500].includes(error.response.status)) {
            setMessage(error.response.data.error);
          }
        }
      }
    }
  };

  return (
    <div className='relative bg-[linear-gradient(270deg,#7FA1F8_0%,#FDFEFE_88%)] min-h-screen overflow-hidden'>
      <div className="absolute inset-0">
        <img 
          src={`${process.env.PUBLIC_URL}/flous/bg/login-bg.png`} 
          alt="" 
          className="w-[900px] ml-[35%] sm:ml-[60%] object-cover z-0"
        />
      </div>
      <div className='relative top-[0px] left-0 right-0 bottom-10 p-2'>
        <div className='flex justify-between'>
          <div className="sm:ml-5 mt-3">
            <a href="/">
              <img
                src={`${process.env.PUBLIC_URL}/flous/logos/trans_1.svg`}
                alt="Flous Logo"
                className="brand-image"
                style={{ opacity: 0.8, height: '150px', width: 'auto' }}
              />
            </a>
          </div>
          <div className="sm:ml-5 mt-5 pt-3">
            <div className='right-0'><LanguageSelect /></div>
          </div>
        </div>
        
        <div className="col-lg-12 mx-auto text-center text-bold h5">
          <span> 
            {t("login.alreadyHaveAccount")} 
            <a className="btn mb-2 text-bold" style={{ textDecoration: 'underline', color: 'blue' }} href="/login">{t("login.signIn")}</a>
          </span>
        </div>
        <div className="d-flex justify-center align-items-start min-h-screen pt-10">
          <div className="col-lg-6 mx-auto">
            <div className="card card-outline elevation-2 p-5" style={{ borderRadius: '15px' }}>
              <div className="card-header text-center flous-police-one">
                <a href="/notfound/" className="h3">{t("setPassword.title")}</a>
              </div>

              {message && <div className="text-center mb-2 mt-2" style={{ color: 'red' }}>{message}</div>}

              <div className="card-body">
                <p className="text-center">{t("setPassword.instructions")}</p>
                <form onSubmit={handleSubmit}>
                  {passwordError && <p className="error text-center mb-1 mt-1">{passwordError}</p>}
                  <label className="input-group col-lg-8 mx-auto">{t("setPassword.password")}</label>
                  <div className="input-group mb-3 col-lg-8 mx-auto position-relative inline-block" >
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock"></span>
                      </div>
                    </div>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      placeholder={t("setPassword.password")}
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      className="form-control"
                      name="password"
                      required
                    />
                    
                    <button
                      className="btn"
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                      }}
                      onClick={togglePasswordVisibility}
                      type="button"
                    >
                      {showPassword ? (
                        <i className="nav-icon fas fa-eye-slash"></i>
                      ) : (
                        <i className="nav-icon fas fa-eye"></i>
                      )}
                    </button>
                  </div>
                  {confirmPasswordError && <p className="error text-center mb-1 mt-1">{confirmPasswordError}</p>}
                  <label className="input-group col-lg-8 mx-auto">{t("setPassword.confirmPassword")}</label>
                  <div className="input-group mb-3 col-lg-8 mx-auto position-relative">
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock"></span>
                      </div>
                    </div>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      placeholder={t("setPassword.confirmPassword")}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="form-control"
                      name="cpassword"
                      required
                    />

                    <button
                      className="btn"
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                      }}
                      onClick={togglePasswordVisibility}
                      type="button"
                    >
                      {showPassword ? (
                        <i className="nav-icon fas fa-eye-slash"></i>
                      ) : (
                        <i className="nav-icon fas fa-eye"></i>
                      )}
                    </button>
                    
                  </div>

                  <div className="col-lg-8 mx-auto">
                    <button type="submit" className="btn flous-btn-gradient btn-block rounded-pill"
                    >
                      {t("setPassword.continue")}
                    </button>
                  </div>
                  
                  <div className="col-lg-8 mx-auto text-center">
                    <span> 
                      {t("setPassword.newLinkText")} 
                      <a className="btn mb-2" style={{ textDecoration: 'underline', color: 'blue' }} href="/forgotpassword">{t("setPassword.newLinkButton")}</a>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
