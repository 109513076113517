import React, { useState, useEffect } from 'react';
import api from '../../../../../services/api';
import { NumberInputControl, sortDictionaryByValues } from '../../../../../services/utils';
import Select from 'react-select';
import RequiredIndicator from '../../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";

const CompanyInformationSection = (props) => {
  const [formData, setFormData] = useState(props.companyInfo);
  const [bcPublicCompanyNames, setBcPublicCompanyNames] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (props.province === "BC") {
      const fetchBcPublicCompany = async () =>{
        const response = await api.get('/bc-public-company-names/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setBcPublicCompanyNames(options);
      };
      fetchBcPublicCompany();
    }
    
  }, []);

  const getOptions = (data) => {
    return Object.entries(data).map(([key, val]) => ({
      value: key,
      label: val,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedData);
    props.sendCompanyInfo(updatedData);
  };

  return (/*companyName*/
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? `${t('forms.formsquestion.toast.completeSection')}` : ''}</div>
        <p className='text-bold text-center'>{t("forms.title.company")}</p>
        
        <div className='row p-2'>
          <p className="col-sm-4 col-form-label">{t("forms.formsquestion.jobOffer.companyName")}<RequiredIndicator /></p>
          <div className="col-sm-8">
            { props.province === 'BC' ? (
              <Select
              name="companyName"
              className="react-select-container rounded-pill flous-input-color"
              classNamePrefix="react-select"
              value={bcPublicCompanyNames.find(option => option.value === formData.companyName)}
              onChange={(selectedOption) => handleChange({ target: { name: 'companyName', value: selectedOption ? selectedOption.value : '' } })}
              options={bcPublicCompanyNames}
              placeholder={`${t('forms.formsquestion.placeholder.option')}`}
              isSearchable
              isClearable
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                  borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                  borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                }),
              }}
              required
            />
            ) : (
              <input 
              type="text" 
              className="form-control rounded-pill flous-input-color"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange} 
            />
            )}
          </div>
        </div>
        <div className='row p-2'>
          <p className="col-sm-4 col-form-label">{t("forms.formsquestion.jobOffer.primaryBusinessActivities")}<RequiredIndicator /></p>
          <div className="col-sm-8">
            <input 
              type="text" 
              className="form-control rounded-pill flous-input-color"
              name="primaryBusinessActivities"
              value={formData.primaryBusinessActivities}
              onChange={handleChange}
              placeholder={t('forms.formsquestion.placeholder.businessActivity')}
            />
          </div>
        </div>
        <div className='row p-2'>
          <p className="col-sm-4 col-form-label">{t("forms.formsquestion.jobOffer.numberOfEmployed")}<RequiredIndicator /></p>
          <div className="col-sm-8">
            <input 
              type="number" 
              className="form-control rounded-pill flous-input-color"
              name="numberOfEmployed"
              value={formData.numberOfEmployed}
              placeholder={t('forms.formsquestion.placeholder.numberEmployees')}
              onChange={handleChange} min={0}
              onKeyDown={(e) => NumberInputControl(e)}
            />
          </div>
        </div>
        <div className='row p-2'>
          <p className="col-sm-4 col-form-label">{t("forms.formsquestion.jobOffer.annualGrossRevenue")}<RequiredIndicator /></p>
          <div className="col-sm-8">
            <input 
              type="number" 
              className="form-control rounded-pill flous-input-color"
              name="annualGrossRevenue"
              value={formData.annualGrossRevenue}
              onChange={handleChange} min={0}
              placeholder='$ (CAD)'
            />
          </div>
        </div>
        <div className='row p-2'>
          <p className="col-sm-4 col-form-label">{t("forms.formsquestion.jobOffer.foundingDate")}<RequiredIndicator /></p>
          <div className="col-sm-8">
            <input 
              type="date" 
              className="form-control rounded-pill flous-input-color"
              name="foundingDate"
              value={formData.foundingDate}
              onChange={handleChange} max={new Date().toISOString().split("T")[0]}
            />
          </div>
        </div>
        { props.province === 'AL' && (
        <div className='row p-2'>
          <p htmlFor="primaryBusinessActivities" className='col-sm-8 col-form-label'>
          {t("forms.formsquestion.jobOffer.primaryBusinessActivities")}
          </p>
          <div className="col-sm-4">
            <input
              type="text"
              className="form-control rounded-pill flous-input-color"
              id="primaryBusinessActivities"
              name="primaryBusinessActivities"
              placeholder=""
              value={formData.primaryBusinessActivities}
              onChange={handleChange}
            />
          </div>
        </div> )}
      </div>
    </div>
  );
};

export default CompanyInformationSection;