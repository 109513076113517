import React, { useEffect, useState } from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import CountryShow from '../../../general/countryShow';
import { companyType, getOptions, sortDictionaryByValues } from '../../../../services/utils';
import api from '../../../../services/api';
import styles from '../styles/pdfstyles';
import { translateDictionaryList } from '../../../../services/general/TranslationServices';

export default function EntrepreneurialExperienceResumeSectionPDF(props) {
  const { t } = useTranslation();
  const lang = 'en'; // Assuming 'en' as default language
  const [provinces, setProvinces] = useState([]);
  
  const [businessSectorsOriginal, setBusinessSectorsOriginal] = useState([]);
    
  const [businessSectors, setBusinessSectors] = useState([]);

  const handleTranslate = async (data)=>{
    if (lang !== 'en') {
      const businessSectorsTranslated = await translateDictionaryList(businessSectorsOriginal, lang);
      setBusinessSectors(businessSectorsTranslated);
    } else {
      setBusinessSectors(businessSectorsOriginal);
    }
  };

  useEffect(()=>{
    handleTranslate();
  }, [lang, businessSectorsOriginal]);
  
  useEffect(() => {
    const fetchBusinessSector = async () => {
        const response = await api.get('/canada/industries-sectors/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setBusinessSectors(options);
        setBusinessSectorsOriginal(options);
    };
    fetchBusinessSector();

  }, []);

  useEffect(() => {
      const fetchProvinces = async() => {
        const response = await api.get('/canada-provinces/');
          const provinceOptions = Object.entries(response.data).map(([key, val]) => ({
            value: key,
            label: val,
          }));
          setProvinces(provinceOptions);
      };
  
      fetchProvinces();
  }, []);

  const renderExperienceCard = (entreExperience, index) => {
    const entrepreneurialExperience = {
      isOwnerOrPartner: entreExperience.entrepreunarialExperience.ownership,
      entrepreneurialGoal: entreExperience.entrepreunarialExperience.newcompany,
    };

    const takeoverBidInfo = {
      hasMadeBid: entreExperience.takeoverBid,
    };

    const companyDetails = {
      establishedDate: entreExperience.companyDetails.establishmentDate,
      country: entreExperience.companyDetails.country,
      province: entreExperience.companyDetails.provinceState,
      city: entreExperience.companyDetails.city,
      streetNumber: entreExperience.companyDetails.streetNumber,
      postalCode: entreExperience.companyDetails.postalCode,
    };

    const companyDetailss = {
      ownerOrPartner: entreExperience.ownershipDetail.ownershipType,
      companyName: entreExperience.ownershipDetail.companyName,
      ownershipPercentage: entreExperience.ownershipDetail.ownershipPercentage,
      managementExperienceMonths: entreExperience.ownershipDetail.managementExperience,
      personalEquityInvestment: entreExperience.ownershipDetail.personalEquityInvestment,
    };

    const companyActivities = {
      companyDescription: entreExperience.companyActivites.businessSector,
      involvesPaydayLoans: entreExperience.companyActivites.paydayLoans,
      involvesExplicitContent: entreExperience.companyActivites.explicitContent,
      involvesRealEstate: entreExperience.companyActivites.realEstate,
    };

    const companyFinance = {
      companyValue: entreExperience.companyFinances.companyValue,
      startupExpenses: entreExperience.companyFinances.startupExpenses,
      operatingExpenses: entreExperience.companyFinances.operatingExpenses,
    };

    const roleExperience = {
      position: entreExperience.roleAndExperience.position,
      startDate: entreExperience.roleAndExperience.startDate,
      minOperatingPeriod: entreExperience.roleAndExperience.operatingPeriod,
    };

    return (
      <View key={index} style={styles.experienceCardEntre}>

        {/* Entrepreneurial Experience */}
        <View style={styles.card}>
          <Text style={styles.cardTitle}>{t("forms.title.entrepreneurialexperience")}</Text>
          
          <View style={styles.row}>
            <View style={styles.column70}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.ownership")}</Text>
              </View>
            <View style={styles.column30}>
              <Text style={styles.value}>{entrepreneurialExperience.isOwnerOrPartner === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{width: '25%'}}>
              <Text style={styles.label}>{t("general.table.type")}</Text>
            </View>
            <View style={{width: '25%'}}>
              <Text style={styles.value}>{entrepreneurialExperience.isOwnerOrPartner === 'Yes' ? companyType('Existing', lang) : companyType(entrepreneurialExperience.entrepreneurialGoal, lang)}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("general.table.company")}</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{companyDetailss.companyName}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("general.table.ownershippercentage")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{companyDetailss.ownershipPercentage}%</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("general.table.country")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}><CountryShow value={companyDetails.country} /></Text>
            </View>
          </View>

        </View>

        {/* Takeover Bid */}
        {entrepreneurialExperience.entrepreneurialGoal === 'Undertake an existing company' && (
          <View style={styles.card}>
            <Text style={styles.cardTitle}>{t("forms.title.takeoverBid")}</Text>
            <View style={styles.row}>
              <View style={styles.column70}>
                <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.takeoverBid")} {t('countries.canada')} ?</Text>
                </View>
              <View style={styles.column30}>
                <Text style={styles.value}>{takeoverBidInfo.hasMadeBid === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
              </View>
            </View>
          </View>
        )}

        {/* Company Details */}
        <View style={styles.card}>
          <Text style={styles.cardTitle}>{t("forms.title.companydetail")}</Text>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.establishmentDate")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{companyDetails.establishedDate}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.provinceState")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>
                {provinces.find(province => province.value === companyDetails.province)?.label || ''}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.city")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{companyDetails.city}</Text>
            </View>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.streetNumber")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{companyDetails.streetNumber}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.labelColumn}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.postalCode")}</Text>
              </View>
            <View style={styles.valueColumn}>
              <Text style={styles.value}>{companyDetails.postalCode}</Text>
            </View>
          </View>
        </View>

        {/* Company Activities */}
        <View style={styles.card}>
          <Text style={styles.cardTitle}>{t("forms.title.companyactivities")}</Text>
          <View style={styles.row}>
            <View style={styles.column70}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.businessSector")}</Text>
              </View>
            <View style={styles.column30}>
              <Text style={styles.value}>
                {companyActivities.companyDescription}-{businessSectors.find((option) => option.value === companyActivities.companyDescription)?.label}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.column70}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.paydayLoans")}</Text>
              </View>
            <View style={styles.column30}>
              <Text style={styles.value}>{companyActivities.involvesPaydayLoans === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.column70}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.explicitContent")}</Text>
              </View>
            <View style={styles.column30}>
              <Text style={styles.value}>{companyActivities.involvesExplicitContent === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.column70}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.realEstate")}</Text>
              </View>
            <View style={styles.column30}>
              <Text style={styles.value}>{companyActivities.involvesRealEstate === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
            </View>
          </View>
        </View>

        {/* Company Finance */}
        <View style={styles.card}>
          <Text style={styles.cardTitle}>{t("forms.title.companyfinance")}</Text>
          <View style={styles.row}>
            <View style={styles.column70}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.companyValue")}</Text>
              </View>
            <View style={styles.column30}>
              <Text style={styles.value}>{companyFinance.companyValue}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.column70}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.startupExpenses")}</Text>
              </View>
            <View style={styles.column30}>
              <Text style={styles.value}>{companyFinance.startupExpenses}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.column70}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.operatingExpenses")}</Text>
              </View>
            <View style={styles.column30}>
              <Text style={styles.value}>{companyFinance.operatingExpenses}</Text>
          </View>
          </View>
        </View>

        {/* Role and Experience */
        <View style={styles.card}>
          <Text style={styles.cardTitle}>{t("forms.title.role")}</Text>
          <View style={styles.row}>
            <View style={styles.column70}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.position")}</Text>
              </View>
            <View style={styles.column30}>
              <Text style={styles.value}>{roleExperience.position}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.column70}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.startDate")}</Text>
              </View>
            <View style={styles.column30}>
              <Text style={styles.value}>{roleExperience.startDate}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.column70}>
              <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.operatingPeriod")}</Text>
              </View>
            <View style={styles.column30}>
              <Text style={styles.value}>{roleExperience.minOperatingPeriod}</Text>
            </View>
          </View>
        </View> /**/}
      </View>
    );
  };

  return (
    <Page size="A4" style={styles.page}>
      <Text style={styles.mainTitle}>{t("client.results.blockTitle.bloc9")}</Text>
      <View style={styles.card}>
        <Text style={styles.cardTitle}>{t("forms.title.managementexperience")}</Text>
        <View style={styles.row}>
          <View style={styles.column70}>
            <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.managementExperience")}</Text>
            </View>
          <View style={styles.column30}>
            <Text style={styles.value}>{props.entrepreunarialExperience.otherManagementExperience.managementExperience === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
          </View>
        </View>
        {props.entrepreunarialExperience.otherManagementExperience.managementExperience === 'Yes' && (
        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.managementStartDate")}</Text>
            </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.entrepreunarialExperience.otherManagementExperience.managementStartDate}</Text>
          </View>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.managementEndDate")}</Text>
            </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.entrepreunarialExperience.otherManagementExperience.managementEndDate}</Text>
          </View>
        </View>
        )}
      </View>

      <View style={styles.card}>
        <Text style={styles.cardTitle}>{t("forms.title.entrepreneurialexperience")}</Text>
        <View style={styles.row}>
          <View style={styles.column70}>
            <Text style={styles.label}>{t("forms.formsquestion.entrepreunarialExperience.entrepreneurialIntro")}</Text>
            </View>
          <View style={styles.column30}>
            <Text style={styles.value}>{props.entrepreunarialExperience.haveBusinesses === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}</Text>
          </View>
        </View>
      </View>
      {props.allEntrepreneurialExperience && props.allEntrepreneurialExperience.length > 0 ? (
        props.allEntrepreneurialExperience.map((entreExperience, index) => renderExperienceCard(entreExperience, index))
      ) : (
        <View style={styles.card}>
          <Text style={styles.cardTitle}>{t("general.table.applicantEmpty.noElement")}</Text>
        </View>
      )}
    </Page>
  );
}

