import React, { useState, useEffect } from 'react';
import EducationDetailSection from './section_education_outside_country/EducationDetailSection';
import DateofStudySection from './section_education_outside_country/DateofStudySection';
import EducationInstitutionSection from './section_education_outside_country/EducationInstitutSection';
import EducationalCredentialAssessmentSection from './section_education_outside_country/EducationalCredentialAssessmentSection';
import { toast, ToastContainer } from 'react-toastify';
import EquivalencyProcessQuebecSection from './section_education_outside_country/EquivalencyProcessQuebecSection';
import LoadingOverlay from '../../../general/LoadingOverlay';
import RequiredIndicator from '../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";
import CountryShow from '../../../general/countryShow';
import { languageConverter, mapStateToDescription } from '../../../../services/utils';

const EducationOutsideCountry = (props) => {
  const [educationOutsideCountry, setEducationOutsideCountry] = useState(props.educationOutsideCountry.educationOutsideCountry);
  const [currentlyStudy, setCurrentlyStudy] = useState(props.educationOutsideCountry.currentlyStudy);
  const [allEducationOutsideCountry, setAllEducationOutsideCountry] = useState(props.allEducationOutsideCountry);
  const [educationDetail, setEducationDetail] = useState(props.educationOutsideCountry.educationDetail);
  const [dateofStudy, setDateofStudy] = useState(props.educationOutsideCountry.dateofStudy);
  const [educationInstitution, setEducationInstitution] = useState(props.educationOutsideCountry.educationInstitution);
  const [educationalCredentialAssessment, setEducationalCredentialAssessment] = useState(props.educationOutsideCountry.educationalCredentialAssessment);
  const [previousCredentials, setPreviousCredentials] = useState(props.educationOutsideCountry.previousCredentials);
  const [equivalencyProcessQuebec, setEquivalencyProcessQuebec] = useState(props.educationOutsideCountry.equivalencyProcessQuebec);
  const [errors, setErrors] = useState({});
  // const [loading, setLoading] = useState(false);
  const [addClicked, setAddClicked] = useState(false);
  const { t } = useTranslation();
  
  const lang = localStorage.getItem('flouslang') || 'en';
  
  const [currentEducation, setCurrentEducation] = useState(null);

  useEffect(() => {
    const educationOutsideCountryData = {
      currentlyStudy,
      educationOutsideCountry,
      educationalCredentialAssessment,
      previousCredentials,
      educationDetail,
      dateofStudy,
      educationInstitution,
      equivalencyProcessQuebec
    };
    props.sendEducationOutsideCountry(educationOutsideCountryData);
    props.onValidate('educationOutsideCountry', false);
  }, [currentlyStudy, educationOutsideCountry, educationalCredentialAssessment, educationDetail, 
    dateofStudy, educationInstitution, equivalencyProcessQuebec, previousCredentials, props]);
  
  useEffect(()=> {    
    props.sendAllEducationOutsideCountry(allEducationOutsideCountry);
    props.onValidate('educationOutsideCountry', false, props.actor, false);
  }, [allEducationOutsideCountry, props]);

  const handleCurrentlyStudyChange = (event) => {
    const { value } = event.target;
    setCurrentlyStudy(value);
    // setEducationOutsideCountry("");
    setErrors({});
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setEducationOutsideCountry(value);
    setErrors({});
  };

  const handleAllEducation = () => {
    if (check()){
      const educationOutsideCountryData = {
        educationalCredentialAssessment,
        previousCredentials,
        educationDetail,
        dateofStudy,
        educationInstitution,
        equivalencyProcessQuebec
      };
      setAddClicked(false);
      if (currentEducation === null) {
        setAllEducationOutsideCountry([...allEducationOutsideCountry, educationOutsideCountryData]);
      }else {
        const updateData = [...allEducationOutsideCountry];
        updateData[currentEducation] = educationOutsideCountryData;
        setAllEducationOutsideCountry(updateData);
      }
      setCurrentEducation(null);
      setEducationDetail({
        degreelevel: '',
        generalFieldOfStudy: '',
        fieldOfStudy: '',
        programDuration: ''
      });
      setDateofStudy({
        startDate: '',
        endDate: ''
      });
      setEducationInstitution({
        institutionName: '',
        languageOfInstruction: '',
        fullTimeStudent: '',
        address: '',
        country: '',
        provinceState: '',
        city: '',
      });
      setEducationalCredentialAssessment({
        ecaObtained: '',
        issuingOrganization: '',
        recognizedGeneralCredential: '',
        recognizedCredential: '',
        recognizedDiplomaLevel: '',
      });
      setEquivalencyProcessQuebec({
        epqObtained: '',
        recognizedGeneralCredential: '',
        recognizedCredential: '',
        recognizedDiplomaLevel: '',
        provinceState: 'Québec'
      });
    }
  }

  const handleCancelEducation = () => {
    setAddClicked(false);
    setCurrentEducation(null);
    setEducationDetail({
      degreelevel: '',
      generalFieldOfStudy: '',
      fieldOfStudy: '',
      programDuration: ''
    });
    setDateofStudy({
      startDate: '',
      endDate: ''
    });
    setEducationInstitution({
      institutionName: '',
      languageOfInstruction: '',
      fullTimeStudent: '',
      address: '',
      country: '',
      provinceState: '',
      city: '',
    });
    setEducationalCredentialAssessment({
      ecaObtained: '',
      issuingOrganization: '',
      recognizedGeneralCredential: '',
      recognizedCredential: '',
      recognizedDiplomaLevel: '',
    });
    setEquivalencyProcessQuebec({
      epqObtained: '',
      recognizedGeneralCredential: '',
      recognizedCredential: '',
      recognizedDiplomaLevel: '',
      provinceState: 'Québec'
    });
    delete errors.educationDetail;
    delete errors.dateofStudy;
    delete errors.educationInstitution;
    delete errors.educationalCredentialAssessment;
    delete errors.equivalencyProcessQuebec;
  }

  const handleEditEducation = (index) => {
    const updatedFormData = [...allEducationOutsideCountry];
    const education = updatedFormData[index];
    setEducationDetail(education.educationDetail);
    setDateofStudy(education.dateofStudy);
    setEducationInstitution(education.educationInstitution);
    setEducationalCredentialAssessment(education.educationalCredentialAssessment);
    setEquivalencyProcessQuebec(education.equivalencyProcessQuebec)
    setAddClicked(true);
    setCurrentEducation(index);
    delete errors.educationDetail;
    delete errors.dateofStudy;
    delete errors.educationInstitution;
    delete errors.educationalCredentialAssessment;
    delete errors.equivalencyProcessQuebec;
  }

  const checkEducationDetails = () => {
    if ((!educationDetail.degreelevel || !educationDetail.generalFieldOfStudy || !educationDetail.fieldOfStudy || !educationDetail.programDuration)
      || 
      (educationDetail.degreelevel === 'PRG' && !educationDetail.prgDegreeDiploma)){
      toast.error(`${t('forms.formsquestion.toast.educationOutside.educationDetail')}`);
      setErrors(prevErrors =>({
        ...prevErrors,
        educationDetail: `${t('forms.formsquestion.toast.educationOutside.educationDetail')}`
      }));
      return false;
    } else {
      delete errors.educationDetail;
      return true;
    }
  }

  const checkDateOfStudy = () => {
    if (!dateofStudy.startDate){
      toast.error(`${t('forms.formsquestion.toast.educationOutside.educationDetail')}`);
      setErrors(prevErrors =>({
        ...prevErrors,
        dateofStudy: `${t('forms.formsquestion.toast.educationOutside.dateOfStudy')}`
      }));
      return false;
    } else {
      delete errors.dateofStudy;
      return true;
    }
  }

  const checkEducationInstitution = () => {
    if (!educationInstitution.institutionName || !educationInstitution.country || 
      !educationInstitution.provinceState || !educationInstitution.city || 
      !educationInstitution.languageOfInstruction || !educationInstitution.fullTimeStudent){
      toast.error(`${t('forms.formsquestion.toast.educationOutside.institution')}`);
      setErrors(prevErrors =>({
        ...prevErrors,
        educationInstitution: `${t('forms.formsquestion.toast.educationOutside.institution')}`
      }));
      return false;
    } else {
      delete errors.educationInstitution;
      return true;
    }
  }

  const checkValidECA = () =>  {
    if (educationalCredentialAssessment.ecaObtained === '' || 
      (educationalCredentialAssessment.ecaObtained === 'Yes' && 
        (educationalCredentialAssessment.issuingOrganization === '' || educationalCredentialAssessment.recognizedCredential === '' || educationalCredentialAssessment.recognizedDiplomaLevel === ''))) {
        toast.error(`${t('forms.formsquestion.toast.educationOutside.eca')}`);
        setErrors(prevErrors =>({
          ...prevErrors,
          educationalCredentialAssessment: `${t('forms.formsquestion.toast.educationOutside.eca')}`
        }));
        return false;
    } else {
      if (educationalCredentialAssessment.ecaObtained === 'Yes') {
        const updatedData = [educationalCredentialAssessment];
        setPreviousCredentials(updatedData);
        // console.log(updatedData);
      }
      delete errors.educationalCredentialAssessment;
      return true;
    }
  }

  const checkValidEPQ = () =>  {
    if (equivalencyProcessQuebec.epqObtained === '' || 
      (equivalencyProcessQuebec.epqObtained === 'Yes' && 
        (equivalencyProcessQuebec.recognizedCredential === '' && equivalencyProcessQuebec.recognizedDiplomaLevel === ''))) {
        toast.error(`${t('forms.formsquestion.toast.educationOutside.epq')}`);
        setErrors(prevErrors =>({
          ...prevErrors,
          equivalencyProcessQuebec: `${t('forms.formsquestion.toast.educationOutside.epq')}`
        }));
        return false;
    } else {
      delete errors.equivalencyProcessQuebec;
      return true;
    }
  }


  const check = () => {
    if (checkEducationDetails() && checkDateOfStudy() && checkEducationInstitution()
    && checkValidECA() && checkValidEPQ()){
      setErrors({});
      return true;
    } else {
      return false;
    }
  }

  const validateSection = () => {
    if (!educationOutsideCountry || !currentlyStudy) {
      toast.error(`${t('forms.formsquestion.toast.educationOutside.moreInfo')}`);
      setErrors(prevErrors =>({
        ...prevErrors,
        begin: `${t('forms.formsquestion.toast.educationOutside.moreInfo')}`
      }));
      return false;
    } else if (educationOutsideCountry === 'No' && currentlyStudy === 'No'){
      toast.success(`${t('forms.formsquestion.toast.sectionValidate')}`);
      setErrors({});
      return true;
    } else if (educationOutsideCountry === 'Yes' || currentlyStudy === 'Yes') {
      if  (allEducationOutsideCountry.length > 0){
        toast.success(`${t('forms.formsquestion.toast.sectionValidate')}`)
        setErrors({});
        return true;
      }else {
        toast.error(`${t('forms.formsquestion.toast.educationOutside.atLeastOne')}`);
        setErrors(prevErrors =>({
          ...prevErrors,
          educationOutsideCountry: `${t('forms.formsquestion.toast.educationOutside.atLeastOne')}`
        }));
        return false;
      }
    }
  }

  const receivedEducationalCredentialAssessment = (data) => {setEducationalCredentialAssessment(data);};
  const receivedEducationDetail = (data) => {setEducationDetail(data);};
  const receivedDateofStudy = (data) => {setDateofStudy(data);};
  const receivedEducationInstitution = (data) => {setEducationInstitution(data);};
  const receivedEquivalencyProcessQuebec = (data) => {setEquivalencyProcessQuebec(data);};
  const receivedPreviousCredentials = (data) => {setPreviousCredentials(data);};

  const pressNext = async () => {

    const validation = validateSection();
    
    await props.onValidate('educationOutsideCountry', validation, props.actor, true);

    if (validation) {
      props.sendNext(props.actor === 'Principal'? "Professional Experience":"Professional Experience Partner", props.actor);
    }
  };

  const goBack = ()=> {
    if (props.actor === 'Principal') {
      props.goBack("Education in country", "Principal");
    } else {
      props.goBack("Education in country Partner", "Partner");
    } 
  };

  return (
    <fieldset id="step5">
      <ul style={{ color: 'red' }}>
        {Object.values(errors).map((value, index) => {
            if (value !== '') {
                return <li key={index}>{value}</li>;
            }
            return null;
        })}
      </ul> 
      {/*<legend>Informations personnelles</legend>*/}
      <div className="form-card">
        <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
          <p className='text-bold text-center'>{t("forms.title.educationoutside")} {t('countries.canada')}</p>
          <div className='row p-2'>
              <p className="col-form-label col-sm-10">{t("forms.formsquestion.educationOutsideCountry.currentlyStudy")} {t('countries.canada')} ?<RequiredIndicator /> </p>
              <div className="form-check col">
                <input 
                  type="radio"
                  name="currentlyStudy"
                  className="form-check-input"
                  value="Yes"
                  onChange={handleCurrentlyStudyChange}
                  checked={currentlyStudy === "Yes"}
                />
                <label className="form-check-label">{t("forms.click.yes")}</label>
              </div>
              <div className="form-check col">
                <input 
                  type="radio"
                  name="currentlyStudy"
                  className="form-check-input"
                  value="No"
                  onChange={handleCurrentlyStudyChange}
                  checked={currentlyStudy === "No"}
                />
                <label className="form-check-label">{t("forms.click.no")}</label>
              </div>
            </div>
          <div className='row p-2'>
            <p className="col-form-label col-sm-10">{t("forms.formsquestion.educationOutsideCountry.educationOutsideCountry")} {t('countries.canada')}?<RequiredIndicator /></p>
            <div className="form-check col">
              <input 
                type="radio"
                className="form-check-input"
                name="educationOutsideCountry"
                value="Yes"
                onChange={handleChange}
                checked={educationOutsideCountry === 'Yes'}
              />
              <p className="form-check-label">{t("forms.click.yes")}</p>
            </div>
            <div className="form-check col">
              <input 
                type="radio"
                className="form-check-input"
                name="educationOutsideCountry"
                value="No"
                onChange={handleChange}
                checked={educationOutsideCountry === 'No'}
              />
              <p className="form-check-label">{t("forms.click.no")}</p>
            </div>
            {/*{loading && <LoadingOverlay />*/}
          </div>
          {((currentlyStudy === 'Yes' || educationOutsideCountry === 'Yes') && (currentlyStudy !== '' && educationOutsideCountry !== '')) && (
            <div>
              <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                <div className='row'>
                    <div className='col-sm col-6 mt-2 text-center'>{t("general.table.fieldOfStudy")}</div>
                    <div className='col-sm col-6 mt-2 text-center'>{t("general.table.country")}</div>
                    <div className='col-sm col-6 mt-2 text-center'>{t("general.table.enddate")}</div>
                    <div className='col-sm col-6 mt-2 text-center'>{t("general.table.studyLanguage")}</div>
                    <div className='col-sm col-6 mt-2 text-center'>{t("general.table.actions")}</div>
                </div>
              </div>
              <div>
                {allEducationOutsideCountry && allEducationOutsideCountry.length > 0 ? (
                  allEducationOutsideCountry.map((educationOut, index) => (
                    <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px' }} key={index}>
                      <div className='row'>
                        <div className='col-sm col-6 mt-2 text-center'>{educationOut.educationDetail.fieldOfStudy}</div>
                        <div className='col-sm col-6 mt-2 text-center'>
                          <CountryShow value={educationOut.educationInstitution.country} />
                        </div>
                        <div className='col-sm col-6 mt-2 text-center'>{educationOut.dateofStudy.endDate? educationOut.dateofStudy.endDate : mapStateToDescription('IP', lang)}</div>
                        {/*<div className='col-sm col-6 mt-2 text-center'>{educationOut.dateofStudy.fullTimeStudent === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`}</div>*/}
                        <div className='col-sm col-6 mt-2 text-center'>{languageConverter(educationOut.educationInstitution.languageOfInstruction)}</div>
                        <div className='col-sm col-6 mt-2 text-center'>
                          <button type='button'
                              className="btn flous-bg-primary mr-1" 
                              onClick={() => handleEditEducation(index)}
                          >
                              <i className="nav-icon fas fa-edit"></i>
                          </button>
                          <button
                            className="btn btn-secondary"
                            onClick={() => setAllEducationOutsideCountry(allEducationOutsideCountry.filter((_, i) => i !== index))}
                          >
                            <i className="nav-icon fas fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='text-center'>{t("general.table.empty.noElement")}</div>
                )}
              
                {!addClicked &&
                  <div className='mt-3'>
                      <button type='button' className='btn btn-block btn-secondary rounded-pill' onClick={()=>{setAddClicked(true)}}>{t("forms.addMoreButton.addStudy")}</button>
                  </div>
                }
              </div>
            </div>          
          )}
        </div>
        {addClicked && (
          <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
        {(currentlyStudy === 'Yes' || educationOutsideCountry === 'Yes') && (
            <div>
                <EducationDetailSection error={errors.educationDetail ? errors.educationDetail : ''}
                  sendEducationDetail={receivedEducationDetail} educationDetail={educationDetail} />
                <DateofStudySection error={errors.dateofStudy ? errors.dateofStudy : ''}
                  sendDateofStudy={receivedDateofStudy} dateofStudy={dateofStudy} />
                <EducationInstitutionSection error={errors.educationInstitution ? errors.educationInstitution : ''}
                  sendEducationInstitution={receivedEducationInstitution} educationInstitution={educationInstitution} />
                <EducationalCredentialAssessmentSection error={errors.educationalCredentialAssessment ? errors.educationalCredentialAssessment : ''}
                sendEducationalCredentialAssessment={receivedEducationalCredentialAssessment}
                educationalCredentialAssessment={educationalCredentialAssessment}
                previousCredentials={previousCredentials} sendPreviousCredentials={receivedPreviousCredentials}
                />
                {props.evaluationCountry ==='Canada' && (
                    <EquivalencyProcessQuebecSection error={errors.equivalencyProcessQuebec ? errors.equivalencyProcessQuebec : ''}
                      sendEquivalencyProcessQuebec={receivedEquivalencyProcessQuebec} equivalencyProcessQuebec={equivalencyProcessQuebec} />
                )}
            </div>
        )}
          <div className='d-flex justify-content-center mb-3'>
            <div className='col-sm-10'>
              <div className='d-flex justify-content-between'>
                <input type="button" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value={`${t("forms.addMoreButton.saveStudy")}`} onClick={handleAllEducation}/>
                <input type="button" className="col-6 btn btn-block btn-secondary rounded-pill mx-1" value={`${t("forms.addMoreButton.cancel")}`} onClick={()=>{handleCancelEducation()}}/>
              </div>
            </div>
          </div>
        </div>
        )}
        
        <div className='d-flex justify-content-center'>
          <div className='col-sm-10'>
            <div className='d-flex flex-column flex-md-row justify-content-between'>
              <input type="button" name="save" className="col-12 col-md-6 mb-2 mb-md-0 btn flous-btn-thirty rounded-pill mx-1" value={t("forms.click.goBack")} onClick={goBack}/>
              <input type="button" name="next" className="col-12 col-md-6 btn flous-btn-primary rounded-pill mx-1" value={t("forms.click.pressNext")} onClick={pressNext}/>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </fieldset>
  );
};

export default EducationOutsideCountry;