import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import EvaluationPage from './sections/evaluationSection';
import PersonalInfoResume from './sections/section_onePage';
import ResidenceInfoResume from './sections/section_twoPage';
import LangueResumeSection from './sections/section_threePage';
import EducationResumeSection from './sections/section_fourPage';
import JobOfferResumeSection from './sections/section_sevenPage';
import ProfessionalExpertiseResumeSection from './sections/section_sixPage';
import ProfessionalExperienceResumeSection from './sections/section_fivePage';
// import EntrepreneurialExperienceResumeSection from './sections/section_heightPage';
import EntrepreneurialExperienceResumeSection from './sections/section_new_heightPage';
import { usePrincipalFormStates } from '../forms/data/principalStates';
import { usePartnerFormStates } from '../forms/data/partnerStates';
import { getExistingFormData } from '../../../services/users/FormsServices';
import FormDetailIntroduction from '../forms/sections/formDetailGeneralSection';
import { getEvaluationData } from '../../../services/users/ResultsServices';
import LoadingOverlay from '../../general/LoadingOverlay';
import { toast, ToastContainer } from 'react-toastify';
import GoToBackButton from '../../general/GoBackLink';
import FamilyMembersResumeSection from './sections/section_familyMembersPage';
import { useTranslation } from "react-i18next";
import { allowedMaritalStatuses, sectionList, sectionListES, sectionListFR } from '../../../services/utils';

export default function UserResultsDetails() {
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formStates, setFormStates] = useState(null);

  const [activeBtn, setActiveBtn] = useState('Summary');
  const [actor, setActor] = useState('Principal');
  const [evaluationCountry, setevaluationCountry] = useState("");
  const [formTitle, setFormTitle] = useState("");
  const [isSameResidence, setIsSameResidence] = useState({
    isSamecurrentResidence: '',
    isSamepreviousAddress: ''
  });
  const [isCoApplication, setIsCoApplication] = useState();
  const [needPartnerInformation, setNeedPartnerInformation] = useState(false);

  const [formStatus, setFormStatus] = useState('');
  const [formCode, setFormCode] = useState('');
  const [creationDate, setCreationDate] = useState('');
  const [updateCompletedDate, setUpdateCompletedDate] = useState('');

  const [appCreationDate, setAppCreationDate] = useState('');
  const [totalProgram, setTotalProgram] = useState(0);
  const [statesList, setStatesList] = useState([]);
  const [principalResults, setPrincipalResults] = useState({});
  const [partnerResults, setPartnerResults] = useState({});
  // const [principalResultsOriginal, setPrincipalResultsOriginal] = useState({});
  // const [partnerResultsOriginal, setPartnerResultsOriginal] = useState({});
  const { t } = useTranslation();
  const {code, pageType} = useParams();
  const pageRef = useRef();

  const principalFormStates = usePrincipalFormStates();
  const partnerFormStates = usePartnerFormStates();

  const setFormData = async (response) =>{
    setevaluationCountry(response.data.data.evaluationCountry);
    setFormTitle(response.data.data.formTitle);
    setIsCoApplication(response.data.data.isCoApplication);
    // setIsSameEExperience(response.data.data.isSameEExperience);
    setIsSameResidence(response.data.data.isSameResidence);
    setCreationDate(response.data.creation_date);
    setUpdateCompletedDate(response.data.update_completed_date);
    setFormStatus(response.data.form_status);
    setFormCode(response.data.form_code);

    principalFormStates.setPersonalInformation(response.data.data.principalApplicantData.personalInformation);
    principalFormStates.setFamilyMembers(response.data.data.principalApplicantData.familyMembers);
    principalFormStates.setChildren(response.data.data.principalApplicantData.children);
    principalFormStates.setResidences(response.data.data.principalApplicantData.residences);
    principalFormStates.setLanguageProficiency(response.data.data.principalApplicantData.languageProficiency);
    principalFormStates.setEducationInCountry(response.data.data.principalApplicantData.educationInCountry);
    principalFormStates.setAllEducationInCountry(response.data.data.principalApplicantData.allEducationInCountry);
    principalFormStates.setEducationOutsideCountry(response.data.data.principalApplicantData.educationOutsideCountry);
    principalFormStates.setAllEducationOutsideCountry(response.data.data.principalApplicantData.allEducationOutsideCountry);
    principalFormStates.setProfessionalExperience(response.data.data.principalApplicantData.professionalExperience);
    principalFormStates.setAllProfessionalExperience(response.data.data.principalApplicantData.allProfessionalExperience);
    principalFormStates.setProfessionalExpertise(response.data.data.principalApplicantData.professionalExpertise);
    principalFormStates.setJobOffer(response.data.data.principalApplicantData.jobOffer);
    principalFormStates.setEntrepreunarialExperience(response.data.data.principalApplicantData.entrepreunarialExperience);
    principalFormStates.setAllEntrepreneurialExperience(response.data.data.principalApplicantData.allEntrepreneurialExperience);
    principalFormStates.setSummary(response.data.data.principalApplicantData.summary);
    principalFormStates.setSpecialProgram(response.data.data.principalApplicantData.specialProgram);
    setNeedPartnerInformation(allowedMaritalStatuses.includes(
      response.data.data.principalApplicantData.personalInformation.personalDetail.maritalStatus
    ));

    partnerFormStates.setPersonalInformation(response.data.data.principalApplicantPartnerData.personalInformation);
    partnerFormStates.setFamilyMembers(response.data.data.principalApplicantPartnerData.familyMembers);
    partnerFormStates.setChildren(response.data.data.principalApplicantPartnerData.children);
    partnerFormStates.setResidences(response.data.data.principalApplicantPartnerData.residences);
    partnerFormStates.setLanguageProficiency(response.data.data.principalApplicantPartnerData.languageProficiency);
    partnerFormStates.setEducationInCountry(response.data.data.principalApplicantPartnerData.educationInCountry);
    partnerFormStates.setAllEducationInCountry(response.data.data.principalApplicantPartnerData.allEducationInCountry);
    partnerFormStates.setEducationOutsideCountry(response.data.data.principalApplicantPartnerData.educationOutsideCountry);
    partnerFormStates.setAllEducationOutsideCountry(response.data.data.principalApplicantPartnerData.allEducationOutsideCountry);
    partnerFormStates.setProfessionalExperience(response.data.data.principalApplicantPartnerData.professionalExperience);
    partnerFormStates.setAllProfessionalExperience(response.data.data.principalApplicantPartnerData.allProfessionalExperience);
    partnerFormStates.setProfessionalExpertise(response.data.data.principalApplicantPartnerData.professionalExpertise);
    partnerFormStates.setJobOffer(response.data.data.principalApplicantPartnerData.jobOffer);
    partnerFormStates.setEntrepreunarialExperience(response.data.data.principalApplicantPartnerData.entrepreunarialExperience);
    partnerFormStates.setAllEntrepreneurialExperience(response.data.data.principalApplicantPartnerData.allEntrepreneurialExperience);
    partnerFormStates.setSummary(response.data.data.principalApplicantPartnerData.summary);
    partnerFormStates.setSpecialProgram(response.data.data.principalApplicantPartnerData.specialProgram);

    if (pageType==='result') {
      setAppCreationDate(response.data.application_data.creation_date);
      setTotalProgram(response.data.application_data.simulation_results.total_programs);
      setPrincipalResults(response.data.application_data.simulation_results.principal);
      setPartnerResults(response.data.application_data.simulation_results.partner);
      // setPrincipalResultsOriginal(response.data.application_data.simulation_results.principal);
      // setPartnerResultsOriginal(response.data.application_data.simulation_results.partner);
      setStatesList(Object.keys(response.data.application_data.simulation_results.principal))
      // console.log(response.data.application_data.simulation_results.principal)
    }

  }

  const lang = localStorage.getItem('flouslang') || 'en';

  /*
  useEffect(()=>{
    const handleTranslate = async ()=>{
      if (lang !== 'en') {
        const principalResultsTranslated = await translateResults(principalResults, lang);
        setPrincipalResults(principalResultsTranslated);
        const partnerResultsTranslated = await translateResults(partnerResults, lang);
        setPartnerResults(partnerResultsTranslated);
      } else {
        setPrincipalResults(principalResultsOriginal);
        setPartnerResults(partnerResultsOriginal);
      }
    };
    handleTranslate();
  }, [lang, principalResultsOriginal, partnerResultsOriginal]);
  */

  useEffect(() =>{
    let isMounted = true;

    const loadForm = async () => {
      try {
        if (isMounted & pageType==='form') {
          const response = await getExistingFormData(code);

          if (response && response.status === 200){
            await setFormData(response);
            setFormStates(principalFormStates);
            setLoading(false);
          }
  
        } else if(isMounted & pageType==='result'){
          const response = await getEvaluationData(code);

          if (response && response.status === 200){
            await setFormData(response);
            setFormStates(principalFormStates);
            setLoading(false);
          }
        
        }
      } catch (error) {
        if (isMounted){
          setError(error);
          setLoading(false);
          if (error.response && [400, 500].includes(error.response.status)){
            toast.error(error.response.data.error);
          }
        }
      }
      
    }

    loadForm();
  }, []);

  const handleBtnClick = (section) => {
    setActiveBtn(section);
  };

  const handleActor = () => {
    setActor(actor === 'Principal' ? 'Partner' : 'Principal');
  };

  useEffect(() => {
    if (actor === 'Principal') {
      setFormStates(principalFormStates);
    } else {
      setFormStates(partnerFormStates);
    }
   
  }, [actor, loading]);


  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }

  const renderContent = () => {
    switch (activeBtn) {
      case "Summary":
        if (pageType==='result'){
          return <EvaluationPage
          evaluationCountry={evaluationCountry}
          appCreationDate={appCreationDate}
          appCode={code}
          totalProgram={totalProgram}
          isCoApplication={isCoApplication}
          statesList={statesList}
          principalResults={principalResults}
          partnerResults={partnerResults}
          />
        } else if (pageType==='form'){
          return <FormDetailIntroduction
          formTitle={formTitle}
          evaluationCountry={evaluationCountry}
          isCoApplication={isCoApplication}
          formStatus={formStatus}
          creationDate={creationDate}
          updateCompletedDate={updateCompletedDate}
          formCode={formCode}
          />
        }
        break;
      case "Personal Informations":
        return <PersonalInfoResume  personalInformation={formStates.personalInformation} />
      case "Family Members":
        return <FamilyMembersResumeSection  familyMembers={formStates.familyMembers} children={formStates.children} />
      case "Residences":
        return <ResidenceInfoResume residences={formStates.residences} />
      case "Languages":
        return <LangueResumeSection languageProficiency={formStates.languageProficiency} />
      case "Education":
        return <EducationResumeSection educationInCountry={formStates.educationInCountry} 
        specialProgram={formStates.specialProgram} allEducationInCountry={formStates.allEducationInCountry} 
        educationOutsideCountry={formStates.educationOutsideCountry} allEducationOutsideCountry={formStates.allEducationOutsideCountry} 
         />
      case "Experience":
        return <ProfessionalExperienceResumeSection professionalExperience={formStates.professionalExperience} 
        allProfessionalExperience={formStates.allProfessionalExperience} />
      case "Expertise":
        return <ProfessionalExpertiseResumeSection professionalExpertise={formStates.professionalExpertise} />
      case "Job offer":
        return <JobOfferResumeSection jobOffer={formStates.jobOffer} />
      case "Entrepreneurial Experience":
        return <EntrepreneurialExperienceResumeSection 
          managementExperience={formStates.entrepreunarialExperience.otherManagementExperience}
          entrepreunarialExperience={formStates.entrepreunarialExperience} 
          allEntrepreneurialExperience={formStates.allEntrepreneurialExperience} 
        />
      default:
        break;
    }
  }

  const handleOpenPDF = () => {
    // Open URL in a new tab
    window.open(`/downloadpdf/${formCode}`, '_blank');
  };
  


  return (
    <div className="content">
      <div ref={pageRef}>
      {/* Content Header */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="mb-2">
            <GoToBackButton />
            <div className='row'>
              <div className="col-sm-6">
                <h1 className="flous-police-one flous-input-color m-0">{t("client.forms.detail.title")}</h1>
              </div>
              
              <div className="col-sm-6 text-right">
                <button className="btn flous-bg-primary" onClick={handleOpenPDF}>
                {t("client.forms.detail.download")}
                </button>
              </div>
               
            </div>
            
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className="content">
        <div className="container-fluid">
          <div className="justify-content-center">
            <div className="">
              <div className="card elevation-2">
                <div className="elevation-1 p-2 mt-3 mx-3" style={{ borderRadius: '15px' }}>
                  <div className="row">
                    {sectionList.map((element, index) => (
                      <div className="col" key={index}>
                        <div
                          className={`btn btn-block ${activeBtn === element ? 'flous-bg-thirty text-bold flous-input-color' : ''}`}
                          onClick={() => handleBtnClick(element)}
                        >
                          {lang === 'en'? element: (lang === 'fr' ? sectionListFR[index]: sectionListES[index])}
                        </div>
                      </div>
                    ))}
                    
                    {needPartnerInformation && 
                      <div className="col">
                        <div
                          className={`btn btn-block flous-bg-secondary`}
                          onClick={() => handleActor()}
                        >
                          {t("client.forms.detail.goto")} {actor === 'Principal' ? 'Partner' : 'Principal'}
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="card-body">
                  {renderContent()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      </div>
    </div>
  );
}
