import React, { useState, useEffect } from 'react';
import NotableAchievements from './section_professional_expertise/NotableAchievementsSection';
import ImpactofExpertise from './section_professional_expertise/ImpactsofExpertiseSection';
import { toast, ToastContainer } from 'react-toastify';
import ProfessionalExpertise from './section_professional_expertise/ProfessionalExpertise';
import { useTranslation } from 'react-i18next';

const ProfessionalExpertiseSection = (props) => {
  const [professionalExpertise, setProfessionalExpertise] = useState(props.professionalExpertise.professionalExpertise);
  const [notableAchievements, setNotableAchievements] = useState(props.professionalExpertise.notableAchievements);
  const [impactofExpertise, setImpactofExpertise] = useState(props.professionalExpertise.impactofExpertise);
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const professionalExpertiseParent = {
        professionalExpertise,
        notableAchievements,
        impactofExpertise
    };
    props.sendProfessionalExpertise(professionalExpertiseParent);
    props.onValidate('professionalExpertise', false, props.actor, false);
  }, [professionalExpertise, notableAchievements, impactofExpertise, props]);

  const receivedExpertiseBlock = (data) => {setProfessionalExpertise(data);};
  const receivedNotableAchievements = (data) => {setNotableAchievements(data);};
  const receivedImpactofExpertise = (data) => {setImpactofExpertise(data);};

  const checkWorkedSameProfession = () =>{
    if (professionalExpertise.workedSameProfession === '' || 
        (professionalExpertise.workedSameProfession === 'Yes' && professionalExpertise.exceptionalExpertise === '')){
      toast.error(`${t('forms.formsquestion.toast.professionalExpertise.option')}`);
      setErrors(prevErrors =>({
        ...prevErrors,
        option: `${t('forms.formsquestion.toast.professionalExpertise.option')}`
      }));
      return false;
    }else if (professionalExpertise.exceptionalExpertise === 'Yes') {
      delete errors.option;
      if (notableAchievements.recognition === 'Yes'){
        if (impactofExpertise.impact !== ''){
          delete errors.option;
          return true;
        }
      }else if (notableAchievements.recognition === 'No'){
        delete errors.option;
        return true;
      } else {
        // toast.error("Please complete all blocks below in the section !");
        setErrors(prevErrors =>({
          ...prevErrors,
          option: `${t('forms.formsquestion.toast.professionalExpertise.option')}`
        }));
        return false;
      }
    } else{
      delete errors.option;
      return true;
    }
  }

  const validateSection = () => {
    props.onValidate('professionalExpertise', false, props.actor, false);

    if (checkWorkedSameProfession()){
      toast.success(`${t('forms.formsquestion.toast.sectionValidate')}`);
      setErrors({});
      return true;
    }else{
      toast.error(`${t('forms.formsquestion.toast.professionalExpertise.option')}`);
      setErrors(prevErrors =>({
        ...prevErrors,
        option: `${t('forms.formsquestion.toast.professionalExpertise.option')}`
      }));
      return false;
    }
  };

  const pressNext = async () => {
    
    const validation = validateSection();

    await props.onValidate('professionalExpertise', validation, props.actor, true);

    if (validation) {
      props.sendNext(props.actor === 'Principal'? "Job offer":"Job offer Partner", props.actor);
    }
  };

  const goBack = ()=> {
    if (props.actor === 'Principal') {
      props.goBack("Professional Experience", "Principal");
    } else {
      props.goBack("Professional Experience Partner", "Partner");
    } 
  };

  return (
    <fieldset id="step7">
      <ul style={{ color: 'red' }}>
        {Object.values(errors).map((value, index) => {
            if (value !== '') {
                return <li key={index}>{value}</li>;
            }
            return null;
        })}
      </ul>
      <div className="form-card">
        <ProfessionalExpertise sendExpertiseBlock={receivedExpertiseBlock} professionalExpertise={professionalExpertise} />
        
        {professionalExpertise.exceptionalExpertise ==='Yes' && (
          <div>
            <NotableAchievements sendNotableAchievements={receivedNotableAchievements} notableAchievements={notableAchievements} />
            {notableAchievements.recognition === 'Yes' && (
              <ImpactofExpertise sendImpactofExpertise={receivedImpactofExpertise} impactofExpertise={impactofExpertise} />
            )}
          </div>
        )}
        
        <div className='d-flex justify-content-center'>
          <div className='col-sm-10'>
            <div className='d-flex flex-column flex-md-row justify-content-between'>
              <input type="button" name="save" className="col-12 col-md-6 mb-2 mb-md-0 btn flous-btn-thirty rounded-pill mx-1" value={t("forms.click.goBack")} onClick={goBack}/>
              <input type="button" name="next" className="col-12 col-md-6 btn flous-btn-primary rounded-pill mx-1" value={t("forms.click.pressNext")} onClick={pressNext}/>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </fieldset>
  );
};

export default ProfessionalExpertiseSection;