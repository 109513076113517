import React, { useEffect, useState } from 'react';
import DegreeDiplomaSection from './section_education_inside_country/DegreeDiplomaSection';
import InstitutionSection from './section_education_inside_country/InstitutionSection';
import DiplomaRecognitionSection from './section_education_inside_country/DiplomaRecognitionSection';
import EquivalencyApplicationStatusSection from './section_education_inside_country/EquivalencyApplicationStatusSection';
import ProvinceEquivalencyProcessSection from './section_education_inside_country/ProvinceEquivalencyProcessSection';
import SpecialProgramSection from './section_education_inside_country/SpecialProgramSection';
import { toast, ToastContainer } from 'react-toastify';
import RequiredIndicator from '../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";
import { canadaStatesConverter, educationLevel, languageConverter, mapStateToDescription } from '../../../../services/utils';
import CountryShow from '../../../general/countryShow';

const EducationInCountry = (props) => {
  const [currentlyStudy, setCurrentlyStudy] = useState(props.educationInCountry.currentlyStudy);
  const [educationInCountry, setEducationInCountry] = useState(props.educationInCountry.educationInCountry);
  const [allEducationInCountry, setAllEducationInCountry] = useState(props.allEducationInCountry); 
  const [applicationProcessed, setApplicationProcessed] = useState(props.educationInCountry.applicationProcessed);
  const [degreeDiploma, setDegreeDiploma] = useState(props.educationInCountry.degreeDiploma);
  const [institution, setInstitution] = useState(props.educationInCountry.institution);
  const [diplomaRecognition, setDiplomaRecognition] = useState(props.educationInCountry.diplomaRecognition);
  const [provinceProcessed, setProvinceProcessed] = useState(props.educationInCountry.provinceProcessed);
  const [recognitionProvince, setRecognitionProvince] = useState(props.educationInCountry.recognitionProvince);
  const [specialProgram, setSpecialProgram] = useState(props.specialProgram);
  const [errors, setErrors] = useState({});
  const [addClicked, setAddClicked] = useState(false);

  const { t } = useTranslation();
  
  const lang = localStorage.getItem('flouslang') || 'en';

  const [currentEducation, setCurrentEducation] = useState(null);

  useEffect(()=>{
    const educationInCountryParent = {
        currentlyStudy,
        educationInCountry,
        applicationProcessed,
        degreeDiploma,
        institution,
        diplomaRecognition,
        provinceProcessed,
        recognitionProvince,
    }
    props.sendEducationInCountry(educationInCountryParent);
    props.onValidate('educationInCountry', false, props.actor, false);
  }, [currentlyStudy, educationInCountry, applicationProcessed, degreeDiploma, 
    institution, diplomaRecognition, provinceProcessed, 
    recognitionProvince, props]);

  useEffect(()=>{
    props.sendSpecialProgram(specialProgram);
    props.onValidate('educationInCountry', false, props.actor, false);
  }, [specialProgram]);

  useEffect(()=> {
    props.sendAllEducationInCountry(allEducationInCountry);
    props.onValidate('educationInCountry', false, props.actor, false);
  }, [allEducationInCountry]);

  const handleCurrentlyStudyChange = (event) => {
    const { value } = event.target;
    setCurrentlyStudy(value);
    setErrors({});
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setEducationInCountry(value);
    setErrors({});
  };

  const handleAllEducation = () => {
    if (validateFields()) {
      const educationInCountryParent = {
        applicationProcessed,
        degreeDiploma,
        institution,
        diplomaRecognition,
        provinceProcessed,
        recognitionProvince
      }
      setAddClicked(false);
      if (currentEducation === null) {
        setAllEducationInCountry([...allEducationInCountry, educationInCountryParent]);
      }else {
        const updateData = [...allEducationInCountry];
        updateData[currentEducation] = educationInCountryParent;
        setAllEducationInCountry(updateData);
      }
      setCurrentEducation(null);
      setApplicationProcessed('');
      setDegreeDiploma({
        degreeDiploma: '',
        startDate: '',
        endDate: ''
      });
      setInstitution({
        institutionName: '',
        languageOfInstruction: '',
        fullTimeStudent: '',
        fieldOfStudy: '',
        studyDuration: '',
        address: '',
        country: 'CAN',
        provinceState: '',
        city: '',
      });
      setDiplomaRecognition({
        diplomaRecognized: '',
        equivalentProcessCompleted: ''
      });
      setProvinceProcessed([]);
      setRecognitionProvince({
        provinceState: '',
      })
    }
  };

  const handleCancelEducation = () => {
    setAddClicked(false);
    setCurrentEducation(null);
    setApplicationProcessed('');
    setDegreeDiploma({
      degreeDiploma: '',
      startDate: '',
      endDate: ''
    });
    setInstitution({
      institutionName: '',
      languageOfInstruction: '',
      fullTimeStudent: '',
      fieldOfStudy: '',
      studyDuration: '',
      address: '',
      country: 'CAN',
      provinceState: '',
      city: '',
    });
    setDiplomaRecognition({
      diplomaRecognized: '',
      equivalentProcessCompleted: ''
    });
    setProvinceProcessed([]);
    setRecognitionProvince({
      provinceState: '',
    })
    delete errors.degreeDiploma;
    delete errors.institution;
    delete errors.diplomaRecognition;
    delete errors.provinceProcessed;
    delete errors.recognitionProvince;
  }

  const handleEditEducation = (index) => {
    const updatedFormData = [...allEducationInCountry];
    const education = updatedFormData[index];
    setApplicationProcessed(education.applicationProcessed);
    setDegreeDiploma(education.degreeDiploma);
    setInstitution(education.institution);
    setDiplomaRecognition(education.diplomaRecognition);
    setProvinceProcessed(education.provinceProcessed);
    setRecognitionProvince(education.recognitionProvince);
    setAddClicked(true);
    setCurrentEducation(index);
    delete errors.degreeDiploma;
    delete errors.institution;
    delete errors.diplomaRecognition;
    delete errors.provinceProcessed;
    delete errors.recognitionProvince;
  }

  const checkDegreeDiploma = () =>{
    if ((!degreeDiploma.degreeDiploma || !degreeDiploma.startDate ) || 
        (degreeDiploma.degreeDiploma === 'PRG' && !degreeDiploma.prgDegreeDiploma)) {
      setErrors(prevErrors =>({
        ...prevErrors,
        degreeDiploma: `${t('forms.formsquestion.toast.educationInCountry.degreeDiploma')}`
      }));
      toast.error(`${t('forms.formsquestion.toast.educationInCountry.degreeDiploma')}`);
      return false;
    } else {
      delete errors.degreeDiploma;
      return true;
    }
  }

  const checkInstitution = () =>{
    if (!institution.institutionName || !institution.languageOfInstruction || !institution.fullTimeStudent ||
      !institution.generalFieldOfStudy || !institution.fieldOfStudy || !institution.studyDuration || /*!institution.country ||*/
      !institution.provinceState || !institution.city
    ) {
      setErrors(prevErrors =>({
        ...prevErrors,
        institution: `${t('forms.formsquestion.toast.educationInCountry.institution')}`
      }));
      toast.error(`${t('forms.formsquestion.toast.educationInCountry.institution')}`);
      return false;
    } else {
      delete errors.institution;
      return true;
    }
  }

  const checkSpecialPrograms = () =>{
    if (!specialProgram.homeCareProgram ||
      (specialProgram.homeCareProgram === 'No' && !specialProgram.proposeProgram)
    ) {
      setErrors(prevErrors =>({
        ...prevErrors,
        specialProgram: `${t('forms.formsquestion.toast.educationInCountry.institution')}`
      }));
      toast.error(`${t('forms.formsquestion.toast.educationInCountry.institution')}`);
      return false;
    } else {
      delete errors.specialProgram;
      return true;
    }
  }

  const checkDiplomaRecognition = () =>{
    if (!diplomaRecognition.equivalentProcessCompleted || 
      (diplomaRecognition.equivalentProcessCompleted === 'Yes' && provinceProcessed.length === 0)
    ) {
      setErrors(prevErrors =>({
        ...prevErrors,
        diplomaRecognition: `${t('forms.formsquestion.toast.educationInCountry.recognition')}`
      }));
      toast.error(`${t('forms.formsquestion.toast.educationInCountry.recognition')}`);
      return false;
    } else {
      delete errors.diplomaRecognition;
      return true;
    }
  }

  const checkApplicationStatus = () =>{
    if ((diplomaRecognition.equivalentProcessCompleted === 'No' && !applicationProcessed) || 
        (applicationProcessed === 'Yes' && provinceProcessed.length === 0)
      ) {
      setErrors(prevErrors =>({
        ...prevErrors,
        recognitionProvince: `${t('forms.formsquestion.toast.educationInCountry.equivalency')}`
      }));
      toast.error(`${t('forms.formsquestion.toast.educationInCountry.equivalency')}`);
      return false;
    } else {
      delete errors.recognitionProvince;
      return true;
    }
  }

  const validateFields = () => {
    setErrors({});
    if (checkInstitution() && checkDegreeDiploma() &&checkDiplomaRecognition() && checkApplicationStatus()) {
      setErrors({});
      return true;
    } else {
      // // toast.error('Please correct the above errors !');
      return false;
    }
  }

  const validateSection = () => {
    if (currentlyStudy ==='Yes' || educationInCountry === 'Yes') {
      if (allEducationInCountry.length !==0) {
        if(checkSpecialPrograms()){
          setErrors({});
          toast.success(`${t('forms.formsquestion.toast.sectionValidate')}`)
          return true;
        } else {
          toast.error(`${t('forms.formsquestion.toast.educationInCountry.specialProgram')}`);
          setErrors(prevErrors =>({
            ...prevErrors,
            specialProgram: `${t('forms.formsquestion.toast.educationInCountry.specialProgram')}`
          }));
          return false;
        }

      } else {
        toast.error(`${t('forms.formsquestion.toast.educationInCountry.atLeastOne')}`);
        setErrors(prevErrors =>({
          ...prevErrors,
          allEducationInCountry: `${t('forms.formsquestion.toast.educationInCountry.atLeastOne')}`
        }));
        return false;
      }
    } else if (currentlyStudy ==='No' && educationInCountry === 'No'){
      setErrors({});
      toast.success(`${t('forms.formsquestion.toast.sectionValidate')}`)
      return true; 
    } else if (!educationInCountry || !currentlyStudy){
      setErrors(prevErrors =>({
        ...prevErrors,
        educationInCountry: `${t('forms.formsquestion.toast.educationInCountry.moreInfo')}`
      }));
      toast.error(`${t('forms.formsquestion.toast.educationInCountry.moreInfo')}`);
      return false;
    } else{
      return false;
    }
  };

  const receivedApplicationProcessed = (data) => {setApplicationProcessed(data);};
  const receivedDegreeDiploma = (data) => {setDegreeDiploma(data);};
  const receivedInstitution = (data) => {setInstitution(data);};
  const receivedDiplomaRecognition = (data) => {setDiplomaRecognition(data);};
  const receivedProvinceProcessed = (data) => {setProvinceProcessed(data);};
  const receivedRecognitionProvince = (data) => {setRecognitionProvince(data);};
  const receivedSpecialProgram = (data) => {setSpecialProgram(data);};

  const pressNext = async ()=> {
    
    const validation = validateSection();

    await props.onValidate('educationInCountry', validation, props.actor, true);

    if (validation) {
      props.sendNext(props.actor === 'Principal'? "Education outside country":"Education outside country Partner", props.actor);
    }
  };

  const goBack = ()=> {
    if (props.actor === 'Principal') {
      props.goBack("Language Proficiency", "Principal");
    } else {
      props.goBack("Language Proficiency Partner", "Partner");
    } 
  };

  return (
    <fieldset id="step4">
      <ul style={{ color: 'red' }}>
        {Object.values(errors).map((value, index) => {
            if (value !== '') {
                return <li key={index}>{value}</li>;
            }
            return null;
        })}
      </ul> 
      {/*<legend>Informations personnelles</legend>*/}
      <div className="form-card">
        <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
        <p className='text-bold text-center'>{t("forms.title.educationin")} {t('countries.canada')}</p> 
          <div style={{ color: 'red' }}>{errors.allEducationInCountry !== '' ? errors.allEducationInCountry : ''}</div>
          <div className="form-group p-3 px-4 mb-4" style={{ borderRadius: '15px' }}>
            <div className='row p-2'>
              <p className="col-form-label col-sm-10">{t("forms.formsquestion.educationInCountry.currentlyStudy")} {t('countries.canada')} ?<RequiredIndicator /> </p>
              <div className="form-check col">
                <input 
                  type="radio"
                  name="currentlyStudy"
                  className="form-check-input"
                  value="Yes"
                  onChange={handleCurrentlyStudyChange}
                  checked={currentlyStudy === "Yes"}
                />
                <label className="form-check-label">{t("forms.click.yes")}</label>
              </div>
              <div className="form-check col">
                <input 
                  type="radio"
                  name="currentlyStudy"
                  className="form-check-input"
                  value="No"
                  onChange={handleCurrentlyStudyChange}
                  checked={currentlyStudy === "No"}
                />
                <label className="form-check-label">{t("forms.click.no")}</label>
              </div>
            </div>
            <div className='row p-2'>
              <p className="col-form-label col-sm-10">{t("forms.formsquestion.educationInCountry.educationInCountry")} {t('countries.canada')} ?<RequiredIndicator /> </p>
              <div className="form-check col">
                <input 
                  type="radio"
                  name="educationInCountry"
                  className="form-check-input"
                  value="Yes"
                  onChange={handleChange}
                  checked={educationInCountry === "Yes"}
                />
                <label className="form-check-label">{t("forms.click.yes")}</label>
              </div>
              <div className="form-check col">
                <input 
                  type="radio"
                  name="educationInCountry"
                  className="form-check-input"
                  value="No"
                  onChange={handleChange}
                  checked={educationInCountry === "No"}
                />
                <label className="form-check-label">{t("forms.click.no")}</label>
              </div>
            </div>
          </div>
          {((currentlyStudy === 'Yes' || educationInCountry === 'Yes') && !(!currentlyStudy || !educationInCountry)) && (
            <div className='mt-2'>
              <div className="form-group p-3 px-4 mb-4" style={{ borderRadius: '15px 15px 0px 0px' }}>
                <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                  <div className='row'>
                    <div className='col-sm col-6 mt-2 text-center'>{t("general.table.fieldOfStudy")}</div>
                    <div className='col-sm col-6 mt-2 text-center'>{t("general.table.country")}</div>
                    <div className='col-sm col-6 mt-2 text-center'>{t("general.table.enddate")}</div>
                    <div className='col-sm col-6 mt-2 text-center'>{t("general.table.studyLanguage")}</div>
                    <div className='col-sm col-6 mt-2 text-center'>{t("general.table.actions")}</div>
                  </div>
                </div>
              {allEducationInCountry && allEducationInCountry.length > 0 ? (
                allEducationInCountry.map((educationIn, index) => (
                  <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px' }} key={index}>
                    <div className='row'>
                      <div className='col-sm col-6 mt-2 text-center'>{educationIn.institution.fieldOfStudy}</div>
                      <div className='col-sm col-6 mt-2 text-center'>
                        <CountryShow value={educationIn.institution.country} />
                      </div>
                      <div className='col-sm col-6 mt-2 text-center'>{educationIn.degreeDiploma.endDate ? educationIn.degreeDiploma.endDate: mapStateToDescription('IP', lang)}</div>
                      <div className='col-sm col-6 mt-2 text-center'>{languageConverter(educationIn.institution.languageOfInstruction)}</div>
                      <div className='col-sm col-6 mt-2 text-center'>
                        <button type='button'
                            className="btn flous-bg-primary mr-1" 
                            onClick={() => handleEditEducation(index)}
                        >
                            <i className="nav-icon fas fa-edit"></i>
                        </button>
                        <button
                          className="btn btn-secondary"
                          onClick={() => setAllEducationInCountry(allEducationInCountry.filter((_, i) => i !== index))}
                        >
                          <i className="nav-icon fas fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className='text-center'>{t("general.table.empty.noElement")}</div>
              )}
              
              {!addClicked &&
                <div className='mt-3'>
                    <button type='button' className='btn btn-block btn-secondary rounded-pill' onClick={()=>{setAddClicked(true)}}>{t("forms.addMoreButton.addStudy")}</button>
                </div>
              }
            </div>
          </div> 
          )}
        </div>
        {addClicked && (
          <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
          {(currentlyStudy === "Yes" || educationInCountry === 'Yes') && (
            <div>
                <InstitutionSection error={errors.institution ? errors.institution : ''}
                  sendInstitution={receivedInstitution} institution={institution}/>
                <DegreeDiplomaSection error={errors.degreeDiploma ? errors.degreeDiploma : ''}
                  sendDegreeDiploma={receivedDegreeDiploma} degreeDiploma={degreeDiploma} isCurrent={"No"}
                />
                <DiplomaRecognitionSection error={errors.diplomaRecognition ? errors.diplomaRecognition : ''}
                  sendDiplomaRecognition={receivedDiplomaRecognition} diplomaRecognition={diplomaRecognition}/>
            </div>
          )}
          {diplomaRecognition.equivalentProcessCompleted === 'No' && (
            <div>
                <EquivalencyApplicationStatusSection 
                sendApplicationProcessed={receivedApplicationProcessed} applicationProcessed={applicationProcessed}
                error={errors.recognitionProvince ? errors.recognitionProvince : ''}
                />
            </div>
          )}
          {(diplomaRecognition.equivalentProcessCompleted === 'Yes' || (diplomaRecognition.equivalentProcessCompleted === 'No' && applicationProcessed === 'Yes')) && (
            <div>
              <ProvinceEquivalencyProcessSection 
              sendProvinceProcessed={receivedProvinceProcessed} provinceProcessed={provinceProcessed} 
              evaluationCountry={props.evaluationCountry} 
              sendRecognitionProvince={receivedRecognitionProvince} recognitionProvince={recognitionProvince}
              error={errors.recognitionProvince ? errors.recognitionProvince : ''}
              />
            </div>
          )}
            {/*<div className='d-flex justify-content-center mt-1 mb-4 m-2'>
              <button type='button' className='col-sm-10 btn btn-block btn-secondary rounded-pill' onClick={handleAllEducation}>Save Education</button>
            </div>*/}
              
            <div className='d-flex justify-content-center mb-3'>
            <div className='col-sm-10'>
                <div className='d-flex justify-content-between'>
                  <input type="button" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value={`${t("forms.addMoreButton.saveStudy")}`} onClick={handleAllEducation}/>
                  <input type="button" className="col-6 btn btn-block btn-secondary rounded-pill mx-1" value={`${t("forms.addMoreButton.cancel")}`} onClick={()=>{handleCancelEducation()}}/>
                </div>
              </div>
            </div>
          </div>
        )}
        <SpecialProgramSection error={errors.specialProgram ? errors.specialProgram : ''}
          sendSpecialProgram={receivedSpecialProgram} specialProgram={specialProgram} />
              
        <div className='d-flex justify-content-center'>
          <div className='col-sm-10'>
            <div className='d-flex flex-column flex-md-row justify-content-between'>
              <input type="button" name="save" className="col-12 col-md-6 mb-2 mb-md-0 btn flous-btn-thirty rounded-pill mx-1" value={t("forms.click.goBack")} onClick={goBack}/>
              <input type="button" name="next" className="col-12 col-md-6 btn flous-btn-primary rounded-pill mx-1" value={t("forms.click.pressNext")} onClick={pressNext}/>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </fieldset>
  );
};

export default EducationInCountry;
