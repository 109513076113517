import React, { createContext, useState, useEffect, useContext } from 'react';
import api from '../services/api';

const AuthContext = createContext(null);

export const useAuth = () => {
    return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [accessToken, setAccessToken] = useState(localStorage.getItem('acessToken') || '');
   //  const navigate = useNavigate();

    useEffect(() => {
    
        const fetchUserData = async () => {
            if (accessToken) {
                try{
                    const response = await api.get('/auth/user/');
                    setUser(response.data);
                } catch (error){
                    // console.error("Failed when fetching user data: ", error);
                    setUser("");
                    localStorage.removeItem('refreshToken')
                    localStorage.removeItem('accessToken')
                }                
            }
        };
        fetchUserData();
        
    }, [accessToken]);
    

    const login = async (username, password) => {
        
        try {
            const response = await api.post('/auth/login/', { username, password });
            
            if (response.status === 200){
                setAccessToken(response.data.access);
                localStorage.setItem('accessToken', response.data.access);
                localStorage.setItem('refreshToken', response.data.refresh);
            }
            return response;
            
        } catch (error){
            throw error;
        }

    };

    const logout = () => {
        setAccessToken('');
        setUser(null);
        setAccessToken(null);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
    };
    
    const registerSelfUser = async (firstname, lastname, date_of_birth, phone, email, password) => {
        try {
            const response = await api.post('/auth/register/', 
                { 
                    'first_name': firstname, 
                    'last_name': lastname, 
                    'date_of_birth': date_of_birth, 
                    'cell_phone_number': phone, 
                    'email': email, 
                    'password': password,
                 });

                 return response;

        } catch (error) {
            console.log(error);
        }
    };
    
    const registerProUser = async (
        firstname, lastname, date_of_birth, phone, email, password, associate
    ) => {
        try {

            const data = { 
                'first_name': firstname, 
                'last_name': lastname, 
                'date_of_birth': date_of_birth, 
                'cell_phone_number': phone, 
                'email': email, 
                'password': password,
                'associate': associate,
            }
            
            const response = await api.post('/auth/register/', data);
            return response;

        } catch (error) {
            return;
            // navigate('/chooseactor', { state: { message: "Your account has been successfully created, \nplease log in below." } });
                        
            // console.log(error);
        }
    };
    
    const resetPassword = async (password, confirmPassword, token, uidb64) => {
        
        try {
            const response = await api.post(`/auth/reset-password/${uidb64}/${token}/`, { new_password1: password, new_password2: confirmPassword });
            return response
        } catch (error) {
            return;
        }
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, setUser, setAccessToken, registerSelfUser, registerProUser, resetPassword}}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
