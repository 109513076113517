import React from 'react';
import CompanyAddressResumeSection from './section_joboffer/companyAddressSection';
import JobDetailResumeSection from './section_joboffer/jobDetailSection';
import CompanyInfoResumeSection from './section_joboffer/companyInfoSection';
import ProfessionalDetailsResumeSection from './section_joboffer/professionalDetailSection';
import { useTranslation } from 'react-i18next';

export default function JobOfferResumeSection(props) {

  const { t } = useTranslation();
  
  return (
    <div id='joboffer'>
      <h3 className='flous-police-one flous-input-color text-center'>{t("client.results.blockTitle.bloc8")}</h3>
        <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
          <div className='row p-2'>
            
            <label htmlFor="jobOffer" className="col-sm-6 col-form-label">{t("forms.formsquestion.jobOffer.jobOffer")} {t('countries.canada')}</label>
            <div className="col-sm-6">
              <input type="text" className="form-control" id="jobOffer" value={props.jobOffer.jobOffer === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
            </div>
          </div>
        </div>
      {props.jobOffer.jobOffer === 'Yes' && (
      <>
      <CompanyAddressResumeSection companyDetail={props.jobOffer.companyDetail} />
      <JobDetailResumeSection jobDetail={props.jobOffer.jobDetail} professionalDetail={props.jobOffer.professionalDetail} />
      <ProfessionalDetailsResumeSection professionalDetail={props.jobOffer.professionalDetail} />
      <CompanyInfoResumeSection jobCompensation={props.jobOffer.jobCompensation} companyInfo={props.jobOffer.companyInfo} />
      </>
      )}
    </div>
  );
}