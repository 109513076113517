import React, { useState, useEffect } from 'react';
import OwnerShipDetailSection from './section_ee/yes/OwnerShipDetailSection';
import CompanyActivitesSection from './section_ee/yes/CompanyActivitiesSection';
import SupportbySpecializedO from './section_ee/yes/SupportbySpecializedOrganizationSection';
import CompanyFinancySection from './section_ee/yes/CompanyFinancySection';
import RoleAndExperienceSection from './section_ee/yes/RoleandExperienceSection';
import CompanyDetailsYesSection from './section_ee/yes/CompanyDetailYesSection';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InnovativeActivities from './section_ee/yes/InnovativeActivitiesSection';
import RequiredIndicator from '../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";
import ManagementExperienceSection from './section_ee/yes/managementExperience';
import CountryShow from '../../../general/countryShow';
import formData from '../formsData';

const EntrepreunarialExperience = (props) => {

  const [haveBusinesses, setHaveBusiness] = useState(props.entrepreunarialExperience.haveBusinesses)
  const [entrepreunarialExperience, setFormData] = useState(props.entrepreunarialExperience.entrepreunarialExperience);
  const [allEntrepreneurialExperience, setAllEnterpreneurialExperience] = useState(props.allEntrepreneurialExperience);
  const [otherManagementExperience, setOtherManagementExperience] = useState(props.entrepreunarialExperience.otherManagementExperience);
  const [ownershipDetail, setOwnershipDetail] = useState(props.entrepreunarialExperience.ownershipDetail);
  const [companyActivites, setCompanyActivites] = useState(props.entrepreunarialExperience.companyActivites);
  const [supportby, setSupportby] = useState(props.entrepreunarialExperience.supportby);
  const [innovativeActivities, setInnovativeActivities] = useState(props.entrepreunarialExperience.innovativeActivities);
  const [companyFinances, setCompanyFinances] = useState(props.entrepreunarialExperience.companyFinances);
  const [roleAndExperience, setRoleAndExperience] = useState(props.entrepreunarialExperience.roleAndExperience);
  const [companyDetails, setCompanyDetails] = useState(props.entrepreunarialExperience.companyDetails);
  const [errors, setErrors] = useState({});
  const [addClicked, setAddClicked] = useState(false);
  const { t } = useTranslation();

  const [existAdded, setExistAdded] = useState(false);
  const [newAdded, setNewAdded] = useState(false);
  const [undertakeAdded, setUndertakeAdded] = useState(false);

  const [showEstablishmentDate, setShowEstablishmentDate] = useState(false);

  const [currentEntrepreneurialExperience, setCurrentEntrepreneurialEperience] = useState(null);


  useEffect(()=>{
    const validateEntrepreneurialExperiences = () =>{
      const existCount = allEntrepreneurialExperience.filter(experience =>['Yes'].includes(experience.entrepreunarialExperience.ownership)).length;
      const newCount = allEntrepreneurialExperience.filter(experience =>['Start a new company'].includes(experience.entrepreunarialExperience.newcompany)).length;
      const undertakeCount = allEntrepreneurialExperience.filter(experience =>['Undertake an existing company'].includes(experience.entrepreunarialExperience.newcompany)).length;
  
      if (existCount === 1) {
        setExistAdded(true);
      } else {
        setExistAdded(false);
      }
      if (newCount >= 1) {
        setNewAdded(true);
      } else {
        setNewAdded(false);
      }
      if (undertakeCount >= 1) {
        setUndertakeAdded(true);
      } else {
        setUndertakeAdded(false)
      }
    }
    
    if (currentEntrepreneurialExperience === null){
      validateEntrepreneurialExperiences();
    }
      
  }, [allEntrepreneurialExperience, entrepreunarialExperience, currentEntrepreneurialExperience]);

  useEffect(() => {
    const entrepreunarialExperienceParent = {
        haveBusinesses,
        entrepreunarialExperience,
        otherManagementExperience,
        ownershipDetail,
        companyActivites,
        supportby,
        innovativeActivities,
        companyFinances,
        roleAndExperience,
        companyDetails
    };
    props.sendEntrepreunarialExperience(entrepreunarialExperienceParent);
    props.onValidate('entrepreunarialExperience', false, props.actor, false);
  }, [haveBusinesses, entrepreunarialExperience, otherManagementExperience, ownershipDetail, 
    companyActivites, supportby, innovativeActivities, companyFinances, roleAndExperience, companyDetails, props]);
  
  useEffect(()=>{
    if(entrepreunarialExperience.ownership === 'Yes' || entrepreunarialExperience.takeoverBid === 'Yes' ||
      entrepreunarialExperience.newcompany === 'Undertake an existing company'
    ){
      setShowEstablishmentDate(true);
    } else {
      setShowEstablishmentDate(false);
    }
  }, [entrepreunarialExperience])
  
  useEffect(()=> {    
    props.sendAllEntrepreneurialExperience(allEntrepreneurialExperience);
    props.onValidate('entrepreunarialExperience', false, props.actor, false);
  }, [allEntrepreneurialExperience, props]);

  const handleHaveBusinessesChange = (event) => {
    const { value } = event.target;
    setHaveBusiness(value);
    if (value === 'No') {
      setAddClicked(false);
    }
    setErrors({});
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedData = {
        ...entrepreunarialExperience,
        [name]: value,
      };
    
    setFormData(updatedData);
    const entrepreunarialExperienceParent = {
      haveBusinesses,
      entrepreunarialExperience: updatedData,
      otherManagementExperience,
      ownershipDetail,
      companyActivites,
      supportby,
      companyFinances,
      roleAndExperience,
      companyDetails
    };
    props.sendEntrepreunarialExperience(entrepreunarialExperienceParent);
    setErrors({});
  };

  const receivedOwnershipDetail = (data) => {setOwnershipDetail(data);};
  const receivedOtherManagementExperience = (data) => {setOtherManagementExperience(data);};
  const receivedCompanyActivites = (data) => {setCompanyActivites(data);};
  const receivedSupportby = (data) => {setSupportby(data);};
  const receivedInnovativeActivities = (data) => {setInnovativeActivities(data);};
  const receivedCompanyFinances = (data) => {setCompanyFinances(data);};
  const receivedRoleAndExperience = (data) => {setRoleAndExperience(data);};
  const receivedCompanyDetails = (data) => {setCompanyDetails(data);};

  const checkOtherManagementExperience = () =>{

    if (props.entrepreunarialExperience.otherManagementExperience.managementExperience === '' || 
      (props.entrepreunarialExperience.otherManagementExperience.managementExperience === 'Yes' &&
        (props.entrepreunarialExperience.otherManagementExperience.managementStartDate === '' ||
        props.entrepreunarialExperience.otherManagementExperience.managementEndDate === ''))){
      setErrors(prevErrors =>({
        ...prevErrors,
        otherManagementExperience: `${t('forms.formsquestion.toast.entrepreneurial.managementExperience')}`
      }));
      toast.error(`${t('forms.formsquestion.toast.entrepreneurial.managementExperience')}`);
      return false;
    } else {
        delete errors.otherManagementExperience;
        return true;
    }
  }
  
  const checkOwnerShipDetails = () =>{

    if (props.entrepreunarialExperience.ownershipDetail.companyName === '' ||
      props.entrepreunarialExperience.ownershipDetail.ownershipPercentage === '' || props.entrepreunarialExperience.ownershipDetail.personalEquityInvestment === ''){
      setErrors(prevErrors =>({
        ...prevErrors,
        ownershipSection: t('forms.formsquestion.toast.entrepreneurial.ownershipDetail')
      }));
      toast.error(`${t('forms.formsquestion.toast.entrepreneurial.ownershipDetail')}`);
      return false;
    } else {
        delete errors.ownershipSection;
        return true;
    }
  }

  const checkCompanyActivites = () =>{

    if (props.entrepreunarialExperience.companyActivites.companyActivity === '' || props.entrepreunarialExperience.companyActivites.paydayLoans === '' ||
      props.entrepreunarialExperience.companyActivites.explicitContent === '' || props.entrepreunarialExperience.companyActivites.realEstate === ''){
      setErrors(prevErrors =>({
        ...prevErrors,
        companyActivites: `${t('forms.formsquestion.toast.entrepreneurial.companyActivities')}`
      }));
      toast.error(`${t('forms.formsquestion.toast.entrepreneurial.companyActivities')}`);
      return false;
    } else {
        delete errors.companyActivites;
        return true;
    }
  }

  const checkSupportBy = () =>{

    if (props.entrepreunarialExperience.supportby.receivedServiceOffer === ''){
      setErrors(prevErrors =>({
        ...prevErrors,
        supportby: `${t('forms.formsquestion.toast.entrepreneurial.organization')}`
      }));
      toast.error(`${t('forms.formsquestion.toast.entrepreneurial.organization')}`);
      return false;
    } else {
        delete errors.supportby;
        return true;
    }
  }

  const checkInnovativeActivities = () =>{

    if (innovativeActivities.innovative === ''){
      setErrors(prevErrors =>({
        ...prevErrors,
        innovativeActivities: `${t('forms.formsquestion.toast.entrepreneurial.innovative')}`
      }));
      toast.error(`${t('forms.formsquestion.toast.entrepreneurial.innovative')}`);
      return false;
    } else {
        delete errors.innovativeActivities;
        return true;
    }
  }

  const checkCompanyFinances = () =>{

    if (props.entrepreunarialExperience.companyFinances.companyValue === '' || props.entrepreunarialExperience.companyFinances.startupExpenses === '' ||
      props.entrepreunarialExperience.companyFinances.operatingExpenses === ''){
      setErrors(prevErrors =>({
        ...prevErrors,
        operatingExpenses: `${t('forms.formsquestion.toast.entrepreneurial.finances')}`
      }));
      toast.error(`${t('forms.formsquestion.toast.entrepreneurial.finances')}`);
      return false;
    } else {
        delete errors.operatingExpenses;
        return true;
    }
  }

  const checkRoleAndExperience = () =>{

    if (props.entrepreunarialExperience.roleAndExperience.position === '' || props.entrepreunarialExperience.roleAndExperience.startDate === '' ||
      props.entrepreunarialExperience.roleAndExperience.operatingPeriod === ''){
      setErrors(prevErrors =>({
        ...prevErrors,
        roleAndExperience: `${t('forms.formsquestion.toast.entrepreneurial.roleAndExperience')}`
      }));
      toast.error(`${t('forms.formsquestion.toast.entrepreneurial.roleAndExperience')}`);
      return false;
    } else {
        delete errors.roleAndExperience;
        return true;
    }
  }

  const checkCompanyDetails = () =>{

    if ((props.entrepreunarialExperience.newcompany === "Start a new company" && props.entrepreunarialExperience.companyDetails.establishmentDate === '') || (props.entrepreunarialExperience.ownership === 'Yes' && props.entrepreunarialExperience.companyDetails.country === '') ||
      props.entrepreunarialExperience.companyDetails.provinceState === '' || props.entrepreunarialExperience.companyDetails.city === '' ||
      (props.entrepreunarialExperience.companyDetails.provinceState === 'BC' && 
        (props.entrepreunarialExperience.companyDetails.haveVisitBC === '' || 
          (props.entrepreunarialExperience.companyDetails.haveVisitBC === 'Yes' && props.entrepreunarialExperience.companyDetails.visitList.length === 0)))
    ){
      setErrors(prevErrors =>({
        ...prevErrors,
        companyDetails: `${t('forms.formsquestion.toast.entrepreneurial.companyDetails')}`
      }));
      toast.error(`${t('forms.formsquestion.toast.entrepreneurial.companyDetails')}`);
      return false;
    } else {
        delete errors.companyDetails;
        return true;
    }
  }

  const checkTakeOverBid = () => {
    if (entrepreunarialExperience.newcompany === "Undertake an existing company" && entrepreunarialExperience.takeoverBid === ""){
      setErrors(prevErrors =>({
        ...prevErrors,
        takeoverBid: `${t('forms.formsquestion.toast.entrepreneurial.takeoverBid')}`
      }));
      toast.error(`${t('forms.formsquestion.toast.entrepreneurial.takeoverBid')}`);
    }else {
      delete errors.takeoverBid;
      return true
    }
  }

  const checkForAdding = () =>{
    if (!entrepreunarialExperience.ownership) {
      toast.error(`${t('forms.formsquestion.toast.entrepreneurial.allBlocks')}`);
    } else if (entrepreunarialExperience.ownership === 'No' && entrepreunarialExperience.newcompany !== ""){
      if (entrepreunarialExperience.newcompany === "Start a new company") {
        if (checkCompanyDetails() && checkInnovativeActivities() && checkOwnerShipDetails() && 
          checkCompanyActivites() && checkSupportBy() && 
          checkCompanyFinances() && checkRoleAndExperience()){
            return true
        } else {
          return false
        }
      } else if (entrepreunarialExperience.newcompany === "Undertake an existing company") {
        if (checkTakeOverBid() && checkCompanyDetails() && checkInnovativeActivities() && checkOwnerShipDetails() && 
          checkCompanyActivites() && checkSupportBy() &&  checkCompanyFinances() && checkRoleAndExperience()){
            return true
        } else {
          return false
        }
      }
    } else if (entrepreunarialExperience.ownership === 'Yes') {
      if (checkCompanyDetails() && checkOwnerShipDetails() && checkInnovativeActivities() && 
        checkCompanyActivites() && checkSupportBy() && 
        checkCompanyFinances() && checkRoleAndExperience()){
          return true
      } else {
        return false
      }
    }
  }

  const validateSection = () => {
    if (!checkOtherManagementExperience() && haveBusinesses === ''){
      toast.error(t('forms.formsquestion.toast.entrepreneurial.twoBlocks'));
      return false;
    } else if (checkOtherManagementExperience()) {
      if (haveBusinesses === 'No'){
        toast.success(t('forms.formsquestion.toast.sectionValidate'));
        return true;
      } else if (haveBusinesses === 'Yes') {
        if (allEntrepreneurialExperience.length > 0){
          toast.success(t('forms.formsquestion.toast.sectionValidate'));
          return true;
        } else {
          toast.error(t('forms.formsquestion.toast.entrepreneurial.atLeastOne'));
          return false;
        }
      } else if (haveBusinesses === '') {
        toast.error(t('forms.formsquestion.toast.entrepreneurial.allBlocks'));
        return false;
      }
    }
  };

  const handleAllEntreprenuerialExperience = () => {
    if ((entrepreunarialExperience.ownership === 'Yes' && existAdded) || 
        (entrepreunarialExperience.newcompany === 'Start a new company' && newAdded) || 
        (entrepreunarialExperience.newcompany === 'Undertake an existing company' && undertakeAdded)) {
          toast.error(`${t('forms.formsquestion.toast.entrepreneurial.categoryExist')}`)
    } else {
      
      if (checkForAdding()){
        const entrepreunarialExperienceObject = {
          entrepreunarialExperience,
          ownershipDetail,
          innovativeActivities,
          companyActivites,
          supportby,
          companyFinances,
          roleAndExperience,
          companyDetails
        };
        setAddClicked(false);
        if (currentEntrepreneurialExperience === null) {
          setAllEnterpreneurialExperience([...allEntrepreneurialExperience, entrepreunarialExperienceObject]);
        }else {
          const updateData = [...allEntrepreneurialExperience];
          updateData[currentEntrepreneurialExperience] = entrepreunarialExperienceObject;
          setAllEnterpreneurialExperience(updateData);
        }
        setCurrentEntrepreneurialEperience(null);
        setFormData({
          ownership: '',
          newcompany: '',
          takeoverBid: ''
        });
        setOwnershipDetail({
          ownershipType: '',
          companyName: '',
          ownershipPercentage: '',
          managementExperience: '',
          personalEquityInvestment: '',
          managementStartDate: '',
          managementEndDate: ''
        });
        setCompanyActivites({
          businessSector: '',
          paydayLoans: '',
          explicitContent: '',
          realEstate: ''
        });
        setSupportby({
          receivedServiceOffer: ''
        });
        setInnovativeActivities({
          innovative: ''
        });
        setCompanyFinances({
          companyValue: '',
          startupExpenses: '',
          operatingExpenses: ''
        });
        setRoleAndExperience({
          position: '',
          startDate: '',
          operatingPeriod: ''
        });
        setCompanyDetails({
          establishmentDate: '',
          country: 'CAN',
          provinceState: '',
          city: '',
          streetNumber: '',
          postalCode: '',
          visitList: [],
          visitDate: '',
          visitedCity: '',
          haveVisitBC: ''
        });
      }
    }
  }

  const handleCancelEntrepreneurialExperienceEdit = () => {
    setAddClicked(false);
    setCurrentEntrepreneurialEperience(null);
    setFormData({
      ownership: '',
      newcompany: '',
      takeoverBid: ''
    });
    setOwnershipDetail({
      ownershipType: '',
      companyName: '',
      ownershipPercentage: '',
      managementExperience: '',
      personalEquityInvestment: '',
      managementStartDate: '',
      managementEndDate: ''
    });
    setCompanyActivites({
      businessSector: '',
      paydayLoans: '',
      explicitContent: '',
      realEstate: ''
    });
    setSupportby({
      receivedServiceOffer: ''
    });
    setInnovativeActivities({
      innovative: ''
    });
    setCompanyFinances({
      companyValue: '',
      startupExpenses: '',
      operatingExpenses: ''
    });
    setRoleAndExperience({
      position: '',
      startDate: '',
      operatingPeriod: ''
    });
    setCompanyDetails({
      establishmentDate: '',
      country: 'CAN',
      provinceState: '',
      city: '',
      streetNumber: '',
      postalCode: '',
      visitList: [],
      visitDate: '',
      visitedCity: '',
      haveVisitBC: ''
    });
    delete errors.entrepreunarialExperience;
    delete errors.ownershipDetail;
    delete errors.companyActivites;
    delete errors.supportby;
    delete errors.innovativeActivities;
    delete errors.companyFinances;
    delete errors.roleAndExperience;
    delete errors.companyDetails;
  }

  const handleEditEntrepreneurailExperience = (index) => {

    const updatedFormData = [...allEntrepreneurialExperience];
    const data = updatedFormData[index];
    setCompanyDetails(data.companyDetails);
    setCurrentEntrepreneurialEperience(index);
    setFormData(data.entrepreunarialExperience);
    setOwnershipDetail(data.ownershipDetail);
    setCompanyActivites(data.companyActivites);
    setSupportby(data.supportby);
    setInnovativeActivities(data.innovativeActivities);
    setCompanyFinances(data.companyFinances);
    setRoleAndExperience(data.roleAndExperience);
    setAddClicked(true);
    setErrors({});
  }

  const pressNext = async () => {
    const validation = validateSection();

    await props.onValidate('entrepreunarialExperience', validation, props.actor, true);

    if (validation){
      props.sendNext(props.actor === 'Principal'? "Summary":"Summary Partner", props.actor);
    }
    
  };

  const goBack = ()=> {
    if (props.actor === 'Principal') {
      props.goBack("Job offer", "Principal");
    } else {
      props.goBack("Job offer Partner", "Partner");
    } 
  };

  return (
    <fieldset id="step3">
      <ul style={{ color: 'red' }}>
          {Object.values(errors).map((value, index) => {
              if (value !== '') {
                  return <li key={index}>{value}</li>;
              }
              return null;
          })}
      </ul>
      <ManagementExperienceSection error={errors.otherManagementExperience ? errors.otherManagementExperience : ''}
        sendOtherManagementExperience={receivedOtherManagementExperience} otherManagementExperience={otherManagementExperience}  />
      <div className="form-card">        
        <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
          <p className='text-bold text-center'>{t("forms.title.entrepreneurialexperience")}</p>
          <div className='row p-2'>
            <p className="col-sm-10">{t("forms.formsquestion.entrepreunarialExperience.entrepreneurialIntro")}<RequiredIndicator /></p>
            <div className='col-sm-2'>
              <div className='row'>
                <div className="form-check col">
                  <input 
                    type="radio"
                    className="form-check-input"
                    name="haveBusinesses"
                    value="Yes"
                    onChange={handleHaveBusinessesChange}
                    checked={haveBusinesses === "Yes"}
                  />
                  <p className="form-check-label">{t("forms.click.yes")}</p>
                </div>
                <div className="form-check col">
                  <input 
                    type="radio"
                    className="form-check-input"
                    name="haveBusinesses"
                    value="No"
                    onChange={handleHaveBusinessesChange}
                    checked={haveBusinesses === "No"}
                  />
                  <p className="form-check-label">{t("forms.click.no")}</p>
                </div>
              </div>
              </div>
          </div>
          {haveBusinesses === 'Yes' && (
            <div>
              <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                <div className='row'>
                  <div className='col-sm col-6 mt-2 text-center'>{t("general.table.type")}</div>
                  <div className='col-sm col-6 mt-2 text-center'>{t("general.table.company")}</div>
                  <div className='col-sm col-6 mt-2 text-center'>{t("general.table.ownershippercentage")}</div>
                  <div className='col-sm col-6 mt-2 text-center'>{t("general.table.country")}</div>
                  <div className='col-sm col-6 mt-2 text-center'>{t("general.table.actions")}</div>
                </div>
              </div>
              <div>
                {allEntrepreneurialExperience && allEntrepreneurialExperience.length > 0 ? (
                  allEntrepreneurialExperience.map((entreExperience, index) => (
                    <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px' }} key={index}>
                      <div className='row'>
                        <div className='col-sm col-6 mt-2 text-center'>{entreExperience.entrepreunarialExperience.ownership === 'Yes' ? 'Existing': entreExperience.entrepreunarialExperience.newcompany}</div>
                        <div className='col-sm col-6 mt-2 text-center'>{entreExperience.ownershipDetail.companyName}</div>
                        <div className='col-sm col-6 mt-2 text-center'>{entreExperience.ownershipDetail.ownershipPercentage} %</div>
                        <div className='col-sm col-6 mt-2 text-center'><CountryShow value={entreExperience.companyDetails.country} /></div>
                        <div className='col-sm col-6 mt-2 text-center'>
                          {currentEntrepreneurialExperience !== null ? (
                            <button type='button'
                                className="btn flous-bg-primary mr-1"  disabled
                            >
                              <i className="nav-icon fas fa-edit"></i>
                            </button>
                          ):(
                              <button type='button'
                                  className="btn flous-bg-primary mr-1" 
                                  onClick={() => handleEditEntrepreneurailExperience(index)}
                              >
                                <i className="nav-icon fas fa-edit"></i>
                              </button>
                          )}
                          <button
                            className="btn btn-secondary" type='button'
                            onClick={() => setAllEnterpreneurialExperience(allEntrepreneurialExperience.filter((_, i) => i !== index))}
                          >
                            <i className="nav-icon fas fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='text-center'>{t("general.table.empty.noentrepreneurial")}</div>
                )}
                {!(existAdded && newAdded && undertakeAdded) && (
                  <>
                  {!addClicked &&
                    <div className='mt-3'>
                        <button type='button' className='btn btn-block btn-secondary rounded-pill' onClick={()=>{setAddClicked(true)}}>{t("forms.addMoreButton.addCompany")}</button>
                    </div>
                  }
                  </>
                )}
              </div>
            </div>          
          )}
        </div>
        {addClicked && (
          <div  className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
              <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
                <p className='text-bold text-center'>Existing Company Ownership</p>
                <div className='row p-2'>
                  <p className="col-sm-10">{t("forms.formsquestion.entrepreunarialExperience.ownership")}<RequiredIndicator /></p>
                  <div className='col-sm-2'>
                    <div className='row'>
                      <div className="form-check col">
                        <input 
                          type="radio"
                          className="form-check-input"
                          name="ownership"
                          value="Yes"
                          onChange={handleChange}
                          checked={entrepreunarialExperience.ownership === "Yes"}
                        />
                        <p className="form-check-label">{t("forms.click.yes")}</p>
                      </div>
                      <div className="form-check col">
                        <input 
                          type="radio"
                          className="form-check-input"
                          name="ownership"
                          value="No"
                          onChange={handleChange}
                          checked={entrepreunarialExperience.ownership === "No"}
                        />
                        <p className="form-check-label">{t("forms.click.no")}</p>
                      </div>
                    </div>
                    </div>
                </div>
              </div>
            {entrepreunarialExperience.ownership ==='Yes' && (
                <div>
                  <CompanyDetailsYesSection error={errors.companyDetails ? errors.companyDetails : ''}
                    sendCompanyDetails={receivedCompanyDetails} companyDetails={companyDetails} 
                    showEstablishmentDate={showEstablishmentDate} existingCompany={entrepreunarialExperience.ownership === 'Yes'} />
                  <OwnerShipDetailSection error={errors.ownershipSection ? errors.ownershipSection : ''}
                    titre={'Ownership Details'}
                    sendOwnershipDetail={receivedOwnershipDetail} ownershipDetail={ownershipDetail}  />
                  <InnovativeActivities error={errors.innovativeActivities ? errors.innovativeActivities : ''}
                    evaluationCountry={props.evaluationCountry}
                      sendInnovativeActivities={receivedInnovativeActivities} innovativeActivities={innovativeActivities} />
                  <CompanyActivitesSection error={errors.companyActivites ? errors.companyActivites : ''}
                    sendCompanyActivities={receivedCompanyActivites} companyActivites={companyActivites} />
                  <SupportbySpecializedO error={errors.supportby ? errors.supportby : ''}
                    evaluationCountry={props.evaluationCountry}
                    sendSupportBySpecializedO={receivedSupportby} supportby={supportby}   />
                  <CompanyFinancySection error={errors.operatingExpenses ? errors.operatingExpenses : ''}
                    sendCompanyFinances={receivedCompanyFinances} companyFinances={companyFinances} />
                  <RoleAndExperienceSection error={errors.roleAndExperience ? errors.roleAndExperience : ''}
                    sendRoleAndExperience={receivedRoleAndExperience} roleAndExperience={roleAndExperience} />
                </div>
            )}
            {entrepreunarialExperience.ownership ==='No' && (
            <div>
              <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
              <p className='text-bold text-center'>New Company or Acquisition Ownership</p>
              <div className='p-2'>
                  <p className="col-form-label">{t("forms.formsquestion.entrepreunarialExperience.newcompany")} {props.evaluationCountry}?<RequiredIndicator /></p>
                  
                    <div className="form-check ml-5">
                    <input 
                        type="radio"
                        className="form-check-input"
                        name="newcompany"
                        value="Start a new company"
                        onChange={handleChange}
                        checked={entrepreunarialExperience.newcompany === "Start a new company"}
                    />
                    <label className="form-check-label">{t("forms.formsquestion.entrepreunarialExperience.start")}</label>
                    </div>
                  
                    <div className="form-check ml-5">
                    <input 
                        type="radio"
                        className="form-check-input"
                        name="newcompany"
                        value="Undertake an existing company"
                        onChange={handleChange}
                        checked={entrepreunarialExperience.newcompany === "Undertake an existing company"}
                    />
                    <label className="form-check-label">{t("forms.formsquestion.entrepreunarialExperience.undertake")}</label>
                    </div>
                  
              </div>
              </div>
              
              {entrepreunarialExperience.newcompany !== '' && (
                <div>
                  {entrepreunarialExperience.newcompany === 'Undertake an existing company' && (
                      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
                      <p className='text-bold text-center'>{t("forms.title.takeoverBid")}</p>
                      <div className='row p-2'>
                        <p className="col-sm-10">
                        {t("forms.formsquestion.entrepreunarialExperience.takeoverBid")} {props.evaluationCountry} ?<RequiredIndicator />
                        </p>
                        <div className="col-sm-2">
                          <div className='row'>
                            <div className="col form-check">
                              <input
                                type="radio"
                                className="form-check-input"
                                id="takeoverBidYes"
                                name="takeoverBid"
                                value="Yes"
                                onChange={handleChange}
                                checked={entrepreunarialExperience.takeoverBid === "Yes"}
                              />
                              <label className="form-check-label" htmlFor="takeoverBidYes">{t("forms.click.yes")}</label>
                            </div>
                            <div className="col form-check">
                              <input
                                type="radio"
                                className="form-check-input"
                                id="takeoverBidNo"
                                name="takeoverBid"
                                value="No"
                                onChange={handleChange}
                                checked={entrepreunarialExperience.takeoverBid === "No"}
                              />
                              <label className="form-check-label" htmlFor="takeoverBidNo">{t("forms.click.no")}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <CompanyDetailsYesSection error={errors.companyDetails ? errors.companyDetails : ''}
                    sendCompanyDetails={receivedCompanyDetails} companyDetails={companyDetails} 
                    showEstablishmentDate={showEstablishmentDate} existingCompany={entrepreunarialExperience.ownership === 'Yes'} />
                  <InnovativeActivities error={errors.innovativeActivities ? errors.innovativeActivities : ''}
                    evaluationCountry={props.evaluationCountry}
                      sendInnovativeActivities={receivedInnovativeActivities} innovativeActivities={innovativeActivities} />
                  <OwnerShipDetailSection error={errors.ownershipSection ? errors.ownershipSection : ''}
                    titre={'Ownership Details'}
                    sendOwnershipDetail={receivedOwnershipDetail} ownershipDetail={ownershipDetail}  />
                  <CompanyActivitesSection error={errors.companyActivites ? errors.companyActivites : ''}
                    sendCompanyActivities={receivedCompanyActivites} companyActivites={companyActivites} />
                  <SupportbySpecializedO error={errors.supportby ? errors.supportby : ''}
                    evaluationCountry={props.evaluationCountry}
                    sendSupportBySpecializedO={receivedSupportby} supportby={supportby}   />
                  <CompanyFinancySection error={errors.operatingExpenses ? errors.operatingExpenses : ''}
                    sendCompanyFinances={receivedCompanyFinances} companyFinances={companyFinances} />
                  <RoleAndExperienceSection error={errors.roleAndExperience ? errors.roleAndExperience : ''}
                    sendRoleAndExperience={receivedRoleAndExperience} roleAndExperience={roleAndExperience} />
                </div>
            )}
          </div>
          )}

        <div className='d-flex justify-content-center mb-3'>
          <div className='col-sm-10'>
            <div className='d-flex justify-content-between'>
              <input type="button" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value={`${t("forms.addMoreButton.saveCompany")}`} onClick={handleAllEntreprenuerialExperience}/>
              <input type="button" className="col-6 btn btn-block btn-secondary rounded-pill mx-1" value={`${t("forms.addMoreButton.cancel")}`} onClick={()=>{handleCancelEntrepreneurialExperienceEdit()}}/>
            </div>
          </div>
        </div>
        
      </div>
    )}
            
        <div className='d-flex justify-content-center'>
          <div className='col-sm-10'>
            <div className='d-flex flex-column flex-md-row justify-content-between'>
              <input type="button" name="save" className="col-12 col-md-6 mb-2 mb-md-0 btn flous-btn-thirty rounded-pill mx-1" value={t("forms.click.goBack")} onClick={goBack}/>
              <input type="button" name="next" className="col-12 col-md-6 btn flous-btn-primary rounded-pill mx-1" value={t("forms.click.pressNext")} onClick={pressNext}/>
            </div>
          </div>
        </div>
          
      </div>
      <ToastContainer />
    </fieldset>
  );
};

export default EntrepreunarialExperience;