import React, { useEffect, useState } from 'react';
import CountrySelect from '../../../../general/CountrySelect';
import ProvinceSelect from '../../../../general/ProvinceSelect';
import { countriesWithProvinces, dateFieldNames, maxDate } from '../../../../../services/utils';
import CitySelect from '../../../../general/CitySelect';
import RequiredIndicator from '../../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';

const PreviousAddressSection = (props) => {

  const [livedInDifferentAddress, setLivedInDifferentAddress] = useState(props.livedInDifferentAddress);
  const [formData, setFormData] = useState({
    startDate: '',
    endDate: '',
    country: '',
    provinceState: '',
    city: '',
    streetNumber: '',
    postalCode: '',
  });

  const [errors, setErrors] = useState({})
  const { t } = useTranslation();

  const [previousAddresses, setPreviousAddresses] = useState(props.previousAddresses);
  const [addClicked, setAddClicked] = useState(false);
  const [currentAddress, setCurrentAddress] = useState(null);

  useEffect(()=>{
    props.sendLivedInDifferentAddress(livedInDifferentAddress);
  }, [livedInDifferentAddress, props]);

  useEffect(()=>{
    props.sendPreviousAddresses(previousAddresses);
    setFormData({
      startDate: '',
      endDate: '',
      country: '',
      provinceState: '',
      city: '',
      streetNumber: '',
      postalCode: '',
    });
  }, [previousAddresses]);

  const handleLivedInDifferentAddress = (event) => {
    setLivedInDifferentAddress(event.target.value);
    setErrors({});
  };
  
  const handleChange = (event) => {
    const { name, value } = event.target;
        
    if (dateFieldNames.includes(name) && value > maxDate) return ;

    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({});
  };

  const handleCountryChange = (e) =>{
    const value = e.target.value;
    const updatedFormData = {
      ...formData,
      country: value,
    };
    setFormData(updatedFormData);
    setErrors({});
  };

  const handleProvinceStateChange = (e) =>{
    const value = e.target.value;
    const updatedFormData = {
      ...formData,
      provinceState: value,
    };
    setFormData(updatedFormData);
    setErrors({});
  };

  const handleCityChange = (e) =>{
    const value = e.target.value;
    const updatedFormData = {
      ...formData,
      city: value,
    };
    setFormData(updatedFormData);
    setErrors({});
  }

  const handleAddAddress = () => {
    const {
      startDate,
      endDate,
      country,
      provinceState,
      city,
      streetNumber,
      postalCode
    } = formData;
  
    // Vérification des champs non vides
    if (!startDate || !endDate || !country || !provinceState || !city || !streetNumber) {
      toast.error("All fields must be completed before submitting the address.");
      setErrors(prevErrors =>({
        ...prevErrors,
        addAddressError: "All fields must be completed before submitting the address."
      }));
      return;
    }
    if (currentAddress === null) {
      const updatedFormData = [
        ...previousAddresses,
        {
          startDate: formData.startDate,
          endDate: formData.endDate,
          country: formData.country,
          provinceState: formData.provinceState,
          city: formData.city,
          streetNumber: formData.streetNumber,
          postalCode: formData.postalCode,
        },
      ];
      setPreviousAddresses(updatedFormData);
    }else {
      const updatedFormData = [...previousAddresses];
      updatedFormData[currentAddress] = {
        startDate: formData.startDate,
        endDate: formData.endDate,
        country: formData.country,
        provinceState: formData.provinceState,
        city: formData.city,
        streetNumber: formData.streetNumber,
        postalCode: formData.postalCode,
      };

      setPreviousAddresses(updatedFormData);
    }    
    setErrors({});
    setAddClicked(false);
    setCurrentAddress(null);
  };

  const handleCancelAddAdress = () => {
    setAddClicked(false);
    setCurrentAddress(null);
    setFormData({
      startDate: '',
      endDate: '',
      country: '',
      provinceState: '',
      city: '',
      streetNumber: '',
      postalCode: '',
    })
    delete errors.addAddressError;
  }
  
  const handleEditAdress = (index) => {
    const updatedFormData = [...previousAddresses];
    const address = updatedFormData[index];
    setFormData({
      startDate: address.startDate,
      endDate: address.endDate,
      country: address.country,
      provinceState: address.provinceState,
      city: address.city,
      streetNumber: address.streetNumber,
      postalCode: address.postalCode,
    })
    setAddClicked(true);
    setCurrentAddress(index);
    delete errors.addAddressError;
  }
  

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? `${t('forms.formsquestion.toast.completeSection')}` : ''}</div>
      <p className='text-bold text-center'>{t("forms.title.previousaddress")}</p>
        <div className='row p-2'>
          <p htmlFor="livedInDifferentAddress" className="col-form-label col-sm-10">
            {t("forms.formsquestion.residences.livedInDifferentAddress")} <RequiredIndicator />
          </p>
          <div className="form-check col">
            <input 
              type="radio"
              className="form-check-input" 
              id="livedInDifferentAddressYes"
              name="livedInDifferentAddress"
              value="Yes"
              onChange={handleLivedInDifferentAddress}
              checked={livedInDifferentAddress === "Yes"}
            />
            <p htmlFor="livedInDifferentAddressYes" className="form-check-p">{t("forms.click.yes")}</p>
          </div>
          <div className="form-check col">
            <input 
              type="radio"
              className="form-check-input" 
              id="livedInDifferentAddressNo"
              name="livedInDifferentAddress"
              value="No"
              onChange={handleLivedInDifferentAddress}
              checked={livedInDifferentAddress === "No"}
            />
            <p htmlFor="livedInDifferentAddressNo" className="form-check-p">{t("forms.click.no")}</p>
          </div>
        </div>
        {livedInDifferentAddress === 'Yes' && (
          <div>
            <ul style={{ color: 'red' }}>
              {Object.values(errors).map((value, index) => {
                  if (value !== '') {
                      return <li key={index}>{value}</li>;
                  }
                  return null;
              })}
            </ul> 
            {props.actor !== 'Principal' && (
              <div className='row p-2'>
              <p className='col-sm-10 col-form-label'>{t("forms.formsquestion.residences.liveWithPartner")} <RequiredIndicator /></p>
              <div className='col-sm-2'>
                <div className='row'>
                  <div className="col form-check">
                    <input 
                      type="radio"
                      className="form-check-input rounded-pill"
                      id="isSamepreviousAddressYes"
                      name="isSamepreviousAddress"
                      value="Yes"
                      onChange={props.sendIsSame}
                      checked={props.isSamepreviousAddress === "Yes"}
                    />
                    <label className="form-check-label" htmlFor="isSamepreviousAddressYes">{t("forms.click.yes")}</label>
                  </div>
                  <div className="col form-check">
                    <input 
                      type="radio"
                      className="form-check-input rounded-pill"
                      id="isSamepreviousAddressNo"
                      name="isSamepreviousAddress"
                      value="No"
                      onChange={props.sendIsSame}
                      checked={props.isSamepreviousAddress === "No"}
                    />
                    <label className="form-check-label" htmlFor="isSamepreviousAddressNo">{t("forms.click.no")}</label>
                  </div>
                </div>
              </div>
            </div>
            )}
            
            {(props.actor === 'Principal' || props.isSamepreviousAddress === 'No') && (
              <>
              <div>
                <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                  <div className='row'>
                    <div className='col-sm col-6 mt-2 text-center'>{t("forms.formsquestion.residences.startDate")}</div>
                    <div className='col-sm col-6 mt-2 text-center'>{t("forms.formsquestion.residences.endDate")}</div>
                    <div className='col-sm col-6 mt-2 text-center'>{t("forms.formsquestion.residences.country")}</div>
                    <div className='col-sm col-6 mt-2 text-center'>{t("forms.formsquestion.residences.provinceState")}</div>
                    <div className='col-sm col-6 mt-2 text-center'>{t("forms.formsquestion.residences.city")}</div>
                    <div className='col-sm col-6 mt-2 text-center'>Actions</div>
                  </div>
                </div>
                <div>
                  {previousAddresses && previousAddresses.length > 0 ? (
                    previousAddresses.map((address, index) => (
                      <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px' }}>
                        <div className='row' key={index}>
                          <div className='col-sm col-6 mt-2 text-center'>{address.startDate}</div>
                          <div className='col-sm col-6 mt-2 text-center'>{address.endDate}</div>
                          <div className='col-sm col-6 mt-2 text-center'>{address.country}</div>
                          <div className='col-sm col-6 mt-2 text-center'>{address.provinceState}</div>
                          <div className='col-sm col-6 mt-2 text-center'>{address.city}</div>
                          <div className='col-sm col-6 mt-2 text-center'>
                          <button type='button'
                            className="btn flous-bg-primary mr-1" 
                            onClick={() => handleEditAdress(index)}
                          >
                            <i className="nav-icon fas fa-edit"></i>
                          </button>
                          <button 
                            className="btn btn-secondary" 
                            onClick={() => {
                              setPreviousAddresses(previousAddresses.filter((_, i) => i !== index));
                            }}
                          >
                            <i className="nav-icon fas fa-trash"></i>
                          </button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <>
                      <div className='text-center'>{t("general.table.empty.noElement")}</div>
                    </>
                  )}
                  {!addClicked &&
                        <div className='mt-3'>
                            <button type='button' className='btn btn-block btn-secondary rounded-pill' onClick={()=>{setAddClicked(true)}}>{t("forms.addMoreButton.addPreviousAddress")}</button>
                        </div>
                  }
                </div>
              </div>
              <div className="mt-2">
                {addClicked && (
                  <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
                  <div className='row p-2'>
                    <p htmlFor="startDate" className="col-sm-4 col-form-label">{t("forms.formsquestion.residences.startDate")}<RequiredIndicator /></p>
                    <div className="col-sm-8">
                      <input 
                        type="date"
                        id="startDate"
                        name="startDate"
                        className="form-control rounded-pill flous-input-color"
                        onChange={handleChange}
                        value={formData.startDate} max={new Date().toISOString().split("T")[0]}
                      />
                    </div>
                  </div>
                  <div className='row p-2'>
                    <p htmlFor="endDate" className="col-sm-4 col-form-label">{t("forms.formsquestion.residences.endDate")}<RequiredIndicator />
                      <i className="nav-icon fas fa-info-circle"  data-tooltip-id="tooltipDateOfDeparture" 
                        data-tooltip-content={t('forms.formsquestion.tooltip.residences.dateOfDeparture')}>
                      </i>
                    </p>
                    <div className="col-sm-8">
                      <input 
                        type="date"
                        id="endDate"
                        name="endDate"
                        className="form-control rounded-pill flous-input-color"
                        onChange={handleChange}
                        value={formData.endDate} max={new Date().toISOString().split("T")[0]}
                      />
                    </div>
                    <Tooltip id='tooltipDateOfDeparture' />
                  </div>
                  <div className='row p-2'>
                    <p htmlFor="country" className="col-sm-4 col-form-label">{t("forms.formsquestion.residences.country")}<RequiredIndicator /></p>
                    <div className="col-sm-8">
                      <CountrySelect value={formData.country} onChange={handleCountryChange} required /> 
                    </div>
                  </div>
                  <div className='row p-2'>
                    <p htmlFor="provinceState" className="col-sm-4 col-form-label">{t("forms.formsquestion.residences.provinceState")} <RequiredIndicator /></p>
                    <div className="col-sm-8">
                      {countriesWithProvinces.includes(formData.country) ? 
                      (<ProvinceSelect 
                        value={formData.provinceState} 
                        onChange={handleProvinceStateChange} 
                        country={formData.country} required
                        />
                      ):
                      (<input 
                        type="text" 
                        className="form-control rounded-pill flous-input-color" 
                        id="provinceState" 
                        name="provinceState" 
                        value={formData.provinceState} 
                        onChange={handleChange} 
                        placeholder={`${t('forms.formsquestion.placeholder.province')}`}
                      />
                      )}
                    </div>
                  </div>
                  <div className='row p-2'>
                    <p htmlFor="city" className="col-sm-4 col-form-label">{t("forms.formsquestion.residences.city")}<RequiredIndicator /></p>
                    <div className="col-sm-8">
                      {countriesWithProvinces.includes(formData.country) ? 
                        (<CitySelect 
                          value={formData.city} 
                          onChange={handleCityChange} 
                          province={formData.provinceState} 
                          required
                          />
                        ):
                        (
                          <input 
                          type="text" 
                          className="form-control rounded-pill flous-input-color" 
                          id="city" 
                          name="city" 
                          value={formData.city} 
                          onChange={handleChange} 
                          placeholder={`${t('forms.formsquestion.placeholder.city')}`}
                        />
                        )}
                    </div>
                  </div>
                  <div className='row p-2'>
                    <p htmlFor="streetNumber" className="col-sm-4 col-form-label">{t("forms.formsquestion.residences.streetNumber")}<RequiredIndicator /></p>
                    <div className="col-sm-8">
                      <input 
                        type="text" 
                        id="streetNumber"
                        name="streetNumber"
                        className="form-control rounded-pill flous-input-color" 
                        onChange={handleChange} 
                        value={formData.streetNumber}
                        placeholder="Street number and name"
                      />
                    </div>
                  </div>
                  <div className='row p-2'>
                    <p htmlFor="postalCode" className="col-sm-4 col-form-label">{t("forms.formsquestion.residences.postalCode")}</p>
                    <div className="col-sm-8">
                      <input 
                        type="text" 
                        id="postalCode"
                        name="postalCode"
                        className="form-control rounded-pill flous-input-color"
                        onChange={handleChange} 
                        value={formData.postalCode}
                        placeholder={`${t('forms.formsquestion.placeholder.codePostal')}`}
                      />
                    </div>
                  </div>
                            
                  <div className='d-flex justify-content-center'>
                    <div className='col-sm-10'>
                      <div className='d-flex justify-content-between'>
                        <input type="button" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value={`${t("forms.addMoreButton.saveAddress")}`} onClick={handleAddAddress}/>
                        <input type="button" className="col-6 btn btn-block btn-secondary rounded-pill mx-1" value={`${t("forms.addMoreButton.cancel")}`} onClick={handleCancelAddAdress}/>
                      </div>
                    </div>
                  </div>
                </div>
                )}
              </div>
            
              {/*<button type='button' className='btn btn-block btn-secondary rounded-pill' onClick={handleAddAddress}>Add</button> */}
            </>
          )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PreviousAddressSection;