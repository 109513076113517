import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import LoadingOverlay from '../../general/LoadingOverlay';
import { toast } from 'react-toastify';
import DeletionModal from '../../general/DeletionModal';
import { useTranslation } from 'react-i18next';
import { DeleteScenario } from '../../../services/users/ResultsServices';
import { programsTranslations } from '../../../services/programsTranslation/programs';

const EvaluatedScenarioItem = ({ program, province }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { user } = useOutletContext();
    const { t } = useTranslation();
    const [deletionModalOpen, setDeletionModalOpen] = useState(false);

    const lang = localStorage.getItem('flouslang') || 'en';
    
    const handleDeleteScenario = async (code) =>{
        setLoading(true);
        try{
            const response = await DeleteScenario(code);
            if (response && [200, 201].includes(response.status)) {
                setLoading(false);
                window.location.reload();
            }
        }  catch (error){
            setLoading(false);
            if (error.response && [400, 500].includes(error.response.status)){
                toast.error(error.response.data.error);
            }
        }
    }

    const handleContinue = async () => {
        await handleDeleteScenario(program.code);
    }
  
    const handleCloseDeletionModal = () =>{
      setDeletionModalOpen(false);
    }

    if (loading) {
        return <LoadingOverlay />;
    }

    if (error) {
        return <div>Error : {error.message}</div>;
    }
    
    return (
        <div className='card p-2 m-1' style={{ borderRadius: '15px' }}>
            <div className='row'>
            <div className='col-sm-4 col-12  text-center text-sm-left'>{programsTranslations[province][lang][program.program_abbr]}</div>
            <div className='col  text-center'>{program.score} %</div>
            <div className='col  text-center'>{program.max_points === 0.0 ? '-':program.points}</div>
            <div className='col-sm-2 col-12 text-center'>
                <a href={(user.role === 'Customer' && user.self_analyst) ? `/user/result/detail/${program.code}` : 
                (user.type_package === 'Enterprise'? 
                    (user.role === 'AssociateAdmin' ? `/enterprise/customer/view/result/program/${program.code}` : `/agent/customer/view/result/program/${program.code}`) : 
                    `/professional/customer/view/result/program/${program.code}`)} className="btn flous-bg-menu mr-2">
                    <i className="nav-icon fas fa-eye"></i>
                </a>
                <button type="button" className="btn btn-secondary mr-2"  onClick={()=>setDeletionModalOpen(true)}>
                <i className="nav-icon fas fa-trash"></i>
                </button>
            </div>
            </div>
            <DeletionModal 
                isOpen={deletionModalOpen}
                bodyText={t("modal.deletionModal.scenario")}
                onContinue={handleContinue}
                onClose={handleCloseDeletionModal}
            />
        </div>
    );
}

export default EvaluatedScenarioItem;