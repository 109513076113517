import React, { useEffect, useState } from 'react';
import AgentCustomersItem from './item/AgentCustomersItem';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { AdvisorCustomersNationalities, fetchAdvisorCustomers } from '../../services/agents/CustomersServices';
import { getCurrentItems } from '../../services/users/FormsServices';
import PaginationSection from '../Layout/PaginationSection';
import NoItemsMessage from '../general/NoItemsToDisplaysItem';
import { toast, ToastContainer } from 'react-toastify';
import LoadingOverlay from '../general/LoadingOverlay';
import FlagComponent from '../general/FlagComponent';
import { fetchFilteredCustomers } from '../../services/enterprise/CustomersServices';
import GoToBackButton from '../general/GoBackLink';
import CountrySelect from '../general/CountrySelect';
import CreateNewCustomersModal from './../enterprise/modal/createNewCustomersModal';
import NewClientSuccessModal from '../enterprise/modal/newClientSuccessModal';
import { useTranslation } from 'react-i18next';

export default function AgentCustomers() {

  const {code} = useParams();

  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useOutletContext();
  const { t } = useTranslation();
  const [nationalities, setNationalities] = useState([]);

  const [country, setCountry] = useState('N.A');
  const [field, setField] = useState('N.A');

  const lang = localStorage.getItem('flouslang') || 'en';

  const advisorCode = code ? code:user.code

  const handleCountryChange = (e) =>{
    setCountry(e.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const queryParams = new URLSearchParams({
      country: country || ''
    }).toString();
    filterItems();
    //console.log(queryParams);
  };

  const filterItems = async () => {
    let isMounted = true;
    if (user.id) {
        try {
          const response = await fetchAdvisorCustomers(advisorCode);
          if (isMounted) {
            if (!country) {
              setCustomers(response.data.data);
              setLoading(false);
              return;
            }
            const filteredItems = response.data.data.filter((item) => {
              const matchesCountry = country ? item.country_cca3 === country : true;
              return matchesCountry;
            });
            setCustomers(filteredItems);
            setLoading(false);
          }
        } catch (error) {
          // console.error('Error when preparing data:', error);
          if (isMounted){
            setError(error);
            setLoading(false);
            throw error;
          }
        }
      }
  };

  useEffect( () => {
    let isMounted = true;

    const getCustomersDataList = async () => {
      if (user.id) {
        try {
          const response = await fetchAdvisorCustomers(advisorCode);
          if (isMounted) {
            if (response && response.status === 200){
              setCustomers(response.data.data);
              setLoading(false);
              isMounted = false;
            }
          }
        } catch (error) {
          if (isMounted){
            setLoading(false);
            if (error.response && [400, 500].includes(error.response.status)){
              setError(error.response.data.error);
              toast.error(error.response.data.error);
              isMounted = false;
            }
          }
        }
      }
    };

    getCustomersDataList();

    return () => {
      isMounted = false;
    };

  }, []);
  
  useEffect( () => {
    let isMounted = true;

    const getCustomersNationalities = async () => {
      if (user.id) {
        try {
          const response = await AdvisorCustomersNationalities(advisorCode);
          if (isMounted) {
            if (response && response.status === 200){
              const nationalities = response.data.data.map(nationality => ({
                value: nationality.country_code,
                label: <FlagComponent cca3={nationality.country_code} />
              }))
              setNationalities(nationalities);
              setLoading(false);
              isMounted = false;
            }
          }
        } catch (error) {
          if (isMounted){
            setError(error.response.data.error);
            setLoading(false);
            if (error.response && [400, 500].includes(error.response.status)){
              toast.error(error.response.data.error);
              isMounted = false;
            }
          }
        }
      }
    };

    getCustomersNationalities();

    return () => {
      isMounted = false;
    };

  }, []);

  useEffect(()=>{
    const shouldReload = sessionStorage.getItem('reloadAfterNavigate');

    if (shouldReload === 'true') {
        sessionStorage.removeItem('reloadAfterNavigate');
        window.location.reload();
    }
  }, [])

  const handleFilteredCustomers = async () => {
    try {
      const response = await fetchFilteredCustomers(advisorCode, country);
      if (response && response.status===200) {
        setCustomers(response.data.data);
        setLoading(false);
      }
    }
    catch (error) {
      if (error.response && [400, 500].includes(error.response.status)){
        setError(error.response.data.error);
        toast.error(error.response.data.error);
      }
    }
  }

  const [page, setPage] = useState(1);
  const pageSize = 5;
  const onPageChange = (page) => {
    setPage(page + 1);
  };
  
  const currentItems = getCurrentItems(customers, page, pageSize);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }

  return (
    <>
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="mb-2">
            <GoToBackButton />
            <div className="col-sm-6">
              <h3 className="m-0 flous-police-one flous-input-color">{code ? `Advisor ${code} / ${t("advisor.customers.title")}` : `${lang === 'fr'? 'Mes':(lang==='es'? 'Mis':'My')} ${t("advisor.customers.title")}` }</h3>
            </div>
          </div>
        </div>
      </div>
      {/* /.content-header */}
      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col">
      
            <div className="row mb-2">
              <button type="button" className="btn btn-lg flous-bg-primary mx-2 col-lg-6 text-bold" data-toggle="modal" data-target="#newCustomers">
              {t("advisor.customers.create")} <i className="nav-icon fas fa-user"></i>
              </button>
              <CreateNewCustomersModal />
              
            </div>

            <div className="card elevation-2 mt-2" style={{ borderRadius: '15px' }}>
                <div className="card-header shadow">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col">
                      <div className="row">
                        <div className="col-sm col-6">
                          <CountrySelect
                            required
                            onChange={handleCountryChange}
                          />
                        </div>
                        <div className="col-sm-2 col-6">
                          <button type="submit" className="btn flous-input-color">
                            <i className="nav-icon fas fa-search"></i> {t("general.table.search")}
                          </button>
                        </div>
                        <div className="col-sm-1 col-6 btn flous-input-color">
                          <i className="nav-icon fas fa-sort"></i> {t("general.table.sort")}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                </div>
                <div className="card-body">
                  <div className='flous-bg-thirty m-2' style={{ borderRadius: '15px' }}>
                      <div className='card-header p-3 mb-3 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                          <div className='row text-bold'>
                              <div className='col-sm-3 col-6 mt-2'>{t("general.table.nationality")}</div>
                              <div className='col-sm-2 col-6 mt-2 text-center'>{t("general.table.firstname")}</div>
                              <div className='col-sm-2 col-6 mt-2 text-center'>{t("general.table.lastname")}</div>
                              <div className='col-sm-2 col-6 mt-2 text-center'>{t("general.table.forms")}</div>
                              <div className='col-sm-3 col-6 mt-2 text-center'>{t("general.table.actions")}</div>
                          </div>
                      </div>
                      {currentItems.length > 0 ? (
                        currentItems.map((customer) => (
                            <AgentCustomersItem 
                              key={customer.code} 
                              customer={customer} 
                              advisorCode={advisorCode}
                            />
                        ))
                      ) : (
                        <NoItemsMessage />
                      )}
                  </div>
                </div>
                <PaginationSection
                    page={page}
                    pageSize={pageSize}
                    totalItems={customers.length}
                    onPageChange={onPageChange}
                  />
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
      {/* /.content */}
    </>
  );
};
