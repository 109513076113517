import React from "react";
import { useTranslation } from "react-i18next";

const PausedProgramModal = ({isOpen, onContinue}) => {

  const { t } = useTranslation();
  if (!isOpen) {
    return null;
  }

  return (
    <>
    {isOpen && (
      <div className="modal-backdrop fade show"></div>
    )}
    <div id="pausedProgram" className="modal fade show" style={{ display: "block" }} role="dialog">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body text-center">
            <div className="d-flex align-items-center justify-content-center w-100 my-3">
              <img
                src={`${process.env.PUBLIC_URL}/modals/paused_program.png`}
                alt="Flous Logo"
                className="brand-image text-center"
                style={{ height: "150px", width: "150px" }}
              />
            </div>
            <h4 className="flous-police-one flous-input-color" id="modalTitle">
              {t("modal.paused.title")}
            </h4>
            <div className="mb-3 flous-police-one">{t("modal.paused.message")}</div>
            
            <div className="col">
              <button className="btn flous-bg-primary rounded-pill px-5" onClick={onContinue}>
              {t("modal.paused.submit")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default PausedProgramModal;