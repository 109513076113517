import React, { useEffect, useState } from 'react';
import { sortDictionaryByValues } from '../../../../../services/utils';
import api from '../../../../../services/api';

import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

export default function ProfessionalDetailsResumeSection(props) {
  const { t } = useTranslation();

  const [isAdvisor, setIsAdvisor] = useState(false);

  const { user } = useOutletContext();
  // Define the professional details information
  const professionalDetails = {
    professionalField: props.professionalDetail.professionalField, // 
    hasAuthorization: props.professionalDetail.authorizationToPractice, // 
    inProcessOfObtainingAuthorization: props.professionalDetail.processOfObtainingAuthorization, // 
    hasDiploma: props.professionalDetail.diplomaAuthorization // 
  };

  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchJob = async () =>{
        const response = await api.get('/professions/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setJobs(options);
    };
    fetchJob();

    setIsAdvisor(user.role !== 'Customer');

  }, []);

  const getOptions = (data) => {
    return Object.entries(data).map(([key, val]) => ({
      value: key,
      label: val,
    }));
  };

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>{t("forms.title.professionaldetail")}</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputProfessionalField" className="col-sm-6 col-form-label">{t("forms.formsquestion.jobOffer.professionalField")}</label>
          <div className="col-sm-6">
            {isAdvisor? (
                <input type="text" className="form-control" id="inputCompanyDescription" 
                  value={`${professionalDetails.professionalField} - ${jobs.find((option) => option.value === professionalDetails.professionalField)?.label}`} 
                  placeholder="N.A"
                />
              ):(
                <input type="text" className="form-control" id="inputCompanyDescription" value={jobs.find((option) => option.value === professionalDetails.professionalField)?.label} placeholder="N.A"/>
              )}
          </div>
        </div>
        {props.professionalDetail.isRegulated && (
          <div className="form-group row">
            <label htmlFor="inputHasAuthorization" className="col-sm-6 col-form-label">{t("forms.formsquestion.jobOffer.authorizationToPractice")}</label>
            <div className="col-sm-6">
              <input type="text" className="form-control" id="inputHasAuthorization" value={professionalDetails.hasAuthorization === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
            </div>
          </div>
        )}
        {props.professionalDetail.isRegulated && props.professionalDetail.authorizationToPractice === 'No' && (
        <div className="form-group row">
          <label htmlFor="inputInProcessOfObtainingAuthorization" className="col-sm-6 col-form-label">{t("forms.formsquestion.jobOffer.processOfObtainingAuthorization")}</label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputInProcessOfObtainingAuthorization" value={professionalDetails.inProcessOfObtainingAuthorization === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
          </div>
        </div>
        )}
        {props.professionalDetail.isRegulated && props.professionalDetail.processOfObtainingAuthorization ==='No' && (
        <div className="form-group row">
          <label htmlFor="inputHasDiploma" className="col-sm-6 col-form-label">{t("forms.formsquestion.jobOffer.diplomaAuthorization")}</label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputHasDiploma" value={professionalDetails.hasDiploma === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
          </div>
        </div>
        )}

        <div className="form-group row">
          <label htmlFor="jobInAISector" className="col-sm-3 col-form-label">{t("forms.formsquestion.jobOffer.jobInAISector")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="jobInAISector" value={props.professionalDetail.jobInAISector === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
          </div>
          <label htmlFor="inputpaydayLoans" className="col-sm-3 col-form-label">{t("forms.formsquestion.professionalExperience.paydayLoans")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputpaydayLoans" value={props.professionalDetail.paydayLoans === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputpaydayLoans" className="col-sm-3 col-form-label">{t("forms.formsquestion.professionalExperience.realEstate")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputpaydayLoans" value={props.professionalDetail.realEstate === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
          </div>
          <label htmlFor="inputexplicitContent" className="col-sm-3 col-form-label">{t("forms.formsquestion.professionalExperience.explicitContent")}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputexplicitContent" value={props.professionalDetail.explicitContent === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`} placeholder="N.A"/>
          </div>
        </div>
      </div>
    </div>
  );
}
