import React from "react";
import { useTranslation } from "react-i18next";

const CompletionFormModal = ({isOpen, onContinue, onCancel}) => {
  const { t } = useTranslation();

  if (!isOpen) {
    return null;
  }

  return (
    <>
    {isOpen && (
      <div className="modal-backdrop fade show"></div>
    )}
    <div 
      id="completionform" 
      className="modal fade show" 
      style={{ display: "block" }} 
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body text-center">
            <div className="d-flex align-items-center justify-content-center w-100 my-3">
              <img
                src={`${process.env.PUBLIC_URL}/flous/stripe/form_submitted.png`}
                alt="Flous Logo"
                className="brand-image text-center"
                style={{ height: "150px", width: "150px" }}
              />
            </div>
            <h4 className="flous-police-one flous-input-color" id="modalTitle">
            {t("modal.completed.title")}
            </h4>
            <div className="text-bold mb-3">
            {t("modal.completed.message")}
            </div>
            <div className="col">
              <button className="btn flous-bg-primary rounded-pill px-5" onClick={onContinue}>
              {t("modal.completed.submit")}
              </button>
            </div>
            <div className="modal-footer d-flex justify-content-center mt-3">
                <button type="button" className="btn btn-lg flous-input-color flous-bg-thirty rounded-pill px-5" data-dismiss="modal" onClick={onCancel}>{t("modal.cancel")}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default CompletionFormModal;