import React from "react";
import { useTranslation } from "react-i18next";

const DeletionModal = ({ isOpen, bodyText, onContinue, onClose}) => {

  const { t } = useTranslation();

  if(!isOpen){
    return null;
  }
  
  return (
    <>
    <div className="modal-backdrop fade show"></div>
    <div id="deleteModalAction" className={`modal fade ${isOpen ? "show" : ""}`} style={{ display: "block" }} role="dialog">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-body text-center">
                {bodyText}
            </div>
            <div className="modal-footer d-flex justify-content-center">
              <button type="button" className="btn btn-secondary rounded-pill w-25" data-dismiss="modal" 
                onClick={onClose}
              >
                {t("modal.cancel")}
              </button>
              <button className="btn flous-bg-primary rounded-pill w-25" onClick={onContinue}>
                {t("modal.continue")}
              </button>
            </div>
          </div>
        </div>
    </div>
    </>
  );
};

export default DeletionModal;
