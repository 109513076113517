import React, { useEffect, useState } from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import CountryShow from '../../../general/countryShow';
import api from '../../../../services/api';
import styles from '../styles/pdfstyles';
import { getOptions, sortDictionaryByValues } from '../../../../services/utils';

export default function JobOfferResumeSectionPDF(props) {
  const { t } = useTranslation();
  const [provinces, setProvinces] = useState([]);

  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchJob = async () =>{
        const response = await api.get('/professions/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setJobs(options);
    };
    fetchJob();

  }, []);

  useEffect(() => {
      const fetchProvinces = async() => {
        const response = await api.get('/canada-provinces/');
          const provinceOptions = Object.entries(response.data).map(([key, val]) => ({
            value: key,
            label: val,
          }));
          setProvinces(provinceOptions);
      };
  
      fetchProvinces();
  }, []);

  const renderCompanyAddress = () => (
    <View style={styles.card}>
      <Text style={styles.cardTitle}>{t("forms.title.companyaddress")}</Text>
      <View style={styles.row}>
        <View style={styles.column70}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.jobOffer")} {t('countries.canada')}?</Text>
        </View>
        <View style={styles.column30}>
          <Text style={styles.value}>
            {props.jobOffer.jobOffer === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
          </Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.provinceState")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>
              {provinces.find(province => province.value === props.jobOffer.companyDetail.provinceState)?.label || ''}
          </Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.city")}</Text>
        </View>
        <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.jobOffer.companyDetail.city}</Text>
        </View>
      </View>
      
      
      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.streetNumber")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{props.jobOffer.companyDetail.streetNumber}</Text>
        </View>

        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.postalCode")}</Text>
        </View>
        <View style={styles.valueColumn}>
          <Text style={styles.value}>{props.jobOffer.companyDetail.postalCode}</Text>
        </View>
      </View>
    </View>
  );

  const renderJobDetail = () => (
    <View style={styles.card}>
      <Text style={styles.cardTitle}>{t("forms.title.jobdetail")}</Text>
      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.dateOfIssued")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.jobOffer.jobDetail.dateOfIssued}</Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.currentJob")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>
            {props.jobOffer.jobDetail.currentJob === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
          </Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.lmiaExempt")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>
            {props.jobOffer.jobDetail.lmiaExempt === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
          </Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.provinceValidated")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>
            {props.jobOffer.jobDetail.provinceValidated === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
          </Text>
        </View>
      </View>
    </View>
  );

  const renderProfessionalDetail = () => (
    <View style={styles.card}>
      <Text style={styles.cardTitle}>{t("forms.title.professionaldetail")}</Text>
      <View style={styles.row}>
        <View style={styles.column30}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.professionalField")}</Text>
          </View>
          <View style={styles.column70}>
            <Text style={styles.value}>
            {props.jobOffer.professionalDetail.professionalField} - {jobs.find((option) => option.value === props.jobOffer.professionalDetail.professionalField)?.label}
          </Text>
        </View>
      </View>
      {props.jobOffer.professionalDetail.isRegulated && (
        <View style={styles.row}>
          <View style={styles.column70}>
            <Text style={styles.label}>{t("forms.formsquestion.jobOffer.authorizationToPractice")}</Text>
            </View>
          <View style={styles.column30}>
            <Text style={styles.value}>
              {props.jobOffer.professionalDetail.authorizationToPractice === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
            </Text>
          </View>
        </View>
      )}
      {props.jobOffer.professionalDetail.isRegulated && props.jobOffer.professionalDetail.authorizationToPractice === 'No' && (
        <View style={styles.row}>
          <View style={styles.column70}>
            <Text style={styles.label}>{t("forms.formsquestion.jobOffer.processOfObtainingAuthorization")}</Text>
            </View>
          <View style={styles.column30}>
            <Text style={styles.value}>
              {props.jobOffer.professionalDetail.processOfObtainingAuthorization === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
            </Text>
          </View>
        </View>
      )}
      {props.jobOffer.professionalDetail.isRegulated && props.jobOffer.professionalDetail.processOfObtainingAuthorization === 'No' && (
        <View style={styles.row}>
          <View style={styles.column70}>
            <Text style={styles.label}>{t("forms.formsquestion.jobOffer.diplomaAuthorization")}</Text>
            </View>
          <View style={styles.column30}>
            <Text style={styles.value}>
              {props.jobOffer.professionalDetail.diplomaAuthorization === 'Yes' ? t('forms.click.yes') : t('forms.click.no')}
            </Text>
          </View>
        </View>
      )}
      <View style={styles.row}>
        <View style={styles.column70}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.jobInAISector")}</Text>
        </View>
        <View style={styles.column30}>
          <Text style={styles.value}>
            {props.jobOffer.professionalDetail.jobInAISector === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`}
          </Text>
        </View>
      </View>
      
      <View style={styles.row}>
        <View style={styles.column70}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.paydayLoans")}</Text>
        </View>
        <View style={styles.column30}>
          <Text style={styles.value}>
            {props.jobOffer.professionalDetail.paydayLoans === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`}
          </Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.column70}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.realEstate")}</Text>
          </View>
          <View style={styles.column30}>
            <Text style={styles.value}>
            {props.jobOffer.professionalDetail.realEstate === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`}
            </Text>
        </View>
      </View>
      
      <View style={styles.row}>
        <View style={styles.column70}>
          <Text style={styles.label}>{t("forms.formsquestion.professionalExperience.explicitContent")}</Text>
          </View>
          <View style={styles.column30}>
            <Text style={styles.value}>
              {props.jobOffer.professionalDetail.explicitContent === 'Yes' ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`}
            </Text>
        </View>
      </View>
    </View>
  );

  const renderCompanyInfo = () => (
    <View style={styles.card}>
      <Text style={styles.cardTitle}>{t("forms.title.company")}</Text>
      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.hourlyRate")}</Text>
        </View>
        <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.jobOffer.jobCompensation.hourlyRate}</Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.annualSalary")}</Text>
        </View>
        <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.jobOffer.jobCompensation.annualSalary}</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.companyName")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.jobOffer.companyInfo.companyName}</Text>
        </View>
        <View style={styles.labelColumn}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.primaryBusinessActivities")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.jobOffer.companyInfo.primaryBusinessActivities}</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.column70}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.annualGrossRevenue")}</Text>
          </View>
          <View style={styles.column30}>
            <Text style={styles.value}>{props.jobOffer.companyInfo.annualGrossRevenue}</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.column70}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.foundingDate")}</Text>
          </View>
          <View style={styles.column30}>
            <Text style={styles.value}>{props.jobOffer.companyInfo.foundingDate}</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.column50}>
          <Text style={styles.label}>{t("forms.formsquestion.jobOffer.numberOfEmployed")}</Text>
          </View>
          <View style={styles.column50}>
            <Text style={styles.value}>{props.jobOffer.companyInfo.numberOfEmployed}</Text>
        </View>
      </View>
    </View>
  );

  return (
    <Page style={styles.page}>
      <Text style={styles.mainTitle}>{t("client.results.blockTitle.bloc8")}</Text>
      {props.jobOffer.jobOffer === 'Yes' ? (
        <>
          {renderCompanyAddress()}
          {renderJobDetail()}
          {renderProfessionalDetail()}
          {renderCompanyInfo()}
        </>
      ) : (
        <View style={styles.card}>
          <Text style={styles.cardTitle}>{t("general.table.applicantEmpty.nojob")}</Text>
        </View>
      )}
    </Page>
  );
}

