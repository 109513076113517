import React, { useEffect, useState } from 'react';
import IdentificationSection from './section_personal_infomation/IdentificationSection';
import PersonalDetailSection from './section_personal_infomation/PersonnalDetailSection';
import PreferredLanguage from './section_personal_infomation/PreferredLanguageSection';
import ContactInfo from './section_personal_infomation/ContactInfoSection';
import AddInfoSection from './section_personal_infomation/AddInfoSection';
import { toast } from 'react-toastify';
import Select from 'react-select';
import RequiredIndicator from '../../../general/requiredIndicator';

import { useTranslation } from "react-i18next";

const PersonalInformation = (props) => {
  const [evaluationCountry, setEvaluationCountry] = useState(props.evaluationCountry);
  const [formTitle, setFormTitle] = useState(props.formTitle);
  const [identification, setIdentification] = useState(props.personalInformation.identification);
  const [personalDetail, setPersonalDetail] = useState(props.personalInformation.personalDetail);
  const [preferredLanguage, setPreferredLanguage] = useState(props.personalInformation.preferredLanguage);
  const [contactInfo, setContactInfo] = useState(props.personalInformation.contactInfo);
  const [addInfo, setAddInfo] = useState(props.personalInformation.addInfo);
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  const countryOptions = [
    { value: '', label: t('forms.formsquestion.placeholder.option') },
    { value: 'Canada', label: t('countries.canada') },
    // { value: 'New Zealand', label: 'New Zealand' },
    // { value: 'Australia', label: 'Australia' }
  ];

  useEffect(()=>{
    props.sendFormTitle(formTitle);
    props.sendevaluationCountry(evaluationCountry);
    props.onValidate('personalInformation', false, props.actor, false);
  }, [evaluationCountry, formTitle, props]);
  
  useEffect(() => {
    const personalInformation = {
      identification,
      personalDetail,
      preferredLanguage,
      contactInfo,
      addInfo,
    }
    props.sendPersonalInformation(personalInformation); 
  }, [identification, personalDetail, preferredLanguage, contactInfo, addInfo, props]);

  const handleCountryChange = (event) => {
    const {value} = event.target
    setEvaluationCountry(value)
    setErrors({});
  };

  const handleFormTitleChange = (event) => {
    const {value} = event.target
    setFormTitle(value);
    setErrors({});
  };


  const checkIdentification = () =>{
    if (identification.firstname === '' || identification.lastname === '' ||
       identification.dateofbirth === '' || identification.countryofbirth === '' || identification.cityofbirth === '') {
        setErrors(prevErrors =>({
          ...prevErrors,
          identification: `${t('forms.formsquestion.toast.personalInformation.identification')}`
        }));
        toast.error(`${t('forms.formsquestion.toast.personalInformation.identification')}`);
        return false;
       }else {
        delete errors.identification;
        return true;
       }
  }

  const checkPersonalDetail = () =>{
    if (personalDetail.dependantChildren === '' || personalDetail.genderIdentity === ''|| personalDetail.nationality === '' ||
        (props.actor === 'Principal' && personalDetail.maritalStatus === '')
      ) {
        toast.error(`${t('forms.formsquestion.toast.personalInformation.personalDetail')}`);
        setErrors(prevErrors =>({
          ...prevErrors,
          personalDetail: `${t('forms.formsquestion.toast.personalInformation.personalDetail')}`
        }));
        return false;
    } else {
      delete errors.personalDetail;
      return true;
    }
  }

  const checkContactInfo = () =>{
    if (contactInfo.email === '' || contactInfo.phone === '') {
        toast.error(`${t('forms.formsquestion.toast.personalInformation.contactInfo')}`);
        setErrors(prevErrors =>({
          ...prevErrors,
          contactInfo: `${t('forms.formsquestion.toast.personalInformation.contactInfo')}`
        }));
        return false;
       }else {
        delete errors.contactInfo;
        return true;
       }
  }

  const checkAddInfo = () =>{
    if (addInfo.netWorth === '' || addInfo.provincialNomination === '' || addInfo.earningsHistory === '') {
        toast.error(`${t('forms.formsquestion.toast.personalInformation.addInfo')}`);
        setErrors(prevErrors =>({
          ...prevErrors,
          addInfo: `${t('forms.formsquestion.toast.personalInformation.addInfo')}`
        }));
        return false;
       }else {
        delete errors.addInfo;
        return true;
       }
  }

  const checkPreferredLanguage = () =>{
    if (preferredLanguage === '') {
        toast.error(`${t('forms.formsquestion.toast.personalInformation.preferredLanguage')}`);
        setErrors(prevErrors =>({
          ...prevErrors,
          preferredLanguage: `${t('forms.formsquestion.toast.personalInformation.preferredLanguage')}`
        }));
        return false;
       }else {
        delete errors.preferredLanguage;
        return true;
       }
  }

  const validateSection = () => {

    props.onValidate('personalInformation', false, props.actor, false);

    if ((props.actor === 'Partner' && 
        (checkIdentification() && checkPersonalDetail() && checkContactInfo())) || 
      (props.actor === 'Principal' && 
        (checkIdentification() && checkPersonalDetail() &&
        checkPreferredLanguage() && checkContactInfo() && checkAddInfo()
      ))){
        toast.success(`${t('forms.formsquestion.toast.sectionValidate')}`);
        setErrors({});
        return true;
    } else {
      return false;
    }
    
  };

  const receivedIdentification = (data) => {setIdentification(data);};
  const receivedPersonalDetail = (data) => {setPersonalDetail(data);};
  const receivedPreferredLanguage = (data) => {setPreferredLanguage(data);};
  const receivedContactInfo = (data) => {setContactInfo(data);};
  const receivedAddInfo = (data) => {setAddInfo(data);};

  const pressNext = async ()=> {

    const validation = validateSection();

    await props.onValidate('personalInformation', validation, props.actor, true);

    if (validation) {
      props.sendNext(props.actor === 'Principal'? "Family Members":"Family Members Partner", props.actor);
    }
    
  };

  const goBack = ()=> {
    if (props.actor === 'Partner') {
      props.goBack("Entrepreneurial Experience", "Principal");
    }
  };

  return (
    <fieldset id="step1">
      <ul style={{ color: 'red' }}>
        {Object.values(errors).map((value, index) => {
            if (value !== '') {
                return <li key={index}>{value}</li>;
            }
            return null;
        })}
      </ul>
      <div className="form-card">
        {props.actor === 'Principal' && (
          <>
            <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
            <p>{t("forms.formsquestion.personalInfo.country")}<RequiredIndicator /></p>
            <Select
              id="country"
              name="evaluation_country"
              classNamePrefix="react-select"
              className="react-select-container rounded-pill flous-input-color"
              value={countryOptions.find(option => option.value === evaluationCountry)}
              onChange={selectedOption => handleCountryChange({ target: { name: 'evaluation_country', value: selectedOption? selectedOption.value: ''} })}
              options={countryOptions}
              placeholder={`${t('forms.formsquestion.placeholder.option')}`}
              isSearchable
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                  borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                  borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                }),
              }}
            />
          </div>
          
          {props.evaluationCountry !== '' && (
            <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
              <p>{t("forms.formsquestion.personalInfo.title")}<RequiredIndicator /></p>
              <input 
                type="text" 
                className="form-control rounded-pill flous-input-color" 
                id="formTitle" 
                name="formTitle" 
                placeholder={`${t('forms.formsquestion.placeholder.formTitle')}`}
                value={props.formTitle}
                onChange={handleFormTitleChange}
              />
            </div>
          )}
        </>
        )}
        {props.evaluationCountry === '' ? <div className='h1'>Please select an option</div> :
            <div>
                < IdentificationSection error={errors.identification ? errors.identification : ''}
                sendIdentification={receivedIdentification} 
                identification={identification} actor={props.actor} 
                formCode={props.formCode}
                />
                < PersonalDetailSection  error={errors.personalDetail ? errors.personalDetail : ''}
                sendPersonalDetail={receivedPersonalDetail} 
                personalDetail={personalDetail} actor={props.actor} 
                />
                < ContactInfo  error={errors.contactInfo ? errors.contactInfo : ''}
                  sendContactInfo={receivedContactInfo} 
                  contactInfo={contactInfo} actor={props.actor}
                  formCode={props.formCode}
                />
                {props.actor === 'Principal' && (
                  <>
                  < PreferredLanguage  error={errors.preferredLanguage ? errors.preferredLanguage : ''}
                  sendPreferredLanguage={receivedPreferredLanguage} 
                  preferredLanguage={preferredLanguage} 
                  />
                  < AddInfoSection  error={errors.addInfo ? errors.addInfo : ''}
                  evaluationCountry={ props.evaluationCountry } 
                  sendAddInfo={receivedAddInfo} addInfo={addInfo}
                  />
                  </>
                )}
                <div className='d-flex justify-content-center'>
                  <div className='col-sm-10'>
                    <div className='d-flex flex-column flex-md-row justify-content-between'>
                      {props.actor === 'Principal' ? (
                        <input type="button" name="next" className="col-12 col-md-12 btn flous-btn-primary rounded-pill mx-1" value={t("forms.click.pressNext")} onClick={pressNext}/>
                      ):(
                        <>
                          <input type="button" name="save" className="col-12 col-md-6 mb-2 mb-md-0 btn flous-btn-thirty rounded-pill mx-1" value={t("forms.click.goBack")} onClick={goBack}/>
                          <input type="button" name="next" className="col-12 col-md-6 btn flous-btn-primary rounded-pill mx-1" value={t("forms.click.pressNext")} onClick={pressNext}/>
                        </>
                      )}
                      
                    </div>
                  </div>
                </div>
                
            </div>

         }
        
      </div>
    </fieldset>
  );
};

export default PersonalInformation;
