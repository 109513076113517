import React, { useState } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import PaymentSection from '../profile/paymentSection';
import SettingsSection from '../profile/settingsSection';
import EnterpriseProfileSection from '../profile/enterpriseProfileSection';
import EnterpriseAccountSection from '../profile/enterpriseAccountSection';
import EnterprisePricingSection from '../profile/enterprisePricingSection';
import ChangeProfilePictureModal from '../client/Modals/profilePictureModal';
import { DeleteProfilePicture, UpdateProfilePicture } from '../../services/general/ProfileServices';
import { BASE_URL } from '../../services/constants';
import GoToBackButton from '../general/GoBackLink';
import LoadingOverlay from '../general/LoadingOverlay';
import { toast } from 'react-toastify';
import DeleteProfilePictureModal from '../client/Modals/deletePictureModal';
import ScrollingMessage from '../../contexts/ScrollingMessage';
import { useTranslation } from "react-i18next";

export default function EnterpriseProfile() {

  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useOutletContext();
  console.log(user);

  const [activeBtn, setActiveBtn] = useState('Account');
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  const handleBtnClick = (section) => {
    setActiveBtn(section);
  };

  const renderSection = () => {
    switch (activeBtn) {
      case 'Account' :
        return <EnterpriseAccountSection user={user}/>
      case 'Profile' :
        return <EnterpriseProfileSection/>     
      case 'Pricing' :
        return <EnterprisePricingSection/>
      case 'Settings' :
        return <SettingsSection/>;
      case 'Payment' :
        return <PaymentSection/>;
      default:
        return;
    }
  }

  const handleFileUpload = (file) => {
    setSelectedFile(file);
  };

  const handleEditProfilePicture = () =>{
    setIsOpen(true);
  }

  const handleSavePicture = async () => {
    // Operation to save picture
    setLoading(true);
    if (selectedFile) {
      try{
        const formData = new FormData();
        formData.append('profile_picture', selectedFile);

        const response = await UpdateProfilePicture(formData);
        if (response && [200, 201].includes(response.status)){
          setLoading(false);
          setIsOpen(false);
          window.location.reload();
        }

      }  catch (error){
        setLoading(false);
        setError(error);
        if (error.response && [400, 500].includes(error.response.status)){
          toast.error(error.response.data.error);
        }
      }
    } else {
      setLoading(false);
      return;
    }
    
    
  }

  const handleDeletePicture = async () => {
    // Operation to save picture
    setLoading(true);
    try{
      const response = await DeleteProfilePicture();
      setLoading(false);
      setIsOpen(false);
      window.location.reload();

    }  catch (error){
      setLoading(false);
      if (error.response && [400, 500].includes(error.response.status)){
        toast.error(error.response.data.error);
      }
    }
  }

  const handleClose = () =>{
    setIsOpen(false);
  }

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }

  return (
    <>
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="mb-2">
            <GoToBackButton />
            <div className="col-sm-6">
              <h4 className="flous-police-one flous-input-color m-0">{t("general.profile.title")}</h4>
            </div>
            {!user.active_subscription && (
              <>
                <ScrollingMessage 
                message={t('modal.paiement.scrolling.noSubscription')} 
                duration={50} 
                repeat={5} />
              </>
            )}
          </div>
        </div>
      </div>
      {/* /.content-header */}

      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              {/* Profile Image */}
              <div className="card card-primary elevation-2" style={{ borderRadius: '15px' }}>
                <div className="card-body box-profile">
                  <div className="d-flex justify-content-center">
                    <div className="image">
                      {user.profile_picture_url ? (
                        <img src={user.profile_picture_url} className="border rounded-circle" alt="User Profile" style={{ width: '200px', height: '200px', objectFit: 'cover' }}/>
                      ):(
                        <img src={`${process.env.PUBLIC_URL}/avatar/avatar.png`} className="border rounded-circle" alt="User Profile" style={{ width: '200px', height: '200px', objectFit: 'cover' }}/>
                      )}
                    </div>
                  </div>
                  <h3 className="profile-username text-center flous-police-one flous-input-color">{user.first_name} {user.last_name}</h3>
                  <p className="text-center flous-police-one flous-input-color">{user.self_analyst ? 'Self Analyst' : (user.role === 'Customer'? 'Client' : (user.role === 'AssociateAdmin'? 'Administrator' : user.role))}</p>
                  <div type="button" className="mt-3 text-center flous-input-color" onClick={handleEditProfilePicture} data-toggle="modal" data-target="#newPicture">
                  {t("general.profile.updatepicture")}
                  </div>
                  <div type="button" className="mt-3 text-center text-red" data-toggle="modal" data-target="#deletePicture">
                  {t("general.profile.deletepicture")}
                  </div>
                  <ChangeProfilePictureModal
                    isOpen={isOpen} 
                    onClose={handleClose} 
                    onSave={handleSavePicture} 
                    onFileSelected={handleFileUpload}
                  />
                  <DeleteProfilePictureModal
                    onDelete={handleDeletePicture}
                  />
                </div>
              </div>
            </div>
            {/* /.col */}
            <div className="col-md-9">
              <div className="card elevation-2">
                <div className="elevation-1 p-2 mt-3 mx-3" style={{ borderRadius: '15px' }}>
                    <div className='row'>
                        <div className='col'><div className={`btn btn-block ${activeBtn === 'Account' ? 'flous-bg-thirty text-bold flous-input-color' : 'btn-light'}`} onClick={() => handleBtnClick('Account')}>{t("enterprise.profile.account.title")}</div></div>
                        <div className='col'><div className={`btn btn-block ${activeBtn === 'Profile' ? 'flous-bg-thirty text-bold flous-input-color' : 'btn-light'}`} onClick={() => handleBtnClick('Profile')}>{t("enterprise.profile.company.title")}</div></div>
                        <div className='col'><div  className={`btn btn-block ${activeBtn === 'Settings' ? 'flous-bg-thirty text-bold flous-input-color' : 'btn-light'}`} onClick={() => handleBtnClick('Settings')}>{t("general.profile.sections.settings.title")}</div></div>
                        <div className='col'><div  className={`btn btn-block ${activeBtn === 'Pricing' ? 'flous-bg-thirty text-bold flous-input-color' : 'btn-light'}`} onClick={() => handleBtnClick('Pricing')}>{t("general.profile.sections.pricing.title")}</div></div>
                        <div className='col'><div  className={`btn btn-block ${activeBtn === 'Payment' ? 'flous-bg-thirty text-bold flous-input-color' : 'btn-light'}`} onClick={() => handleBtnClick('Payment')}>{t("general.profile.sections.payment.title")}</div></div>
                    </div>
                </div>
                <div className="card-body">
                    {renderSection()}
                </div>
              </div>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
      </section>
      {/* /.content */}
    </>
  );
};

