import React from 'react';
import { Page, View, Text } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import styles from '../styles/pdfstyles';
import { mapStateToDescription } from '../../../../services/utils';

const FormDetailIntroductionPDF = ({ data }) => {

  const lang = localStorage.getItem('flouslang') || 'en';

  const { t } = useTranslation();
  
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.card}>
        <Text style={styles.title}>{t('client.forms.myforms.summary.title')}</Text>
        <View style={styles.separator} />
        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t('client.forms.myforms.summary.formTitle')}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{data.formTitle || ''}</Text>
          </View>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t('client.forms.myforms.summary.formCode')}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{data.formCode || ''}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t('client.forms.myforms.summary.evaluationCountry')}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{data.evaluationCountry || 'Canada'}</Text>
          </View>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t('client.forms.myforms.summary.creationDate')}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{data.creationDate || ''}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t('client.forms.myforms.summary.lastUpdate')}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{data.updateCompletedDate || ''}</Text>
          </View>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t('client.forms.myforms.summary.status')}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{mapStateToDescription(data.status, lang)}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t('client.forms.myforms.summary.isCoApplication')}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{data.isCoApplication ? `${t('forms.click.yes')}`: `${t('forms.click.no')}`}</Text>
          </View>
          <View style={styles.labelColumn} />
          <View style={styles.valueColumn} />
        </View>
      </View>
    </Page>
  );
};

export default FormDetailIntroductionPDF;

