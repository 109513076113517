import React from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import CountryShow from '../../../general/countryShow';
import { gender, mStatus, preferredLang } from '../../../../services/utils';
import styles from '../styles/pdfstyles';

export default function PersonalInfoResumePDF(props) {
  const { t } = useTranslation();
  const lang = localStorage.getItem('flouslang') || 'en';

  return (
    <Page size="A4" style={styles.page}>
      <Text style={styles.mainTitle}>{t("client.results.blockTitle.bloc1")}</Text>

      {/* Identification Section */}
      <View style={styles.card}>
        <Text style={styles.sectionTitle}>{t("forms.title.identification")}</Text>
        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.firstname")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.personalInformation.identification.firstname || 'N.A'}</Text>
          </View>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.lastname")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.personalInformation.identification.lastname || 'N.A'}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.dateofbirth")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.personalInformation.identification.dateofbirth || 'N.A'}</Text>
          </View>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.countryofbirth")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}><CountryShow value={props.personalInformation.identification.countryofbirth || 'N.A'} /></Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.cityofbirth")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.personalInformation.identification.cityofbirth || 'N.A'}</Text>
          </View>
        </View>
      </View>

      {/* Personal Details Section */}
      <View style={styles.card}>
        <Text style={styles.sectionTitle}>{t("forms.title.personaldetail")}</Text>
        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.genderIdentity")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{gender(props.personalInformation.personalDetail.genderIdentity || 'N.A', lang)}</Text>
          </View>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.nationality")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}><CountryShow value={props.personalInformation.personalDetail.nationality || 'N.A'} /></Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.maritalStatus")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{mStatus(props.personalInformation.personalDetail.maritalStatus || 'N.A', lang)}</Text>
          </View>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.preferredLanguage")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{preferredLang(props.personalInformation.preferredLanguage) || 'N.A'}</Text>
          </View>
        </View>
      </View>

      {/* Contact Information Section */}
      <View style={styles.card}>
        <Text style={styles.sectionTitle}>{t("forms.title.contactinfo")}</Text>
        <View style={styles.row}>
          <View style={{width: "20%"}}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.email")}</Text>
          </View>
          <View style={{width: "30%"}}>
            <Text style={styles.value}>{props.personalInformation.contactInfo.email || 'N.A'}</Text>
          </View>
          <View style={{width: "20%"}}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.phone")}</Text>
          </View>
          <View style={{width: "30%"}}>
            <Text style={styles.value}>{props.personalInformation.contactInfo.phone || 'N.A'}</Text>
          </View>
        </View>
      </View>

      {/* Additional Information Section */}
      <View style={styles.card}>
        <Text style={styles.sectionTitle}>{t("forms.title.addinfo")}</Text>
        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.netWorth")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.personalInformation.addInfo.netWorth || 'N.A'}</Text>
          </View>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.earningsHistory")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.personalInformation.addInfo.earningsHistory || 'N.A'}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.totalCurrentAssets")}</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>{props.personalInformation.addInfo.totalCurrentAssets || 'N.A'}</Text>
          </View>
          <View style={styles.labelColumn}>
            <Text style={styles.label}>{t("forms.formsquestion.personalInfo.provincialNomination")} Canada?</Text>
          </View>
          <View style={styles.valueColumn}>
            <Text style={styles.value}>
              {props.personalInformation.provincialNomination === 'Yes'
                ? t('forms.click.yes')
                : t('forms.click.no')}
            </Text>
          </View>
        </View>
      </View>
    </Page>
  );
}

