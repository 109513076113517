import { StyleSheet } from '@react-pdf/renderer';

const pdfStyles = StyleSheet.create({
  page: {
    paddingHorizontal: 20,
    paddingVertical: 50,
    backgroundColor: '#ffffff',
  },
  mainTitle: {
    fontSize: 20,
    fontFamily: 'Helvetica-Bold',
    color: '#0066CC',
    textAlign: 'center',
    marginBottom: 20,
  },
  card: {
    backgroundColor: '#f8f9ff',
    borderRadius: 8,
    padding: 16,
    paddingBottom: 5,
    marginBottom: 10,
    //marginTop: 5,
    width: '100%',
  },
  cardTitle: {
    fontSize: 14,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'center',
    marginBottom: 10,
  },
  experienceCard: {
    backgroundColor: '#ffffff',
    borderRadius: 4,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 10,
    borderBottom: '1px solid #e2e8f0',
  },
  experienceCardEntre: {
    backgroundColor: '#ffffff',
    borderRadius: 4,
    padding: 8,
    marginBottom: 8,
    border: '1px solid #e2e8f0',
  },
  sectionTitle: {
    fontSize: 16,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'center',
    marginBottom: 16,
    color: '#000000',
  },
  secondTitle: {
    fontSize: 12,
    fontFamily: 'Helvetica-Bold',
    marginBottom: 16,
    color: '#000000',
  },
  title: {
    fontSize: 16,
    marginBottom: 0,
    fontFamily: 'Helvetica-Bold',
  },
  row: {
    flexDirection: 'row',
    marginBottom: 12,
    gap: 12,
  },
  labelColumn: {
    width: '30%',
  },
  valueColumn: {
    width: '20%',
  },
  column70: {
    width: '70%',
  },
  column30: {
    width: '30%',
  },
  column50: {
    width: '50%',
  },
  label: {
    fontSize: 10,
    color: '#004AAD',
    fontFamily: 'Helvetica-Bold',
  },
  value: {
    fontSize: 10,
    padding: '6 8',
    backgroundColor: '#ffffff',
    borderRadius: 4,
    minHeight: 28,
    borderBottom: '1px solid #e2e8f0',
    fontFamily: 'Helvetica',
  },
  educationCard: {
    backgroundColor: '#ffffff',
    borderRadius: 4,
    padding: 8,
    marginBottom: 8,
    borderBottom: '1px solid #e2e8f0',
  },
  memberCard: {
    backgroundColor: '#ffffff',
    borderRadius: 4,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 5,
    marginTop: 5,
    borderBottom: '1px solid #e2e8f0',
  },
  memberRow: {
    flexDirection: 'row',
    marginBottom: 8,
    gap: 8,
  },
  memberLabel: {
    fontSize: 10,
    color: '#000000',
    fontFamily: 'Helvetica',
    flex: 1,
  },
  memberValue: {
    fontSize: 10,
    color: '#000000',
    fontFamily: 'Helvetica',
    flex: 1,
  },
  separator: {
    marginVertical: 10,
    borderBottom: '1 solid #ccc',
  },
  addressCard: {
    backgroundColor: '#ffffff',
    borderRadius: 4,
    padding: 8,
    marginBottom: 8,
    borderBottom: '1px solid #e2e8f0',
  },
});

export default pdfStyles;