import React from 'react';
import { Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

const styles = StyleSheet.create({
  page: {
    padding: 20,
    position: 'relative', // Nécessaire pour positionner la date en bas
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    paddingBottom: 10,
  },
  leftText: {
    fontSize: 14,
    fontWeight: 'bold',
    flex: 1,
  },
  rightText: {
    fontSize: 12,
    fontStyle: 'italic',
    textAlign: 'right',
    flex: 1,
  },
  logoContainer: {
    flex: 1, // Prend tout l'espace vertical restant
    justifyContent: 'center', // Centre verticalement
    alignItems: 'center', // Centre horizontalement
  },
  logo: {
    width: 200,
    height: 200,
    marginBottom: 20,
  },
  date: {
    position: 'absolute',
    bottom: 20, // Position en bas de la page
    left: 0,
    right: 0,
    textAlign: 'center', // Centré horizontalement
    fontSize: 12,
    color: 'gray',
  },
  cardTitle: {
    fontSize: 14,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'center',
    marginBottom: 10,
  },
});

export default function PartnerFirstPagePDF({ data }) {
  const { t } = useTranslation();
  const currentDate = new Date().toLocaleDateString();

  return (
    <Page size="A4" style={styles.page}>
      {/* En-tête */}
      <View style={styles.header}>
        <Text style={styles.leftText}>Flous Inc.</Text>
        <Text style={styles.rightText}>{data?.formTitle || (data?.formCode || 'Form Code N/A')}</Text>
      </View>

      {/* Conteneur du logo */}
      <View style={styles.logoContainer}>
        <Image style={styles.logo} src="/logo192.png" />
        <Text style={styles.cardTitle}>{t("forms.sections.partnerTitle")}</Text>
      </View>

      {/* Date en bas */}
      <Text style={styles.date}>Generated on: {currentDate}</Text>
    </Page>
  );
}
