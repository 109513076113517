// formStates.js
import { useState } from 'react';

export const usePartnerFormStates = () => {
  //const [activeBtn, setActiveBtn] = useState('Personal Information');
  //const [evaluationCountry, setevaluationCountry] = useState("");
  const [personalInformation, setPersonalInformation] = useState({
    identification: {
      firstname: '',
      lastname: '',
      dateofbirth: '',
      countryofbirth: '',
      cityofbirth: ''
    },
    personalDetail: {
      genderIdentity: '',
      nationality: '',
      maritalStatus: '',
      personIncluded: '',
      firstname: '',
      lastname: '',
      dateofbirth: '',
      relationStart: '',
      relationEnd: '',
      marriageDate: '',
      spouseDeath: '',
    },
    preferredLanguage: '',
    contactInfo: {
      email: '',
      phone: '',
    },
    addInfo: {
      netWorth: '',
      earningsHistory: '',
      totalCurrentAssets: '',
      provincialNomination: '',
    },
  });

  const [children, setChildren] = useState({
    dependantChildren: '',
    totalDependantChildren: '',
    childrenUnder22: '',
  });

  const [familyInfo, setFamilyInfo] = useState({
    totalSibling: 0,
    totalCanadianOrPResident: 0
  });

  const [familyMembers, setFamilyMembers] = useState([]);

  const [residences, setResidences] = useState({
    currentResidence: {
      country: '',
      provinceState: '',
      city: '',
      streetNumber: '',
      postalCode: '',
      startDate: '',
      haveStatus: '',
    },
    statusInCountry: {
      currentStatus: '',
    },
    immigrationDocument: {
      dateOfIssue: '',
      dateOfExpiration: '',
      dateOfEntry: '',
    },
    livedInDifferentAddress: '',
    previousAddresses: []
  });

  const [languageProficiency, setLanguageProficiency] = useState({
    // languageProficiency: '',
    firstOfficialLanguage: '',
    frenchLanguageProficiency: {
      frenchtest: '',
      frenchEducation: '',
      testType: '',
      testDate: '',
      speakingScore: '',
      listeningScore: '',
      writingScore: '',
      readingScore: '',
      speakingLevel: '',
      listeningLevel: '',
      writingLevel: '',
      readingLevel: '',
    },
    englishLanguageProficiency: {
      englishtest: '',
      englishEducation: '',
      testType: '',
      testDate: '',
      speakingScore: '',
      listeningScore: '',
      writingScore: '',
      readingScore: '',
      speakingLevel: '',
      listeningLevel: '',
      writingLevel: '',
      readingLevel: '',
    }
  });

  const [educationInCountry, setEducationInCountry] = useState({
    currentlyStudy: '',
    educationInCountry: '',
    applicationProcessed: '',
    degreeDiploma: {
      degreeDiploma: '',
      prgDegreeDiploma: '',
      startDate: '',
      endDate: '',
      pgwpEligibility: ''
    },
    institution: {
      institutionName: '',
      publicInstitution: '',
      languageOfInstruction: '',
      fullTimeStudent: '',
      generalFieldOfStudy: '',
      fieldOfStudy: '',
      studyDuration: '',
      address: '',
      country: 'CAN',
      provinceState: '',
      city: '',
    },
    diplomaRecognition: {
      equivalentProcessCompleted: ''
    },
    recognitionProvince: {
      provinceState: '',
    },
    provinceProcessed: [],
  });
  const [specialProgram, setSpecialProgram] = useState({
    homeCareProgram: '',
    proposeProgram: ''
  });
  const [allEducationInCountry, setAllEducationInCountry] = useState([]);

  const [educationOutsideCountry, setEducationOutsideCountry] = useState({
    educationOutsideCountry: '',
    educationDetail: {
      degreelevel: '',
      prgDegreeDiploma: '',
      generalFieldOfStudy: '',
      fieldOfStudy: '',
      programDuration: ''
    },
    dateofStudy: {
      startDate: '',
      endDate: ''
    },
    educationInstitution: {
      institutionName: '',
      languageOfInstruction: '',
      fullTimeStudent: '',
      address: '',
      country: '',
      provinceState: '',
      city: '',
    },
    educationalCredentialAssessment: {
      ecaObtained: '',
      issuingOrganization: '',
      recognizedGeneralCredential: '',
      recognizedCredential: '',
      recognizedDiplomaLevel: '',
    },
    previousCredentials: [],
    equivalencyProcessQuebec: {
      epqObtained: '',
      recognizedGeneralCredential: '',
      recognizedCredential: '',
      recognizedDiplomaLevel: '',
      provinceState: 'Québec'
    },
  });
  const [allEducationOutsideCountry, setAllEducationOutsideCountry] = useState([]);

  const [professionalExperience, setProfessionalExperience] = useState({
    professionalExperience: '',
    jobDetail: {
      jobTitle: '',
      isRegulated: false,
      selfEmployed: '',
      currentJob: '',
      startDate: '',
      endDate: '',
      authorization: '',
      obtainingAuthorization: '',
      jobInAIOrIT: '',
    },
    // jobDuration: {
    //  startDate: '',
    //  endDate: ''
    // },
    companyInfo: {
      companyName: '',
      hoursPerWeek: '',
      hourlyRate: '',
    },
    jobSpecifics: {
      paydayLoans: '',
      explicitContent: '',
      realEstate: ''
    },
    jobAddress: {
      country: '',
      provinceState: '',
      city: '',
      streetNumber: '',
      postalCode: ''
    }
  });
  const [allProfessionalExperience, setAllProfessionalExperience] = useState([]);

  const [professionalExpertise, setProfessionalExpertise] = useState({
    professionalExpertise: {
      workedSameProfession: '',
      exceptionalExpertise: '',
    },
    notableAchievements: {
      recognition: '',
    },
    impactofExpertise: {
      impact: '',
    }
  });

  const [jobOffer, setJobOffer] = useState({
    jobOffer: '',
    companyDetail: {
      country: 'CAN',
      provinceState: '',
      city: '',
      streetNumber: '',
      postalCode: ''
    },
    jobDetail: {
      dateOfIssued: '',
      currentJob: '',
      lmiaExempt: '',
      provinceValidated: ''
    },
    professionalDetail: {
      professionalField: '',
      jobInAISector: '',
      isRegulated: false,
      authorizationToPractice: '',
      processOfObtainingAuthorization: '',
      diplomaAuthorization: '',
      paydayLoans: '',
      explicitContent: '',
      realEstate: ''
    },
    jobCompensation: {
      hourlyRate: '',
      annualSalary: ''
    },
    companyInfo: {
      companyName: '',
      publicCompany: '',
      numberOfEmployed: '',
      annualGrossRevenue: '',
      foundingDate: '',
      primaryBusinessActivities: ''
    }
  });

  const [entrepreunarialExperience, setEntrepreunarialExperience] = useState({
    haveBusinesses: '',
    entrepreunarialExperience: {
      ownership: '',
      newcompany: '',
      takeoverBid: ''
    },
    otherManagementExperience: {
      managementExperience: '',
      managementStartDate: '',
      managementEndDate: ''
    },
    ownershipDetail: {
      ownershipType: '',
      companyName: '',
      ownershipPercentage: '',
      managementExperience: '',
      personalEquityInvestment: '',
      managementStartDate: '',
      managementEndDate: ''
    },
    companyActivites: {
      businessSector: '',
      paydayLoans: '',
      explicitContent: '',
      realEstate: ''
    },
    supportby: {
      receivedServiceOffer: ''
    },
    innovativeActivities: {
      innovative: ''
    },
    companyFinances: {
      companyValue: '',
      startupExpenses: '',
      operatingExpenses: ''
    },
    roleAndExperience: {
      position: '',
      startDate: '',
      operatingPeriod: ''
    },
    companyDetails: {
      establishmentDate: '',
      country: 'CAN',
      provinceState: '',
      city: '',
      streetNumber: '',
      postalCode: '',
      visitList: [],
      visitDate: '',
      visitedCity: '',
      haveVisitBC: ''
    }
  });
  
  const [allEntrepreneurialExperience, setAllEntrepreneurialExperience] = useState([]);

  const [summary, setSummary] = useState({
    personalInformation: false,
    familyMembers: false,
    residences: false,
    languageProficiency: false,
    educationInCountry: false,
    educationOutsideCountry: false,
    professionalExperience: false,
    professionalExpertise: false,
    jobOffer: false,
    entrepreunarialExperience: false
  });

  return {
    //activeBtn, setActiveBtn,
    //evaluationCountry, setevaluationCountry,
    personalInformation, setPersonalInformation,
    children, setChildren,
    familyInfo, setFamilyInfo,
    familyMembers, setFamilyMembers,
    residences, setResidences,
    languageProficiency, setLanguageProficiency,
    educationInCountry, setEducationInCountry,
    specialProgram, setSpecialProgram,
    allEducationInCountry, setAllEducationInCountry,
    educationOutsideCountry, setEducationOutsideCountry,
    allEducationOutsideCountry, setAllEducationOutsideCountry,
    professionalExperience, setProfessionalExperience,
    allProfessionalExperience, setAllProfessionalExperience,
    professionalExpertise, setProfessionalExpertise,
    jobOffer, setJobOffer,
    entrepreunarialExperience, setEntrepreunarialExperience,
    allEntrepreneurialExperience, setAllEntrepreneurialExperience,
    summary, setSummary,

  };
};
