import React from "react";
import Select from "react-select";
import CountrySelect from "../../../../general/CountrySelect";
import RequiredIndicator from "../../../../general/requiredIndicator";
import ProvinceSelect from "../../../../general/ProvinceSelect";
import { countriesWithProvinces } from "../../../../../services/utils";
import CitySelect from "../../../../general/CitySelect";
import { useTranslation } from "react-i18next";

const FamilyMemberForm = ({ 
    formData, 
    errors, 
    relationships,
    allowedRelationships,
    handleChange, 
    handleCityChange, 
    handleProvinceStateChange, 
    handleCountryChange, 
    handleNationalityChange, 
    handleAddMember, 
    handleCancelMember, 
}) => {
    
    const { t } = useTranslation();

    const filteredRelationships = relationships.filter(rel => allowedRelationships.includes(rel.value))

    return (
        <div className="">
            <div className="mt-2">
                <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
                    
                    {errors.formData && (
                        <>
                            <p style={{ marginTop: '20px', padding: '10px', color: 'red'}}>
                                {errors.formData}
                            </p>
                        </>
                    )}
                    <div className="row p-2">
                        <p className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.firstname")} <RequiredIndicator /></p>
                        <div className="col-sm-6">
                            <input
                                type="text"
                                id="firstname"
                                name="firstname"
                                className="form-control rounded-pill flous-input-color"
                                onChange={handleChange}
                                value={formData.firstname}
                            />
                        </div>
                    </div>
                    <div className="row p-2">
                        <p className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.lastname")} <RequiredIndicator /></p>
                        <div className="col-sm-6">
                            <input
                                type="text"
                                id="lastname"
                                name="lastname"
                                className="form-control rounded-pill flous-input-color"
                                onChange={handleChange}
                                value={formData.lastname}
                            />
                        </div>
                    </div>
                    <div className="row p-2">
                        <p className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.nationality")} <RequiredIndicator /></p>
                        <div className="col-sm-6">
                            <CountrySelect
                                value={formData.nationality}
                                onChange={handleNationalityChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="row p-2">
                        <p className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.dateofbirth")} <RequiredIndicator /></p>
                        <div className="col-sm-6">
                            <input
                                type="date"
                                id="birthday"
                                name="birthday"
                                className="form-control rounded-pill flous-input-color"
                                onChange={handleChange}
                                placeholder='YYYY/MM/DD'
                                value={formData.birthday} max={new Date().toISOString().split("T")[0]}
                            />
                        </div>
                    </div>
                    <div className="row p-2">
                        <p className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.deathday")}</p>
                        <div className="col-sm-6">
                            <input
                                type="date"
                                id="deathday"
                                name="deathday"
                                className="form-control rounded-pill flous-input-color"
                                onChange={handleChange}
                                value={formData.deathday} max={new Date().toISOString().split("T")[0]}
                            />
                        </div>
                    </div>
                    
                    <div className="row p-2">
                        <p className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.familyRelationship")} <RequiredIndicator /></p>
                        <div className="col-sm-6">
                            <Select
                                id="familyRelationship"
                                name="familyRelationship"
                                className="react-select-container rounded-pill flous-input-color"
                                classNamePrefix="react-select"
                                value={filteredRelationships.find((option) => option.value === formData.familyRelationship)}
                                onChange={(selectedOption) => handleChange({ target: { name: 'familyRelationship', value: selectedOption ? selectedOption.value : '' } })}
                                options={filteredRelationships}
                                placeholder={`${t('forms.formsquestion.placeholder.option')}`}
                                isSearchable
                                isClearable
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        width: '100%',
                                        borderRadius: '20px',
                                        borderColor: '#ccc',
                                    }),
                                }}
                                required
                            />
                        </div>
                    </div>
                    {(formData.familyRelationship !== '') && (
                    <div className='row p-2'>
                        <p className='col-sm-10 col-form-label'>{t("forms.formsquestion.familyMembers.permanentOrCan")}<RequiredIndicator /></p>
                        <div className='col-sm-2'>
                        <div className='row'>
                            <div className="col form-check">
                            <input 
                                type="radio"
                                className="form-check-input"
                                id="permanentYes"
                                name="permanent"
                                value="Yes"
                                onChange={handleChange}
                                checked={formData.permanent === "Yes"}
                            />
                            <label className="form-check-label" htmlFor="permanentYes">{t("forms.click.yes")}</label>
                            </div>
                            <div className="col form-check">
                            <input 
                                type="radio"
                                className="form-check-input"
                                id="permanentNo"
                                name="permanent"
                                value="No"
                                onChange={handleChange}
                                checked={formData.permanent === "No"}
                            />
                            <label className="form-check-label" htmlFor="permanentNo">{t("forms.click.no")}</label>
                            </div>
                        </div>
                        </div>
                    </div>)}
                </div>
                {(formData.familyRelationship !== '') && ( 
                <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
                    <div className='row p-2'>
                        <p className='col-sm-10 col-form-label'>{t("forms.formsquestion.familyMembers.liveWith")}<RequiredIndicator /></p>
                        <div className='col-sm-2'>
                        <div className='row'>
                            <div className="col form-check">
                            <input 
                                type="radio"
                                className="form-check-input"
                                id="liveWithYes"
                                name="liveWith"
                                value="Yes"
                                onChange={handleChange}
                                checked={formData.liveWith === "Yes"}
                            />
                            <label className="form-check-label" htmlFor="liveWithYes">{t("forms.click.yes")}</label>
                            </div>
                            <div className="col form-check">
                            <input 
                                type="radio"
                                className="form-check-input"
                                id="liveWithNo"
                                name="liveWith"
                                value="No"
                                onChange={handleChange}
                                checked={formData.liveWith === "No"}
                            />
                            <label className="form-check-label" htmlFor="liveWithNo">{t("forms.click.no")}</label>
                            </div>
                        </div>
                        </div>
                    </div>
                    {(formData.liveWith === "No") && (
                    <>
                    <div className="row p-2">
                        <p className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.country")}<RequiredIndicator /></p>
                        <div className="col-sm-6">
                            <CountrySelect
                                value={formData.country}
                                onChange={handleCountryChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="row p-2">
                        <p className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.provinceState")}<RequiredIndicator /></p>
                        <div className="col-sm-6">
                            {countriesWithProvinces.includes(formData.country) ? (
                                <ProvinceSelect
                                    value={formData.provinceState}
                                    onChange={handleProvinceStateChange}
                                    country={formData.country}
                                    required
                                />
                            ) : (
                                <input
                                    type="text"
                                    className="form-control rounded-pill flous-input-color"
                                    id="provinceState"
                                    name="provinceState"
                                    value={formData.provinceState}
                                    onChange={handleChange}
                                    placeholder={`${t('forms.formsquestion.placeholder.province')}`}
                                />
                            )}
                        </div>
                    </div>
                    <div className="row p-2">
                        <p className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.city")}<RequiredIndicator /></p>
                        <div className="col-sm-6">
                            {countriesWithProvinces.includes(formData.country) ? (
                                <CitySelect
                                    value={formData.city}
                                    onChange={handleCityChange}
                                    province={formData.provinceState}
                                    required
                                />
                            ) : (
                                <input
                                    type="text"
                                    className="form-control rounded-pill flous-input-color"
                                    id="city"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                    placeholder={`${t('forms.formsquestion.placeholder.city')}`}
                                />
                            )}
                        </div>
                    </div>
                    <div className="row p-2">
                        <p className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.streetNumber")} <RequiredIndicator /></p>
                        <div className="col-sm-6">
                            <input
                                type="text"
                                id="streetNumber"
                                name="streetNumber"
                                className="form-control rounded-pill flous-input-color"
                                onChange={handleChange}
                                value={formData.streetNumber}
                                placeholder={`${t('forms.formsquestion.placeholder.streetNumber')}`}
                            />
                        </div>
                    </div>
                    <div className="row p-2">
                        <p className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.postalCode")}</p>
                        <div className="col-sm-6">
                            <input
                                type="text"
                                id="postalCode"
                                name="postalCode"
                                className="form-control rounded-pill flous-input-color"
                                onChange={handleChange}
                                value={formData.postalCode}
                                placeholder={`${t('forms.formsquestion.placeholder.codePostal')}`}
                            />
                        </div>
                    </div>
                    {(formData.country === 'CAN') && (
                        <div className="row p-2">
                            <p className="col-sm-6 col-form-label">{t("forms.formsquestion.familyMembers.startDate")} <RequiredIndicator /></p>
                            <div className="col-sm-6">
                                <input
                                    type="date"
                                    id="startDate"
                                    name="startDate"
                                    className="form-control rounded-pill flous-input-color"
                                    onChange={handleChange}
                                    value={formData.startDate}
                                    placeholder="Start Date"
                                />
                            </div>
                        </div>
                    )}
                    </>
                    )}
                </div>)}
            </div>
                
            <div className='d-flex justify-content-center'>
                <div className='col-sm-10'>
                    <div className='d-flex justify-content-between'>
                        <input type="button" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value={`${t("forms.addMoreButton.saveFamilyMember")}`} onClick={handleAddMember}/>
                        <input type="button" className="col-6 btn btn-block btn-secondary rounded-pill mx-1" value={`${t("forms.addMoreButton.cancel")}`} onClick={()=>{handleCancelMember()}}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FamilyMemberForm;
